// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CompanyField_clickable__zeRHl {\n    padding: 10px;\n    border-radius: 5px;\n    background-color: var(--light);\n    gap: 8px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Company/CompanyField.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,8BAA8B;IAC9B,QAAQ;AACZ","sourcesContent":[".clickable {\n    padding: 10px;\n    border-radius: 5px;\n    background-color: var(--light);\n    gap: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"clickable": "CompanyField_clickable__zeRHl"
};
export default ___CSS_LOADER_EXPORT___;
