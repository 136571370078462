import dayjs, { Dayjs } from "dayjs"
import { AppointmentCategoryVisibilityTypes } from "../Contexts/TaskContext/TaskContext"

export interface IAppointment extends ICreateAppointment {
    id: number
}

export interface ICreateAppointment {
    authorId?: number
    organizationId?: number
    start?: Dayjs
    end?: Dayjs
    createdDate?: Dayjs
    description?: string
    title?: string
    invitedOrganizationIds?: Array<number>
    invitedPersonIds?: Array<number>
    invitedUserIds?: Array<number>
    notifyParticipants?: boolean
    appointmentCategory?: number
    visibility?: number
}

export const defaultAppointment: IAppointment = {
    id: 0,
    authorId: 0,
    organizationId: 0,
    start: dayjs(),
    end: dayjs(),
    createdDate:dayjs(),
    description: "",
    title: "",
    invitedOrganizationIds: [],
    invitedPersonIds: [],
    invitedUserIds: [],
    notifyParticipants: false,
    appointmentCategory: -1,
    visibility: AppointmentCategoryVisibilityTypes.Organization,
}

export interface ICreateAppointmentCategory {
    name?: string
    color?: string
    visibility?: number
    createdBy?: number
    organizationId?: number
}

export interface IAppointmentCategory extends ICreateAppointmentCategory {
    id?: number
}

export const defaultAppointmentCategory: IAppointmentCategory = {
    id: 0,
    name: '',
    color: '#000000',
    visibility: 0,
    createdBy: 0,
    organizationId: 0
}

export interface IAppointmentVisibility {
    id?: number,
    visibility?: number,
    name?: string
}

export const appointmentVisibilityList: IAppointmentVisibility[] = [
    { id: 0, visibility: AppointmentCategoryVisibilityTypes.Private, name: 'Privat' },
    { id: 1, visibility: AppointmentCategoryVisibilityTypes.Organization, name: 'Organisation' }
]