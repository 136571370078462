import { IDocument } from "../Contexts/DocumentContext/DocumentContext";

export function formatBytes(bytes: number, decimals = 1) {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toFixed(dm) + ' ' + sizes[i];
}


export function totalFileSizeBytes(array: Array<IDocument>, decimals = 1) {
    if (array.length === 0) return '0 Bytes';
    let totalSize = 0;

    array.forEach((document) => {
        totalSize += (document.fileSize ?? 0);
    });

    return formatBytes(totalSize, decimals);
}

export function getMaxSpaceOfSubscription(subscription: string) {
    switch (subscription) {
        case "eCockpit Free":
            return 1073741824;
        case "eCockpit Business":
        case "eCockpit Business (Early-Bird)":
            return 16106127360;
        case "eCockpit Business Pro":
        case "eCockpit Business Pro (Early-Bird)":
            return 107374182400;
        default:
            return 1073741824;
    }
}