import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import PostScriptTimeForm from '../../Forms/PostScriptTimeForm'



function TimeFilterDialog({ open, onClose, object, setObject, submitChange }: any) {
  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      submitChange()
      handleClose()
    } catch (error) {
    } finally {
      setLoading(false)
    }

  }

  const handleClose = async () => {
    setLoading(false)
    setObject((old: any) => ({ ...old, open: false }))
    if (onClose) {
      onClose()
    }
  }

  return (
    <Modal
      open={open}
      title={"Filter meine Zeiterfassung"}
      onClose={handleClose}
    >
      <PostScriptTimeForm
        object={object}
        /* @ts-ignore */
        setObject={setObject}
      />
      <CTAButton
        style={{
          marginTop: "20px"
        }}
        title={loading ? <CircularProgress size={18} color='inherit' /> : "Übernehmen"}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
      />
    </Modal>
  )
}

export default TimeFilterDialog