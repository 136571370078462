import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { AppointmentCategoryVisibilityTypes, DefaultAppointmentCategoryIds, useTasks } from "../../Contexts/TaskContext/TaskContext";
import { IHoliday, useTimeTracking } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { IAppointment } from "../../Interfaces/Appointment";
import { ITask } from "../../Interfaces/Task";
import styles from "./Calendar.module.css";
import { calculateContrastColor } from "./ColorUtils";
import CreateAppointmentModal from "./CreateAppointmentModal";

interface dayProps {
    day: any,
    rowIndex?: number,
    colIndex?: number,
    monthRows?: number,
    selectedView?: string,
    showAppointmentInfoModal?: boolean,
    setShowAppointmentInfoModal?: Dispatch<SetStateAction<boolean>>
    setClickedAppointment?: Dispatch<SetStateAction<IAppointment>>
    showTaskInfoModal?: boolean,
    setShowTaskInfoModal?: Dispatch<SetStateAction<boolean>>
    setClickedTask?: Dispatch<SetStateAction<ITask>>
    showHolidayInfoModal?: boolean,
    setShowHolidayInfoModal?: Dispatch<SetStateAction<boolean>>
    setClickedHoliday?: Dispatch<SetStateAction<IHoliday>>
    privateCheckedCategories?: { [categoryId: number]: boolean };
    organizationCheckedCategories?: { [categoryId: number]: boolean };
    //hour: number; // Die Stunde für diesen Tag
}

export default function DayOfMonth({ day, rowIndex, colIndex, monthRows, selectedView, setShowAppointmentInfoModal, setClickedAppointment, setShowTaskInfoModal, setClickedTask, showHolidayInfoModal, setShowHolidayInfoModal, setClickedHoliday, privateCheckedCategories, organizationCheckedCategories }: dayProps) {

    const [dayAppointments, setDayAppointments] = useState<any>([]);
    const [dayTasks, setDayTasks] = useState<any>([]);
    const [dayHolidays, setDayHolidays] = useState<any>([])

    const { appointments, setSelectedDay, monthIndex, allTasksUnformated, appointmentCategories } = useTasks();
    const { staff } = useStaff()
    const { persons } = useContacts()
    const { organizationHolidays } = useTimeTracking()
    const [open, setOpen] = useState(false);
    dayjs.extend(isBetween);

    useEffect(() => {
        const currDayTasks = allTasksUnformated?.filter((t) =>
            t.scheduledDate != null &&
            dayjs(t.scheduledDate).format("DD-MM-YY") === day.format("DD-MM-YY")
        );
        if (currDayTasks != null && currDayTasks.length > 1) {
            currDayTasks.sort((a, b) => {
                if (a.scheduledDate != null && b.scheduledDate != null) {
                    return a.scheduledDate.valueOf() - b.scheduledDate.valueOf();
                }
                return 0;
            })
        }
        setDayTasks(currDayTasks);
    }, [allTasksUnformated, day])

    useEffect(() => {
        const currDayAppointments = appointments?.filter(
            (a) =>
                dayjs(a.start).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                dayjs(a.end).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                day.isBetween(a.start, a.end, 'day')
        );

        if (currDayAppointments != null && currDayAppointments.length > 1) {
            currDayAppointments.sort((a, b) => {
                if (a.start != null && b.start != null && a.end != null && b.end != null) {
                    const startComparison = (a.start).valueOf() - (b.start).valueOf()
                    if (startComparison !== 0) {
                        return startComparison
                    }
                }
                return 0;
            })
        }
        setDayAppointments(currDayAppointments);
    }, [appointments, day])

    useEffect(() => {
        const currDayHolidays = organizationHolidays?.filter((h) =>
        ((h.accepted && !h.canceled && !h.rejected) &&
            (dayjs(h.startDate).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                dayjs(h.endDate).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                day.isBetween(h.startDate, h.endDate, 'day'))))

        if (currDayHolidays != null && currDayHolidays.length > 1) {
            currDayHolidays.sort((a, b) => {
                if (a.startDate != null && b.startDate != null && a.endDate != null && b.endDate != null) {
                    const startComparison = new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
                    if (startComparison !== 0) {
                        return startComparison
                    }
                }
                return 0
            })
        }
        setDayHolidays(currDayHolidays)
    }, [organizationHolidays, day])

    function getCurrentDayClass() {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
            ? {
                //styling to point out current day
                backgroundColor: 'var(--brand)',
                color: "white",
                borderRadius: '100%',
                width: '1.75rem'
            }
            : {}
    }
    return (
        <>
            <div className={styles.calendarCellMonth}
                style={{
                    //styling so only top right and bottom right have rounded corners on the outside
                    borderTopRightRadius: (rowIndex === 0 && colIndex === 6) ? "var(--borderRadius)" : "0px",
                    borderBottomRightRadius: (monthRows && rowIndex === monthRows - 1 && colIndex === 6) ? "var(--borderRadius)" : "0px",
                    //styling so days outside of currently displayed month are grey
                    backgroundColor: selectedView === "Monat" ? ((dayjs(new Date(dayjs().year(), monthIndex)).format("MM-YYYY") === day.format("MM-YYYY")) ? "white" : "whitesmoke") : "white"
                }}
            >
                <header style={{ display: 'flex', flexDirection: 'column', alignItems: "center", cursor: 'pointer', borderBottom: selectedView === "Woche" ? '1px solid var(--light-contrast)' : "", marginBottom: '12px' }}
                    onClick={() => {
                        setSelectedDay!(day)
                        setOpen(true)
                    }}>

                    {rowIndex === 0 && (
                        <div className={styles.dayTextContent}>{day.format('ddd')}</div>
                    )}
                    <div className={styles.dayNumericContent} style={getCurrentDayClass()}>{day.format('DD')}</div>
                </header>
                <div style={{ overflow: 'auto' }}
                >
                    {dayHolidays?.map((h: IHoliday, idx: any) => {
                        var category = appointmentCategories?.find((ac) => ac.id === DefaultAppointmentCategoryIds.Holiday)
                        return (
                            <React.Fragment key={"holidayFragment" + idx}>
                                {(organizationCheckedCategories && (organizationCheckedCategories[DefaultAppointmentCategoryIds.Holiday] === true)) &&
                                    <div className={styles.appointmentMonth}
                                        style={{
                                            cursor: 'pointer',
                                            background: category?.color,
                                            color: calculateContrastColor('#32b13d'),
                                            overflow: selectedView === "Monat" ? "hidden" : "show",
                                            whiteSpace: selectedView === "Monat" ? "nowrap" : "break-spaces",
                                            textOverflow: selectedView === "Monat" ? "ellipsis" : ""
                                        }}
                                        key={"task" + idx}
                                        onClick={() => {
                                            setClickedHoliday!(h)
                                            setShowAppointmentInfoModal!(false)
                                            setShowTaskInfoModal!(false)
                                            setShowHolidayInfoModal!(true)
                                        }}>
                                        {staff.find((s) => s.id === h.userId)?.person?.completeName ?? staff.find((s) => s.id === h.userId)?.username}
                                        {dayjs(new Date(h.startDate)).format("DD-MM-YYYY") !== dayjs(new Date(h.endDate)).format("DD-MM-YYYY") &&
                                            <>
                                                <span> (</span>
                                                {(dayjs(h.endDate).diff(dayjs(h.startDate), 'day') - dayjs(h.endDate).diff(dayjs(day), 'day')) + 1}/{(dayjs(h.endDate).diff(dayjs(h.startDate), 'day')) + 1}

                                                <span>)</span>
                                            </>}
                                    </div>}
                            </React.Fragment>)
                    })}

                    {dayAppointments?.map((a: any, idx: any) => {
                        var category = appointmentCategories?.find(ac => ac.id === a.appointmentCategory)
                        //if appointment has no category check visibility to assign it the correct id (private/organization)
                        if (category == null && a.visibility === AppointmentCategoryVisibilityTypes.Organization) {
                            category = appointmentCategories?.find(ac => ac.id === DefaultAppointmentCategoryIds.OrganizationNoCategory)
                        } else if (category == null && a.visibility === AppointmentCategoryVisibilityTypes.Private) {
                            category = appointmentCategories?.find(ac => ac.id === DefaultAppointmentCategoryIds.PrivateNoCategory)
                        }
                        return (
                            <React.Fragment key={"appointmentFragment" + idx}>
                                {/* check to determine if appointments should be displayed depending on checked category boxes*/}
                                {(((organizationCheckedCategories && privateCheckedCategories) && ((a.appointmentCategory == null && organizationCheckedCategories[DefaultAppointmentCategoryIds.OrganizationNoCategory] === true) || (organizationCheckedCategories[a.appointmentCategory] === true) || (privateCheckedCategories[a.appointmentCategory] === true)))) &&
                                    <div className={styles.appointmentMonth}
                                        style={{
                                            cursor: 'pointer',
                                            background: category?.color,
                                            color: category?.color ? calculateContrastColor(category.color) : "",
                                            overflow: selectedView === "Monat" ? "hidden" : "show",
                                            whiteSpace: selectedView === "Monat" ? "nowrap" : "break-spaces",
                                            textOverflow: selectedView === "Monat" ? "ellipsis" : ""

                                        }}
                                        key={"appointment" + idx}
                                        onClick={() => {
                                            setClickedAppointment!(a);
                                            setShowTaskInfoModal!(false)
                                            setShowHolidayInfoModal!(false)
                                            setShowAppointmentInfoModal!(true);
                                        }}>
                                        {a.title}
                                        {/* If appointment doesnt end on same day as it starts it is a multiday appointment*/}
                                        {new Date(a.start).getDate() - new Date(a.end).getDate() !== 0
                                            ?
                                            <>
                                                <span> (Tag </span>
                                                {(dayjs(a.end).diff(dayjs(a.start), 'day') - dayjs(a.end).diff(dayjs(day), 'day')) + 1}/{(dayjs(a.end).diff(dayjs(a.start), 'day')) + 1}
                                                <span>)</span>
                                            </>
                                            :
                                            // If it isn't a multiday appointment and selected view is weekview -> display timespan of appointment
                                            selectedView === "Woche" && <span> ({dayjs(new Date(a.start)).format("HH:mm")}-{dayjs(new Date(a.end)).format("HH:mm")})</span>
                                        }
                                    </div>}
                            </React.Fragment>
                        )
                    })}
                    {dayTasks?.map((t: any, idx: any) => {
                        var category = appointmentCategories?.find((ac) => ac.id === DefaultAppointmentCategoryIds.Tasks)
                        return (
                            <React.Fragment key={"taskFragment" + idx}>
                                {(organizationCheckedCategories && (organizationCheckedCategories[DefaultAppointmentCategoryIds.Tasks] === true)) &&
                                    <div className={styles.appointmentMonth}
                                        style={{
                                            cursor: 'pointer',
                                            background: category?.color,
                                            color: calculateContrastColor('#FB5A5A'),
                                            overflow: selectedView === "Monat" ? "hidden" : "show",
                                            whiteSpace: selectedView === "Monat" ? "nowrap" : "break-spaces",
                                            textOverflow: selectedView === "Monat" ? "ellipsis" : ""
                                        }}
                                        key={"task" + idx}
                                        onClick={() => {
                                            setClickedTask!(t)
                                            setShowAppointmentInfoModal!(false)
                                            setShowHolidayInfoModal!(false)
                                            setShowTaskInfoModal!(true)
                                        }}>
                                        {t.title}
                                    </div>}
                            </React.Fragment>)
                    })}


                </div>
                <div style={{ flex: '1 0 0%', cursor: 'pointer', minHeight: 'auto' }}
                    onClick={() => {
                        setSelectedDay!(day)
                        setOpen(true)
                    }}>
                </div>
            </div >
            <CreateAppointmentModal open={open}
                setOpen={setOpen}
            />
        </>
    )
}