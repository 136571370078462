import { AddRounded, Edit } from "@mui/icons-material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { defaultProduct, IProduct, IProductDTO, useProducts } from "../../Contexts/ProductContext/ProductProvider";
import Card from "../Card/Card";
import EditProductDataForm from "../Forms/EditProductDataForm";
import { productFormModes } from "../../Pages/Verwaltung/Produkte";

export interface props {
    height?: string
    width?: string
    order?: string
}

interface IProductDataCardProps extends props {
    clickedProduct: IProductDTO
    setClickedProduct: Dispatch<SetStateAction<IProductDTO>>
    setFormMode?: Dispatch<SetStateAction<productFormModes>>
    formMode?: productFormModes
}

export const validateProductForm = (product: IProduct, setError: Dispatch<SetStateAction<any>>) => {
    let valid = true;

    if ((product.title?.length ?? 0) < 1) {
        valid = false;
        setError((old: any) => ({ ...old, titleError: true }))
    } else {
        setError((old: any) => ({ ...old, titleError: false }))
    }

    if (product.cost === undefined || isNaN(product.cost)) {
        valid = false;
        setError((old: any) => ({ ...old, errorCost: true }))
    } else {
        setError((old: any) => ({ ...old, errorCost: false }))
    }

    if (product.ust === undefined || isNaN(product.ust)) {
        valid = false;
        setError((old: any) => ({ ...old, errorUst: true }))
    } else {
        setError((old: any) => ({ ...old, errorUst: false }))
    }

    return valid;
}

export default function ProductDataCard({ width, height, order, clickedProduct, setClickedProduct, formMode, setFormMode }: IProductDataCardProps) {
    const [product, setProduct] = useState<IProductDTO>(defaultProduct);

    const productContext = useProducts();

    const [error, setError] = useState<any>({});

    const handleSubmit = () => {
        switch (formMode) {
            case "createProduct":
                if (validateProductForm(product, setError)) {
                    productContext.addProduct(product);
                    setProduct(defaultProduct);
                }
                break;
            case "editProduct":
                if (validateProductForm(clickedProduct!, setError)) {
                    productContext.updateProduct(clickedProduct);
                    setProduct(defaultProduct);
                }
                break;
            default:
                if (validateProductForm(product, setError)) {
                    productContext.addProduct(product);
                    setProduct(defaultProduct);
                }
                break;
        }


    }

    useEffect(() => {
        console.log(formMode)
    }, [formMode]);

    return (
        <Card
            title={formMode === "editProduct" ? 'Produkt bearbeiten' : 'Produkt erstellen'}
            style={{ width, height, order }}
            fullHeightBody={true}
            right={() => <div></div>}
            cta={true}
            ctaTitle={formMode === "editProduct" ? 'Speichern' : 'Produkt erstellen'}
            buttonIcon={formMode === "editProduct" ? <Edit fontSize='small' /> : <AddRounded fontSize='small' />}
            ctaFunction={handleSubmit}
        >
            {
                formMode === "createProduct" ? (
                    <EditProductDataForm
                        object={product}
                        setObject={setProduct}
                        errorVariables={error}
                        mode={formMode}
                        setFormMode={setFormMode}
                    />
                )
                    : formMode === "editProduct" ? (
                        <EditProductDataForm
                            object={clickedProduct}
                            setObject={setClickedProduct}
                            errorVariables={error}
                            mode={formMode}
                            setFormMode={setFormMode}
                        />
                    )
                        : <EditProductDataForm
                            object={product}
                            setObject={setProduct}
                            errorVariables={error}
                            mode={formMode}
                            setFormMode={setFormMode}
                        />
            }

        </Card >
    )
}