import React from 'react'

const OrganizationRightsReducer = (state: any, action: any) => {
  switch (action.type) {
    default:
      return {
        ...state
      }
  }
}

export default OrganizationRightsReducer