import React, { useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import Card from '../Card/Card'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { LinearProgress } from '@mui/material'

function ExpectedTaxCard({ width, height, order }: card) {

  const { accounting } = useBills();

  return (
    <Card
      style={{ height, width, order }}
      title="Erwartete Steuerlast"
    >
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "1em" }}>
        <span style={{ fontWeight: "600" }}>Steuer</span>
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <LinearProgress style={{ height: "0.75em" }} variant="determinate" color='success' value={accounting?.expectedTax === 0 ? 100 : (accounting?.expectedTax! / (accounting?.profit! - accounting?.loss!)) * 100} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>Einkommen</span>
          <span style={{ fontWeight: "600" }}>Steuer</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>{(accounting?.profit! - accounting?.loss!)?.toFixed(2)} €</span>
          <span style={{ fontWeight: "600" }}>{accounting?.expectedTax?.toFixed(2)} €</span>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "1em" }}>
        <span style={{ fontWeight: "600" }}>Sozialversicherung</span>
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <LinearProgress style={{ height: "0.75em" }} variant="determinate" color='success' value={((accounting?.profit ?? 0) - (accounting?.loss ?? 0)) < accounting?.expectedSocialSecurity! ? (((accounting?.profit ?? 0) - (accounting?.loss ?? 0)) / accounting?.expectedSocialSecurity!) * 100 : (1 - ((accounting?.expectedSocialSecurity! / (((accounting?.profit ?? 0) - (accounting?.loss ?? 0)))))) * 100} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>Einkommen</span>
          <span style={{ fontWeight: "600" }}>Abgaben</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>{(accounting?.profit! - accounting?.loss!)?.toFixed(2)} €</span>
          <span style={{ fontWeight: "600" }}>{accounting?.expectedSocialSecurity?.toFixed(2)} €</span>
        </div>
      </div>
    </Card>
  )
}

export default ExpectedTaxCard