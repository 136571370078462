import { AddRounded } from "@mui/icons-material"
import Card from "../../../Components/Card/Card"
import { ITagDTO, useContacts } from "../../../Contexts/ContactsContext/ContactsProvider"
import SelectableTagRow from "./SelectableTagRow"
import ButtonControl from "../../../Components/Buttons/ButtonControl/ButtonControl"
import { SetStateAction, useEffect, useState } from "react"
import { TagOverviewCardModes } from "./TagAdministration"
import EditCreateTagDialog from "../../../Components/Dialogs/EditCreateTagDialog/EditCreateTagDialog"
import Filter, { IFilterOption } from "../../../Components/Filter/Filter"

interface ITagOverviewCard {
    mode: TagOverviewCardModes
    setMode: React.Dispatch<SetStateAction<TagOverviewCardModes>>
    selectedTag: ITagDTO
    handleTagSelect: (tag: ITagDTO) => void
}

function TagOverviewCard({ mode, setMode, selectedTag, handleTagSelect }: ITagOverviewCard) {

    const { tags, addTag, removeTag } = useContacts()

    const [filteredTags, setFilteredTags] = useState<ITagDTO[]>([]);

    const buttons = [
        {
            body: (active: boolean) => <span>Personen</span>,
            value: "person"
        },
        {
            body: (active: boolean) => <span>Organisationen</span>,
            value: "org"
        }
    ]

    const filterOptions: IFilterOption[] = [
        { title: "Titel", valueKey: "title" },
        { title: "Beschreibung", valueKey: "description" }
    ]

    const [dialogOpen, setDialogOpen] = useState(false);

    return (
        <Card
            title="Tags"
            cta
            ctaTitle="Tag erstellen"
            ctaFunction={() => setDialogOpen(true)}
            buttonIcon={<AddRounded fontSize='small' />}
            right={() => <><ButtonControl buttons={buttons} setValue={setMode} value={mode} /> <Filter dataToFilter={filteredTags} setDataToFilter={setFilteredTags} originalData={tags} filterOptions={filterOptions}/></>}
        >
            <EditCreateTagDialog open={dialogOpen} onClose={() => setDialogOpen(false)} />
            {filteredTags.map((tag, index) => {
                return (<SelectableTagRow tag={tag} index={index} isSelected={selectedTag.id === tag.id} handleTagSelect={handleTagSelect} />)
            })}
        </Card>
    )
}

export default TagOverviewCard