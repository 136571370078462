import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styles from './BillsCard.module.css';

//Icons

import { useNavigate } from 'react-router-dom';
import { IPerson, useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { IIntervallDTO, ISubscriptionPosition, useIntervalls } from '../../Contexts/IntervallContext/IntervallProvider';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import { Collapse, Tooltip } from '@mui/material';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import { IntervallFormModes } from '../Forms/EditCreateIntervall';

interface props {
  intervall: IIntervallDTO
  isExpense?: boolean
  setClickedIntervall?: Dispatch<SetStateAction<IIntervallDTO>>
  setFormMode?: Dispatch<SetStateAction<IntervallFormModes>>
}

interface Document {
  id: number,
  title: string,
  description: string,
  fileEnding: string,
  fileId: string,
  base64: string,
  organizationId: number,
}

const IntervallRow = ({ intervall, isExpense, setClickedIntervall, setFormMode }: props) => {
  let navigate = useNavigate();

  const { getCompanyByID } = useContacts();
  const { persons } = useContacts();

  const intervallContext = useIntervalls();
  const dialogContext = useModals();

  const onPressEdit = () => {
    dialogContext.changeVisibility!("editCreateIntervall", true, { props: { object: intervall, mode: "edit" } });
  }

  const onPressDelete = () => {
    dialogContext.changeVisibility!("yesNoDialog", true, { props: { object: { modalTitle: "Intervallleistung löschen", submitFunction: () => intervallContext.removeIntervall(intervall), cancelButtonText: "Abbrechen", confirmButtonText: "Löschen", modalText: "Möchten Sie diese Intervallleistung wirklich löschen?" } } });
  }

  const options = [
    {
      text: "Bearbeiten",
      function: onPressEdit
    },
    {
      text: "Löschen",
      function: onPressDelete
    }
  ]

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const [sum, setSum] = useState<number>(0);

  useEffect(() => {
    let tempSum = 0;
    intervall.subscriptionPositions?.forEach((subscriptionPosition: ISubscriptionPosition) => {
      tempSum += ((subscriptionPosition.netto * subscriptionPosition.quantity) -
        (subscriptionPosition.hasDiscount ? (subscriptionPosition.discountType! === false ? subscriptionPosition.netto! * subscriptionPosition.quantity! * (subscriptionPosition.discount! / 100) : subscriptionPosition.discount!) : 0))
    })
    setSum(tempSum)
  }, [intervall.subscriptionPositions])

  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    if(setFormMode && setClickedIntervall) {
      setClickedIntervall(intervall);
      setFormMode("editIntervall");
    }
  }


  return (
    <div style={{ borderWidth: "1px", borderColor: "#f2f2f2", borderStyle: "solid", borderRadius: "8px", marginBottom: "0.5vh" }}>

      <div className="itemContainer" style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
        <div onClick={() => handleClick()} style={{ width: "100%", display: "flex", flexDirection: "column", alignContent: "left" }}>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "center", marginLeft: "1.3vw" }}>
            {/*expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />*/}
              <span className='bold-big' style={{ fontSize: 15 }}>{intervall.title ?? ""}</span>
            </div>
            <MoreOptionsButton options={options}></MoreOptionsButton>
          </div>

          <div style={{ fontSize: 14, marginLeft: "2em", width: "100%" }}>

            <span className='bold-big' style={{ width: "100%", display: "flex", alignItems: "center", marginBottom: "1vh" }}>
              { /* Change displayed icon depending on whether customer is organization or person*/
                intervall.customerOrganizationId ? <Tooltip placement='left' title="Kunde (Organisation)"><AccountBalanceOutlinedIcon style={{ fontSize: 14, marginRight: "1px" }} /></Tooltip> : <Tooltip placement='left' title="Kunde (Person)"><PersonOutlineOutlinedIcon style={{ fontSize: 14, marginRight: "1px" }} /></Tooltip>
              }
              {intervall.customerOrganizationId /* get customer person or organization*/
                ? getCompanyByID!(intervall.customerOrganizationId)?.name
                : persons.find(
                  (person: IPerson) =>
                    person.id === intervall.customerPersonId
                )?.completeName ?? "*Kein Kunde zugewiesen"}
            </span>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <span style={{ width: "50%", display: "flex" }}>
                <span style={{ width: "50%" }}>
                  Letzte Abrechnung: {" "}
                </span>
                <span style={{ width: "50%" }}>
                  {intervall?.lastBillingDate
                    ? new Date(intervall.lastBillingDate).toLocaleDateString(
                      "de-DE",
                      { day: "2-digit", month: "2-digit", year: "numeric" }
                    )
                    : "-"}
                </span>
              </span>
              <span style={{ width: "50%", display: "flex" }}>
                <span style={{ width: "50%" }}>
                  Nächste Abrechnung: {" "}
                </span>
                <span style={{ width: "50%" }}>
                  {intervall.nextBillingDate
                    ? new Date(intervall.nextBillingDate).toLocaleDateString(
                      "de-DE",
                      { day: "2-digit", month: "2-digit", year: "numeric" }
                    )
                    : "-"}
                </span>
              </span>

            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <span style={{ width: "50%", display: "flex" }}>
                <span style={{ width: "50%" }}>Gültig bis:</span>
                <span style={{ width: "50%" }}>
                  {intervall.validUntil
                    ? new Date(intervall.validUntil).toLocaleDateString()
                    : "Kündigung"}
                </span>
              </span>

              <span style={{ width: "50%", display: "flex" }}>
                <span style={{ width: "50%" }}>Abrechnung alle:</span>
                <span style={{ width: "50%" }}>
                  {intervall.period}{" "}
                  {intervall.periodIsDays
                    ? "Tage"
                    : intervall.periodIsMonths
                      ? "Monate"
                      : intervall.periodIsYears
                        ? "Jahre"
                        : ""}
                </span>
              </span>
            </div>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
              <span style={{ width: "50%", display: "flex" }}>
                <span style={{ width: "50%" }}>
                  {isExpense ? "Ausgaben:" : "Einnahmen:"}
                </span>
                <span style={{ width: "50%" }}>
                  {`${sum.toFixed(2)}€`}
                </span>
              </span>
            </div>

          </div>
        </div>

      </div>
      {/*<Collapse in={expanded} style={{ marginLeft: "1.8em" }}>
        <div >
          Collapse
        </div>
                  </Collapse>*/}

    </div>
  )

}

export default IntervallRow;