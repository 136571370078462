import { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useTasks } from '../../../Contexts/TaskContext/TaskContext'
import { ITask, ITaskDTO } from '../../../Interfaces/Task'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateTaskForm from '../../Forms/CreateTaskForm'
import FinishTaskForm from '../../Forms/FinishTaskForm'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'

const FinishTaskDialog = ({ open, onClose, mode, object }: dialogWithProps<ITask | ITaskDTO>) => {

  const { defaultTask, addTask, updateTask, finishTask } = useTasks()

  const { changeVisibility } = useModals()

  const [taskToSubmit, setTaskToSubmit] = useState<ITaskDTO>(object ?? defaultTask!)

  const handleSubmit = () => {
    /*if (mode == "edit") {
      updateTask!(taskToSubmit)
    } else {
      addTask!(taskToSubmit)
    }*/

    if (finishTask) {
      finishTask(taskToSubmit);
    }
    onClose ? onClose() : changeVisibility!("finishTask", false)
  }

  useEffect(() => {
    setTaskToSubmit(object ?? defaultTask!)
  }, [object])

  return (
    <Modal
      title={mode == "edit" ? "Aufgabe fertigstellen" : 'Aufgabe erstellen'}
      open={open}
      onClose={onClose ? () => onClose() : () => changeVisibility!("finishTask", false)}
    >
      <FinishTaskForm
        object={taskToSubmit}
        setObject={setTaskToSubmit}
      />
      <CTAButton
        title="Speichern"
        onClickHandler={() => handleSubmit()}
      />
    </Modal>
  )
}

export default FinishTaskDialog