import React, { ReactNode, useState } from 'react'

import { card } from '../../Interfaces/Card'
import Card from '../Card/Card'
import styles from './ProjectCard.module.css'
import { IProjectDTO, defaultProjectDTO, useTasks } from '../../Contexts/TaskContext/TaskContext'
import ProjectRow from '../Project/ProjectRow'
import { AddRounded } from '@mui/icons-material'
import EditCreateProjectModal from '../Dialogs/EditCreateProject/EditCreateProjectModal'
import YesNoDialog from '../Dialogs/YesNoDialog/YesNoDialog'
import { useUser } from '../../Contexts/UserContext/UserContext'

interface IProjectCard extends card {
  right?: (props: { expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>> }) => ReactNode
  showOnlyOwnProjects?: boolean
}

const ProjectCard = ({ width, height, order, right, showOnlyOwnProjects = false }: IProjectCard) => {

  const { projects, tasks } = useTasks()
  const { user } = useUser();

  const [showCreateProjectModal, setShowCreateProjectModal] = useState(false);

  const [projectToEdit, setProjectToEdit] = useState<IProjectDTO>(defaultProjectDTO);
  const [showEditProjectModal, setShowEditProjectModal] = useState(false);

  const handleEdit = (project: IProjectDTO) => {
    setProjectToEdit(project);
    setShowEditProjectModal(true);
  }

  const closeEditModal = () => {
    setShowEditProjectModal(false);
    setProjectToEdit(defaultProjectDTO);
  }

  return (
    <Card
      title='Projekte'
      style={{ width, height, order }}
      cta={user?.rights?.createProject}
      ctaTitle="Projekt erstellen"
      ctaFunction={() => { setShowCreateProjectModal((old) => (!old)) }}
      buttonIcon={<AddRounded fontSize='small' />}
      right={right}
    >
      <EditCreateProjectModal open={showCreateProjectModal} onClose={() => setShowCreateProjectModal(false)} />
      <EditCreateProjectModal open={showEditProjectModal} onClose={() => closeEditModal()} object={projectToEdit} mode="edit" />
      {
        projects.map((project, index) => {
          if (!showOnlyOwnProjects || (showOnlyOwnProjects && project.userIds.includes(user?.userId ?? 0))) {
            return <ProjectRow project={project} index={index} handleEdit={handleEdit} />
          } else {
            return <></>
          }
        })
      }
    </Card>
  )
}

export default ProjectCard