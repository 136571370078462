import Modal from "../Dialog/Dialog"
import CTAButton from "../Buttons/CTAButton/CTAButton"
import FormRow from "../Forms/FormUtils/FormRow"
import { useEffect, useState } from "react"
import { useEMail } from "../../Contexts/EMailContext/EMailProvider"
import { useSnackBar } from "../../Hooks/useSnackBar"
import { ContactDataType, IPerson, useContacts } from "../../Contexts/ContactsContext/ContactsProvider"
import { IOrganization } from "../../Interfaces/Company"
import { useUser } from "../../Contexts/UserContext/UserContext"
import { PhoneCardModes } from "./PhoneCard"
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored"

interface props {
    open: boolean,
    setOpen: (val: boolean) => void
    organizationId?: number
    personId?: number
    phoneCardMode?: PhoneCardModes
}

const MissedCallMailDialog = ({ open, setOpen, organizationId, personId, phoneCardMode }: props) => {

    const { sendMissedCallMail } = useEMail();

    const { enqueueSnackbar } = useSnackBar();

    const { getPersonByID, getCompanyByID } = useContacts();
    const { user } = useUser();

    const [person, setPerson] = useState<IPerson | undefined>();
    const [organization, setOrganization] = useState<IOrganization | undefined>();
    const [personToUser, setPersonToUser] = useState<IPerson | undefined>()

    const [text, setText] = useState<string>("");
    const [mailTo, setMailTo] = useState<string>("");

    const [mailToError, setMailToError] = useState(false);

    const onClose = () => {
        setOpen(false)
    }

    const handleSubmit = () => {
        
        if (sendMissedCallMail && checkEmailExists(mailTo)) {
            if (phoneCardMode === "person") sendMissedCallMail(convertNewlinesToBreaks(text), 0, personId);
            if (phoneCardMode === "organization") sendMissedCallMail(convertNewlinesToBreaks(text), organizationId, 0);
        }
        onClose();

    }

    //Function to convert \n to <br> Tags for correct Email format
    const convertNewlinesToBreaks = (text: string): string => {
        return text.replace(/\n/g, '<br>');
    };

    //Function to check if an email has been entered for the selected person/organization
    const checkEmailExists = (email : string) => {
        if(phoneCardMode === "person") {

            //check if an email can be found from the contactData prop of person
            const foundMail = person?.contactData?.find(item => item.type === ContactDataType.mail);
            if(foundMail !== undefined) {
                //if an email was found, set MailToError to false and return true
                setMailToError(false);
                return true;
            }
            else {
                //if no email was found set MailToError to true and return false
                setMailToError(true);
                return false;
            }
        }
        else if (phoneCardMode === "organization") {
            //check if organization has an associated email
            if(organization?.eMail !== undefined && organization.eMail.length > 0) {
                setMailToError(false)
                return true;
            }
            else {
                setMailToError(true);
                return false;
            }
        }
        else if (phoneCardMode === "undefined") {
            setMailToError(true);
            return false;
        }
    }

    useEffect(() => {
        console.log("Org", organizationId, "Pers", personId);
        if (personId !== undefined) {
            setPerson(getPersonByID!(personId));
        }
        if (organizationId !== undefined) {
            setOrganization(getCompanyByID!(organizationId))
        }

        //DEBUG
        console.log("person", person)
        console.log("org", organization)
    }, [organizationId, personId])

    useEffect(() => {
        console.log("user", user);
        if (user?.personId) {
            setPersonToUser(getPersonByID!(user?.personId))
        }
    }, [user])

    useEffect(() => {
        if (phoneCardMode === "person") {
            setMailTo((person?.contactData?.find(item => item.type === ContactDataType.mail))?.value ?? "");
            let standardText = `
            Sehr geehrte*r${person?.salutation ? " " + person?.salutation : ""} ${person?.completeName}!\n
            Sie haben soeben einen Anruf von ${personToUser?.completeName ?? ""} verpasst.\n
            Bitte um Ihren Rückruf unter der Telefonnummer: ${personToUser?.contactData?.find(item => item.type === ContactDataType.phone)?.value ?? ""}\n
            Vielen Dank,\n
            ${personToUser?.completeName ?? ""}
            `

            //Replace indentation from code for standardText
            standardText = standardText.replace(/^\s+/gm, '')
            setText(standardText);
        }
        if (phoneCardMode === "organization") {
            setMailTo(organization?.eMail ?? "");
            let standardText = `
            Sehr geehrte*r ${organization?.managingDirector ?? ""}!\n
            Sie haben soeben einen Anruf von ${personToUser?.completeName ?? ""} verpasst.\n
            Bitte um Ihren Rückruf unter der Telefonnummer: ${personToUser?.contactData?.find(item => item.type === ContactDataType.phone)?.value ?? ""}\n
            Vielen Dank,\n
            ${personToUser?.completeName ?? ""}
            `
            setText(standardText.replace(/^\s+/gm, ''));
        }
        if (phoneCardMode === "undefined") {
            let standardText = `
            Sehr geehrte*r !\n
            Sie haben soeben einen Anruf von verpasst.\n
            Bitte um Ihren Rückruf unter der Telefonnummer:\n
            Vielen Dank!\n
            `
            setText(standardText.replace(/^\s+/gm, ''));
        }
    }, [person, organization]);

    useEffect(() => {
        checkEmailExists(mailTo);
    }, [mailTo])

    return (
        <Modal
            title="Rückrufmail senden"
            open={open}
            onClose={onClose}
        >
            <FormRow
                type="text"
                label="An"
                value={mailTo}
                onChange={() => { }}
                readonly
                error={mailToError}
                errorMessage={InputFieldErrorMessages.PhoneCardMissedCallMailError}
            />
            <FormRow
                label="Text"
                value={text}
                onChange={(val) => setText(val)}
                placeholder="Text Ihrer Rückrufmail"
                type="multiline"
            />
            <CTAButton
                style={{ marginTop: "20px" }}
                title="Senden"
                onClickHandler={() => handleSubmit()}
            />
        </Modal>
    )
}

export default MissedCallMailDialog