import { Dispatch, SetStateAction, useState } from 'react';
import Modal from '../../Components/Dialog/Dialog';
import CreateAppointmentForm from '../../Components/Forms/CreateAppointmentForm';
import { IAppointment, ICreateAppointment } from '../../Interfaces/Appointment';
import { Dayjs } from 'dayjs';

interface modalProps {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>,
    mode?: string
    appointment?: IAppointment,
    setShowAppointmentInfoModal?: Dispatch<SetStateAction<boolean>>
    initialDate?: Dayjs; // Initial date for the new appointment
}

const CreateAppointmentModal = ({ open, setOpen, mode, appointment, setShowAppointmentInfoModal }: modalProps) => {

    const [titleError, setTitleError] = useState(false)
    const [visibilityError, setVisibilityError] = useState(false)
    

    function resetErrorVariables() {
        setTitleError(false);
        setVisibilityError(false);
    }

    const validateInput = (appointment: ICreateAppointment | IAppointment) => {
        if (appointment?.title?.length === 0 || appointment?.title == null) {
            setTitleError(true);
        } else {
            setTitleError(false);
        }

        if (appointment.visibility == null) {
            setVisibilityError(true);
        } else {
            setVisibilityError(false)
        }

        if (appointment?.title?.length === 0 || appointment?.title == null || appointment.visibility == null) {
            return false;
        }

        return true;
    }
    return (
        <Modal open={open}
            title={mode == "edit" ? "Termin bearbeiten" : "Termin erstellen"}
            onClose={() => {
                setOpen(false)
                resetErrorVariables();
            }}
        >
            <CreateAppointmentForm onClose={() => {
                setOpen(false)
            }}
                errorVariables={{ titleError, visibilityError }}
                validateInput={validateInput}
                appointmentToEdit={appointment}
                mode={mode}
                setShowAppointmentInfoModal={setShowAppointmentInfoModal}
            />
        </Modal>
    )
}

export default CreateAppointmentModal;