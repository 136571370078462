// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Service_accent__eEw-2 {\n    height: 12px;\n    width: 4px;\n    border-radius: 0 10px 10px 0;\n    background-color: var(--brand);\n}\n\n.Service_container__wQhoK {\n    margin: 0 -10px;\n    padding: 10px 10px 10px 0px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Service/Service.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,4BAA4B;IAC5B,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B","sourcesContent":[".accent {\n    height: 12px;\n    width: 4px;\n    border-radius: 0 10px 10px 0;\n    background-color: var(--brand);\n}\n\n.container {\n    margin: 0 -10px;\n    padding: 10px 10px 10px 0px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accent": "Service_accent__eEw-2",
	"container": "Service_container__wQhoK"
};
export default ___CSS_LOADER_EXPORT___;
