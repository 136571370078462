import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'

/* Icons */
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import Card from '../Card/Card'
import { useProducts } from '../../Contexts/ProductContext/ProductProvider'
import PersonalRow from './PersonalRow'
import { StaffDTO, useStaff } from '../../Contexts/StaffContext/StaffProvider'
import { useTimeTracking } from '../../Contexts/TimeTrackingContext/TimeTrackingProvider'
import { IconButton, Tooltip } from '@mui/material'
import SearchBar from '../SearchBar/SearchBar'
import { SearchRounded } from '@mui/icons-material'
import Filter, { IFilterOption } from '../Filter/Filter'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { userFormModes } from '../../Pages/Verwaltung/Personal/Personal'

interface IPersonalCard extends card {
  setClickedUser?: Dispatch<SetStateAction<StaffDTO>>
  setFormMode?: Dispatch<SetStateAction<userFormModes>>
}

function PersonalCard({ width, height, order, setClickedUser, setFormMode }: IPersonalCard) {

  const { staff, fetchStaff } = useStaff();
  const { persons } = useContacts();

  const filterOptions: IFilterOption[] = [
    { title: "Username", valueKey: "username" },
    { title: "Rolle", valueKey: "organizationRoleName" },
    { title: "E-Mail", valueKey: "eMail" },
    { title: "Name (Person)", valueKey: "personId", referenceList: persons, referenceListValueKey: "completeName" }
  ]

  useEffect(() => {
    fetchStaff!();
  }, [])

  const [keyword, setKeyword] = useState("")
  //Regelt das Öffnen und Schließen der Expandable Section der ServiceCard
  const handleExpand = (prevExpanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>) => {
    //Beim Schließen der Suchleiste wird der Suchwert zurückgesetzt und alle Leistungen wieder angezeigt
    if (prevExpanded) {
      setKeyword("")
    }
    setExpanded((val) => !val)
  }

  const [filteredStaff, setFilteredStaff] = useState<StaffDTO[]>(staff);
  const [originalStaffData, setOriginalStaffData] = useState<StaffDTO[]>(staff);
  useEffect(() => {
    setFilteredStaff(staff.filter((element: StaffDTO) => element.eMail.toLowerCase().includes(keyword.toLowerCase()) || element.username.toLowerCase().includes(keyword.toLowerCase()) || element.person?.completeName?.toLowerCase().includes(keyword.toLowerCase())))
    setOriginalStaffData(staff.filter((element: StaffDTO) => element.eMail.toLowerCase().includes(keyword.toLowerCase()) || element.username.toLowerCase().includes(keyword.toLowerCase()) || element.person?.completeName?.toLowerCase().includes(keyword.toLowerCase())))

  }, [keyword, staff])

  return (
    <Card
      title='Personal'
      style={{ width, height, order }}
      fullHeightBody={true}
      right={() => <Filter dataToFilter={filteredStaff} setDataToFilter={setFilteredStaff} filterOptions={filterOptions} originalData={originalStaffData}/>}
    >
      {filteredStaff.map((person: any) => {
        return (
          <PersonalRow key={person.id} staff={person} setClickedUser={setClickedUser} setFormMode={setFormMode}/>
        )
      })}
    </Card>
  )
}

export default PersonalCard