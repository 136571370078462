import ChevronLeftRoundedIcon from '@mui/icons-material/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import { Button, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import dayjs from 'dayjs';
import { Dispatch, SetStateAction } from 'react';
import ctaButtonStyles from "../../Components/Buttons/CTAButton/CTAButton.module.css";
import { useTasks } from '../../Contexts/TaskContext/TaskContext';
import styles from "./Calendar.module.css";

interface headerProps {
    selectedView?: string
    setSelectedView?: Dispatch<SetStateAction<string>>
    weekIndex?: number
    setWeekIndex?: Dispatch<SetStateAction<number>>
}

export default function CalendarHeader({ selectedView, setSelectedView, weekIndex, setWeekIndex }: headerProps) {
    const { monthIndex, setMonthIndex } = useTasks();


    function handleNext() {
        if (selectedView === "Monat") {
            setMonthIndex!(monthIndex + 1);
        } else {
            if (weekIndex) setWeekIndex!(weekIndex + 1)
        }
    }

    function handlePrev() {
        if (selectedView === "Monat") {
            setMonthIndex!(monthIndex - 1);
        } else {
            if (weekIndex) setWeekIndex!(weekIndex - 1)
        }
    }

    function handleReset() {
        // Math.random() required because otherwise the button doesn't reset the small calendar in the sidebar
        setMonthIndex!(monthIndex === dayjs().month()
            ? monthIndex + Math.random()
            : dayjs().month());

        setWeekIndex!(weekIndex === dayjs().week()
            ? weekIndex + Math.random()
            : dayjs().week())
    }

    const handleViewSelectChange = (event: SelectChangeEvent) => {
        if (setSelectedView) {
            setSelectedView(event.target.value as string);
        }
    }

    function generateHeaderTextContent(monthIndex: number, selectedView?: string, weekIndex?: number) {
        if (selectedView === "Monat") {
            //if monthly view is selected return current month and year as headertext
            const monthDate = dayjs(new Date(dayjs().year(), monthIndex));
            return monthDate.format("MMMM YYYY");
        } else if (weekIndex) {
            //if monthly view is not selected and weekindex is defined get the date of the first day and last day of the week
            const startOfWeek = dayjs().week(weekIndex).day(1);
            const endOfWeek = dayjs().week(weekIndex).day(7);

            //check if first day and last day are in different months
            if (startOfWeek.month() !== endOfWeek.month()) {
                //if they are in different months check if they are in different years
                if (startOfWeek.year() !== endOfWeek.year()) {
                    return "KW " + startOfWeek.format("w (MMM YYYY") + endOfWeek.format(" - MMM YYYY)");
                } else {
                    return "KW " + startOfWeek.format("w (MMM") + endOfWeek.format(" - MMM YYYY)");
                }
            } else {
                //if they are in same month just display the calendarweek year and month
                return "KW " + startOfWeek.format("w (MMMM YYYY)");
            }
        } else {
            //if selected view is unknown string print error string
            return "KW ?";
        }
    }

    return (
        <header className={styles.calendarHeader}>
            <Button className={ctaButtonStyles.button.concat(" " + styles.button)}
                style={{ backgroundColor: "var(--brand-light", color: "var(--brand)", minHeight: '35px' }}
                onClick={handleReset}>
                Heute
            </Button>
            <Button className={styles.button} size="medium" variant="text"
                style={{ maxWidth: '3em', minWidth: '3em', marginRight: '0px' }}
                onClick={handlePrev}>
                <ChevronLeftRoundedIcon style={{ color: 'var(--brand)' }} />
            </Button>

            <Button className={styles.button} size="medium" variant="text"
                style={{ maxWidth: '3em', minWidth: '3em', marginRight: '0px' }}
                onClick={handleNext}>
                <ChevronRightRoundedIcon style={{ color: 'var(--brand)' }} />
            </Button>
            <h2 className={styles.monthHeader}>{generateHeaderTextContent(monthIndex, selectedView, weekIndex)}</h2>
            <Select value={selectedView} style={{ marginLeft: 'auto', marginRight: '0px' }} size='small'
                onChange={handleViewSelectChange}>
                <MenuItem value={"Monat"}>Monat</MenuItem>
                <MenuItem value={"Woche"}>Woche</MenuItem>
            </Select>
        </header>
    )
}