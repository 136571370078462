import { ExpandLessRounded, MoreVertRounded } from '@mui/icons-material';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import { Collapse, IconButton } from '@mui/material';
import { useEffect, useState } from 'react';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { IProjectDTO, useTasks } from '../../Contexts/TaskContext/TaskContext';
import { getClassNames } from '../../Hooks/Utilities/Utlitites';
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton';
import ContextMenu from '../Menu/ContextMenu';
import styles from './Project.module.css';
import YesNoDialog from '../Dialogs/YesNoDialog/YesNoDialog';
import Task from '../Task/Task';
import { useUser } from '../../Contexts/UserContext/UserContext';
import AreaRow from '../Task/AreaRow';
import EditAreaPicturesDialog from '../Dialogs/EditAreaPicturesDialog/EditAreaPicturesDialog';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

interface IProjectRow {
    project: IProjectDTO
    index: number
    handleEdit?: (project: IProjectDTO) => void
}



const ProjectRow = ({ project, index, handleEdit }: IProjectRow) => {

    console.log("projectrow", project);

    const { deleteProject, allTasksUnformated } = useTasks();
    const { user } = useUser();

    const [expanded, setExpanded] = useState(false)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
    const [anchorElProject, setAnchorElProject] = useState<HTMLElement | null>(null)

    const handleDelete = () => {
        if (deleteProject) deleteProject(project);
    }

    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [tasksForProject, setTasksForProject] = useState(allTasksUnformated?.filter(t => t.projectId === project.id && t.userIds?.includes(user?.userId ?? 0)));

    useEffect(() => { setTasksForProject(allTasksUnformated?.filter(t => t.projectId === project.id && t.userIds?.includes(user?.userId ?? 0))) }, [allTasksUnformated])

    useEffect(() => { console.log(project) }, [project])

    const [editAreaPicturesOpen, setEditAreaPicturesOpen] = useState<boolean>(false);

    const navigate = useNavigate();

    const optionsMoptions = [
        {
            label: "Bearbeiten",
            onClick: () => { if (handleEdit) handleEdit(project); console.log(handleEdit) },
            selectable: false
        },
        {
            label: "Lieferschein erstellen",
            onClick: () => { navigate(`/aufgaben/lieferschein/erstellen?projectId=${project.id}`) },
            selectable: false
        },
        /*{
           label: "Mitarbeiter erstellen",
           onClick: () => null,
           selectable: false
       },
       {
           label: "Projektdatum verschieben",
           onClick: () => null,
           selectable: false
       },
       {
           label: "Projekt abbrechen",
           onClick: () => null,
           selectable: false
       },*/
        {
            label: "Löschen",
            onClick: () => setShowConfirmModal(true),
            selectable: false
        },
    ]
    const [options, setOptions] = useState(optionsMoptions);
    useEffect(() => {
        let tempOptions = options;
        if (!user?.rights?.updateProject) {
            tempOptions = (tempOptions.filter(option => option.label !== "Bearbeiten"));
        }
        if (!user?.rights?.deleteTask) { //TODO: Create Right to create DeliveryNote
            tempOptions = (tempOptions.filter(option => option.label !== "Lieferschein erstellen"));
        }
        if (!user?.rights?.deleteTask) { //TODO: Create Right to create DeliveryNote
            tempOptions = (tempOptions.filter(option => option.label !== "Löschen"));
        }
        setOptions(tempOptions)
    }, [user?.rights])


    return (
        <>
            <YesNoDialog open={showConfirmModal} onClose={() => setShowConfirmModal(false)} object={{ submitFunction: handleDelete, cancelFunction: () => setShowConfirmModal(false), modalTitle: "Projekt löschen", modalText: "Möchten Sie dieses Projekt wirklich löschen?" }} />
            <div className='flex column' style={{ marginInline: "-10px", padding: "15px 0", height: "100%", backgroundColor: index % 2 == 1 ? "var(--ultra-light)" : "transparent" }} >
                <div className='flex centered-aligned stretched-justify' style={{ paddingRight: "10px", position: "relative" }} onClick={() => setExpanded((old) => !old)}>
                    <div className={getClassNames([styles.accentContainer, "centered"])} >
                        <div className={styles.accent} />
                    </div>
                    <div className='flex centered-justified column'>
                        <header className='flex' style={{ height: "100%", paddingLeft: "13px" }}>
                            <span style={{ fontWeight: "500" }}>{project.title}</span>
                        </header>
                        <div className='flex centered-aligned' style={{ gap: "8px" }}>
                            <div className='flex centered-aligned' style={{ paddingLeft: "13px", fontSize: "14px", gap: "5px", marginTop: "5px" }}>
                                <AccessTimeRoundedIcon color='inherit' fontSize='inherit' />
                                <span style={{ color: "var(--text-contrast)" }}>{project.scheduledDate != null ? dayjs(project.scheduledDate).format('DD.MM.YY') : "Fortlaufend"}</span>
                            </div>
                            <span>|</span>
                            <div className='flex centered' style={{ fontSize: "14px", gap: "5px", marginTop: "5px", padding: "2px 8px", borderRadius: "100px", backgroundColor: "var(--light)" }}>
                                <PeopleRoundedIcon color='inherit' fontSize='inherit' />
                                <span style={{ color: "var(--text-contrast)", fontWeight: "400" }}>{project.userIds.length}</span>
                            </div>
                        </div>
                    </div>
                    <div className='flex centered-aligned'>
                        <IconButton onClick={(e) => { e.stopPropagation(); setExpanded((old) => !old); }}>
                            {!expanded && <ExpandMoreRoundedIcon fontSize='small' />}
                            {expanded && <ExpandLessRounded fontSize='small' />}
                        </IconButton>
                        {options?.length > 0 && <>
                            <IconTooltipButton
                                arrow
                                title='Optionen'
                                style={{ marginLeft: "-5px" }}
                                onClick={(e) => setAnchorElProject(e.currentTarget)}
                            >
                                <MoreVertRounded
                                    fontSize='small'
                                />
                            </IconTooltipButton>
                            <ContextMenu
                                anchorEl={anchorElProject}
                                onClose={() => setAnchorElProject(null)}
                                options={options}
                            />
                        </>}
                    </div>
                </div>
                {(<Collapse in={expanded}>
                    <div style={{ paddingLeft: "0.25em", paddingRight: "0.25em", marginTop: "0.25em" }}>
                        <span style={{ fontWeight: 500 }}>Aufgaben</span>
                        {
                            tasksForProject?.map((task, i) => {
                                return <Task mode='normal' openModalOnTaskClick={true} index={i} organizationIndex={index} key={i} task={task} />
                            })
                        }
                        {project?.areas &&
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", marginTop: "0.25em" }}>
                                <span style={{ fontWeight: 500 }}>Bereiche</span>
                                <IconTooltipButton
                                    arrow
                                    title='Optionen'
                                    style={{ marginLeft: "-5px" }}
                                    onClick={(e) => setAnchorEl(e.currentTarget)}
                                >
                                    <MoreVertRounded
                                        fontSize='small'
                                    />
                                </IconTooltipButton>
                                <ContextMenu
                                    anchorEl={anchorEl}
                                    onClose={() => setAnchorEl(null)}
                                    options={[
                                        {
                                            label: "Bearbeiten",
                                            onClick: () => { setEditAreaPicturesOpen(true) },
                                            selectable: false
                                        }
                                    ]}
                                />
                                <EditAreaPicturesDialog inputProject={project} isTask={false} onClose={() => { setEditAreaPicturesOpen(false); }} open={editAreaPicturesOpen} />
                            </div>
                        }
                        {
                            project?.areas?.map((area, i) => {
                                return <AreaRow index={i} area={area} project={project} />
                            })
                        }

                    </div>
                </Collapse>)}
                <div className={styles.body} style={{ /*backgroundColor*/ }}>
                    {/*<SearchBar
                            value={searchValue}
                            onChangeHandler={handleSearch}
                            style={{ marginBottom: "10px" }}
                />*/}

                </div>
            </div >
        </>
    )
}

export default ProjectRow