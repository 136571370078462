import { AddRounded, Edit } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import uuid from 'react-uuid'
import { defaultCompany, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { IPersonDTO } from '../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { card } from '../../Interfaces/Card'
import { IOrganization, IOrganizationDTO } from '../../Interfaces/Company'
import ButtonControl from '../Buttons/ButtonControl/ButtonControl'
import Card from '../Card/Card'
import CreateCompanyForm from '../Forms/CreateCompanyForm'
import CreatePersonForm from '../Forms/CreatePersonForm'
import { generateId } from '../Forms/PersonContactDataDynamicForm'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import { IDocument, useDocuments } from '../../Contexts/DocumentContext/DocumentContext'
import DocumentRow from '../DocumentRow/DocumentRow'
import DocumentHeaderRow from '../DocumentRow/DocumentHeaderRow'
import Pagination from '../Pagination/Pagination'
import DocumentUploadRow from '../DocumentRow/DocumentUploadRow'

interface ContactFormCard extends card {
    clickedPerson?: IPersonDTO
    setClickedPerson?: Dispatch<SetStateAction<IPersonDTO>>
    clickedCompany?: IOrganization | IOrganizationDTO
    setClickedCompany?: Dispatch<SetStateAction<IOrganization | IOrganizationDTO>>
    formMode?: contactFormModes
    setFormMode?: Dispatch<SetStateAction<contactFormModes>>
}

export type contactFormModes = "company" | "person" | "companyEdit" | "personEdit"

const ContactFormCard = ({ width, height, order, clickedPerson, setClickedPerson, clickedCompany, setClickedCompany, formMode, setFormMode }: ContactFormCard) => {

    const { createCompany, editCompany } = useContacts()

    const { defaultPerson, addPerson, updatePerson } = useContacts()

    const { enqueueSnackbar } = useSnackBar()

    //* States
    //Eingabemodus
    //const [mode, setMode] = useState<mode>(formMode as mode)

    const [companyToSubmit, setCompanyToSubmit] = useState<IOrganizationDTO | IOrganization>({
        ...defaultCompany
    })

    const [loading, setLoading] = useState(false)

    const [personToSubmit, setPersonToSubmit] = useState<IPersonDTO>({
        ...defaultPerson!
    })

    //Buttons, die den Eingabemodus kontrollieren
    const buttons = [
        {
            body: (active: boolean) => <span>Organisation</span>,
            value: "company"
        },
        {
            body: (active: boolean) => <span>Person</span>,
            value: "person"
        }

    ]

    //Gibt den Modus in geeignetem Format zurück
    const getMode = (mode: contactFormModes) => {
        switch (mode) {
            case "company":
                return "Organisation erstellen"
            case "person":
                return "Person erstellen"
            case "personEdit":
                return "Person bearbeiten"
            case "companyEdit":
                return "Organisation bearbeiten"
        }
    }

    //Form error variables
    const [lastNameError, setLastNameError] = useState(false);
    const [organizationNameError, setOrganizationNameError] = useState(false);

    //Abschicken eines Items
    const handleSubmit = async () => {
        try {
            setLoading(true)
            switch (formMode) {
                case "company":
                    if (validateInputCompany(companyToSubmit)) {
                        await createCompany!(companyToSubmit);
                        setCompanyToSubmit(defaultCompany);
                    }
                    break;
                case "person":
                    if (validateInputPerson(personToSubmit)) {
                        await addPerson!(personToSubmit);
                        const contactDataInitialState = [{ id: generateId(), type: 1, value: "" }, { id: generateId(), type: 2, value: "" }, { id: generateId(), type: 3, value: "" }];
                        setPersonToSubmit(defaultPerson);
                    }
                    break;
                case "companyEdit":
                    if (validateInputCompany(clickedCompany!)) {
                        await editCompany!(clickedCompany!);
                        setFormMode!("company");
                    }
                    break;
                case "personEdit":
                    if (validateInputPerson(clickedPerson!)) {
                        await updatePerson!(clickedPerson!);
                        setFormMode!("person");
                    }
                    break;
            }
        } catch (error) {
            enqueueSnackbar("Fehler beim Erstellen", { variant: "error" })
        } finally {
            setLoading(false)
        }
    }

    //Validation function, called before submitting person to API for create/update
    function validateInputPerson(person: IPersonDTO) {

        //set error if user does not enter a last name
        if (person.lastname?.length === 0) {
            setLastNameError(true)
        } else {
            setLastNameError(false);
        }

        if (person.lastname?.length === 0) {
            return false;
        }

        return true;
    }

    function validateInputCompany(company: IOrganizationDTO) {
        if (company.name?.length === 0) {
            setOrganizationNameError(true);
        } else {
            setOrganizationNameError(false);
        }

        if (company.name?.length === 0) {
            return false;
        }
        return true;
    }

    const { getAllDocumentsForPerson, documentsForPerson, getAllDocumentsForCompany, documentsForCompany } = useDocuments();
    const [page, setPage] = useState<number>(0);
    const itemsPerPage = 9;

    useEffect(() => {
        if (clickedPerson?.id) {
            getAllDocumentsForPerson(clickedPerson.id)
        } else if(clickedCompany?.id) {
            getAllDocumentsForCompany(clickedCompany.id)
        }

        console.log("clickedPerson", clickedPerson, "clickedCompany", clickedCompany)
    }, [clickedPerson, clickedCompany])

    return (
        <Card
            title={getMode(formMode!)}
            style={{ width, height, order }}
            //right={({expanded, setExpanded}) => <Tooltip title="Leistungen suchen"><IconButton style={{backgroundColor: expanded ? "var(--brand)" : undefined, color: expanded ? "white" : undefined}} onClick={() => handleExpand(expanded, setExpanded)}><SearchRoundedIcon /></IconButton></Tooltip>}
            right={() => (
                <ButtonControl
                    buttons={buttons}
                    value={formMode!}
                    setValue={setFormMode!}
                />
            )}
            buttonIcon={formMode?.toLowerCase().endsWith("edit") ? <Edit fontSize='small' /> : <AddRounded fontSize='small' />}
            cta
            ctaTitle={loading
                ? <CircularProgress size={16} color="inherit" />
                : formMode === "personEdit" || formMode === "companyEdit"
                    ? "Speichern"
                    : "Erstellen"}
            ctaFunction={() => handleSubmit()}
        >
            {
                formMode === "company" ? (

                    <CreateCompanyForm
                        object={companyToSubmit}
                        /* @ts-ignore */
                        setObject={setCompanyToSubmit}
                        errorVariables={{ organizationNameError }}
                    />
                )
                    : formMode === "person" ?
                        (
                            <CreatePersonForm
                                object={personToSubmit}
                                setObject={setPersonToSubmit}
                                setFormMode={setFormMode}
                                mode={formMode}
                                errorVariables={{ lastNameError }}
                            />
                        )
                        : formMode === "companyEdit" ?
                            (
                                <>{clickedCompany?.eCockpitOrganizationId === null &&
                                    <div style={{ textAlign: "center", padding: "3vh" }}>
                                        eCockpit Organisationen können nur von einem Administrator in den Einstellungen bearbeitet werden.
                                    </div>
                                }

                                    {clickedCompany?.eCockpitOrganizationId !== null &&
                                        <>
                                            <CreateCompanyForm
                                                object={clickedCompany!}
                                                /* @ts-ignore */
                                                setObject={setClickedCompany}
                                                setFormMode={setFormMode}
                                                errorVariables={{ organizationNameError }}
                                            />
                                        </>
                                    }

                                </>
                            )
                            : formMode === "personEdit" ?
                                (
                                    <CreatePersonForm
                                        object={clickedPerson!}
                                        /* @ts-ignore */
                                        setObject={setClickedPerson}
                                        mode={formMode}
                                        setFormMode={setFormMode}
                                        errorVariables={{ lastNameError }}
                                    />
                                )
                                : <></>
            }
            {formMode?.endsWith("Edit") &&
                <div>
                    <DocumentHeaderRow key={1} />
                    <DocumentUploadRow key={2} organizationId={clickedCompany?.id} personId={clickedPerson?.id} />
                    {formMode === ("personEdit") &&
                        <>
                            {documentsForPerson?.get(clickedPerson?.id ?? 0)?.map((document: IDocument) => {
                                return <><DocumentRow key={document.id} document={document} /></>
                            })}
                        </>
                    }
                    {formMode === ("companyEdit") &&
                        <>
                            {documentsForCompany?.get(clickedCompany?.id ?? 0)?.map((document: IDocument) => {
                                return <><DocumentRow key={document.id} document={document} /></>
                            })}
                        </>
                    }
                    <div style={{ marginTop: "2em" }}>
                        <Pagination items={formMode === ("personEdit") ? (documentsForPerson?.get(clickedPerson?.id ?? 0) ?? []) : (documentsForCompany?.get(clickedPerson?.id ?? 0) ?? [])} itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
                    </div>
                </div>
            }
        </Card>
    )
}

export default ContactFormCard