import { AlignHorizontalCenter, ArrowForward, CheckBoxOutlineBlankTwoTone, CloseRounded, Navigation } from '@mui/icons-material'
import { CircularProgress, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import InputField from '../../Components/InputField/InputField'
import styles from './Login.module.css'


import { useNavigate } from 'react-router-dom'

import { baseConnection } from '../../Connection/BaseConnection'



function ResetPassword() {

    const [resetPasswordData, setResetPasswordData] = useState<{ email: string }>({
        email: ""
    });

    const navigation = useNavigate();

    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);

    const handleReset = async () => {
        try {
            setLoading(true);
            const response = await baseConnection.post("/Authentication/requestReset", {
                requestString: resetPasswordData.email
            })

            if (response.status === 200) {
                setEmailSent(true);
            }

        } catch (error) {
            console.log(error)
        } finally {
            setLoading(false);
        }
    }

    function checkEmail(email: string) {
        const allowed = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!allowed.test(email)) {
            setContinueButtonDisabled(true);
        } else {
            setContinueButtonDisabled(false);
        }
    }

    useEffect(() => {
        checkEmail(resetPasswordData.email);

        function onKeyup(e: KeyboardEvent) {
            if (e.key === "Enter" && !continueButtonDisabled) handleReset()
          }
          window.addEventListener('keyup', onKeyup);
          return () => window.removeEventListener('keyup', onKeyup);
    }, [resetPasswordData.email, continueButtonDisabled])

    return (
        <div className='page flex centered'>
            <section className={styles.container.concat(" flex column")}>
                <div style={{ textAlign: "center", marginTop: "1%", marginBottom: "1%", fontSize: "3vh", fontWeight: "600" }}>
                    Passwort zurücksetzen
                </div>

                {!emailSent &&
                    <>
                        <InputField placeholder='E-Mail' label='E-Mail Adresse' value={resetPasswordData.email} onChangeHandler={(email) => setResetPasswordData((prevData) => ({ ...prevData, email }))} />
                        <div className='flex stretched-justify' style={{ marginTop: "20px" }}>
                            <button className='secondary-button pointer' onClick={() => navigation("/")}>
                                Zurück zum Login
                            </button>

                            {!continueButtonDisabled &&
                                <button onClick={async () => handleReset()} className="cta-button pointer" style={{ width: "40%" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                                </button>
                            }
                            {continueButtonDisabled &&
                                <button disabled className="cta-button-disabled not-allowed" style={{ width: "40%" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                                </button>
                            }

                        </div>
                    </>
                }

                {emailSent &&
                    <>
                        <div style={{ marginTop: "1vh", textAlign: "center" }}>
                            Eine Anfrage, um Ihr Passwort zurückzusetzen wurde an {resetPasswordData.email} gesendet. <br /> <br />
                            Sollten Sie keine E-Mail erhalten, überprüfen Sie bitte, ob Sie die korrekte E-Mail Adresse angegeben haben und versuchen Sie es erneut.
                        </div>
                    </>
                }

            </section>
        </div>
    )
}

export default ResetPassword