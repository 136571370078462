import { Button, Switch } from '@mui/material'
import React from 'react'
import { defaultCompany, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { IOrganizationDTO } from '../../Interfaces/Company'
import { ICompanyData } from '../../Interfaces/CompanyData'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import ResetFormButton from '../Buttons/ResetFormButton/ResetFormButton'
import CardExpandable from '../Card/CardExpandable/CardExpandable'
import { contactFormModes } from '../ContactCards/ContactFormCard'
import InputField from '../InputField/InputField'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'
import { form } from './CreateTaskForm'
import FormRow from './FormUtils/FormRow'
import { Person } from '@mui/icons-material'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import Android12Switch from '../Buttons/Switch/Android12Switch'

const EditCompanyDataForm = ({ object, setObject, setFormMode, errorVariables }: any) => {


    const handleReset = () => {
        setObject(defaultCompany)
        setFormMode!("company")
    }

    const goToServicePortal = () => {
        if (process.env!.NODE_ENV === "production") {
            window.open('https://billing.stripe.com/p/login/9AQ8x8eCBcrcaOIbII', '_blank');
        } else if (process.env!.NODE_ENV === "development") {
            window.open('https://billing.stripe.com/p/login/test_00geXb7oBdRxgiAeUU', '_blank');
        }

    }

    const { companyData } = useCompanyData();

    return (
        <form className='flex column' style={{ gap: "10px" }}>
            <FormRow
                type='text'
                label='Orga.-name:'
                placeholder='Name'
                value={object?.name}
                onChange={(val) => setObject((prev: any) => ({ ...prev, name: val }))}
                error={errorVariables?.organizationNameError}
                errorMessage={InputFieldErrorMessages.NameRequired}
            />
            <div className='form-row-label'>
                <span className="label">
                    Geschäftsführer:
                </span>
                <InputField
                    placeholder='Vorname Nachname'
                    value={object?.managingDirector ?? ""}
                    onChangeHandler={(val) => setObject((prev: any) => ({ ...prev, managingDirector: val }))}
                />
            </div>
            {/*<div className='form-row-label'>
                <span className="label">
                    Slogan:
                </span>
                <InputField
                    multiline
                    placeholder='Slogan'
                    value={object?.slogan}
                    onChangeHandler={(val) => setObject((prev: any) => ({ ...prev, slogan: val }))}
                />
            </div>*/}
            <div className='form-row-label'>
                <CardExpandable title='Kontaktdaten' style={{ marginTop: "10px" }}>
                    <FormRow
                        type='text'
                        label='Website:'
                        placeholder='Website'
                        value={object?.website}
                        onChange={(val) => setObject((prev: any) => ({ ...prev, website: val }))}
                    />
                    <FormRow
                        type='text'
                        label='Telefonnummer'
                        placeholder='Telefonnummer'
                        value={object?.telephone}
                        onChange={(val) => setObject((prev: any) => ({ ...prev, telephone: val }))}
                    />
                    <FormRow
                        type='text'
                        label='E-Mail:'
                        placeholder='E-Mail'
                        value={object?.eMail}
                        onChange={(val) => setObject((prev: any) => ({ ...prev, eMail: val }))}
                    />
                </CardExpandable>
                <CardExpandable title='Adresse' style={{ marginTop: "-3px" }}>
                    <FormRow
                        type='text'
                        label='Straße:'
                        placeholder='Straße'
                        value={object?.address?.street ?? ""}
                        onChange={(val) => setObject((prev: any) => {
                            if (prev.address) {
                                return { ...prev, address: { ...prev.address, street: val } }
                            }
                            return { ...prev, address: { id: 0, street: val } }
                        })}
                    />
                    <FormRow
                        type='text'
                        label='Hausnummer:'
                        placeholder='Hausnummer'
                        value={object?.address?.housenumber ?? ""}
                        onChange={(val) => setObject((prev: any) => {
                            if (prev.address) {
                                return { ...prev, address: { ...prev.address, housenumber: val } }
                            }
                            return { ...prev, address: { id: 0, housenumber: val } }
                        })}
                    />
                    <FormRow
                        type='text'
                        label='Postleitzahl:'
                        placeholder='Postleitzahl'
                        value={object?.address?.postalcode ?? ""}
                        onChange={(val) => setObject((prev: any) => {
                            if (prev.address) {
                                return { ...prev, address: { ...prev.address, postalcode: val } }
                            }
                            return { ...prev, address: { id: 0, postalcode: val } }
                        })}
                    />
                    <FormRow
                        type='text'
                        label='Stadt:'
                        placeholder='Stadt'
                        value={object?.address?.city ?? ""}
                        onChange={(val) => setObject((prev: any) => {
                            if (prev.address) {
                                return { ...prev, address: { ...prev.address, city: val } }
                            }
                            return { ...prev, address: { id: 0, city: val } }
                        })}
                    />
                </CardExpandable>
                <CardExpandable title='Steuerdaten' style={{ marginTop: "-3px" }}>
                    {/* <div className='form-row noselect' onClick={() => setObject((oldVal) => ({...oldVal, billable: true}))}>
                    <span style={{fontSize: "14px"}}>Kleinunternehmer</span>
                    <Android12Switch color="secondary" checked={object?.kleinunternehmer} onChange={(e, val) => setObject((oldVal) => ({...oldVal, kleinunternehmer: val}))}/>
                </div> */}
                    <FormRow
                        title='Kleinunternehmer'
                        type='switch'
                        value={object?.kleinunternehmer}
                        onChange={(val) => setObject((old: any) => ({ ...old, kleinunternehmer: val }))}
                    />
                    <FormRow
                        title='Pauschaliert'
                        type='switch'
                        value={object?.pauschaliert}
                        onChange={(val) => setObject((old: any) => ({ ...old, pauschaliert: val }))}
                    />
                    <FormRow
                        type='text'
                        label='Steuernummer:'
                        placeholder='Steuernummer'
                        value={object?.taxNumber}
                        onChange={(val) => setObject((prev: any) => ({ ...prev, taxNumber: val }))}
                    />
                    <FormRow
                        type='text'
                        label='UID:'
                        placeholder='UID'
                        value={object?.uid}
                        onChange={(val) => setObject((prev: any) => ({ ...prev, uid: val }))}
                    />
                    <FormRow
                        type='text'
                        label='Gericht'
                        placeholder='Gericht'
                        value={object?.court ?? ""}
                        onChange={(val) => setObject((prev: any) => ({ ...prev, court: val }))}
                    />
                </CardExpandable>
                <CardExpandable title='Bankdaten' style={{ marginTop: "-3px", marginBottom: "2%" }}>
                    <FormRow
                        label='Bank'
                        placeholder='Bank'
                        type='text'
                        value={object?.bank?.bank ?? ""}
                        onChange={(val) => setObject((old: any) => ({ ...old, bank: { ...old.bank, bank: val } }))}
                    />
                    <FormRow
                        label='IBAN'
                        placeholder='ATXX XXXX XXXX XXXX'
                        type='text'
                        value={object?.bank?.iban ?? ""}
                        onChange={(val) => setObject((old: any) => ({ ...old, bank: { ...old.bank, iban: val } }))}
                    />
                    <FormRow
                        label='BIC'
                        placeholder='XXXXXXXX'
                        type='text'
                        value={object?.bank?.bic ?? ""}
                        onChange={(val) => setObject((old: any) => ({ ...old, bank: { ...old.bank, bic: val } }))}
                    />
                </CardExpandable>
                <div className='form-row' style={{ marginBottom: "1em" }}>
                    <span>Ist Kunde </span><Android12Switch color='secondary' checked={object.customer} onChange={(event, checked) => setObject((old: any) => ({ ...old, customer: checked }))}></Android12Switch>
                </div>
                {object.id === companyData.id &&
                    <div style={{ textAlign: "center", marginBottom: "" }}>
                        <Button
                            variant='outlined'
                            component="label"
                            style={{ marginBottom: "1vh", width: "50%", borderColor: "#390090", color: "grey" }}
                            onClick={() => goToServicePortal()}
                        >
                            <Person style={{ marginRight: ".5vw", color: "gray" }} />
                            Kundenportal
                        </Button>
                    </div>
                }
                <ResetFormButton
                    title="Felder zurücksetzen"
                    onClickHandler={() => handleReset()}
                />
            </div>
        </form>
    )
}

export default EditCompanyDataForm