
import { useEffect, useState } from 'react'
import UnternehmerCard from '../../Components/AccountingCards/UnternehmerCard'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CardRow from '../../Components/Card/CardRow/CardRow'
import ComponentCarousel, { ICarouselItem } from '../../Components/Carousel/ComponentCarousel'
import CurrentMonthCard from '../../Components/CurrentMonthCard/CurrentMonthCard'
import JournalCard from '../../Components/JournalCard/JournalCard'
import PageNew from '../../Components/Page/PageNew'
import ServiceCard from '../../Components/ServiceCard/ServiceCard'
import TaskCard from '../../Components/TaskCard/TaskCard'
import TimeTrackingCard from '../../Components/TimeTrackingCard/TimeTrackingCard'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { useMobileView } from '../../Hooks/useMobileView'
import ButtonControl from '../../Components/Buttons/ButtonControl/ButtonControl'
import ProjectCard from '../../Components/ProjectCard/ProjectCard'
import { createRef } from "react"

const HomeScreenCarousel = [
  { Component: TimeTrackingCard, nodeRef: createRef() },
  { Component: CurrentMonthCard, nodeRef: createRef() },
  { Component: TaskCard, nodeRef: createRef() },
  { Component: ServiceCard, nodeRef: createRef(), componentProps: { cta: true, ctaTitle: "Leistung erstellen" } },
  { Component: JournalCard, nodeRef: createRef() },
]

function Home() {
  const { user } = useUser()

  const mobile = useMobileView()

  const [componentsToDisplay, setComponentsToDisplay] = useState<ICarouselItem[]>(HomeScreenCarousel)


  useEffect(() => {
    setComponentsToDisplay((old: ICarouselItem[]) => {
      let temp = [];
      if ((user?.organizationRoleId === 20147 || user?.organizationRoleId === 20165)) {
        temp.push({ Component: ProjectCard, nodeRef: createRef() })
      }

      temp = [...temp, ...HomeScreenCarousel];

      if (user?.rights?.readWorktime === false) {
        temp = temp.filter(el => el.Component !== CurrentMonthCard)
      }
      if (user?.rights?.readService === false) {
        temp = temp.filter(el => el.Component !== ServiceCard)
      }
      if (user?.rights?.readJournal === false) {
        temp = temp.filter(el => el.Component !== JournalCard)
      }
      if (user?.rights?.readTask === false) {
        temp = temp.filter(el => el.Component !== TaskCard)
      }

      return temp;
    })
  }, [user])

  const [cardMode, setCardMode] = useState<"project" | "task">("task");
  const buttons = [
    {
      body: (active: boolean) => <span>Projekte</span>,
      value: "project"
    },
    {
      body: (active: boolean) => <span>Aufgaben</span>,
      value: "task"
    }

  ]

  const buttonControl = () => {
    return (
      <ButtonControl buttons={buttons} setValue={setCardMode} value={cardMode} />
    )
  }
  useEffect(() => { console.log("USER", user) }, [user])

  return (
    <>
      {mobile !== true &&
        <>
          <CardColumn
            width={!(user?.organizationRoleId === 20147 || user?.organizationRoleId === 20165) ? '25%' : '100%'}
            height='100%'
          >
            <TimeTrackingCard
              height='33%'
              width='100%'
            />
            <>{(user?.organizationRoleId === 20147 || user?.organizationRoleId === 20165) ?
              <><ProjectCard
                showOnlyOwnProjects={true}
                height='66%'
              /></>
              :
              <>
                {cardMode === "task" ?
                  <TaskCard
                    height='66%'
                    cta={true}
                    showOnlyOwnTasks={true}
                    openModalOnTaskClick={true}
                    right={buttonControl}
                  />
                  :
                  <ProjectCard
                    showOnlyOwnProjects={true}
                    height='66%'
                    right={buttonControl}
                  />}
              </>
            }</>
          </CardColumn>
          {!(user?.organizationRoleId === 20147 || user?.organizationRoleId === 20165) &&
            <>
              <CardColumn
                width='25%'
                height='100%'
              >
                {user?.organizationRoleName === "Owner"
                  ? <UnternehmerCard height='33%' width='100%' />
                  : <CurrentMonthCard height='33%' width='100%' />}

                <ServiceCard
                  width='100%'
                  height='66%'
                  cta
                  ctaTitle='Leistung erstellen'
                />
              </CardColumn>

              <CardColumn
                height='100%'
                width='50%'
              >
                <JournalCard
                  height='100%'
                  width='100%'
                />
              </CardColumn>
            </>
          }

        </>
      }
      {mobile === true &&

        <ComponentCarousel componentsToDisplay={componentsToDisplay} />

      }
    </>
  )
}

export default Home