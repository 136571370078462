import { useSnackbar } from "notistack";
import { useEffect } from "react";



export const useTravelMutationNotifications = (postTravelSuccess: boolean, postTravelError: boolean, editTravelSuccess: boolean, editTravelError: boolean) => {

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (postTravelSuccess) {
            enqueueSnackbar("Fahrt erfolgreich erstellt", { variant: "success" });
        }
    }, [postTravelSuccess])

    useEffect(() => {
        if (postTravelError) {
            enqueueSnackbar("Fehler beim Erstellen der Fahrt", { variant: "error" });
        }
    }, [postTravelError])

    useEffect(() => {
        if (editTravelSuccess) {
            enqueueSnackbar("Fahrt erfolgreich bearbeitet", { variant: "success" });
        }
    }, [editTravelSuccess])

    useEffect(() => {
        if (editTravelError) {
            enqueueSnackbar("Fehler beim Bearbeiten der Fahrt", { variant: "error" });
        }
    }, [editTravelError])
}