import { Dayjs } from "dayjs"
import { StaffDTO } from "../../Contexts/StaffContext/StaffProvider"
import { IPause, IWorkTime } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import { form } from "./CreateTaskForm"
import Form from "./FormUtils/Form"
import FormRow from "./FormUtils/FormRow"

export interface IPostscriptTimePauseAdminForm extends form<any> {
    staffMember?: StaffDTO
}

const EditWorktimePauseAdminForm = ({ object, setObject, staffMember }: IPostscriptTimePauseAdminForm) => {

    return (
        <Form>
            <FormRow
                type="text"
                label="Mitarbeiter"
                value={staffMember?.person?.completeName}
                onChange={() => { }}
                readonly
            />
            <FormRow
                label="Start"
                value={object.start}
                onChange={(val: Dayjs) => setObject((old: any) => ({ ...old, start: val }))}
                type="date"
            />
            <FormRow
                label="Ende"
                value={object.end}
                onChange={(val: Dayjs) => setObject((old: any) => ({ ...old, end: val }))}
                type="date"
            />
        </Form>
    )
}

export default EditWorktimePauseAdminForm