import { useEffect, useMemo } from "react"
import { useContacts } from "../Contexts/ContactsContext/ContactsProvider"
import useAuthConnection from "./useAuthConnection"

type items = "personen" | "statistiken" | "produkte" | "rechnungsvorschau" | "fahrten" | "lieferscheine" | "firmen"

type itemsToFetch = items[]

//TODO

/** 
* Die Items sollten in den jeweiligen Kontexten nicht verwendet werden, sondern als @param additionalPromise der Funktion übergeben werden.
*/
export const useFetch = (items: itemsToFetch, additionalPromises?: Promise<any>[]) => {


    const {fetchCompanies} = useContacts()

    const {fetchPersons} = useContacts()

    const connection = useAuthConnection()

    const fallback: any = []



    
    useEffect(() => {
        let promises: Promise<any>[] = [...(additionalPromises ?? fallback)]
        
        items.forEach((item) => {
            switch(item) {
                case "personen":
                    promises.push(fetchPersons!()) //Funktion zum Fetchen der Daten soll in den Array gepusht werden
                    break
                case "firmen":
                    promises.push(fetchCompanies!())
                    break
                default: 
                    break
            }
        })
    
        try {
            Promise.all(promises) //return-Wert könnte zur Diagnose herangezogen werden, bei welchem Promise abgebrochen wurde -> Unsicher über Ausführung
        } catch (error) {
            console.log(error)
        }

    }, [])
}

/* 

Instant-Fetch:
- Journal
- Zeiterfassung
- Kanäle für das Journal
- Abteilungen
- Leistungen (limit: 50) wären ideal -> Eigener Endpunkt für Statistiken wäre aber dann notwendig
    - Stundentarif vom User
- Statistiken für diesen Monat
- Mitarbeiter
- Mitarbeiter-Daten = User-Provider


--> Logik des Checks, ob Items bereits vorhanden sind, soll in den fetch-Funktionen in den Providern stattfinden.

Optionales Fetchen
- Personen
- Alle Statistiken
- Produkte
- Rechnungsvorschau
- Fahrten
    - Fahrten soll gleichzeitig auch die Tarife vom User fetchen
- Firmen -> Endpunkt für Namen und ID und Endpunkt für alle Firmen?
- Lieferscheine 


*/