import { Button, Checkbox, IconButton, ListItem, ListItemButton } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { ITask, ITaskDTO } from '../../Interfaces/Task'

import styles from './Task.module.css'

//Icons
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import { useDeliveryNote } from '../../Contexts/DeliveryNoteContext/DeliveryNoteContext';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { ITaskSelectMode } from '../TaskCard/TaskCard';
import { IProject, IProjectHasArea, useTasks } from '../../Contexts/TaskContext/TaskContext';
import ContextMenu from '../Menu/ContextMenu';
import { CameraAlt, Done, MoreVertRounded, PlayArrow, PlayArrowRounded, StopRounded } from '@mui/icons-material';
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton';
import { taskFormModes } from '../../Pages/Aufgaben/Aufgaben';
import { useNavigate } from 'react-router-dom';
import { useServices } from '../../Contexts/ServiceContext/ServiceContext';
import { useUser } from '../../Contexts/UserContext/UserContext';
import PicturesToTaskModal from '../PicturesToTaskModal/PicturesToTaskModal';
import CapturePicturesModal from '../Camera/CapturePicturesModal';

function AreaRow({ area, index, project }: { area: IProjectHasArea, index?: number, project: IProject }) {
    const { changeVisibility } = useModals()
    const { modifySelectedTask, selectedTasks, deleteTask, finishTask } = useTasks()
    const { user } = useUser();

    const [picturesModalOpen, setPicturesModalOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [isBefore, setIsBefore] = useState<boolean>(true);

    useEffect(() => {
        console.log()
    }, [area])

    return (
        <div style={{ display: "flex", justifyContent: "space-between", paddingTop: "2px", paddingBottom: "2px", backgroundColor: (index ?? 0) % 2 === 0 ? "var(--ultra-light)" : "transparent" }}>
            <div className='flex centered-aligned pointer' style={{ width: "73%", paddingLeft: "2.5%" }} onClick={() => {}}>
                {/* ToDo: Fix multiselect <Checkbox
                    checked={selectedTasks.find((selectedTask) => selectedTask.id == task.id) !== undefined}
                    onChange={(e, checked) => handleChange(checked)}
                    style={{ color: "var(--brand)" }}
                />*/}
                <span style={{ width: "70%" }}>
                    {area.title}
                </span>
            </div>
            <div className='flex pointer' style={{ width: "25%", justifyContent: "flex-end" }} onClick={() => {}}>
                {true &&
                    <IconTooltipButton onClick={(e) => { e?.stopPropagation(); setPicturesModalOpen(true); }} arrow title={'Leistung zu Aufgabe starten'} placement="right">
                        <CameraAlt sx={{ color: 'var(--text-light)' }} fontSize='small' />
                    </IconTooltipButton>
                }
            </div>

            <CapturePicturesModal
                inputTask={area}
                project={project}
                isTask={false}
                open={picturesModalOpen}
                onClose={() => { setPicturesModalOpen(false) }}
                onSubmit={() => {
                    //if (isBefore) {
                    /*if (startServiceToTask) {
                        //startServiceToTask({ Task: area, Documents: object.documents });
                        setPicturesModalOpen(false);
                    }*/
                    /*} else {
                        if (stopServiceToTask) {
                            stopServiceToTask({ Task: task, Documents: object.documents });
                            setPicturesModalOpen(false);
                        }
                    }*/
                }}
            />
            {/*
            <PicturesToTaskModal beforeOrAfter={isBefore} onSubmit={() => {
                if (isBefore) {
                    if (startServiceToTask) {
                        startServiceToTask({ Task: task, Documents: object.documents });
                        setPicturesModalOpen(false);
                    }
                } else {
                    if (stopServiceToTask) {
                        stopServiceToTask({ Task: task, Documents: object.documents });
                        setPicturesModalOpen(false);
                    }
                }
            }} object={object} setObject={setObject} open={picturesModalOpen} onClose={() => { setPicturesModalOpen(false) }} />
        */}
        </div>
    )
}

export default AreaRow