import { useEffect, useState } from "react"
import { IEmployee, useEmployees } from "../../Contexts/EmployeeContext/EmployeeProvider"
import FormRow from "../Forms/FormUtils/FormRow"
import CTAButton from "../Buttons/CTAButton/CTAButton"
import { useTimeTracking } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import useAuthConnection from "../../Hooks/useAuthConnection"
import { AxiosResponse } from "axios"
import { useSnackBar } from "../../Hooks/useSnackBar"
import { IDocument, downloadFile } from "../../Contexts/DocumentContext/DocumentContext"
import PdfViewer from '../../Components/PDF/all-pages';
import DownloadIcon from '@mui/icons-material/Download';
import { CircularProgress, IconButton } from "@mui/material"
import { useParams } from "react-router-dom"
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored"
import { Dayjs } from "dayjs"

function GenerateWorktimeOverviewForUserForm() {

    const { id } = useParams();

    const [selectedDate, setSelectedDate] = useState<Dayjs>();
    const [userId, setUserId] = useState<number>(parseInt(id ?? "0"));

    const [pdf, setPdf] = useState<IDocument>();

    const { employees } = useEmployees();

    const authConnection = useAuthConnection()

    const [activeEmployees, setActiveEmployees] = useState(employees.filter((employee: IEmployee) => employee.active))

    const { enqueueSnackbar, closeSnackbar } = useSnackBar();

    const [loading, setLoading] = useState(false);

    const [userError, setUserError] = useState(false);
    const [dateError, setDateError] = useState(false);

    //DEBUG
    //useEffect(() => { console.log(selectedDate?.getMonth(), selectedDate?.getFullYear(), userId, pdf) }, [selectedDate, userId, pdf])

    const handleSubmit = async () => {
        setUserError(false); setDateError(false);

        if (validateInput(userId, selectedDate ? selectedDate.month() : undefined, selectedDate ? selectedDate.year() : undefined)) {

            const month = selectedDate!.month() + 1; //get month returns value between 0 and 11, api uses 1-12 for months
            const year = selectedDate!.year();

            setLoading(true);

            let x = enqueueSnackbar("Übersicht wird generiert", { variant: "default", autoHideDuration: 10000 })

            await authConnection.post("/worktime/generateWorktimeOverview", {
                "month": month,
                "year": year,
                "userIds": [userId],
                "pdfType": 0 //fixed value for API handling
            })
                .then((res: AxiosResponse) => {
                    closeSnackbar(x);
                    setPdf(res.data);
                    console.log(res.data)
                    setLoading(false);
                })
                .catch((error: any) => {
                    enqueueSnackbar("Fehler beim generieren der Übersicht", { variant: "error" });
                    setLoading(false);
                })
        }

    }

    const handleDownload = async () => {
        let x = enqueueSnackbar("Übersicht wird heruntergeladen", { variant: "default", autoHideDuration: 10000 });
        if (pdf) {
            downloadFile(pdf, `/document/download/${pdf?.id}`, () => { })
        }

    }

    function validateInput(id?: number, month?: number, year?: number) {

        let inputIsValid = true;

        if (month === undefined || year === undefined) {
            setDateError(true);
            inputIsValid = false;
        }

        if (id === null || id === undefined || Number.isNaN(id)) {
            setUserError(true);
            inputIsValid = false
        }

        return inputIsValid;
    }

    return (
        <div style={{ height: "100" }}>
            <FormRow
                type="select"
                label="Mitarbeiter*in"
                value={userId}
                onChange={(val) => setUserId(val)}
                selectOptions={{
                    titleKey: "username",
                    valueKey: "id",
                    options: activeEmployees,
                    multiple: false
                }}
                error={userError}
                errorMessage={InputFieldErrorMessages.EmployeeRequired}
            />
            <FormRow
                type="date"
                dateOptions={
                    { type: "Month" }
                }
                value={selectedDate ?? ""}
                label="Monat auswählen"
                onChange={(val) => setSelectedDate(val)}
                error={dateError}
                errorMessage={InputFieldErrorMessages.YearMonthRequired}
            />
            <div style={{ display: "flex", justifyContent: "center" }}>
                <CTAButton
                    onClickHandler={() => handleSubmit()}
                    title="Arbeitszeitübersicht generieren"
                    style={{ width: "40%", marginTop: "2vh" }}
                />

            </div>
            {loading &&
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress style={{ marginTop: "2vh" }} />
                </div>
            }
            {pdf &&
                <>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", marginTop: "2vh", width: "100%" }}>
                        <div style={{}}>
                            <span style={{ fontSize: "2.5vh", fontWeight: "bold" }}>Vorschau</span>
                        </div>
                    </div>
                    <div style={{ width: "100%" }}><IconButton onClick={() => handleDownload()} style={{ marginLeft: "90%" }}><DownloadIcon /></IconButton></div>
                </>
            }

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>

                {pdf &&
                    <PdfViewer pdf={`data:${pdf ? pdf.fileEnding : "application/pdf"};base64,${pdf?.base64}`} />
                }
            </div>
        </div>
    )
}

export default GenerateWorktimeOverviewForUserForm