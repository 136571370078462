import CTAButton from "../Buttons/CTAButton/CTAButton";
import ResetFormButton from "../Buttons/ResetFormButton/ResetFormButton";
import Modal from "../Dialog/Dialog"

const CreateBillModal = ({ open, setOpen, handleSubmit, isOffer = false }: any) => {

    const onClose = () => {
        setOpen(false);
    }

    return (
        <Modal
            open={open}
            title={isOffer ? "Angebot als angenommen markieren" : "Rechnung erstellen"}
            onClose={onClose}
        >
            <div className="form-row" style={{ textAlign: "center", justifyContent: "center", width: "100%" }}>
                <span>Sobald {isOffer ? "das Angebot als angenommen markiert ist" : "die Rechnung erstellt ist"}, können Sie {isOffer ? "es" : "diese"} nicht mehr bearbeiten. <br />Möchten Sie fortfahren?</span>
            </div>
            <div className="form-row" style={{ marginTop: "1em" }}>
                <CTAButton onClickHandler={handleSubmit} title={isOffer ? "Angebot als angenommen markieren" : "Rechnung erstellen"} />
            </div>
            <div className="form-row" style={{ marginTop: "1em" }}>
                <ResetFormButton showIcon={false} style={{ width: "100%" }} onClickHandler={() => { setOpen(false) }} title="Abbrechen" />
            </div>
        </Modal>
    )
}

export default CreateBillModal;