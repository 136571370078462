import { ClickAwayListener, Fade, Zoom } from '@mui/material'
import React, { ReactNode, useLayoutEffect, useRef, useState } from 'react'
import styles from './Dropdown.module.css'

function Dropdown<T>({renderFunction, options, headerElement, activeElement, setActiveElement} : {renderFunction: (props: {activeElement: T | null, setActiveElement: React.Dispatch<React.SetStateAction<T | null>>, option: any, index: number }) => ReactNode, options: T[], headerElement: ({activeElement, setExpanded, expanded, setActiveElement} : {expanded: boolean, activeElement: T | null, setExpanded: React.Dispatch<React.SetStateAction<boolean>>, setActiveElement: React.Dispatch<React.SetStateAction<T | null>>}) => JSX.Element, activeElement: T | null, setActiveElement: React.Dispatch<React.SetStateAction<T | null>>}) {

  //Referenz für das HeaderElements des Dropdowns -> Wird benötigt um die width zu bekommen
  const headerRef = useRef<HTMLDivElement | null>(null)

  //Breite des Header-Elements inkl. Padding
  const [width, setWidth] = useState(0)

  //useLayoutEffect wird ausgeführt, wenn die Elemente auf dem Bildschirm "gepaintet werden" -> nach Computisierung der Inhalte
  useLayoutEffect(() => {
    setWidth(headerRef?.current?.clientWidth!)
  }, [])

  //Regelt den State, ob das Dropdown aufgeklappt ist oder nicht.
  const [expanded, setExpanded] = useState(false)

  return (
    <ClickAwayListener onClickAway={() => setExpanded(false)}>
    <div className={styles.container} ref={headerRef}>
      <div>
        {headerElement({activeElement, setExpanded, expanded, setActiveElement})}
      </div>
      <Fade in={expanded}>
        <div className={styles.dropdown} style={{width: width /* 40 = padding des Elements auf der rechten Seite -> Sollte unbedingt noch verallgemeinert werden */}}>
          {options.map((option: T, index: number) => (
            renderFunction({activeElement, setActiveElement, option, index})
          ))}
        </div>
      </Fade>
      
    </div>
    </ClickAwayListener>
  )
}

export default Dropdown