// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".BillingPosition_accent__nApwx {\n    height: 12px;\n    width: 4px;\n    border-radius: 0 10px 10px 0;\n    background-color: var(--brand);\n}\n\n.BillingPosition_container__ud9ef {\n    margin: 0 -10px;\n    padding: 5px 0px 5px 0;\n}", "",{"version":3,"sources":["webpack://./src/Components/BillingPosition/BillingPosition.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,UAAU;IACV,4BAA4B;IAC5B,8BAA8B;AAClC;;AAEA;IACI,eAAe;IACf,sBAAsB;AAC1B","sourcesContent":[".accent {\n    height: 12px;\n    width: 4px;\n    border-radius: 0 10px 10px 0;\n    background-color: var(--brand);\n}\n\n.container {\n    margin: 0 -10px;\n    padding: 5px 0px 5px 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"accent": "BillingPosition_accent__nApwx",
	"container": "BillingPosition_container__ud9ef"
};
export default ___CSS_LOADER_EXPORT___;
