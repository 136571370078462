import React from 'react'
import { card } from '../../../Interfaces/Card'
import Card from '../../Card/Card'
import TimeOverviewContent from '../../Dialogs/TimeOverviewChartDialog/TimeOverviewChartContent';



const CurrentMonthStatsCard = ({ width, height, order }: card) => {
    return (
        <Card
            style={{ width, height, order }}
            title="Aktuelle Woche"
        >
            <div style={{ width: "90%", height: "80%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                <TimeOverviewContent forall={true} />
            </div>
        </Card>
    )
}

export default CurrentMonthStatsCard