// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TaskExpandable_body__FZePx {\n    padding: 10px;\n}\n\n.TaskExpandable_header__FjEwc {\n    padding: 10px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Task/TaskExpandable.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".body {\n    padding: 10px;\n}\n\n.header {\n    padding: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"body": "TaskExpandable_body__FZePx",
	"header": "TaskExpandable_header__FjEwc"
};
export default ___CSS_LOADER_EXPORT___;
