import { useState } from "react";
import { IDocument, downloadFile } from "../../Contexts/DocumentContext/DocumentContext";
import { Collapse, Tooltip } from '@mui/material'
import MoreOptionsButton from '../../Components/Buttons/MoreOptionsButton/MoreOptionsButton'
import { ContentPaste, Download, ExpandLessRounded, ExpandMoreRounded, PersonOutline, SaveAlt, SnoozeOutlined, WorkHistoryOutlined, WorkOffOutlined, WorkOutline } from '@mui/icons-material'
import { formatBytes } from "../../utils/helper";

const DocumentRow = ({ document }: { document: IDocument }) => {

    const [expanded, setExpanded] = useState<boolean>(false);
    const [downloading, setDownloading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const handleDownload = (file: IDocument) => {
        //setDownloading(true);
        downloadFile(file, `/document/download/${file.id}`, (event: any) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            setProgress(progress);
        }).then(() => {
            //setDownloading(false);
        });
    }


    const options = [
        {
            text: 'Herunterladen',
            function: () => {
                handleDownload(document);
            }
        },
        {
            text: "Löschen",
            function: () => { }
        }
    ]


    return (
        <div style={{ borderWidth: "1px", borderColor: "#f2f2f2", borderStyle: "solid", borderRadius: "8px" }} key={/*id*/ 1}>
            <div className="itemContainer" style={{ width: "100%", display: "flex", flexDirection: "row", padding: "1%", alignItems: "center", cursor: "pointer", borderBottom: expanded ? "1px solid #e6e6e6" : "" }}>
                <div onClick={() => setExpanded(!expanded)} style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: "80%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {/*expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />*/}
                        <Tooltip title="Mitarbeiter" placement="bottom">
                            <ContentPaste style={{ marginRight: "0.5vh" }} />
                        </Tooltip>
                        <span>
                            {document.title}
                        </span>
                    </div>
                    <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <Tooltip title="Datum" placement="bottom">
                            <SaveAlt />
                        </Tooltip>
                        <span>
                            {formatBytes(document.fileSize ?? 0)}
                        </span>
                    </div>
                </div>
                <div style={{ width: "10%", marginLeft: "auto", marginRight: "1vw" }}>
                    <MoreOptionsButton options={options}></MoreOptionsButton>
                </div>
            </div>
            <Collapse in={expanded}>
                <div style={{ width: "100%" }}>
                </div>
            </Collapse >
        </div >
    )
}

export default DocumentRow