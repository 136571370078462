import MoreOptionsButton from "../../../Components/Buttons/MoreOptionsButton/MoreOptionsButton";
import { ITagDTO, useContacts } from "../../../Contexts/ContactsContext/ContactsProvider"
import styles from "./SelectableTagRow.module.css"
import PeopleRoundedIcon from '@mui/icons-material/PeopleRounded';
import AccountBalanceOutlinedIcon from '@mui/icons-material/AccountBalanceOutlined';
import { SetStateAction, useState } from "react";
import EditCreateTagDialog from "../../../Components/Dialogs/EditCreateTagDialog/EditCreateTagDialog";
import YesNoDialog from "../../../Components/Dialogs/YesNoDialog/YesNoDialog";

interface SelectableTagRow {
    tag: ITagDTO
    index: number
    isSelected: boolean
    handleTagSelect: (tag: ITagDTO) => void
}

function SelectableTagRow({ tag, index, isSelected, handleTagSelect }: SelectableTagRow) {

    const { removeTag, downloadTagCSV } = useContacts();

    const onPressEdit = () => {
        setDialogOpen(true);
    }

    const onPressDelete = () => {
        setConfirmDialogOpen(true);
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        },
        {
            text: "CSV Export",
            function: () => downloadTagCSV!(tag)
        }
    ]

    const [dialogOpen, setDialogOpen] = useState(false);

    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const confirmDialogProps = {
        submitFunction: () => removeTag!(tag),
        cancelFunction: () => setConfirmDialogOpen(false),
        modalTitle: "Tag löschen",
        modalText: "Möchten Sie diesen Tag wirklich löschen?"
    }

    return (
        <div>
            <EditCreateTagDialog open={dialogOpen} onClose={() => setDialogOpen(false)} object={tag} mode={"edit"} />
            <YesNoDialog open={confirmDialogOpen} onClose={() => setConfirmDialogOpen(false)} object={confirmDialogProps} />
            <label className={styles.row} htmlFor={`checkbox-${tag.id}`}>
                <input className={styles.checkbox} type="checkbox" id={`checkbox-${tag.id}`} checked={isSelected} onChange={() => handleTagSelect(tag)} />
                <div className={styles.titleContainer}>{tag.title}</div>
                <div className={styles.countContainer}>
                    <PeopleRoundedIcon color='inherit' fontSize='inherit' />
                    {tag.countPersons}
                </div>
                <div className={styles.countContainer}>
                    <AccountBalanceOutlinedIcon fontSize="inherit" />
                    {tag.countOrganizations}
                </div>
                <div style={{ width: "15%", marginLeft: "auto" }}>
                    <MoreOptionsButton options={options}></MoreOptionsButton>
                </div>
            </label>
        </div>
    )
}

export default SelectableTagRow