import { CircularProgress } from '@mui/material'
import React, { Dispatch, useEffect, useState } from 'react'
import { defaultCompany, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IOrganization, IOrganizationDTO } from '../../../Interfaces/Company'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateCompanyForm from '../../Forms/CreateCompanyForm'
import Form from '../../Forms/FormUtils/Form'
import FormRow from '../../Forms/FormUtils/FormRow'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'

export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

const EditStatusDialog = ({ open, onClose, object, mode }: dialogWithProps<IOrganizationDTO | IOrganization>) => {

    const { editCompany } = useContacts()

    const { changeVisibility } = useModals()

    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editStatus", false)
    }



    useEffect(() => {
        setCompanyToSubmit(object ?? defaultCompany)
    }, [object])

    const [loading, setLoading] = useState(false)

    const [companyToSubmit, setCompanyToSubmit] = useState<IOrganizationDTO | IOrganization>(mode == "create" ? { ...defaultCompany } : { ...object! })

    const handleSubmit = async () => {
        try {
            setLoading(true)
            await editCompany!(companyToSubmit as IOrganization)
            changeVisibility!("editStatus", false)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            title="Status bearbeiten"
            onClose={() => handleClose()}
        >
            {object?.eCockpitOrganizationId === null &&
                <div style={{ textAlign: "center", padding: "3vh" }}>
                    eCockpit Organisationen können nur von einem Administrator in den Einstellungen bearbeitet werden.
                </div>
            }

            {object?.eCockpitOrganizationId !== null &&
                <>
                    <Form>
                        <FormRow
                            label="Status"
                            type='select'
                            value={companyToSubmit.status ?? 1}
                            onChange={(val) => setCompanyToSubmit((old) => ({ ...old, status: val }))}
                            selectOptions={{
                                options: [
                                    {
                                        id: 1,
                                        title: "Ok"
                                    },
                                    {
                                        id: 2,
                                        title: "Mäßig"
                                    },
                                    {
                                        id: 3,
                                        title: "Optimierungsbedarf"
                                    },
                                    {
                                        id: 4,
                                        title: "Konkurs/Geschlossen"
                                    },
                                ],
                                titleKey: "title",
                                valueKey: "id"
                            }}
                        />
                    </Form>
                    <CTAButton
                        style={{
                            marginTop: "20px",
                            zIndex: 1,
                        }}
                        title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                        onClickHandler={() => handleSubmit()}
                        disabled={loading}
                    />
                </>
            }

        </Modal>
    )
}

export default EditStatusDialog