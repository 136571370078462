import { NavLink, useLocation } from "react-router-dom";
import { PageInfo } from "../Interfaces/PageInfo";
import { Collapse, IconButton } from "@mui/material";
import { ExpandLessRounded } from "@mui/icons-material";
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { useMemo, useState } from "react";
import styles from './Navigation.module.css'


export default function NavigationItem({ pageInfo }: { pageInfo: PageInfo }) {

    const { pathname } = useLocation();

    const isActive = pathname === pageInfo.path;
    const isSubpathActive = pathname.startsWith(pageInfo.path);

    const hasSubpages = useMemo(() => (pageInfo.subpages && pageInfo.subpages.length > 0), [pageInfo.subpages]);

    const [expanded, setExpanded] = useState(false);

    return (
        <div className="flex flex-col w-[100%]">
            <div className={
                `flex flex-row items-center justify-between rounded-xl transition-colors duration-200  ${!isActive && "hover:bg-light"} min-h-[40px] ${isActive && "bg-brand hover:bg-brand"} ${(isSubpathActive && !isActive) && "bg-brand-light hover:bg-brand-light"}`}>
                <NavLink to={pageInfo.path} className={`text-text-contrast font-semibold text-base flex flex-row ${hasSubpages ? "w-[80%]" : "w-[100%]"} ml-1 gap-3 ${isActive && "text-white fill-white"}`}>
                    {pageInfo.icon}
                    {pageInfo.name}
                </NavLink>
                {
                    pageInfo.subpages && (
                        <IconButton key={`${pageInfo.id}-icon-button`} style={{ color: `${isActive ? "white" : "var(--text-contrast)"}`, width: "20%" }} onClick={() => { setExpanded(!expanded) }}>
                            {expanded ? <ExpandLessRounded color='inherit' /> : <ExpandMoreRoundedIcon color='inherit' />}
                        </IconButton>
                    )
                }
            </div>
            {pageInfo.subpages &&
                <div className="ml-3 mt-1">
                    <Collapse in={expanded}>
                        {pageInfo.subpages.map((pageInfo: PageInfo) => (
                            <NavigationItem key={pageInfo.id} pageInfo={pageInfo} />
                        ))}
                    </Collapse>
                </div>
            }

        </div>
    )
}