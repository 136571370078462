import { Button, IconButton } from "@mui/material"
import React, { useEffect, useRef, useState } from "react"
import FormRow from "./FormUtils/FormRow"
import styles from "./FormUtils/Form.module.css"
import AddIcon from '@mui/icons-material/Add'
import RemoveIcon from '@mui/icons-material/Remove';
import uuid from 'react-uuid';
import InputField from "../InputField/InputField"
import { IContactData, IPersonDTO } from "../../Contexts/ContactsContext/ContactsProvider"
import { IContact } from "../../Contexts/ContactsContext/ContactsProvider"

//Decrement counter to prevent accidental match in database for contact data
let counter = 0;
export const generateId = (): number => {
    return counter--;
}

//This component is used to render a dynamic form for contact data
const PersonContactDataDynamicForm = ({ contactData, object, setContactData, mode }: any) => {

    //Reference to check if contactData has been passed to this component later in useEffect
    const contactDataRef = useRef(contactData)

    //Set initial phone fields
    const [phoneFields, setPhoneFields] = useState<Array<IContactData>>(contactData && contactData.length ? contactData.filter((number: IContactData) => number.type === 1) : [{ id: generateId(), type: 1, value: "" }]);
    //Set initial email fields
    const [emailFields, setEmailFields] = useState<Array<IContactData>>(contactData && contactData.length ? contactData.filter((number: IContactData) => number.type === 2) : [{ id: generateId(), type: 2, value: "" }]);
    //Set initial website fields
    const [websiteFields, setWebsiteFields] = useState<Array<IContactData>>(contactData && contactData.length ? contactData.filter((number: IContactData) => number.type === 3) : [{ id: generateId(), type: 3, value: "" }]);

    //References to store the current values of phone, email and website fields
    const phoneFieldsRef = useRef(phoneFields);
    const emailFieldsRef = useRef(emailFields);
    const websiteFieldsRef = useRef(websiteFields);

    //Use effect to update the contact data when object.id or object.telefonnummer changes
    useEffect(() => {
        setPhoneFields(contactData && contactData.length && contactData.filter((number: IContactData) => number.type === 1).length > 0 ? contactData.filter((number: IContactData) => number.type === 1) : [{ id: generateId(), type: 1, value: "" }]);
        setEmailFields(contactData && contactData.length && contactData.filter((email: IContactData) => email.type === 2).length > 0 ? contactData.filter((email: IContactData) => email.type === 2) : [{ id: generateId(), type: 2, value: "" }]);
        setWebsiteFields(contactData && contactData.length && contactData.filter((website: IContactData) => website.type === 3).length > 0 ? contactData.filter((website: IContactData) => website.type === 3) : [{ id: generateId(), type: 3, value: "" }]);
    }, [object.id, object.telefonnummer])

    //Use effect to update the contact data when phone, email or website fields change
    useEffect(() => {
        if(phoneFieldsRef.current !== phoneFields || websiteFieldsRef.current !== websiteFields || emailFieldsRef.current !== emailFields) {
            setContactData([...phoneFields, ...emailFields, ...websiteFields]);
            phoneFieldsRef.current = phoneFields;
            websiteFieldsRef.current = websiteFields;
            emailFieldsRef.current = emailFields;
            contactDataRef.current = contactData;
        }
    }, [phoneFields, websiteFields, emailFields])


    //Handle fields for phone number
    const addPhoneField = () => {
        let tempPhoneFields = [...phoneFields]
        tempPhoneFields.push({
            id: generateId(),
            type: 1,
            value: ""
        })
        setPhoneFields(tempPhoneFields)
    }

    const removePhoneField = (id: number) => {
        let tempPhoneFields = [...phoneFields]
        tempPhoneFields = tempPhoneFields.filter(number => number.id !== id)
        setPhoneFields(tempPhoneFields)
    }


    //Handle fields for email
    const addEmailField = () => {
        let tempEmailFields = [...emailFields]
        tempEmailFields.push({
            id: generateId(),
            type: 2,
            value: ""
        })
        setEmailFields(tempEmailFields)
    }

    const removeEmailField = (id: number) => {
        let tempEmailField = [...emailFields]
        tempEmailField = tempEmailField.filter(email => email.id !== id)
        setEmailFields(tempEmailField)
    }


    //Handle fields for website
    const addWebsiteField = () => {
        let tempWebsiteFields = [...websiteFields]
        tempWebsiteFields.push({
            id: generateId(),
            type: 3,
            value: ""
        })
        setWebsiteFields(tempWebsiteFields)
    }

    const removeWebsiteField = (id: number) => {
        let tempWebsiteFields = [...websiteFields]
        tempWebsiteFields = tempWebsiteFields.filter(website => website.id !== id)
        setWebsiteFields(tempWebsiteFields)
    }

    //Function to set phone data
    const setPhoneData = (id: number, value: string) => {
        const tempPhoneFields = phoneFields.map((field: IContactData) => {
            if (field.id === id) {
                return { ...field, value }
            }
            return field
        })
        setPhoneFields(tempPhoneFields);
    }

    //Function to set email data
    const setEmailData = (id: number, value: string) => {
        const tempEmailFields = emailFields.map((field: IContactData) => {
            if (field.id === id) {
                return { ...field, value }
            }
            return field
        })
        setEmailFields(tempEmailFields);
    }

    //Function to set website data
    const setWebsiteData = (id: number, value: string) => {
        const tempWebsiteFields = websiteFields.map((field: IContactData) => {
            if (field.id === id) {
                return { ...field, value }
            }
            return field
        })
        setWebsiteFields(tempWebsiteFields)
    }
    return (
        <div>
            <span className="label">
                Telefonnummer:
            </span>
            {phoneFields.map((number: IContactData) => (
                <div className={styles.dynamicFormContainer} key={`tel-${number.id}`}>

                    <div className="form-row-label">
                        <InputField
                            type="tel"
                            placeholder="Telefonnummer"
                            value={number.value}
                            onChangeHandler={(value) => setPhoneData(number.id, value)}
                        />
                    </div>

                    <IconButton onClick={addPhoneField} color="primary" className={styles.dynamicFormButton}>
                        <AddIcon />
                    </IconButton>


                    <IconButton onClick={() => removePhoneField(number.id)} color="primary" className={styles.dynamicFormButton} disabled={phoneFields.length > 1 ? false : true}>
                        <RemoveIcon />
                    </IconButton>


                </div>

            ))}


            <span className="label">
                Email:
            </span>
            {emailFields.map((email: IContactData) => (
                <div className={styles.dynamicFormContainer} key={`mail-${email.id}`}>
                    <div className="form-row-label">
                        <InputField
                            type="email"
                            placeholder="Email"
                            value={email.value}
                            onChangeHandler={(value) => setEmailData(email.id, value)}
                        />
                    </div>

                    <IconButton onClick={addEmailField} color="primary" className={styles.dynamicFormButton}>
                        <AddIcon />
                    </IconButton>

                    <IconButton onClick={() => removeEmailField(email.id)} color="primary" className={styles.dynamicFormButton} disabled={emailFields.length > 1 ? false : true}>
                        <RemoveIcon />
                    </IconButton>
                </div>
            ))}


            <span className="label">
                Website:
            </span>
            {websiteFields.map((website: IContactData) => (
                <div className={styles.dynamicFormContainer}>
                    <div className="form-row-label" key={`web-${website.id}`}>
                        <InputField
                            type="url"
                            placeholder="Website"
                            value={website.value}
                            onChangeHandler={(value) => setWebsiteData(website.id, value)}
                        />
                    </div>

                    <IconButton onClick={addWebsiteField} color="primary" className={styles.dynamicFormButton}>
                        <AddIcon />
                    </IconButton>

                    <IconButton onClick={() => removeWebsiteField(website.id)} color="primary" className={styles.dynamicFormButton} disabled={websiteFields.length > 1 ? false : true}>
                        <RemoveIcon />
                    </IconButton>
                </div>
            ))}

        </div>

    )
}

export default PersonContactDataDynamicForm