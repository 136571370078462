import { Button } from '@mui/material';
import { CartContext } from '../../Contexts/ShoppingCartContext/CartContext';
import { useContext } from 'react';
import { getProductData, getCorrectUserPrice } from './ProductsStore';
import CTAButton from '../Buttons/CTAButton/CTAButton';



interface CartProductProps {
    id: string;
    quantity: number;
}

const CartProduct: React.FC<CartProductProps> = ({ id, quantity }) => {
    const cart = useContext(CartContext);
    const productData = getProductData(id);

    return (
        <>
            <h3>{productData?.title}</h3>
            <p>Menge: {quantity}</p>
            <p>{getCorrectUserPrice(quantity, productData?.id)}€</p>
            <p>{productData?.description}</p>
            <CTAButton title={'Entfernen'} onClickHandler={() => cart.deleteFromCart(id)} style={{width: '33%'}}/>
            <hr></hr>
        </>
    )
}

export default CartProduct;
