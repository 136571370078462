import WorktimeOverviewCard from "../../../Components/PersonalCards/WorkTimeOverviewCard"

function Arbeitszeitenuebersicht() {
    return (
        <>
            <WorktimeOverviewCard
                width="100%"
                height="100%"
            />
        </>
    )
}

export default Arbeitszeitenuebersicht