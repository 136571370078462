// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchBar_container__tSidI {\n    padding: 10px;\n    border-radius: var(--borderRadius);\n    background-color: var(--light);\n    gap: 10px;\n}\n.SearchBar_container__tSidI > input {\n    outline: none;\n    border: none;\n    width: 100%;\n    background-color: transparent;\n}", "",{"version":3,"sources":["webpack://./src/Components/SearchBar/SearchBar.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;IAClC,8BAA8B;IAC9B,SAAS;AACb;AACA;IACI,aAAa;IACb,YAAY;IACZ,WAAW;IACX,6BAA6B;AACjC","sourcesContent":[".container {\n    padding: 10px;\n    border-radius: var(--borderRadius);\n    background-color: var(--light);\n    gap: 10px;\n}\n.container > input {\n    outline: none;\n    border: none;\n    width: 100%;\n    background-color: transparent;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchBar_container__tSidI"
};
export default ___CSS_LOADER_EXPORT___;
