import { AxiosResponse } from 'axios'
import queryString from 'query-string'
import { useState } from 'react'
import { useLocation } from 'react-router-dom'
import DeliveryNoteFormCard from '../../Components/DeliveryNote/DeliveryNoteFormCard'
import DeliveryNotePdfViewerCard from '../../Components/PdfViewerCard/DeliveryNotePdfViewerCard'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { IPerson } from '../../Contexts/ContactsContext/ContactsProvider'
import { IProductDTO } from '../../Contexts/ProductContext/ProductProvider'
import { IDeliveryNote } from '../../Contexts/TaskContext/TaskContext'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useMobileView } from '../../Hooks/useMobileView'
import { IOrganization } from '../../Interfaces/Company'
import { ICompanyData } from '../../Interfaces/CompanyData'
import { IServiceDTO } from '../../Interfaces/Service'
import { ITask, ITaskDTO } from '../../Interfaces/Task'

export interface IDeliveryNotePosition {
  id?: number
  title?: string
  description?: string
  quantity?: number
  netto?: number
  deliveryNoteId?: number
  productId?: number
  serviceId?: number
  taskId?: number
  organizationId?: number
  unit?: number
}

export interface IDeliveryNoteDTO extends IDeliveryNote {
  services?: Array<IServiceDTO>
  products?: Array<IProductDTO>
  positions?: Array<IDeliveryNotePosition>
  organization?: ICompanyData
  customerOrganization?: IOrganization
  customerOrganizationId?: number
  customerPerson?: IPerson
  customerPersonId?: number
  isOrganization?: boolean
  signature?: any
  tasks?: Array<ITaskDTO | ITask>
}

const LieferscheinErstellen = () => {
  // Access the current location object
  const location = useLocation();

  // Parse and extract query parameters
  const queryParams = queryString.parse(location.search);
  const id = parseInt(queryParams.id && !Array.isArray(queryParams.id) ? queryParams.id : '', 10);
  const taskId = parseInt(queryParams.taskId && !Array.isArray(queryParams.taskId) ? queryParams.taskId : '', 10);
  const projectId = parseInt(queryParams.projectId && !Array.isArray(queryParams.projectId) ? queryParams.projectId : '', 10);

  const connection = useAuthConnection();
  const [document, setDocument] = useState<any>(undefined);

  const isMobile = useMobileView();

  const generatePdf = () => {
    connection.post(`/task/GeneratePreviewPdf`, { id: id }).then((res: AxiosResponse) => {
      setDocument(res.data);
    });
  }

  const { companyData } = useCompanyData();

  const [deliveryNoteDTO, setDeliveryNoteDTO] = useState<IDeliveryNoteDTO>({
    organization: companyData,
    date: new Date(),
    isOrganization: true,
    textBefore: "Sehr geehrte Damen und Herren,\n\nvielen Dank für Ihre Bestellung und das damit verbundene Vertrauen!\nWir liefern Ihnen wie vereinbart folgende Waren:",
    textAfter: "Die gelieferte Ware bleibt bis zur vollständigen Bezahlung unser Eigentum." +
      `\n\nMit freundlichen Grüßen\nIhr Team von ${companyData.name}`
  });

  return (
    <>
      {!isMobile &&
        <>
          <div className='w-[50%]'>
            <DeliveryNoteFormCard
              deliveryNoteDTO={deliveryNoteDTO}
              setDeliveryNoteDTO={setDeliveryNoteDTO}
              setDocument={setDocument}
              id={id}
              taskId={taskId}
              projectId={projectId}
            />
          </div>
          <div className='w-[50%]'>
            <DeliveryNotePdfViewerCard object={deliveryNoteDTO} setObject={setDeliveryNoteDTO} document={document} setDocument={setDocument} />
          </div>

        </>
      }

      {isMobile &&
        <>
          <DeliveryNoteFormCard
            deliveryNoteDTO={deliveryNoteDTO}
            setDeliveryNoteDTO={setDeliveryNoteDTO}
            setDocument={setDocument}
            id={id}
            taskId={taskId}
            projectId={projectId}
          />
          <DeliveryNotePdfViewerCard object={deliveryNoteDTO} setObject={setDeliveryNoteDTO} document={document} setDocument={setDocument} />
        </>
      }
    </>



  )
}

export default LieferscheinErstellen