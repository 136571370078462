import { Button } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Crop } from 'react-image-crop'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { journalDTO } from '../../Interfaces/Journal'
import InputField from '../InputField/InputField'
import MultiLineInputField from '../InputField/MultiLineInputField'
import Form from './FormUtils/Form'
import FormRow from './FormUtils/FormRow'
import styles from '../PersonalSettingsCard/ProfilePictureCard.module.css'
import { AddBox, Delete, FileUpload, PlusOne } from '@mui/icons-material'
import { useDocuments } from '../../Contexts/DocumentContext/DocumentContext'
import { authConnection } from '../../Connection/BaseConnection'
import uuid from 'react-uuid'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FileInput from '../FileInput/FileInput'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'

const CreateJournalForm = ({ object, setObject, error }: { error?: any, object?: journalDTO | null, setObject: Dispatch<SetStateAction<journalDTO>> }) => {
  const { persons } = useContacts();
  const { companies } = useContacts();

  return (
    <Form>
      <FormRow
        label='Titel'
        placeholder='Journaltitel eingeben'
        type='text'
        value={object?.title}
        onChange={(val) => setObject((prev: journalDTO) => ({ ...prev, title: val }))}
        error={error.titleError || error.titleTooLongError}
        errorMessage={error.titleError ? InputFieldErrorMessages.TitleError : "Titel darf maximal 500 Zeichen lang sein"}
      />
      <FormRow
        label='Journaltext'
        placeholder='Journaltext eingeben(2500 Zeichen)'
        type='multiline'
        value={object?.description}
        onChange={(val) => setObject((prev: journalDTO) => ({ ...prev, description: val }))}
        error={error.descriptionError || error.descriptionTooLongError}
        errorMessage={error.descriptionError ? InputFieldErrorMessages.JournaltextError : InputFieldErrorMessages.JournaltextTooLongError}
      />
      {/*<div className='form-row-label'>
        <span className="label">
          Priorität:
        </span>
        <InputField
          type='number'
          step='1'
          value={object?.priority}
          onChangeHandler={(val) => setObject((old: journalDTO) => ({ ...old, priority: parseInt(val) >= 0 && parseInt(val) <= 5 ? parseInt(val) : old.priority }))}
          placeholder="Priorität"
        />
  </div>*/}
      {/*<FormRow
        value={object?.date ? object.date : new Date()}
        onChange={(val) => setObject((old) => ({ ...old, date: val }))}
        label="Datum"
        type="Date"
  />*/}
      <FormRow
        value={object?.personId}
        type="select"
        onChange={(val) => setObject((old: journalDTO) => ({ ...old, personId: val, customerOrganizationId: persons.find(temp => temp.id === val)?.belongsToOrganizationId }))}
        label="Betroffene Person (optional)"
        placeholder='Person auswählen'
        selectOptions={{
          options: persons,
          titleKey: "completeName",
          valueKey: "id",
        }}
      />
      <FormRow
        value={object?.customerOrganizationId}
        type="select"
        onChange={(val) => setObject((old: journalDTO) => ({ ...old, customerOrganizationId: val }))}
        label="Betroffene Firma (optional)"
        placeholder='Firma auswählen'
        selectOptions={{
          options: companies,
          titleKey: "name",
          valueKey: "id",
        }}
      />
      <div className='form-row-label'>
        <span className="label">
          {"Anhänge (optional)"}
        </span>
        {object?.files?.map((file: any, index: number) => {
          return (<FileInput file={file} setFile={(newFile: any) => { setObject((old: any) => ({ ...old, files: old.files.map((file: any, i: number) => { if (i === index) { return newFile } return file }) })) }} key={index} setObject={setObject} index={index} ></FileInput>)
        })}
      </div>
      {((object?.documents && object.documents.length && object.files && object.files.length && object.documents.length === object.files.length) || (object?.files === undefined) || (object.files.length === 0)) &&
        <div style={{ width: "100%" }}>
          <Button variant='outlined' component="label" style={{ marginBottom: "1vh", borderColor: "gray", color: "gray" }} onClick={() => { setObject((old: any) => ({ ...old, files: old.files ? [...old.files, {}] : [{}] })) }}>
            <AddBox style={{ marginRight: "2px" }} />
          </Button>
        </div>
      }
    </Form >
  )
}

export default CreateJournalForm