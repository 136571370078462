import React from 'react'
import OfferCard from '../../Components/OfferCard/OfferCard'
import Page from '../../Navigation/Page'

const ManageOffers = () => {
  return (
    <>
      <OfferCard
        height='100%'
        width='100%'
      />
    </>
  )
}

export default ManageOffers