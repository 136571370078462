import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import styles from './BillsCard.module.css'

import { IBillDTO, IBillingPosition, DiscountType } from '../../Interfaces/Bill';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import EditBillingPositionDialog from '../Dialogs/EditBillingPosition/EditBillingPositionDialog';
import useAuthConnection from '../../Hooks/useAuthConnection';
import { AxiosResponse } from 'axios';
import { IIntervallDTO, IIntervallPosition, useIntervalls } from '../../Contexts/IntervallContext/IntervallProvider';
import EditCreateIntervallPositionDialog from '../Dialogs/EditCreateIntervalDialog/EditCreateIntervallPositionDialog';
import ContextMenu from '../Menu/ContextMenu';
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton';
import { MoreVertRounded } from '@mui/icons-material';

function SubscriptionPositionRow({ intervall, setIntervall, intervallPosition, intervallPositions, setIntervallPositions, backgroundColor, mode }: { intervall: IIntervallDTO, setIntervall: Dispatch<SetStateAction<IIntervallDTO>>, intervallPosition: IIntervallPosition, intervallPositions: any, setIntervallPositions: any, backgroundColor?: string, generatePdf: () => void, getOpenServicesOfCustomerId?: () => void, mode: "create" | "edit" | "createNewIntervall" }) {
    const [open, setOpen] = useState<boolean>(false);

    const onPressEdit = () => {
        setOpen(true);
    }

    const { removeIntervallPosition } = useIntervalls();

    const connection = useAuthConnection();

    const onPressDelete = () => {
        if (mode !== "createNewIntervall") {
            removeIntervallPosition(intervallPosition);
        }

        setIntervallPositions(intervallPositions.filter((bp: IBillingPosition) => {
            console.log(bp.id, intervallPosition.id)
            if (bp.id === intervallPosition.id) {
                return false;
            }
            return true;
        }));
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const onSubmitEdit = (tempBillingPosition: IBillingPosition) => {
        let tempBillingPositions = intervallPositions;

        tempBillingPositions = tempBillingPositions.map((tempBP: IBillingPosition) => {
            if (tempBP.id === intervallPosition.id) {
                return tempBillingPosition;
            }
            return tempBP;
        });

        setIntervallPositions(tempBillingPositions);
    }


    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    return (
        <>
            <div className='flex row' style={{ width: "100%", alignItems: "center", justifyContent: "space-between", backgroundColor: backgroundColor ?? "transparent" }}>
                <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: backgroundColor ?? "transparent" }}>
                    <header className='flex centered-aligned stretched-justify'>
                        <div className='flex centered-aligned' style={{ gap: "6px" }}>
                            <div className={styles.accent} />
                            <span className='bold-big'>{intervallPosition.title}</span>
                        </div>
                    </header>
                    <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                        <span>
                            Netto-Einzel: {intervallPosition?.netto?.toFixed(2).replace(".", ",")} €
                        </span>
                        <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                        <span>
                            Steuer: {intervallPosition?.tax?.toFixed(2).replace(".", ",")} %
                        </span>
                        <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                        <span>
                            Menge: {intervallPosition?.quantity?.toFixed(2).replace(".", ",")}
                        </span>
                        {intervallPosition.hasDiscount === true &&
                            <>
                                <div style={{ height: "12px", width: "1px", backgroundColor: 'black', borderRadius: "100px" }} />
                                <span>
                                    Rabatt: {intervallPosition.discount?.toFixed(2).replace(".", ",")} {intervallPosition.discountType === DiscountType.FixedValue && " €"} {intervallPosition.discountType === DiscountType.Percentage && " %"}
                                </span>
                            </>
                        }
                    </section>
                    <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                        <span>
                            Netto-Gesamt: {(((intervallPosition?.netto ?? 0) * (intervallPosition?.quantity ?? 0) - ((intervallPosition.discountType === DiscountType.FixedValue ? ((intervallPosition?.discount ?? 0)) : ((intervallPosition?.netto ?? 0) * (intervallPosition?.quantity ?? 0) * ((intervallPosition?.discount ?? 0) / 100)))))).toFixed(2).replace(".", ",")} €
                        </span>
                    </section>
                </div>
                <IconTooltipButton
                    title='Optionen'
                    arrow
                    style={{ marginLeft: "-5px" }}
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                >
                    <MoreVertRounded fontSize='small' />
                </IconTooltipButton>
                <ContextMenu
                    anchorEl={anchorEl}
                    onClose={() => setAnchorEl(null)}
                    options={
                        [
                            /* TODO {
                                label: "Verrechnen",
                                onClick: () => null,
                                selectable: false
                            },*/
                            {
                                label: "Bearbeiten",
                                onClick: () => setOpen(true),
                                selectable: false
                            },
                            {
                                label: "Löschen",
                                onClick: onPressDelete,
                                selectable: false
                            },
                        ]
                    }
                />
            </div>
            <EditCreateIntervallPositionDialog mode={mode} intervall={intervall} open={open} setOpen={setOpen} object={intervallPosition} setIntervall={setIntervall}></EditCreateIntervallPositionDialog>
        </>
    )
}

export default SubscriptionPositionRow