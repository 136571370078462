// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DoubleOptInConfirmationPage_container__4vD\\+1 {\n    padding: 40px;\n    border-radius: var(--borderRadiusOuter);\n    background-color: var(--background);\n    width: 50%;\n    gap: 10px;\n}\n\n@media all and (max-width: 768px) {\n    .DoubleOptInConfirmationPage_container__4vD\\+1 {\n        gap: 6vh;\n        width: 100%;\n    }\n}", "",{"version":3,"sources":["webpack://./src/Pages/DoubleOptIn/DoubleOptInConfirmationPage.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uCAAuC;IACvC,mCAAmC;IACnC,UAAU;IACV,SAAS;AACb;;AAEA;IACI;QACI,QAAQ;QACR,WAAW;IACf;AACJ","sourcesContent":[".container {\n    padding: 40px;\n    border-radius: var(--borderRadiusOuter);\n    background-color: var(--background);\n    width: 50%;\n    gap: 10px;\n}\n\n@media all and (max-width: 768px) {\n    .container {\n        gap: 6vh;\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "DoubleOptInConfirmationPage_container__4vD+1"
};
export default ___CSS_LOADER_EXPORT___;
