import { AxiosResponse } from 'axios'
import React, { createContext, ReactNode, useContext, useEffect, useReducer, useState } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { ICompanyData } from '../../Interfaces/CompanyData'
import { CompanyDataReducer } from './CompanyDataReducer'
import { errorHandler } from '../../Connection/BaseConnection'
import { autoHideDurationDefault } from '../../Global/Variables'
import dayjs from 'dayjs'

interface companyDataContext {
    companyData: ICompanyData
    setCompanyData?: React.Dispatch<React.SetStateAction<ICompanyData>>
    setFiscalYear?: (year: Date) => void,
    setLogo?: (formData: FormData, b64: string, mode: string) => void
    updateCompanyData?: (companyData: ICompanyData) => void
}

const defaultValue = {
    name: "",
    slogan: "",
    id: null,
    website: "",
    telephone: "",
    eMail: "",
    uid: "",
    taxNumber: "",
    registrationNumber: "",
    court: "",
    kleinunternehmer: false,
    pauschaliert: false,
    logoRectangle: "",
    logoRectangleBase64: "",
    logoSquare: "",
    deleted: false,
    subscribedSince: "",
    eCockpitOrganizationId: null,
    fiscalYear: [],
    subscription: ""
}

const CompanyDataContext = createContext<companyDataContext>({
    companyData: defaultValue
})

const CompanyDataProvider = ({ children }: { children: ReactNode }) => {

    const connection = useAuthConnection();

    const { enqueueSnackbar, closeSnackbar } = useSnackBar();

    const [state, dispatch] = useReducer(CompanyDataReducer, {
        name: "AHK Softwaregutachten.at e.U.",
        slogan: "So einfach wie möglich, so kompliziert wie notwendig.",
        id: 1,
        website: "www.softwaregutachten.at",
        telephone: "0660 12312312",
        eMail: "kontakt@softwaregutachten.at",
        uid: "123123",
        taxNumber: "123123",
        registrationNumber: "123123",
        court: "Klagenfurt",
        kleinunternehmer: false,
        pauschaliert: false,
        logoRectangle: "",
        logoSquare: "",
        logo: "",
        deleted: false,
        subscribedSince: "1.1.2022",
        eCockpitOrganizationId: 1,
        fiscalYear: [],
        subscription: ""
    })

    const fetchCompanyData = () => {
        connection.get('/organization/ofself')
            .then((res: AxiosResponse) => {
                // Get current year
                const currentYear = dayjs().year();

                // January 1st of current year
                const januaryFirst = dayjs().year(currentYear).startOf('year').format('YYYY-MM-DD');

                // December 31st of current year
                const decemberThirtyFirst = dayjs().year(currentYear).endOf('year').format('YYYY-MM-DD');

                setCompanyData({ ...res.data, fiscalYear: [januaryFirst, decemberThirtyFirst] });
            })
    }

    const setLogo = (formData: FormData, b64: string, mode: string) => {
        let x = enqueueSnackbar("Logo wird gesetzt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/organization/setLogo", formData, {
            params: {
                mode
            }
        })
            .then(() => {
                closeSnackbar(x);
                enqueueSnackbar("Logo wurde erfolgreich gesetzt", { variant: "success" })

                if (mode === "Rectangle") {
                    setCompanyData((old: ICompanyData) => ({ ...old, logoRectangleBase64: b64.split(",")[1] }))
                } else {
                    setCompanyData((old: ICompanyData) => ({ ...old, logoSquareBase64: b64.split(",")[1] }))
                }
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            });
    }

    const updateCompanyData = (company: ICompanyData) => {
        let x = enqueueSnackbar("Firmendaten werden gespeichert");

        connection.put("/organization/UpdateECockpitOrganization", { ...company })
            .then((res: AxiosResponse) => {
                const { data } = res;
                dispatch({
                    type: "EDIT_COMPANY",
                    payload: company
                })

                closeSnackbar(x);
                enqueueSnackbar("Firmendaten erfolgreich bearbeitet.", { variant: "success" })

                return data;
            })
            .catch((error: any) => {
                // Error
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);


                    if (error.response.status !== 401) {
                        enqueueSnackbar("Ein Fehler ist aufgetreten. Sollte dieser Fehler weiter bestehen melden Sie sich bitte bei unserer Hotline.", { variant: "error" })
                    } else {
                        //Wenn Passwort oder Username falsch
                        enqueueSnackbar("Ihre Authentifizierung ist abgelaufen, bitte loggen Sie sich erneut ein.", { variant: "warning" })
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the 
                    // browser and an instance of
                    // http.ClientRequest in node.js

                    enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
                } else {
                    enqueueSnackbar("Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite neu zu laden.", { variant: "error" })
                }
            })
    }

    useEffect(() => { fetchCompanyData(); }, [])

    const setFiscalYear = (year: Date) => {
        const currentYear = dayjs(year).year();

        // January 1st of current year
        const januaryFirst = dayjs().year(currentYear).startOf('year').format('YYYY-MM-DD');

        // December 31st of current year
        const decemberThirtyFirst = dayjs().year(currentYear).endOf('year').format('YYYY-MM-DD');

        setCompanyData((old) => ({ ...old, fiscalYear: [januaryFirst, decemberThirtyFirst] }))
    }

    const [companyData, setCompanyData] = useState<ICompanyData>({
        ...defaultValue
    })

    return (
        <CompanyDataContext.Provider value={{
            companyData,
            setCompanyData,
            setLogo,
            setFiscalYear,
            updateCompanyData
        }}>
            {children}
        </CompanyDataContext.Provider>
    )
}

export default CompanyDataProvider

export const useCompanyData = () => useContext(CompanyDataContext)