import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { productsArray } from './ProductsStore';
import ProductCard from './ProductCard';
import { Divider, Typography } from '@mui/material';
import { useContext } from 'react';
import { CartContext } from '../../Contexts/ShoppingCartContext/CartContext';

const BorderGrid = styled(Grid)(({ theme }) => ({
    boxSizing: 'border-box',
}));

const PaddedBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    // ... other styles you might have
}));

export default function StoreComponent() {

    const cart: any = useContext(CartContext);
    const items = cart.items;

    const hasBusinnes = items.some((item: { id: string; }) => item.id === productsArray[0].id || item.id === productsArray[1].id);
    const hasBusiness0 = items.some((item: { id: string; }) => item.id === productsArray[0].id);
    const hasBusiness1 = items.some((item: { id: string; }) => item.id === productsArray[1].id);
    const hasModule = items.some((item: { id: string; }) => item.id === productsArray[2].id || item.id === productsArray[3].id || item.id === productsArray[4].id || item.id === productsArray[5].id || item.id === productsArray[6].id);

    const len = productsArray.length;
    return (
        <Grid container sx={{backgroundColor: ''}} columnSpacing={{ xs: 1, sm: 2, md: 2 }} rowSpacing={{ xs: 2, sm: 2, md: 3 }}>
            <BorderGrid
                item
                xs={12} sm={12} md={12} lg={4} xl={4}
                style={{
                    pointerEvents: hasModule ? "none" : "auto",
                    opacity: hasModule ? 0.4 : 1
                }}
            >
                <PaddedBox>
                    <Typography margin='10px' variant="h5">Business Pläne</Typography>
                    <Divider sx={{margin: '10px', borderColor: 'darkgrey'}}/>
                    {productsArray.slice(0, 1).map((product, idx) => (
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <ProductCard key={idx} product={product} />
                        </div>
                    ))}
                    {productsArray.slice(1, 2).map((product, idx) => (
                        <div style={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            pointerEvents: hasBusiness0 ? "none" : "auto",
                            opacity: hasBusiness0 ? 0.4 : 1
                        }}>
                            <ProductCard key={idx} product={product} />
                        </div>
                    ))}
                </PaddedBox>
            </BorderGrid>
            <BorderGrid
                item
                xs={12} sm={12} md={12} lg={4} xl={4}
                style={{
                    pointerEvents: hasBusinnes ? "none" : "auto",
                    opacity: hasBusinnes ? 0.4 : 1
                }}
            >
                <PaddedBox>
                    <Typography margin='10px' variant="h5">Module</Typography>
                    <Divider sx={{margin: '10px', borderColor: 'darkgrey'}}/>
                    {productsArray.slice(2, len - 2).map((product, idx) => (
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <ProductCard key={idx + 2} product={product} />
                        </div>
                    ))}
                </PaddedBox>
            </BorderGrid>
            <BorderGrid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <PaddedBox>
                    <Typography margin='10px' variant="h5">Kontingente</Typography>
                    <Divider sx={{margin: '10px', borderColor: 'darkgrey'}}/>
                    {productsArray.slice(len - 2, len).map((product, idx) => (
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
                            <ProductCard key={idx + len - 2} product={product} />
                        </div>
                    ))}
                </PaddedBox>
            </BorderGrid>
        </Grid>
    );
}
