import dayjs, { Dayjs } from "dayjs"

export interface ITravel extends ITravelDTO {
    id: number
}

export interface ITravelDTO {
    startDestination: string
    endDestination: string
    return: boolean
    distance: number
    note?: string
    serviceId?: number | null
    organizationId: number
    customerOrganizationId?: number
    userIds: number[]
    date: Dayjs
    startMileage?: number
    endMileage?: number
    timeStart?: Dayjs | null
    timeEnd?: Dayjs | null
}

export const defaultTravel: ITravel = {
    id: 0,
    startDestination: "",
    endDestination: "",
    return: false,
    distance: 0,
    note: "",
    serviceId: null,
    //!
    organizationId: 0,
    customerOrganizationId: undefined,
    userIds: [],
    date: dayjs(),
    timeStart: null,
    timeEnd: null
}