import { useEffect, useState } from "react"
import { AppointmentCategoryVisibilityTypes, useTasks } from "../../Contexts/TaskContext/TaskContext"
import { IAppointmentCategory, ICreateAppointmentCategory, appointmentVisibilityList } from "../../Interfaces/Appointment"
import styles from "../../Pages/Calendar/Calendar.module.css"
import CTAButton from "../Buttons/CTAButton/CTAButton"
import { InputFieldErrorMessages } from "../InputField/InputFieldRefactored"
import FormRow from "./FormUtils/FormRow"

interface props {
    onClose: () => void,
    errorVariables?: Record<string, boolean>,
    validateInput: (a: ICreateAppointmentCategory) => boolean,
    appointmentCategoryToEdit?: IAppointmentCategory,
    mode?: string,
    visibility?: AppointmentCategoryVisibilityTypes
}



const CreateAppointmentCategoryForm = ({ onClose, errorVariables, validateInput, appointmentCategoryToEdit, mode, visibility }: props) => {
    const [appointmentCategory, setAppointmentCategory] = useState<IAppointmentCategory>({ id: 0, visibility: visibility ?? 0, color: '#38FFFC' })
    const { updateAppointmentCategory, addAppointmentCategory } = useTasks()
    const handleSubmit = () => {
        if (validateInput(appointmentCategory!)) {
            if (mode === "edit") {
                updateAppointmentCategory!(appointmentCategory)
            } else {
                addAppointmentCategory!(appointmentCategory)
            }

            onClose()
        }
    }

    useEffect(() => {
        if (appointmentCategoryToEdit && mode === "edit") {
            setAppointmentCategory(appointmentCategoryToEdit)
        }
    }, [appointmentCategoryToEdit])

    return (
        <form
            className="form">
            <div style={{ display: "flex", flexDirection: "row", gap: "10px", alignItems: 'center' }}>
                <FormRow type="text"
                    value={appointmentCategory?.name}
                    onChange={(val) => setAppointmentCategory((old) => ({ ...old, name: val }))}
                    label="Name"
                    placeholder="Name eingeben"
                    maxLength={200}
                    error={errorVariables?.nameError}
                    errorMessage={InputFieldErrorMessages.NameRequired}
                />

                <div className={"form-row-label"} style={{ width: 'fit-content' }}>
                    <span className="label">
                        Farbe
                    </span>
                    <input type="color" value={appointmentCategory?.color}
                        onChange={(event) => setAppointmentCategory((old) => ({ ...old, color: event.target.value }))}
                        className={styles.colorPicker}
                    />
                </div>
            </div>
            <FormRow type="select"
                value={appointmentCategory.visibility}
                onChange={(val) => {
                    console.log(appointmentVisibilityList)
                    setAppointmentCategory((old) => ({ ...old, visibility: val }))
                }}
                label="Sichtbarkeit"
                placeholder="Sichtbarkeit auswählen"
                selectOptions={{
                    titleKey: 'name',
                    valueKey: 'visibility',
                    options: appointmentVisibilityList
                }}
                error={errorVariables?.visibilityError}
                errorMessage={InputFieldErrorMessages.VisibilityRequired} />
            <CTAButton
                title="Speichern"
                onClickHandler={() => handleSubmit()}
            />
        </form>
    )
}

export default CreateAppointmentCategoryForm
