import React, { useEffect, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CompanyRegister from '../../Components/ContactCards/CompanyRegister'
import ContactFormCard, { contactFormModes } from '../../Components/ContactCards/ContactFormCard'
import PersonCard from '../../Components/ContactCards/PersonCard'
import { defaultCompany } from '../../Contexts/ContactsContext/ContactsProvider'
import { IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useFetch } from '../../Hooks/useFetch'
import { IOrganization } from '../../Interfaces/Company'
import Page from '../../Navigation/Page'

const Contacts = () => {
    useFetch(["firmen", "personen"])

    const { defaultPerson } = useContacts()

    const [clickedPerson, setClickedPerson] = useState<IPersonDTO>(defaultPerson!);
    const [clickedCompany, setClickedCompany] = useState<IOrganization>(defaultCompany);

    const [formMode, setFormMode] = useState<contactFormModes>("company");

    return (
        <>
            <CardColumn
                height='100%'
                width='25%'
            >
                <CompanyRegister
                    height='100%'
                    cta
                    ctaTitle='Organisation erstellen'
                    setClickedCompany={setClickedCompany}
                    setFormMode={setFormMode}
                />
            </CardColumn>
            <CardColumn
                height='100%'
                width='25%'
            >
                <PersonCard
                    cta
                    ctaTitle='Person erstellen'
                    height='100%'
                    clickedCompany={clickedCompany}
                    setClickedPerson={setClickedPerson}
                    setFormMode={setFormMode}
                />
            </CardColumn>
            <CardColumn
                height='100%'
                width='50%'
            >
                <ContactFormCard
                    height='100%'
                    clickedCompany={clickedCompany}
                    setClickedCompany={setClickedCompany}
                    clickedPerson={clickedPerson}
                    setClickedPerson={setClickedPerson}
                    formMode={formMode}
                    setFormMode={setFormMode}
                />
            </CardColumn>
        </>
    )
}

export default Contacts