import { authConnection } from "../Connection/BaseConnection"
import { useEffect } from "react"
import useRefreshToken from "./useRefreshToken"
import { useUser } from "../Contexts/UserContext/UserContext"
import { useLocalStorage } from "./useLocalStorage"


const useAuthConnection = () => {
    const refresh = useRefreshToken()
    const { user, setUser, token } = useUser()

    //Provider erreicht anscheinend nicht jede Axios-Instanz-weshalb der Token aus dem localStorage gezogen wird
    //TODO: Security-Measures
    const MAX_REQUESTS_COUNT = 25
    const INTERVAL_MS = 10
    let PENDING_REQUESTS = 0

    let lsToken = localStorage.getItem("Token");

    authConnection.interceptors.request.use(
        async (config) => {
            if (!config?.headers!['Authorization']) {
                config!.headers!['Authorization'] = `Bearer ${(token ?? lsToken)}`;
            }


            return config;
            
            return new Promise((resolve, reject) => {
                let interval = setInterval(() => {
                    if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
                        PENDING_REQUESTS++
                        clearInterval(interval)
                        resolve(config)
                    }
                }, INTERVAL_MS)
            });
        },
        (error) => Promise.reject(error)
    )

    authConnection.interceptors.response.use(
        response => {
            if (response.status >= 200 && response.status < 300) {
                PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

                return Promise.resolve(response)
            } else {
                return Promise.reject(response);
            }
        },
        async (error) => {
            PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

            const prevRequest = error?.config

            if (error?.response?.status === /* Sollte auf den tatsächlichen Code angepasst werden */ 401 && !prevRequest?.sent) {
                prevRequest.sent = true
                const newAccessToken = await refresh()

                if (newAccessToken === undefined) {
                    setUser!(null);
                } else {
                    localStorage.setItem("AccessToken", newAccessToken)
                }
                prevRequest.headers["Authorization"] = `Bearer ${newAccessToken}`

                return authConnection(prevRequest)
            } else {
                return Promise.reject(error);
            }
        }
    )

    return authConnection
}

export default useAuthConnection