import { AddRounded, Drafts, Send } from "@mui/icons-material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ButtonControl from "../../Components/Buttons/ButtonControl/ButtonControl";
import Card from "../../Components/Card/Card";
import CardColumn from "../../Components/Card/CardColumn/CardColumn";
import NewsletterRow from "../../Components/NewsletterRow/NewsletterRow";
import NewsletterSentRow from "../../Components/NewsletterSentRow/NewsletterSentRow";
import { INewsletterDesign, useContacts } from "../../Contexts/ContactsContext/ContactsProvider";

const NewsletterOverview = () => {
    const { newsletterDrafts, newsletterSent, copyNewsletterDraft } = useContacts();

    const navigate = useNavigate();

    const [mode, setMode] = useState<"drafts" | "sent">("drafts");
    const buttons = [
        {
            body: (active: boolean) => active ? <span className='centered' style={{ gap: "2px", fontSize: "small" }}><Drafts fontSize='small' />Entwürfe</span> : <Drafts fontSize='small' />,
            value: "drafts"
        },
        {
            body: (active: boolean) => active ? <span className='centered' style={{ gap: "2px", fontSize: "small" }}><Send fontSize='small' />Versendet</span> : <Send fontSize='small' />,
            value: "sent"
        }
    ]


    return (
        <>
            <CardColumn
                height="95%"
                width="100%"
            >
                <Card
                    style={{ height: "100%", width: "100%" }}
                    title={(mode === "sent" ? "Versendete Newsletter" : "Newsletter Entwürfe")}
                    ctaTitle="Neuer Newsletter"
                    cta
                    right={() => <ButtonControl setValue={(val) => setMode(val)} value={mode} buttons={buttons} />}
                    buttonIcon={<AddRounded fontSize='small' />}
                    ctaFunction={() => { navigate(`/kommunikation/Newsletter/bearbeiten/`) }}
                >
                    {mode === "drafts" &&
                        <div style={{ display: "flex", flexDirection: "column", gap: "0.75em" }}>
                            <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
                                <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", paddingLeft: "1em", paddingRight: "1em" }}>
                                    <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Status</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "25%", alignItems: "center", gap: "0.33em" }}>Titel</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Autor</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Erstellt</div>
                                    <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Zuletzt geändert</div>
                                </div>
                            </div>
                            {newsletterDrafts?.map((draft: INewsletterDesign) => {
                                return <NewsletterRow onPressCopy={() => {
                                    copyNewsletterDraft!(draft)
                                        .then((res: INewsletterDesign) => { navigate(`/kommunikation/Newsletter/bearbeiten/${res.id!}`) })
                                }} draft={draft} onPressDelete={() => { }} onPressEdit={() => { navigate(`/kommunikation/Newsletter/bearbeiten/${draft.id}`) }} />
                            })}
                        </div>
                    }
                    {mode === "sent" &&
                        <>
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.75em" }}>
                                <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
                                    <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", paddingLeft: "1em", paddingRight: "1em" }}>
                                        <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Status</div>
                                        <div style={{ display: "flex", flexDirection: "row", width: "25%", alignItems: "center", gap: "0.33em" }}>Titel</div>
                                        <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Autor</div>
                                        <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Anzahl Empfänger</div>
                                        <div style={{ display: "flex", flexDirection: "row", width: "15%", alignItems: "center", gap: "0.33em" }}>Erstellt</div>
                                    </div>
                                </div>
                                {newsletterSent?.map((sentNewsletter: INewsletterDesign) => {
                                    return <NewsletterSentRow onPressCopy={() => { }} sentNewsletter={sentNewsletter} onPressDelete={() => { }} onPressEdit={() => { navigate(`/kommunikation/Newsletter/bearbeiten/${sentNewsletter.id}`) }} />
                                })}
                            </div>
                        </>}
                </Card>
            </CardColumn>
        </>
    )

}

export default NewsletterOverview