import { ArrowForward } from "@mui/icons-material"
import { CircularProgress } from "@mui/material"
import InputField from "../../Components/InputField/InputField"
import styles from './Login.module.css'
import { useState, useEffect } from 'react'
import { useNavigate, useParams } from "react-router-dom"
import { baseConnection } from "../../Connection/BaseConnection"
import { AxiosError } from "axios"

function NewPassword() {

    const [newPasswordData, setNewPasswordData] = useState<{ password: string, confirmPassword: string }>({
        password: "",
        confirmPassword: ""
    });

    const navigation = useNavigate();
    const [loading, setLoading] = useState(false);
    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
    const { code: passwordResetCode } = useParams();

    const [resetSuccess, setResetSuccess] = useState(false);
    const [invalidCode, setInvalidCode] = useState(false);
    const [codeExpired, setCodeExpired] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    const handleNewPassword = async () => {
        try {
            setLoading(true);
            const response = await baseConnection.post("/Authentication/resetPassword", {
                password: newPasswordData.password,
                resetCode: passwordResetCode
            })
            setLoading(false);
            setResetSuccess(true);

        } catch (error) {
            const err = error as AxiosError;
            console.log(err)
            setErrorMessage(err.response?.data as string);
            console.log(errorMessage);
            if (err.response?.status == 404) {
                setInvalidCode(true);
            }
            else if (err.response?.status === 409) {
                setCodeExpired(true);
            }
        }
    }

    const [errorPassword, setErrorPassword] = useState(false);
    const [errorConfirmPassword, setErrorConfirmPassword] = useState(false);

    function checkPassword(password: string) {
        const minLength = 6;
        const maxLength = 100;

        if (password.length >= 6 && password.length <= 100) {
            setErrorPassword(false);
            return;
        }

        if (password == "") {
            setErrorPassword(false);
            return;
        }

        if (password.length < 6 || password.length > 100) {
            setErrorPassword(true);
            return;
        }
    }

    function checkPasswordConfirmation(password: string, passwordCheck: string) {

        if (passwordCheck == "") {
            setErrorConfirmPassword(false);
            return;
        }

        if (password === passwordCheck) {
            setErrorConfirmPassword(false);
            return;
        }

        if (password !== passwordCheck) {
            setErrorConfirmPassword(true);
        }

    }

    useEffect(() => {
        checkPassword(newPasswordData.password);
        checkPasswordConfirmation(newPasswordData.password, newPasswordData.confirmPassword);

        if (!errorPassword && !errorConfirmPassword && (newPasswordData.password !== "" || newPasswordData.confirmPassword !== "")) {
            setContinueButtonDisabled(false);
        } else {
            setContinueButtonDisabled(true);
        }

        function onKeyup(e: KeyboardEvent) {
            if (e.key === "Enter" && !continueButtonDisabled) handleNewPassword()
          }
          window.addEventListener('keyup', onKeyup);
          return () => window.removeEventListener('keyup', onKeyup);

    }, [newPasswordData.password, newPasswordData.confirmPassword, continueButtonDisabled, errorPassword, errorConfirmPassword])

    return (
        <div className='page flex centered'>
            <section className={styles.container.concat(" flex column")}>
                <div style={{ textAlign: "center", marginTop: "1%", marginBottom: "1%", fontSize: "3vh", fontWeight: "600" }}>
                    Passwort zurücksetzen
                </div>

                {!resetSuccess && !(invalidCode || codeExpired) &&
                    <>
                        <InputField label="Neues Passwort" placeholder="Passwort" value={newPasswordData.password} onChangeHandler={(password) => setNewPasswordData((prevData) => ({ ...prevData, password }))} error={errorPassword} errorMessage="Passwort muss mindestens 6 Zeichen lang sein!" isSecure required isPassReset />
                        <InputField label="Neues Passwort bestätigen" placeholder="Passwort bestätigen" value={newPasswordData.confirmPassword} onChangeHandler={(confirmPassword) => setNewPasswordData((prevData) => ({ ...prevData, confirmPassword }))} error={errorConfirmPassword} errorMessage="Passwörter stimmen nicht überein!" isSecure required isPassReset />

                        <div className='flex stretched-justify' style={{ marginTop: "20px" }}>
                            <button className='secondary-button pointer' onClick={() => navigation("/")}>
                                Zurück zum Login
                            </button>

                            {!continueButtonDisabled &&
                                <button onClick={async () => handleNewPassword()} className="cta-button pointer" style={{ width: "40%" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                                </button>
                            }
                            {continueButtonDisabled &&
                                <button disabled className="cta-button-disabled not-allowed" style={{ width: "40%" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                                </button>
                            }

                        </div>
                    </>
                }

                {resetSuccess &&
                    <div style={{ marginTop: "1vh", textAlign: "center" }}>
                        Password wurde erfolgreich zurückgesetzt. Sie können sich nun mit Ihrem neuen Passwort anmelden! <br />
                        <div style={{ marginTop: "3vh" }}>
                            <button className='cta-button pointer' onClick={() => navigation("/")}>
                                Zurück zum Login
                            </button>
                        </div>
                    </div>
                }

                {(invalidCode || codeExpired) &&
                    <>
                        <div style={{ marginTop: "1vh", textAlign: "center" }}>
                            {errorMessage} <br />
                            <div style={{ marginTop: "3vh" }}>
                                <button className='cta-button pointer' onClick={() => navigation("/reset")}>
                                    Neue Anfrage erstellen
                                </button>
                            </div>
                        </div>
                    </>

                }


            </section>
        </div>
    )
}

export default NewPassword