import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { AppointmentCategoryVisibilityTypes, DefaultAppointmentCategoryIds, useTasks } from "../../Contexts/TaskContext/TaskContext";
import { IHoliday, useTimeTracking } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { IAppointment } from "../../Interfaces/Appointment";
import { ITask } from "../../Interfaces/Task";
import styles from "./Calendar.module.css";
import { calculateContrastColor } from "./ColorUtils";
import CreateAppointmentModal from "./CreateAppointmentModal";
import { Bolt } from "@mui/icons-material";

interface dayProps {
    day: dayjs.Dayjs,
    rowIndex?: number,
    colIndex?: number,
    monthRows?: number,
    selectedView?: string,
    showAppointmentInfoModal?: boolean,
    setShowAppointmentInfoModal?: Dispatch<SetStateAction<boolean>>,
    setClickedAppointment?: Dispatch<SetStateAction<IAppointment>>,
    showTaskInfoModal?: boolean,
    setShowTaskInfoModal?: Dispatch<SetStateAction<boolean>>,
    setClickedTask?: Dispatch<SetStateAction<ITask>>,
    showHolidayInfoModal?: boolean,
    setShowHolidayInfoModal?: Dispatch<SetStateAction<boolean>>,
    setClickedHoliday?: Dispatch<SetStateAction<IHoliday>>,
    privateCheckedCategories?: { [categoryId: number]: boolean },
    organizationCheckedCategories?: { [categoryId: number]: boolean }
}

export default function Day({ day, rowIndex, colIndex, monthRows, selectedView, setShowAppointmentInfoModal, setClickedAppointment, setShowTaskInfoModal, setClickedTask, showHolidayInfoModal, setShowHolidayInfoModal, setClickedHoliday, privateCheckedCategories, organizationCheckedCategories }: dayProps) {

    const [dayAppointments, setDayAppointments] = useState<IAppointment[]>([]);
    const [dayTasks, setDayTasks] = useState<ITask[]>([]);
    const [dayHolidays, setDayHolidays] = useState<IHoliday[]>([]);
    const [open, setOpen] = useState(false);
    const [initialDate, setInitialDate] = useState<dayjs.Dayjs>(day);
    const weekViewRef = useRef<HTMLDivElement>(null);

    const { appointments, setSelectedDay, monthIndex, allTasksUnformated, appointmentCategories } = useTasks();
    const { staff } = useStaff();
    const { persons } = useContacts();
    const { organizationHolidays } = useTimeTracking();

    dayjs.extend(isBetween);

    // Filter tasks for the current day
    useEffect(() => {
        const currDayTasks = allTasksUnformated?.filter((t: ITask) =>
            t.scheduledDate != null &&
            dayjs(t.scheduledDate).format("DD-MM-YY") === day.format("DD-MM-YY")
        );
        setDayTasks(currDayTasks);
    }, [allTasksUnformated, day]);

    // Filter appointments for the current day
    useEffect(() => {
        const currDayAppointments = appointments?.filter(
            (a: IAppointment) =>
                dayjs(a.start).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                dayjs(a.end).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                day.isBetween(a.start, a.end, 'day')
        ) ?? [];
        setDayAppointments(currDayAppointments);
    }, [appointments, day]);

    // Filter holidays for the current day
    useEffect(() => {
        const currDayHolidays = organizationHolidays?.filter((h: IHoliday) =>
        ((h.accepted && !h.canceled && !h.rejected) &&
            (dayjs(h.startDate).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                dayjs(h.endDate).format("DD-MM-YY") === day.format("DD-MM-YY") ||
                day.isBetween(h.startDate, h.endDate, 'day')))) ?? [];
        setDayHolidays(currDayHolidays);
    }, [organizationHolidays, day]);

    // Scroll to 8am in week view
    useEffect(() => {
        if (selectedView === "Woche" && weekViewRef.current) {
            const eightAmElement = weekViewRef.current.querySelector(`[data-hour="8"]`) as HTMLElement;
            if (eightAmElement) {
                weekViewRef.current.scrollTop = eightAmElement.offsetTop;
            }
        }
    }, [selectedView]);

    // Highlight the current day
    function getCurrentDayClass() {
        return day.format("DD-MM-YY") === dayjs().format("DD-MM-YY")
            ? {
                backgroundColor: "#390099",// "var(--primary)",
                color: "white",

                borderRadius: "50%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "2rem",
                height: "1.7rem"
            }
            : {};
    }

    function isValidHex(color: string | undefined): boolean {
        return typeof color === 'string' && /^#[0-9A-F]{6}$/i.test(color);
    }

    function getColorWithDefault(color: string | undefined, defaultColor: string): string {
        if (color && isValidHex(color)) {
            return color;
        }
        return defaultColor;
    }

    function renderAppointments() {

        // Array zur Nachverfolgung der Spalten für die Termine
        const columns: { [key: number]: IAppointment[] } = {};

        return (
            <>
                {dayAppointments.map((a: IAppointment, idx: number) => {
                    var category = appointmentCategories?.find(ac => ac.id === a.appointmentCategory);
                    if (category == null && a.visibility === AppointmentCategoryVisibilityTypes.Organization) {
                        category = appointmentCategories?.find(ac => ac.id === DefaultAppointmentCategoryIds.OrganizationNoCategory);
                    } else if (category == null && a.visibility === AppointmentCategoryVisibilityTypes.Private) {
                        category = appointmentCategories?.find(ac => ac.id === DefaultAppointmentCategoryIds.PrivateNoCategory);
                    }
                    const color = getColorWithDefault(category?.color, "#000000");
                    const validColor = isValidHex(color) ? color : "#000000";
                    const startHour = dayjs(a.start).hour();
                    const startMinute = dayjs(a.start).minute();
                    const hourHeight = 60; // 60px per hour block
                    const offsetTop = startHour * hourHeight + (startMinute / 60) * hourHeight;
                    const durationHours = dayjs(a.end).diff(dayjs(a.start), 'hour', true);
                    const height = durationHours * hourHeight;

                    let column = 0;
                    while (columns[column] && columns[column].some(appt => dayjs(appt.start).isBefore(a.end) && dayjs(appt.end).isAfter(a.start))) {
                        column++;
                    }
                    if (!columns[column]) columns[column] = [];
                    columns[column].push(a);

                    const width = `${100 / (Object.keys(columns).length)}%`;
                    const left = `${column * 100 / (Object.keys(columns).length)}%`;
                    const timeRange = `${dayjs(a.start).format('HH:mm')} - ${dayjs(a.end).format('HH:mm')}`;

                    return (
                        <React.Fragment key={a.id}>
                            <div className={styles.appointment}
                                style={{ backgroundColor: validColor, top: `${offsetTop}px`, height: `${height}px`, left: left, width: width, position: selectedView === "Woche" ? 'absolute' : 'relative', boxSizing: 'border-box' }}
                                onClick={() => {
                                    setClickedAppointment!(a);
                                    setShowAppointmentInfoModal!(true);
                                    setShowTaskInfoModal!(false);
                                    setShowHolidayInfoModal!(false);
                                }}>
                                <span style={{ color: calculateContrastColor(validColor) }}>
                                    {a.title} <br /> {timeRange}
                                </span>
                            </div>
                        </React.Fragment>
                    );
                })}
            </>
        );
    }

    function renderTasks() {
        return (
            <>
                {dayTasks.map((t: ITask, idx: number) => {
                    const color = '#000000';
                    const validColor = isValidHex(color) ? color : '#000000';
                    return (
                        <React.Fragment key={t.id}>
                            <div className={styles.task}
                                style={{ backgroundColor: validColor, padding: '2px 4px', margin: '2px 0', boxSizing: 'border-box', position: selectedView === "Woche" ? 'absolute' : 'relative' }}
                                onClick={() => {
                                    setClickedTask!(t);
                                    setShowAppointmentInfoModal!(false);
                                    setShowTaskInfoModal!(true);
                                    setShowHolidayInfoModal!(false);
                                }}>
                                <span style={{ color: calculateContrastColor(validColor) }}>
                                    {t.title}
                                </span>
                            </div>
                        </React.Fragment>
                    );
                })}
            </>
        );
    }
    function handleHourClick(hour: number) {
        setSelectedDay!(day);
        setInitialDate(day.hour(hour).minute(0));
        setOpen(true);
    }
    return (
        <>
            <div className={styles.calendarCell}
                style={{
                    borderTopRightRadius: (rowIndex === 0 && colIndex === 6) ? "var(--borderRadius)" : "0px",
                    borderBottomRightRadius: (monthRows && rowIndex === monthRows - 1 && colIndex === 6) ? "var(--borderRadius)" : "0px",
                    backgroundColor: selectedView === "Monat" ? ((dayjs(new Date(dayjs().year(), monthIndex)).format("MM-YYYY") === day.format("MM-YYYY")) ? "white" : "whitesmoke") : "white"
                }}
            >
                <header style={{ display: 'flex', flexDirection: 'column', alignItems: "center", cursor: 'pointer', borderBottom: selectedView === "Woche" ? '1px solid var(--light-contrast)' : "", marginBottom: '12px' }}
                    onClick={() => {
                        setSelectedDay!(day);
                        setInitialDate(day);
                        setOpen(true);
                    }}>
                    {rowIndex === 0 && (
                        <div className={styles.dayTextContent}>{day.format('ddd')}</div>
                    )}
                    <div className={styles.dayNumericContent} style={getCurrentDayClass()}>{day.format('DD')}</div>
                </header>
                <div style={{ overflow: 'auto', position: 'relative' }}>
                    {selectedView === "Woche" ? (
                        <div className={styles.weekGrid} ref={weekViewRef}>
                            <div className={styles.weekContent}>
                                {Array.from({ length: 24 }).map((_, hour) => (
                                    <div
                                        className={styles.weekHour}
                                        key={hour}
                                        data-hour={hour}
                                        onClick={() => handleHourClick(hour)}
                                    >
                                        <div className={styles.hourLabel}>{hour}:00</div>
                                        <div className={styles.hourContent}></div>
                                        <hr className={styles.hourSeparator} />
                                    </div>
                                ))}
                                {renderAppointments()}
                                {renderTasks()}
                            </div>
                        </div>
                    ) : (
                        <div>
                            {renderAppointments()}
                            {renderTasks()}
                        </div>
                    )}
                </div>
                <div style={{ flex: '1 0 0%', cursor: 'pointer', minHeight: 'auto' }}
                    onClick={() => {
                        setSelectedDay!(day);
                        setInitialDate(day);
                        setOpen(true);
                    }}>
                </div>
            </div>
            <CreateAppointmentModal open={open} setOpen={setOpen} initialDate={initialDate} />
        </>
    );
}
