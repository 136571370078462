import React from 'react'
import { IContact, INewsletterDesign, IPerson, IPersonDTO, ITag } from '../ContactsContext/ContactsProvider'
import { IOrganization, IOrganizationDTO } from '../../Interfaces/Company'

const ContactsReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_PERSONS":
      return {
        ...state, /*Jetztiger Zustand vom State + , + veränderte Sachen im aktuellen State */
        persons: action.payload
      }
    case "ADD_PERSON":
      return {
        ...state,
        persons: [...state.persons, action.payload].sort((a: IPersonDTO, b: IPersonDTO) => a?.lastname?.localeCompare(b?.lastname ?? "zzz") ?? 0)
      }
    case "ADD_PERSON_PROFILE_PICTURE":
      return {
        ...state,
        persons: state.persons.map((person: IPersonDTO) => {
          if (person.id === action.payload.id) {
            return { ...person, profilePictureBase64: action.payload.base64 };
          }
          return person;
        })
      }
    case "UPDATE_PERSON":
      return {
        ...state,
        persons: state.persons.map((person: IPerson) => person.id == action.payload.id ? { ...action.payload } : person).sort((a: IPersonDTO, b: IPersonDTO) => a?.lastname?.localeCompare(b?.lastname ?? "zzz") ?? 0)
      }

    case "DELETE_PERSON":
      return {
        ...state,
        persons: state.persons.filter((person: IPerson) => person.id !== action.payload.id) /*filter returned true oder false, hier wollen wir eine Person
                löschen, wenn die ID von der Person nicht im Arry ist, dann return true, sonst return false, dann wird sie gekickt! <statt zwei immer drei = oder !==>*/
      }
    case "SET_CONTACTS":
      return {
        ...state,
        contacts: action.payload
      }
    case "ADD_CONTACT":
      let emptyArray: Array<any> = [];
      return {
        ...state,
        contacts: [...state.contacts, action.payload],
        persons: state.persons.map((person: IPerson) => {
          if (person.id === action.payload[0].personId) {
            return { ...person, contactData: [...(person.contactData ?? []), ...action.payload] }
          }
          else return person
        })
      }
    case "UPDATE_CONTACT":
      return {
        ...state,
        contacts: state.contacts.map((contact: IContact) => contact.id == action.payload.id ? { ...action.payload } : contact)
      }
    case "DELETE_CONTACT":
      return {
        ...state,
        contacts: state.contacts.filter((contact: IContact) => contact.id !== action.payload.id)
      }
    case "EDIT_COMPANY":
      return {
        ...state,
        companies: state.companies?.map((company: IOrganization) => company.id == action.payload.id ? { ...action.payload } : company).sort((a: IOrganizationDTO, b: IOrganizationDTO) => a?.name?.localeCompare(b?.name ?? "zzz") ?? 0)
      }
    case "DELETE_COMPANY":
      return {
        ...state,
        companies: state.companies?.filter((company: IOrganization) => company.id !== action.payload.id)
      }
    case "CREATE_COMPANY":
      return {
        ...state,
        companies: [...state.companies, action.payload].sort((a: IOrganizationDTO, b: IOrganizationDTO) => a?.name?.localeCompare(b?.name ?? "zzz") ?? 0)
      }
    case "SET_COMPANIES":
      return {
        ...state,
        companies: [...action.payload]
      }
    case "SET_COMPANIES":
      return {
        ...state,
        companies: action.payload
      }
    case "SET_TAGS":
      return {
        ...state,
        tags: action.payload
      }
    case "ADD_TAG":
      return {
        ...state,
        tags: [action.payload, ...state.tags].sort((a: ITag, b: ITag) => a?.title?.localeCompare(b?.title ?? "zzz") ?? 0)
      }
    case "ADD_TAG_TO_PERSONS":
      return {
        ...state,
        persons: state.persons.map((person: IPerson) => {
          if (action.payload.personIds.includes(person.id)) {
            if (person.tags?.find((tag: ITag) => tag.id === action.payload.tagId)) {
              return person
            }
            return { ...person, tags: [...(person.tags ?? []), state.tags.find((tag: ITag) => tag.id === action.payload.tagId)] }
          }
          return person;
        })
      }
    case "EDIT_TAG":
      return {
        ...state,
        tags: state.tags.map((tag: ITag) => {
          if (tag.id === action.payload.id) {
            return action.payload
          }
          return tag;
        })
      }
    case "REMOVE_TAG_FROM_PERSONS":
      return {
        ...state,
        persons: state.persons.map((person: IPerson) => {
          if (action.payload.personIds.includes(person.id)) {
            return { ...person, tags: (person.tags ?? []).filter((tag: ITag) => tag.id !== action.payload.tagId) }
          }
          return person;
        })
      }
    case "ADD_TAG_TO_COMPANIES":
      return {
        ...state,
        companies: state.companies.map((company: IOrganization) => {
          if (action.payload.companyIds.includes(company.id)) {
            if (company.tags?.find((tag: ITag) => tag.id === action.payload.tagId)) {
              return company
            }
            return { ...company, tags: [...(company.tags ?? []), state.tags.find((tag: ITag) => tag.id === action.payload.tagId)] }
          }
          return company;
        })
      }
    case "REMOVE_TAG_FROM_COMPANIES":
      return {
        ...state,
        companies: state.companies.map((company: IOrganization) => {
          if (action.payload.companyIds.includes(company.id)) {
            return { ...company, tags: (company.tags ?? []).filter((tag: ITag) => tag.id !== action.payload.tagId) }
          }
          return company;
        })
      }
    case "UPDATE_TAG":
      return {
        ...state,
        tags: state.tags.map((tag: ITag) => tag.id === action.payload.id ? action.payload : tag)
      }
    case "REMOVE_TAG":
      return {
        ...state,
        tags: state.tags.filter((tag: ITag) => tag.id !== action.payload.id)
      }
    case "SET_NEWSLETTERDRAFTS":
      return {
        ...state,
        newsletterDrafts: action.payload
      }
    case "ADD_NEWSLETTERDRAFTS":
      return {
        ...state,
        newsletterDrafts: [...state.newsletterDrafts, action.payload]
      }
    case "UPDATE_NEWSLETTERDRAFTS":
      return {
        ...state,
        newsletterDrafts: state.newsletterDrafts.map((draft: INewsletterDesign) => {
          console.log(draft.id, action.payload.id)
          if (draft.id === action.payload.id) {
            return action.payload;
          }
          return draft;
        })
      }
    case "SET_NEWSLETTERSENT":
      return {
        ...state,
        newsletterSent: action.payload
      }
    case "ADD_NEWSLETTERSENT":
      return {
        ...state,
        newsletterSent: [...state.newsletterSent, action.payload]
      }
    case "SET_NEWSLETTERQUEUE":
      return {
        ...state,
        newsletterQueue: action.payload
      }
    case "ADD_NEWSLETTERQUEUE":
      return {
        ...state,
        newsletterQueue: [...state.newsletterQueue, action.payload].sort((a, b) => new Date(b.scheduledFor).getMinutes() - new Date(a.scheduledFor).getMinutes())
      }
    default:
      return {
        ...state
      }
  }
}

export default ContactsReducer