// App.js

import { useEffect, useState } from "react";
import { IProject, useTasks } from "../../../Contexts/TaskContext/TaskContext";
import { useUser } from "../../../Contexts/UserContext/UserContext";
import Modal from "../../Dialog/Dialog";
import CameraCapture from "../../Camera/CameraCapture";
import FileUpload from "../../Camera/FileUpload";
import { Button } from "@mui/material";
import { Delete, DoubleArrow, KeyboardDoubleArrowLeft, Save } from "@mui/icons-material";
import { authConnection } from "../../../Connection/BaseConnection";
import { IDocument } from "../../../Contexts/DocumentContext/DocumentContext";

function EditAreaPicturesDialog({ open, onClose, inputProject, isTask = true }: { open: boolean, onClose: any, inputProject: IProject, isTask: boolean }) {
    const { tasksUnformated, addDocumentToTask, deleteDocumentToTask, addDocumentToArea } = useTasks();
    const { user } = useUser();
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [capturedImages, setCapturedImages] = useState<any>([]);
    const [project, setProject] = useState(inputProject);

    useEffect(() => {
        setProject(inputProject);

        if (open && (selectedFiles === null || selectedFiles.length <= 0)) {
            authConnection.get(`/project/GetPicturesToTask/${project.id}`).then(res => {
                console.log(res.data);
                setCapturedImages(res.data);
            })
        }
    }, [open])

    useEffect(() => {
        setCapturedImages([]);
    }, [project])

    const handleFilesSelected = (files: any) => {
        setSelectedFiles(files);
    };

    const handleCapture = (image: any) => {
        setCapturedImages((prevImages: any) => [...prevImages, image]);
        if (isTask) {
            if (addDocumentToTask) {
                addDocumentToTask(image, project.id, true)
            }
        } else {
            if (addDocumentToArea) {
                addDocumentToArea(image, project.id, true, project.id)
            }
        }
    };

    const deletePictureFromArea = (areaId: number, documentId: number) => {
        authConnection.delete(`/project/DeletePictureToArea/${areaId}/${documentId}`).then(res => {
            setCapturedImages((old: any) => old.map((t: any) => ({ ...t, documents: t?.documents?.filter((doc: IDocument) => doc.id !== documentId) })))
        })
    }

    return (
        <Modal
            open={open}
            title={`Bilder bearbeiten zu Projekt: ${project?.title}`}
            onClose={onClose}
            maxWidth='lg'
        >

            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h2 style={{ width: "100%" }}>Aufgenommene Bilder:</h2>
                {capturedImages.map((area: any) => (
                    (<>
                        <h3 style={{ width: "100%" }}>{area.title}:</h3>
                        {area?.documents?.map((image: IDocument, index: number) =>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: index === capturedImages.length - 1 ? "0em" : "1em" }}>
                                <img style={{ width: "calc(85%)", marginBottom: "0.25em" }} key={index} src={`data:${image.fileEnding};base64,${image.base64}`} alt={`captured-${index}`} />
                                <Button startIcon={<Delete />} onClick={() => { deletePictureFromArea(area.id, image.id!) }} color="error" variant='contained'>Entfernen</Button>
                            </div>
                        )}
                    </>)
                ))}
            </div>
        </Modal>
    );
}

export default EditAreaPicturesDialog;
