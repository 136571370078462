// App.js
import React, { useEffect, useState } from 'react';
import FileUpload from './FileUpload';
import CameraCapture from './CameraCapture';
import Modal from '../Dialog/Dialog';
import { Button, IconButton } from '@mui/material';
import CTAButton from '../Buttons/CTAButton/CTAButton';
import { Delete, DoubleArrow, KeyboardDoubleArrowLeft, Save } from '@mui/icons-material';
import { useTasks } from '../../Contexts/TaskContext/TaskContext';
import { useUser } from '../../Contexts/UserContext/UserContext';

function CapturePicturesModal({ open, onClose, inputTask, isTask = true, project }: any) {
    const { tasksUnformated, addDocumentToTask, deleteDocumentToTask, addDocumentToArea } = useTasks();
    const { user } = useUser();
    const [selectedFiles, setSelectedFiles] = useState<any>([]);
    const [capturedImages, setCapturedImages] = useState<any>([]);
    const [task, setTask] = useState(inputTask);

    const [nextTask, setNextTask] = useState(tasksUnformated.filter(t => t.id! > task.id && t.customerOrganizationId === task.customerOrganizationId && t.userIds?.includes(user?.userId!)).sort((a: any, b: any) => a.id - b.id)[0] ?? undefined)
    const [lastTask, setLastTask] = useState(tasksUnformated.filter(t => t.id! < task.id && t.customerOrganizationId === task.customerOrganizationId && t.userIds?.includes(user?.userId!)).sort((a: any, b: any) => b.id - a.id)[0] ?? undefined);
    useEffect(() => {
        setTask(inputTask);
    }, [open])

    useEffect(() => {
        setNextTask(tasksUnformated.filter(t => t.id! > task.id && t.customerOrganizationId === task.customerOrganizationId && t.userIds?.includes(user?.userId!)).sort((a: any, b: any) => a.id - b.id)[0] ?? undefined)
        setLastTask(tasksUnformated.filter(t => t.id! < task.id && t.customerOrganizationId === task.customerOrganizationId && t.userIds?.includes(user?.userId!)).sort((a: any, b: any) => b.id - a.id)[0] ?? undefined)
        setSelectedFiles([]);
        setCapturedImages([]);
    }, [task, user, open])

    const handleFilesSelected = (files: any) => {
        setSelectedFiles(files);
    };

    const handleCapture = (image: any) => {
        setCapturedImages((prevImages: any) => [...prevImages, image]);
        if (isTask) {
            if (addDocumentToTask) {
                addDocumentToTask(image, task.id, true)
            }
        } else {
            if (addDocumentToArea) {
                addDocumentToArea(image, task.id, true, project.id)
            }
        }
    };

    return (
        <Modal
            open={open}
            title={`Bilder aufnehmen für ${task?.title}`}
            onClose={onClose}
            maxWidth='lg'
        >
            {(user?.organizationId !== 20875 && user?.organizationId !== 20902 && user?.organizationRoleName?.toLowerCase() !== "user") &&
                <FileUpload onFilesSelected={handleFilesSelected} />
            }
            <CameraCapture onCapture={handleCapture} />

            {(user?.organizationId !== 20875 && user?.organizationId !== 20902 && user?.organizationRoleName?.toLowerCase() !== "user") &&
                <div style={{ marginTop: "0.5em", display: "flex", flexDirection: "row", justifyContent: "space-between", width: "100%" }}>
                    <Button style={{ width: "33.3%" }} startIcon={nextTask ? <KeyboardDoubleArrowLeft /> : <Save />} onClick={() => { if (lastTask) { setTask(lastTask) } else { onClose(); } }} color="info" variant='contained'>{lastTask?.title ?? "Zurück"}</Button>
                    <Button style={{ width: "33.3%" }} endIcon={nextTask ? <DoubleArrow /> : <Save />} onClick={() => { if (nextTask) { setTask(nextTask) } else { onClose(); } }} color="info" variant='contained'>{nextTask?.title ?? "Speichern"}</Button>
                </div>
            }


            {(user?.organizationId !== 20875 && user?.organizationId !== 20902 && user?.organizationRoleName?.toLowerCase() !== "user") &&
                <div>
                    <h2>Hochgeladene Dateien:</h2>
                    <ul>
                        {selectedFiles.map((file: any) => (
                            <li key={file.name}>{file.name}</li>
                        ))}
                    </ul>
                </div>
            }


            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <h2 style={{ width: "100%" }}>Aufgenommene Bilder:</h2>
                {capturedImages.map((image: any, index: number) => (
                    <div style={{ display: "flex", flexDirection: "column", alignItems: "center", marginBottom: index === capturedImages.length - 1 ? "0em" : "1em" }}>
                        <img style={{ width: "calc(85%)", marginBottom: "0.25em" }} key={index} src={image} alt={`captured-${index}`} />
                        {(user?.organizationId !== 20875 && user?.organizationId !== 20902 && user?.organizationRoleName?.toLowerCase() !== "user") &&

                            <Button startIcon={<Delete />} onClick={() => { setCapturedImages((old: any) => old.filter((t: any) => t !== image)) }} color="error" variant='contained'>Entfernen</Button>
                        }
                    </div>
                ))}
            </div>
        </Modal>
    );
}

export default CapturePicturesModal;
