import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import { ITag, defaultTag, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import FormRow from '../../Forms/FormUtils/FormRow'



function EditCreateTagDialog({ open, onClose, object, mode = "create" }: dialogWithProps<ITag>) {

    const { changeVisibility } = useModals()

    const { enqueueSnackbar } = useSnackBar()

    //* Management der Daten zum Submitten an die Datenbank
    const [tag, setTag] = useState<ITag>(object ?? defaultTag)
    const { addTag, editTag } = useContacts();
    //*Ladestate beim Abschicken an die API
    const [loading, setLoading] = useState(false)

    //TODO Implementieren -> Bernhard obzw. Nico
    //Funktion zum Handeln des Hinzufügens -> Wird ein Promise werden
    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (mode == "create") {
                if (tag) {
                    addTag!(tag)
                }
            } else {
                editTag!(tag);
            }
            if (onClose) {
                onClose()
            }
        } catch (error) {
            enqueueSnackbar(`Fehler beim ${mode == "create" ? "Erstellen" : "Speichern"}.`, { variant: "error" })
        } finally {
            setLoading(false)
        }

    }


    useEffect(() => {
        setTag(object ?? defaultTag)
    }, [object])


    return (
        <Modal
            open={open}
            title={mode == "create" ? 'Tag erstellen' : "Tag bearbeiten"}
            onClose={() => onClose!()}
        >
            <FormRow
                type='text'
                value={tag?.title}
                label='Titel'
                onChange={(val) => setTag((old: ITag | undefined) => ({ ...old, title: val }))}
            />
            <FormRow
                type='multiline'
                value={tag?.description}
                label='Beschreibung'

                onChange={(val) => setTag((old: ITag | undefined) => ({ ...old, description: val }))}
            />
            <div style={{ height: "1em" }}></div>
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : mode == "create" ? "Erstellen" : "Speichern"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}

export default EditCreateTagDialog