import React, { useEffect, useState } from 'react'
import BillDataCard from '../../Components/BillCards/BillDataCard'
import BillingpositionsCard from '../../Components/BillCards/BillingpositionsCard'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import Page from '../../Navigation/Page'

import PdfViewer from '../../Components/PDF/all-pages';
import { sampleBase64pdf } from '../../Components/PDF/samplebase64pdf'
import Card from '../../Components/Card/Card'
import { useNavigate, useParams } from 'react-router-dom'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { IBillDTO, IBillingPosition, IBillListDTO } from '../../Interfaces/Bill'
import { AxiosResponse } from 'axios'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import ErrorDialog from '../../Components/Dialogs/ErrorDialog/ErrorDialog'
import { IService } from '../../Interfaces/Service'
import { useProducts } from '../../Contexts/ProductContext/ProductProvider'
import { Button } from '@mui/material'
import { Check, Download } from '@mui/icons-material'
import CreateBillModal from '../../Components/BillCards/CreateBillModal'
import { downloadFile } from '../../Contexts/DocumentContext/DocumentContext'
import OfferDataCard from '../../Components/OfferCard/OfferDataCard'
import BillPdfViewerCard from '../../Components/PdfViewerCard/BillPdfViewerCard'
import CreateTaskDialog from '../../Components/Dialogs/CreateTaskDialog/CreateTaskDialog'
import GenerateTasksDialog from '../../Components/Dialogs/GenerateTasksDialog/GenerateTasksDialog'
import CreateBill from './CreateBill'


const CreateOffer = () => {

  return (
    <>
      <CreateBill type='Offer' />
    </>
  )
}

export default CreateOffer