import { Switch } from '@mui/material'
import { useEffect, useState } from 'react'
import { baseConfig } from '../../Connection/BaseConnection'
import { IProductDTO, defaultProduct } from '../../Contexts/ProductContext/ProductProvider'
import { productFormModes } from '../../Pages/Verwaltung/Produkte'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import ResetFormButton from '../Buttons/ResetFormButton/ResetFormButton'
import CardExpandable from '../Card/CardExpandable/CardExpandable'
import { InputFieldErrorMessages } from '../InputField/InputFieldRefactored'
import { form } from './CreateTaskForm'
import FormRow from './FormUtils/FormRow'
import { roundNumberToDecimalPlaces } from '../../utils/MathUtils'

const EditProductDataForm = ({ object: product, setObject: setProduct, errorVariables, mode, setFormMode }: form<IProductDTO, productFormModes>) => {

    const [grossPrice, setGrossPrice] = useState<number>((product.salesPrice ?? 0) * (1 + ((product.ust ?? 0) / 100)));

    useEffect(() => {
        const tempGrossPrice = (product.salesPrice ?? 0) * (1 + ((product.ust ?? 0) / 100))
        const grossPriceRoundedToTwoDecimals = roundNumberToDecimalPlaces(tempGrossPrice, 2);
        setGrossPrice(grossPriceRoundedToTwoDecimals);
    }, [product.id])

    const printLabel = () => {

        const printWindow = window.open('', '_blank');

        printWindow?.document.write(`
        <html>
            <head>
                <style>
                    @media print {
                        .no-print, .no-print * {
                            display: none;
                        }
                        .invoice-panel {
                            border: 0 solid #fff;
                            box-sizing: border-box;
                        }
                    }
                    
                    @media screen {
                        .invoice-panel {
                            border: 1px solid #aaa;
                        }
                    }

                    html, body {
                        margin: 0;
                        padding: 0;
                        border: solid 0 #fff;
                    }
                    body, p, div, table {
                        font-family:sans-serif;
                        font-size: ${product.labelLength! * 0.155}mm; 
                    }
                    .label-panel {
                        width: ${product.labelWidth}mm;
                        height: ${product.labelLength}mm;
                        margin: 0 auto;
                        text-align: center;
                        align-items: center;
                        box-sizing: border-box;
                        background-color: #fff;
                        display: flex;
                        flex-direction: column;
                    }
                    .label-panel > div {
                        flex: 1;
                        display: flex;
                        flex-direction: column;
                      }
                    .label-panel img {
                        flex: 1;
                        max-width: 100%;
                        max-height: 100%;
                      }
                      .label-panel .product-title,
                      .label-panel .product-price {
                        flex: 0 0 auto;
                        padding: 4px;
                      }
                </style>
            </head>
            <body>
                <div class="label-panel">
                    <div>
                        <div class="product-title">
                            <b>${product.title}</b>
                        </div>
                    </div>
                    <div>
                        <div class="product-price">
                            <b>Preis:</b>
                            <span>${product.salesPrice}€</span>
                        </div>
                    </div>
                    <div>
                        <img src=${baseConfig.baseURL}/${product.isQrCode ? "qrcode" : "barcode"}/${product.codeValue}></img>
                    </div>
                    </div>
                </div>
                <script>
                    var img = new Image();
                    img.src = '${baseConfig.baseURL}/${product.isQrCode ? "qrcode" : "barcode"}/${product.codeValue}';
                
                    img.onload = function() {
                      window.print();
                    };
                </script>
            </body>
        </html>
        `)

    }

    const handleReset = () => {
        setProduct(defaultProduct);
        console.log("handling reset")
        if (setFormMode) {
            console.log("setting form mode")
            setFormMode("createProduct");
            console.log(mode)
        }
    }

    const [printLabelError, setprintLabelError] = useState(false);

    //Function to check if print label button is disabled/enabled
    function verifyLabelDimensions() {
        if (product.labelLength && product.labelWidth && !isNaN(product.labelLength) && !isNaN(product.labelWidth) && product.labelLength >= 10 && product.labelWidth > 10) {
            setprintLabelError(false);
            printLabel();
        } else {
            setprintLabelError(true);
        }
    }

    const handleGrossPriceChange = (value: number) => {
        setGrossPrice(value);

        const tempGrossPrice = value;
        const taxInPercent = product.ust ?? 0;
        const tax = taxInPercent / 100;
        const salesPrice = tempGrossPrice / (1 + tax);
        const salesPriceRounded = roundNumberToDecimalPlaces(salesPrice, 2);
        setProduct({ ...product, salesPrice: salesPriceRounded })
    }

    const handleSalesPriceChange = (value: number) => {
        setProduct({ ...product, salesPrice: value })

        const salesPrice = value;
        const taxInPercent = product.ust ?? 0;
        const tax = taxInPercent / 100;
        const grossPrice = salesPrice * (1 + tax)
        const grossPriceRounded = roundNumberToDecimalPlaces(grossPrice, 2);
        setGrossPrice(grossPriceRounded);
    }

    const handleUstChange = (value: number) => {
        setProduct({ ...product, ust: value });

        const taxInPercent = value;
        const tax = taxInPercent / 100;
        const currentSalesPrice = product.salesPrice ?? 0;
        const grossPrice = currentSalesPrice * (1 + tax);
        const grossPriceRounded = roundNumberToDecimalPlaces(grossPrice, 2);
        setGrossPrice(grossPriceRounded);
    }

    return (
        <form className='form'>
            <FormRow
                label="Titel"
                placeholder="Produkttitel"
                onChange={(val) => setProduct((prev: IProductDTO) => ({ ...prev, title: val }))}
                value={product?.title ?? ""}
                type="text"
                error={errorVariables?.titleError}
                errorMessage={InputFieldErrorMessages.TitleError}
            />
            <FormRow
                label="Beschreibung"
                placeholder="Beschreibung des Produkts"
                onChange={(val: string) => setProduct((prev: any) => ({ ...prev, description: val }))}
                value={product?.description}
                type="multiline"
            />
            <FormRow
                label="Einkaufspreis (nur für Statistik, intern!)"
                type="number"
                step=".1"
                placeholder="9.99"
                onChange={(val) => setProduct((old: IProductDTO) => ({ ...old, cost: parseFloat(val) }))}
                value={product?.cost}
                error={errorVariables?.errorCost}
                errorMessage="Bitte geben Sie einen Einkaufspreis an"
            />
            <FormRow
                label="Steuer (in Prozent)"
                placeholder="20"
                value={product.ust}
                onChange={(val) => handleUstChange(val)}
                type="number"
                step="1"
                error={errorVariables?.errorUst}
                errorMessage="Bitte geben Sie einen Wert für die Steuer in % an"
            />
            <FormRow
                label="Verkaufspreis (netto)"
                type="number"
                step=".01"
                placeholder="19.99"
                onChange={(val) => handleSalesPriceChange(val)}
                value={product?.salesPrice}
                error={errorVariables?.errorCost}
                errorMessage="Bitte geben Sie einen Verkaufspreis (netto) an"
            />
            <FormRow
                label="Verkaufspreis (brutto)"
                type="number"
                step=".1"
                placeholder="23.98"
                value={grossPrice}
                onChange={(val) => handleGrossPriceChange(val)}
                error={errorVariables?.errorCost}
                errorMessage="Bitte geben Sie einen Verkaufspreis (brutto) an"
            />

            <FormRow
                label="Menge auf Lager (leer für z.B. digitale Produkte)"
                type='number'
                step='1'
                value={product.count}
                onChange={(val) => setProduct((old: IProductDTO) => ({ ...old, count: parseFloat(val) }))}
                placeholder="Menge"
            />
            <FormRow
                type='text'
                label='Produkt Code'
                value={product.codeValue ?? ""}
                onChange={(val) => setProduct((old: IProductDTO) => ({ ...old, codeValue: val }))}
                placeholder='6 253765 889553'
            />
            <CardExpandable
                title='Barcode/QR-Code'
            >
                {product.codeValue ? <div>
                    <div className='form-row noselect' onClick={() => setProduct((old: IProductDTO) => ({ ...old, isQrCode: !product.isQrCode }))}>
                        <span style={{ fontSize: "14px" }}>Barcode</span>
                        <Switch color="secondary" checked={product.isQrCode} onChange={(e, val) => setProduct((old: IProductDTO) => ({ ...old, isQrCode: !product.isQrCode }))} />
                        <span style={{ fontSize: "14px" }}>QR-Code</span>
                    </div>

                    {product.isQrCode && product.codeValue &&
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img style={{ width: "50%" }} src={`${baseConfig.baseURL}/qrcode/${product.codeValue}`}></img>
                        </div>
                    }
                    {!product.isQrCode && product.codeValue &&
                        <div style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <img style={{ width: "50%" }} src={`${baseConfig.baseURL}/barcode/${product.codeValue}`}></img>
                        </div>
                    }
                    {product.codeValue &&
                        <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                            <FormRow
                                type='number'
                                label='Etikette-Breite (in mm)'
                                value={product.labelWidth}
                                onChange={(val) => setProduct((old: IProductDTO) => ({ ...old, labelWidth: val }))}
                                error={printLabelError}
                                errorMessage={InputFieldErrorMessages.PrintLabelError}
                            />
                            <FormRow
                                type='number'
                                label='Etikette-Länge (in mm)'
                                value={product.labelLength}
                                onChange={(val) => setProduct((old: IProductDTO) => ({ ...old, labelLength: val }))}
                                error={printLabelError}
                            />
                        </div>
                    }
                    {
                        mode === "editProduct" && product.codeValue &&
                        <div style={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "2%" }}>
                            <CTAButton title="Etikett zu Produkt erstellen" onClickHandler={() => { verifyLabelDimensions() }} style={{ width: "40%" }} />
                        </div>
                    }
                </div>
                    : <div style={{ textAlign: "center" }}>Geben Sie zunächst einen Produkt Code an</div>}
            </CardExpandable>

            <ResetFormButton
                title="Felder zurücksetzen"
                onClickHandler={() => handleReset()}
            />

        </form>
    )
}

export default EditProductDataForm