import { useEffect, useState } from "react"
import { IPause, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import Modal from "../../Dialog/Dialog"
import Pagination from "../../Pagination/Pagination"
import { dialogWithProps } from "../EditCreateCompanyDialog/EditCreateCompanyDialog"
import PauseRowAdmin from "./PauseRowAdmin"
import { useModals } from "../../../Contexts/DialogContext/DialogProvider"

const PausesOverivewAdminDialog = ({ open, onClose, object, mode }: dialogWithProps<any>) => {

    const { pausesOrganization } = useTimeTracking();
    const { changeVisibility } = useModals()
    const [page, setPage] = useState<number>(0);


    const handleClose = async () => {
        if (onClose) {
            onClose();
        }
        changeVisibility!("pausesOverviewAdmin", false);
    }

    const [pausesToWorktime, setPausesToWorktime] = useState<IPause[]>([]);

    useEffect(() => {
        if (pausesOrganization) {
            setPausesToWorktime(pausesOrganization!.filter((pause: IPause) => {
                if (object && pause.workTimeId === object.id) {
                    return true;
                }
                return false;
            }))
        }
    }, [object])

    return (
        <Modal
            open={open}
            title={"Pausenübersicht"}
            onClose={handleClose}
        >
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "flex-start", minHeight: "55vh" }}>
                {pausesToWorktime && pausesToWorktime?.length > 0 && pausesToWorktime!.map((pause, index) => {
                    if (index >= (page) * 5 && index < (page + 1) * 5) {
                        return (<PauseRowAdmin pause={pause} key={index} n={index} workTime={object}/>)
                    }
                })}
                {(!pausesToWorktime || pausesToWorktime?.length === 0) &&
                    <div>Für diese Arbeitszeit wurden keine Pausen eingetragen</div>
                }
            </div>
            <Pagination items={pausesToWorktime ?? []} itemsPerPage={5} page={page} setPage={setPage} />
        </Modal>
    )
}

export default PausesOverivewAdminDialog