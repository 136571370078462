import { ArrowForward } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { authConnection } from '../../../Connection/BaseConnection'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { User } from '../../../Interfaces/User'
import Modal from '../../Dialog/Dialog'
import InputField from '../../InputField/InputField'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'

const ChangePasswordDialog = ({ open, onClose, object, mode }: dialogWithProps<User>) => {

    const { changeVisibility } = useModals();

    const [loading, setLoading] = useState(false);
    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);

    const [currentPassword, setCurrentPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

    const [currentPasswordError, setCurrentPasswordError] = useState(false);
    const [newPasswordError, setNewPasswordError] = useState(false);
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const [changeSuccess, setChangeSuccess] = useState(false);

    const minimumPasswordLength = 6;

    const handleClose = () => {
        setLoading(false);
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editPassword", false);

        setTimeout(() => {
            setContinueButtonDisabled(true);
            setCurrentPassword("");
            setNewPassword("");
            setConfirmPassword("");
            setCurrentPasswordError(false);
            setNewPasswordError(false);
            setConfirmPasswordError(false);
            setChangeSuccess(false);
        }, 250)
    }


    const handleSubmit = async () => {
        try {
            setLoading(true);
            const response = await authConnection.post("User/UpdatePassword", {
                currentPassword: currentPassword,
                newPassword: newPassword
            })

            if (response.status === 200) {
                setChangeSuccess(true);
            }
        }
        catch (error) {
            setCurrentPasswordError(true);
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    function checkPasswordLength(password: string) {
        if (password.length !== 0 && password.length < minimumPasswordLength) {
            return true;
        }
        return false;
    }

    function checkConfirmPassword(pw: string, confirm: string) {

        if (pw.length === 0 || confirm.length === 0 || (pw.length !== 0 && confirm.length !== 0 && pw === confirm)) {
            return false;
        }

        return true;
    }

    useEffect(() => {
        setNewPasswordError(checkPasswordLength(newPassword));
    }, [newPassword])

    useEffect(() => {
        setConfirmPasswordError(checkConfirmPassword(newPassword, confirmPassword))
    }, [newPassword, confirmPassword])

    useEffect(() => {
        if (!newPasswordError && !confirmPasswordError && currentPassword.length > 5 && newPassword.length !== 0 && confirmPassword.length !== 0) {
            setContinueButtonDisabled(false);
        } else {
            setContinueButtonDisabled(true);
        }
    }, [newPasswordError, confirmPasswordError])

    return (

        <Modal
            open={open}
            title="Ändern Sie Ihr Passwort"
            onClose={() => handleClose()}
        >
            {!changeSuccess &&
                <div>
                    <div className="form-row-label" style={{ marginBottom: "2vh" }}>
                        <span className='label'>
                            Derzeitiges Password:
                        </span>
                        <InputField
                            type="string"
                            placeholder="Derzeitiges Passwort"
                            value={currentPassword}
                            isSecure={true}
                            onChangeHandler={(val) => setCurrentPassword(val)}
                            error={currentPasswordError}
                            errorMessage="Falsches Passwort. Versuchen Sie es erneut"
                        />
                    </div>

                    <div className="form-row-label" style={{ marginBottom: "2vh" }}>
                        <span className='label'>
                            Neues Password:
                        </span>
                        <InputField
                            type="string"
                            placeholder="Neues Passwort (mind. 6 Zeichen)"
                            value={newPassword}
                            isSecure={true}
                            onChangeHandler={(val) => setNewPassword(val)}
                            error={newPasswordError}
                            errorMessage="Passwort muss mindestens 6 Zeichen lang sein"
                        />
                    </div>

                    <div className="form-row-label" style={{ marginBottom: "2vh" }}>
                        <span className='label'>
                            Neues Password bestätigen:
                        </span>
                        <InputField
                            type="string"
                            placeholder="Neues Passwort bestätigen"
                            value={confirmPassword}
                            isSecure={true}
                            onChangeHandler={(val) => setConfirmPassword(val)}
                            error={confirmPasswordError}
                            errorMessage="Passwörter stimmen nicht überein"
                        />
                    </div>

                    <div>
                        {!continueButtonDisabled &&
                            <button onClick={async () => handleSubmit()} className="cta-button pointer" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                            </button>}

                        {continueButtonDisabled &&
                            <button disabled className="cta-button-disabled not-allowed" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>
                                    Weiter
                                    <ArrowForward fontSize='small' />
                                </span>
                                }
                            </button>
                        }
                    </div>
                </div>
            }
            {changeSuccess &&
                <div>
                    <h2 style={{ textAlign: "center" }}>
                       Ihr Passwort wurde erfolgreich geändert.
                    </h2>
                </div>
            }


        </Modal>



    )
}

export default ChangePasswordDialog