import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IService } from '../../Interfaces/Service'
import styles from './BillsCard.module.css'

//Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Button, ClickAwayListener, Collapse, Grow, Menu, MenuItem, MenuList, Modal, Paper, Popper } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import { IProductDTO, useProducts } from '../../Contexts/ProductContext/ProductProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { IStaff, StaffDTO } from '../../Contexts/StaffContext/StaffProvider';

interface props {
    staff: StaffDTO,
    workingHours?: number,
    serviceHours?: number,
    setClickedUsers: Dispatch<SetStateAction<IStaff[]>>
}

interface Document {
    id: number,
    title: string,
    description: string,
    fileEnding: string,
    fileId: string,
    base64: string,
    organizationId: number,
}

const AdminPersonalCurrentMonthRow = ({ staff, workingHours, serviceHours, setClickedUsers }: props) => {
    let navigate = useNavigate();

    const productContext = useProducts();
    const dialogContext = useModals();

    const displayName = staff.person?.lastname ? (staff.person.firstname ? ((staff.person.title ? staff.person.title + " " : "") + staff.person.firstname + " " + staff.person.lastname) : (staff.person.lastname)) : staff.username;

    const onPressEdit = () => {
        dialogContext.changeVisibility!("editCreatePersonal", true, { props: { object: staff, mode: "edit" } });
    }

    const onPressDelete = () => {
        //productContext.removeProduct(product);
    }

    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }
    ]

    const [expanded, setExpanded] = useState<boolean>(false);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    //Kann das vielleicht gelöscht werden? Wird wohl nicht verwendet!
    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);


    //UseEffect (der Hook, der auf die Variablen hört) für Expanded überwachen und User dem Array hinzufügen/Entfernen
    useEffect(() => {
      setClickedUsers((old)=>{
        let tempArray = [...old] ///Achtung, auf "old" können wir nicht arbeiten, hier lässt er uns nicht filtern, daher müssen wir das Array in ein TempArray kopieren
        if (expanded === true)
        {
            tempArray.push(staff)
        }
        else
        {
            //old ist das "alte Array und wird gefiltert: Wenn expanded false ist und die ID vom tempstaff (= Variable i.d. Foreach) ungleich der ArrayID, dann..."
            tempArray = tempArray.filter(tempStaff => tempStaff.id !== staff.id)
        }
        console.log("hallo ", tempArray)
        return tempArray //sobald geschwungene Klammern aufgehen, wird es zu einer Funktion und wir müssen spezifizieren, was wir zurückgeben wollen.
      })
    
  
    }, [expanded])
        

    return (
        <>
            {staff &&
                <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: "white" }} >
                    <header className='flex centered-aligned stretched-justify'>
                        <div className='flex centered-aligned pointer' onClick={() => setExpanded(!expanded)} style={{ gap: "6px" }}>
                            <div className={(staff.workingStatus === 0) ? styles.accent : (staff.workingStatus === 1 ? styles.working : styles.pause)} />
                            <span className='bold-big'>{displayName}{(workingHours !== undefined && serviceHours !== undefined ? " - " + (serviceHours / (workingHours === 0 ? 1 : workingHours)).toFixed(1) : "")}</span>
                        </div>
                    </header>
                    <Collapse in={expanded}>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"Arbeitszeit: " + (workingHours ? workingHours.toFixed(1) + " h" : 0.0.toFixed(1) + " h")}</span>
                            </div>
                        </section>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"Leistungszeit: " + (serviceHours ? serviceHours.toFixed(1) + " h" : 0.0.toFixed(1) + " h")}</span>
                            </div>
                        </section>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"Verhältnis: " + (workingHours && serviceHours ? (serviceHours / workingHours).toFixed(1) : 0.0.toFixed(1))}</span>
                            </div>
                        </section>
                    </Collapse>
                </div>
            }
        </>
    )
}

export default AdminPersonalCurrentMonthRow;