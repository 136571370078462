import { createContext, useState } from "react";
import { getProductData, getCorrectUserPrice } from "../../Components/ShoppingCart/ProductsStore";


export const CartContext = createContext({
    items: [],
    getProductQuantity: (id: any) => { },
    addOneToCart: (id: any) => { },
    removeOneFromCart: (id: any) => { },
    deleteFromCart: (id: any) => { },
    getTotalCost: () => { }


});

export function CartProvider({ children }: any) {
    const [cartProducts, setCartProducts]: any = useState([]);


    // [{ id: 1, quantity: 2 }]
    function getProductQuantity(id: any) {
        const quantity = cartProducts.find((product: { id: any; quantity: number; }) => product.id === id)?.quantity

        if (quantity === undefined) {
            return 0;
        }

        return quantity;
    }

    // [{ id: 1, quantity: 2 }, { id: 2, quantity: 1 }]
    function addOneToCart(id: any) {
        const quantity = getProductQuantity(id);

        if (quantity === 0) { //product is not in cart
            setCartProducts(
                [
                    ...cartProducts,
                    {
                        id: id,
                        quantity: 1
                    }
                ]
            )
        } else { //product is in cart
            // [{ id: 1, quantity: 2 }, { id: 2, quantity: 1 + 1}]
            setCartProducts(
                cartProducts.map(
                    (product: { id: any; quantity: number; }) => product.id === id ? { ...product, quantity: product.quantity + 1 } : product
                )
            )
        }
    }

    function removeOneFromCart(id: any) {
        const quantity = getProductQuantity(id);

        if (quantity == 1) {
            deleteFromCart(id);
        } else {
            setCartProducts(
                cartProducts.map(
                    (product: { id: any; quantity: number; }) => product.id === id ? { ...product, quantity: product.quantity - 1 } : product
                )
            )
        }
    }

    function deleteFromCart(id: any) {
        // [] if an object meets a condition, add the object to array
        // [product1, product2, product3]
        // [product1, product3]
        setCartProducts(
            (cartProducts: any[]) =>
                cartProducts.filter(currentProduct => {
                    return currentProduct.id != id;
                })
        )
    }

    function getTotalCost() {
        let totalCost = 0;
        cartProducts.map((cartItem: { id: string; quantity: number; }) => {
            const productData = getProductData(cartItem.id);
            if (productData.singularIncrease === true) {
                totalCost += getCorrectUserPrice(cartItem.quantity, cartItem.id) * cartItem.quantity;
            } else {
                totalCost += getCorrectUserPrice(cartItem.quantity, cartItem.id);
            }
        });
        return totalCost;
    }

    const contextValue = {
        items: cartProducts,
        getProductQuantity,
        addOneToCart,
        removeOneFromCart,
        deleteFromCart,
        getTotalCost,

    }

    return (
        <CartContext.Provider value={contextValue}>
            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
// Context (cart, addToCart, removeCart)
// Provider -> gives your React app access to all the things in your context