import React from 'react'
import { IProductDTO } from './ProductProvider'

const ProductReducer = (state: any, action: any) => {
    switch (action.type) {
        case "SET_PRODUCTS":
            return {
                ...state,
                products: action.payload
            }
        case "ADD_PRODUCT":
            return {
                ...state,
                products: [...state.products, action.payload]
            }
        case "REMOVE_PRODUCT":
            return {
                ...state,
                products: state.products.filter((product: IProductDTO) => {
                    if (product.id === action.payload.id) {
                        return false;
                    }
                    return true;
                })
            }
        case "UPDATE_PRODUCT":
            return {
                ...state,
                products: state.products.map((product: IProductDTO) => {
                    if (product.id === action.payload.id) {
                        return action.payload;
                    }
                    return product;
                })
            }
        default:
            return {
                ...state
            }
    }

}

export default ProductReducer