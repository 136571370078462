import { Undo } from '@mui/icons-material'
import { Button } from '@mui/material'
import React, { ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import styles from './CTAButton.module.css'

type backgroundColors = "error" | "base"

function ResetFormButton({ showIcon = true, onClickHandler, title, secondary = false, backgroundColor, disabled, style }: { showIcon?: boolean, onClickHandler: () => void, title: ReactNode, secondary?: boolean, backgroundColor?: backgroundColors, disabled?: boolean, style?: React.CSSProperties }) {


  const [color, setColor] = useState<{ color: string, light: string } | undefined>(undefined)

  useLayoutEffect(() => {
    switch (backgroundColor) {
      case "error":
        setColor({
          color: "var(--error)",
          light: "var(--error-light)"
        })
        break
      case "base":
        setColor({
          color: "var(--text-contrast)",
          light: "var(--light)"
        })
        break


    }
  }, [])

  return (
    <div style={{ width: "100%", display: "flex", justifyContent: "center", marginBottom: "10px" }}>
      <Button
        className={styles.button}
        style={{
          backgroundColor: !secondary ? "var(--secondary-light)" : "var(--brand-light)",
          color: !secondary ? "var(--secondary)" : "var(--brand)",
          minHeight: "35px",
          width: "15em",
          ...style
        }}
        disabled={disabled ?? false}
        onClick={onClickHandler}
      >
        {showIcon && <Undo style={{ marginRight: "2%" }} />}{title}
      </Button>
    </div>
  )
}

export default ResetFormButton