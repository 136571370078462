import React, { useEffect, useState } from 'react'
import { IOrganization } from '../../Interfaces/Company'
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import styles from './CompanyField.module.css'
import { getClassNames } from '../../Hooks/Utilities/Utlitites';
import { IContactData } from '../../Contexts/ContactsContext/ContactsProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';

interface ICompanyFieldProps {
    company: IOrganization
    companyData: IContactData[]
}

function CompanyField(props: ICompanyFieldProps) {

    const { changeVisibility } = useModals();

    const handleEdit = () => {
        changeVisibility!("editCreateCompany", true, { props: { object: props.company, mode: "edit" } })
    }

    const [companyDataEmpty, setCompanyDataEmpty] = useState(!(props.companyData
        .filter((contact) => contact.value !== "" && contact.value !== null).length > 0));

    useEffect(() => {
        setCompanyDataEmpty(!(props.companyData
            .filter((contact) => contact.value !== "" && contact.value !== null).length > 0))
    }, [props.companyData])

    const getElement = (type: number, value: string) => {
        switch (type) {
            case 1:
                if (value === "") {

                }
                return (
                    <a href={`tel:${value}`} className={getClassNames(["flex centered-aligned", styles.clickable])} style={{ textDecoration: "none", color: "var(--text)" }}>
                        <div className='flex centered-aligned' style={{ gap: "8px" }}>
                            <LocalPhoneRoundedIcon fontSize='small' />
                            <span>{value}</span>
                        </div>
                    </a>
                )
            case 2:
                return (
                    <a href={`mailto:${value}`} className={getClassNames(["flex centered-aligned", styles.clickable])} style={{ textDecoration: "none", color: "var(--text)" }}>
                        <div className='flex centered-aligned' style={{ gap: "8px", width: "100%" }}>
                            <EmailRoundedIcon fontSize='small' />
                            <span style={{ maxWidth: "calc(100%)", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{value}</span>
                        </div>
                    </a>
                )
            case 3:

                let displayURL = value;
                displayURL = displayURL.replace(new RegExp("^https?://"), "");
                return (
                    <a style={{ textDecoration: "none", color: "var(--text)" }} className={getClassNames(["flex centered-aligned", styles.clickable])} target="_blank" href={value}>
                        <div className='flex centered-aligned' style={{ gap: "8px" }}>
                            <PublicRoundedIcon fontSize='small' />
                            <span>{displayURL}</span>
                        </div>
                    </a>
                )
            default:
                return null
        }
    }

    return (
        <div className='flex column' style={{ padding: "10px", gap: "4px" }}>
            {props.companyData !== undefined && props.companyData.length > 0 && !companyDataEmpty ? (
                props.companyData
                    .filter((contact) => contact.value !== "" && contact.value !== null) // filter out empty values
                    .map((contact) => (
                        <div key={contact.id}>{getElement(contact.type, contact.value)}</div>
                    ))
            ) : (
                <div className='centered column' style={{ padding: "10px", gap: "10px" }}>
                    <span style={{ color: "var(--text-contrast" }}>Keine Daten zum Unternehmen.</span>
                    <button className='cta-button pointer' onClick={() => handleEdit()}>
                        Hinzufügen
                    </button>
                </div>
            )}
        </div>
    );
}

export default CompanyField