import { AccessTime, AccessTimeFilled, CalendarMonth, CalendarToday, Drafts, Edit, InsertInvitation, NoteAdd, Person, ScheduleSend, Send, Title } from "@mui/icons-material"
import { INewsletterDesign, INewsletterQueueItem } from "../../Contexts/ContactsContext/ContactsProvider"
import MoreOptionsButton from "../Buttons/MoreOptionsButton/MoreOptionsButton"
import { useStaff } from "../../Contexts/StaffContext/StaffProvider"
import dayjs, { Dayjs } from "dayjs"

interface props {
    queueItem: INewsletterQueueItem,
    draft: INewsletterDesign,
    onPressEdit: (draft: INewsletterDesign) => void
    onPressCancelSchedule: (draft: INewsletterDesign) => void
}

const NewsletterQueueRow = ({ draft, queueItem, onPressEdit, onPressCancelSchedule }: props) => {
    const { staff } = useStaff();


    const options = [
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Versenden abbrechen",
            function: onPressCancelSchedule
        }
    ]

    return <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
        <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", paddingLeft: "1em", paddingRight: "1em" }}>
            <div style={{ display: "flex", flexDirection: "row", width: "20%", alignItems: "center", gap: "0.33em" }}>{new Date(queueItem.scheduledFor ?? "") > new Date() ? <AccessTime /> : <Send />}{new Date(queueItem.scheduledFor ?? "") > new Date() ? "Geplant" : "Versendet"}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "25%", alignItems: "center", gap: "0.33em" }}><Title />{draft.title}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "20%", alignItems: "center", gap: "0.33em" }}><Person />{staff.find(temp => temp.id === draft.authorId)?.username}</div>
            <div style={{ display: "flex", flexDirection: "row", width: "20%", alignItems: "center", gap: "0.33em" }}><InsertInvitation />{new Date(queueItem.scheduledFor ?? "").toLocaleString()}</div>
        </div>
        <MoreOptionsButton options={options}></MoreOptionsButton>
    </div>
}

export default NewsletterQueueRow