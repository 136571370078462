import { useEffect, useState } from "react";
import { AppointmentCategoryVisibilityTypes, useTasks } from "../../Contexts/TaskContext/TaskContext";
import { IAppointmentCategory } from "../../Interfaces/Appointment";
import styles from "./Calendar.module.css";
import CalendarCategoriesCollapsibleCheckbox from "./CalendarCategoriesCollapsibleCheckbox";

interface CalendarCategoriesProps {
    privateCheckedCategories: { [categoryId: number]: boolean };
    setPrivateCheckedCategories: React.Dispatch<React.SetStateAction<{ [categoryId: number]: boolean }>>;
    organizationCheckedCategories: { [categoryId: number]: boolean };
    setOrganizationCheckedCategories: React.Dispatch<React.SetStateAction<{ [categoryId: number]: boolean }>>;
}

function CalendarCategories({ privateCheckedCategories, setPrivateCheckedCategories, organizationCheckedCategories, setOrganizationCheckedCategories }: CalendarCategoriesProps) {
    const { appointmentCategories } = useTasks()
    const [organizationCategories, setOrganizationCategories] = useState<IAppointmentCategory[]>(appointmentCategories?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Organization))

    const [privateCategories, setPrivateCategories] = useState<IAppointmentCategory[]>(appointmentCategories?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Private));

    useEffect(() => {
        setOrganizationCategories(
            appointmentCategories?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Organization))

        setPrivateCategories(appointmentCategories?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Private))
    }, [appointmentCategories])

    return (
        <div className={styles.calendarCategoryContainer} style={{ overflow: 'scroll', height: '58%', scrollbarWidth: 'none' }}>
            <CalendarCategoriesCollapsibleCheckbox childrenElements={organizationCategories}
                setChildrenElements={setOrganizationCategories}
                parentLabel="Organisation"
                parentColor="var(--brand)"
                displayDefaultCategories
                checkedCategories={organizationCheckedCategories}
                setCheckedCategories={setOrganizationCheckedCategories} />

            <CalendarCategoriesCollapsibleCheckbox childrenElements={privateCategories}
                parentLabel="Privat"
                parentColor="#0096FF"
                checkedCategories={privateCheckedCategories}
                setCheckedCategories={setPrivateCheckedCategories}
            />
        </div>
    )
}

export default CalendarCategories