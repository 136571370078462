import React from 'react'
import { journal, journalDTO } from '../../Interfaces/Journal'

const JournalReducer = (state: any, action: any) => {
  switch (action.type) {
    case "SET_JOURNAL":
      return {
        ...state,
        entries: action.payload
      }
    case "SET_JOURNALWITHFILTER":
      return {
        ...state,
        entriesWithFilter: action.payload
      }
      case "SET_JOURNALFILTER":
      console.log("action.payload",action.payload)

        return {
          ...state,
          journalFilter: action.payload
        }
    case "ADD_JOURNAL":

      if(state.journalFilter.personIds.includes(action.payload.personId) || state.journalFilter.companyIds.includes(action.payload.customerOrganizationId)){
        return {
          ...state,
          entries: [action.payload, ...state.entries],
          entriesWithFilter: [action.payload, ...state.entriesWithFilter]
        }
      }
      return {
        ...state,
        entries: [action.payload, ...state.entries]
      }
    case "ADDRANGE_JOURNAL":
      return {
        ...state,
        entries: [...state.entries, ...action.payload,]
      }
      case "ADDRANGE_JOURNALWITHFILTER":
      return {
        ...state,
        entriesWithFilter: [...state.entriesWithFilter, ...action.payload,]
      }
    case "UPDATE_JOURNAL":
      return {
        ...state,
        entries: state.entries.map((journal: journal) => {
          if (journal.id === action.payload.id) {
            return action.payload;
          }
          return journal;
        })
      }
    case "DELETE_JOURNAL":
      return {
        ...state,
        entries: state.entries.filter((journal: journal) => {
          if (journal.id === action.payload.id) {
            return false;
          }
          return true;
        })
      }
    default:
      return {
        ...state
      }
  }
}

export default JournalReducer