import React, { useState } from 'react'
import SyncAltRoundedIcon from '@mui/icons-material/SyncAltRounded';
import TrendingFlatRoundedIcon from '@mui/icons-material/TrendingFlatRounded';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { CircularProgress, Collapse } from '@mui/material';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { ITravel } from '../../Interfaces/Travel';
import { useDeleteTravelMutation } from '../../redux/apiSlices/Travel';
import dayjs from 'dayjs';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import { useDeleteTravelMutationNotifications } from './Travel.hooks';

const Travel = ({ travel, index }: { travel: ITravel, index: number }) => {
    const { getCompanyByID } = useContacts()

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const [deleteTravel, { isSuccess: deleteTravelSuccess, isError: deleteTravelError, isLoading: deleteTravelLoading, error }] = useDeleteTravelMutation();

    useDeleteTravelMutationNotifications(deleteTravelSuccess, deleteTravelError, deleteTravelLoading, error);

    const { changeVisibility } = useModals()

    const [expanded, setExpanded] = useState(false);

    const handleExpand = () => {
        setExpanded(!expanded);
    }

    const options = [
        {
            text: "Bearbeiten",
            function: () => changeVisibility!("editCreateTravel", true, { props: { mode: "edit", object: travel } }),

        },
        {
            text: "Löschen",
            function: async () => { await deleteTravel(travel); setAnchorEl(null) },
        },

    ]


    return (
        <div className={`flex rounded ${index % 2 === 1 ? 'bg-[var(--light)]' : 'bg-transparent'} pt-2 pb-2`}>
            <div className='flex flex-col w-[80%] justify-center text-center items-center cursor-pointer' onClick={() => handleExpand()}>
                <span className='font-medium'>
                    {travel.startDestination}
                </span>
                <span className='font-medium'>
                    {travel.endDestination}
                </span>

                <span>
                    {travel.date ? dayjs(travel.date).format('DD.MM.YYYY') : ""}
                </span>
                <span>
                    {travel.distance}km
                </span>
                <span>
                    {travel?.customerOrganizationId ? getCompanyByID!(travel?.customerOrganizationId)?.name : ""}
                </span>
                <Collapse in={expanded}>
                    <div>
                        <span> {travel.note ? travel.note : "Keine Notiz"}</span>
                    </div>

                </Collapse>
            </div>
            <div className='flex max-xl:flex-col w-[20%] justify-center items-center'>

                {travel.return ? //<HeightRoundedIcon fontSize='small' /> : <SouthRoundedIcon fontSize='small' />
                    <SyncAltRoundedIcon fontSize='small' /> : <TrendingFlatRoundedIcon fontSize='small' />
                }

                {deleteTravelLoading ? <CircularProgress size={18} /> : <MoreOptionsButton options={options} />}
            </div>
        </div>
    )
}

export default Travel