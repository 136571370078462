import React, { Dispatch, SetStateAction } from "react";
import { IHoliday } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { IAppointment } from "../../Interfaces/Appointment";
import { ITask } from "../../Interfaces/Task";
import DayOfMonth from "./CalendarDayMonth";

interface monthProps {
    month: any,
    selectedView?: string,
    showAppointmentInfoModal?: boolean,
    setShowAppointmentInfoModal?: Dispatch<SetStateAction<boolean>>
    setClickedAppointment?: Dispatch<SetStateAction<IAppointment>>
    showTaskInfoModal?: boolean,
    setShowTaskInfoModal?: Dispatch<SetStateAction<boolean>>
    setClickedTask?: Dispatch<SetStateAction<ITask>>
    showHolidayInfoModal?: boolean,
    setShowHolidayInfoModal?: Dispatch<SetStateAction<boolean>>
    setClickedHoliday?: Dispatch<SetStateAction<IHoliday>>
    privateCheckedCategories?: { [categoryId: number]: boolean };
    organizationCheckedCategories?: { [categoryId: number]: boolean };
    //hour: any;
}

export default function Month({ month, selectedView, showAppointmentInfoModal, setShowAppointmentInfoModal, setClickedAppointment, showTaskInfoModal, setShowTaskInfoModal, setClickedTask, showHolidayInfoModal, setShowHolidayInfoModal, setClickedHoliday, privateCheckedCategories, organizationCheckedCategories }: monthProps) {
    return (
        <>
            <div style={{
                flex: '1 1 0%', display: 'grid',
                gridTemplateColumns: `repeat(7, minmax(0, ${100 / 7}%))`,
                gridTemplateRows: `repeat(${month.length}, minmax(0, ${100 / month.length}%))`,
            }}
            >
                {month.map((row: any, i: any) => (
                    <React.Fragment key={i}>
                        {row.map((day: any, idx: any) => (
                            <DayOfMonth monthRows={month.length} day={day} key={idx} colIndex={idx} rowIndex={i}
                                selectedView={selectedView}
                                showAppointmentInfoModal={showAppointmentInfoModal}
                                setShowAppointmentInfoModal={setShowAppointmentInfoModal}
                                setClickedAppointment={setClickedAppointment}
                                showTaskInfoModal={showTaskInfoModal}
                                setShowTaskInfoModal={setShowTaskInfoModal}
                                setClickedTask={setClickedTask}
                                setClickedHoliday={setClickedHoliday}
                                showHolidayInfoModal={showHolidayInfoModal}
                                setShowHolidayInfoModal={setShowHolidayInfoModal}
                                privateCheckedCategories={privateCheckedCategories}
                                organizationCheckedCategories={organizationCheckedCategories} 
                                //hour={1}
                                />
                        ))}
                    </React.Fragment>
                ))}
            </div>

        </>
    )
}