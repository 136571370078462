import { createRef, useEffect, useState } from "react";
import { IDocument, downloadFile, useDocuments } from "../../Contexts/DocumentContext/DocumentContext";
import { Box, Button, Collapse, LinearProgress, LinearProgressProps, Tooltip, Typography } from '@mui/material'
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton'
import { Backup, ContentPaste, Delete, Download, ExpandLessRounded, ExpandMoreRounded, FileUpload, PersonOutline, SaveAlt, SnoozeOutlined, Upload, WorkHistoryOutlined, WorkOffOutlined, WorkOutline } from '@mui/icons-material'
import { formatBytes } from "../../utils/helper";
import { authConnection } from "../../Connection/BaseConnection";
import uuid from "react-uuid";
import InputField from "../InputField/InputField";

interface props {
    personId?: number,
    organizationId?: number
}

const DocumentUploadRow = ({ personId, organizationId }: props) => {
    const chunkSize = 1048576 * 10; //1048576 is 1 MB, here we use 4 MB per chunk

    const [file, setFile] = useState<any>({});
    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState<any>({})
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState<number | undefined>()
    const [progress, setProgress] = useState(0)
    const [fileGuid, setFileGuid] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)

    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }

    const getFileContext = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setFile(e.target.files[0])
        }

        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        setFileToBeUpload(_file)
        const _fileID = uuid();
        setFileGuid(_fileID)
    }

    const fileUpload = () => {
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk).then(() => { setCounter(counter + 1); })
        }
    }

    const uploadCompleted = async () => {
        var formData = new FormData();
        formData.append('fileName', fileGuid);

        const response: any = await authConnection.post("/document/UploadComplete", null, {
            params: {
                fileName: fileGuid,
                originalFileName: file.name,
                contentType: file.type,
                personId: personId,
                organizationId: organizationId
            },
            data: formData,
        });

        const data = response.data;
        if (data.isSuccess) {
            setProgress(100);
            setTimeout(() => {
                setShowProgress(false);
                console.log("data", data, "data.data", data.data)
                console.log("personId", personId, "organizationId", organizationId)
                if (personId && personId > 0) {
                    addDocumentToPerson(personId, data.data);
                } else if(organizationId && organizationId > 0) {
                    addDocumentToOrganization(organizationId, data.data);
                }
                setProgress(0);
                setFile({});
            }, 1000)
        }
    }

    const uploadChunk = async (chunk: any) => {
        try {
            await uploadFile(chunk, "/document/UploadChunks", (event: any) => {
                const progress = (((event.loaded / event.total / chunkCount) + ((counter - 1) / chunkCount)) * 100);
                setProgress(progress);
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    const progressInstance = <LinearProgress value={progress} />;

    useEffect(() => {
        if (fileSize > 0 && progress < 100) {
            setShowProgress(true);
            fileUpload();
        }
    }, [fileToBeUpload, counter]);


    function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const { downloadDocument, addDocumentToPerson, addDocumentToOrganization } = useDocuments();

    const [downloading, setDownloading] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: "rgb(10,200,10)",
            '&:hover': {
                bgcolor: "rgb(10,250,10)",
            },
        }),
    };

    const uploadFile = (chunk: any, url: string, onProgress: any) => {
        return authConnection({
            url,
            method: 'POST',
            data: chunk,
            params: {
                id: counter,
                fileName: fileGuid,
            },
            headers: { 'Content-Type': 'application/json' },
            onUploadProgress: onProgress,
        })
            .then((response: any) => {
                const data = response.data;
                if (data.isSuccess) {
                    setBeginingOfTheChunk(endOfTheChunk!);
                    setEndOfTheChunk(endOfTheChunk! + chunkSize);
                    if (counter == chunkCount) {
                        uploadCompleted();
                    }
                } else {
                    console.log('Error Occurred:', data.errorMessage)
                }
            })
            .catch((error) => {
                // Handle error
            });
    };

    const fileInputRef = createRef<any>();

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event: any) => {
        getFileContext(event);


        const file = event.target.files[0];
        if (file) {
            console.log('Selected file:', file);
        }
    };

    return (
        <div>
            <div
                style={{ borderWidth: "1px", borderColor: "#f2f2f2", borderStyle: "solid", borderRadius: "8px", backgroundColor: "var(--brand-light)" }} key={/*id*/ 1}>
                <div className="itemContainer" style={{ width: "100%", display: "flex", flexDirection: "row", padding: "1%", alignItems: "center", cursor: "pointer" }}>
                    <div onClick={handleFileUploadClick} style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ width: "80%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            {/*expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />*/}
                            <Tooltip title="Mitarbeiter" placement="bottom">
                                <Upload style={{ marginRight: "0.5vh" }} />
                            </Tooltip>
                            <span>
                                Neue Datei hochladen
                            </span>
                        </div>
                        <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                        </div>
                    </div>
                    <div style={{ width: "10%", marginLeft: "auto", marginRight: "1vw" }}>
                    </div>
                </div>
                <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                />
            </div>
            {file.name && progress !== 0 &&
                <div style={{ borderWidth: "1px", borderColor: "#f2f2f2", borderStyle: "solid", borderRadius: "8px" }} key={/*id*/ 1}>
                    <div className="itemContainer" style={{ width: "100%", display: "flex", flexDirection: "row", padding: "1%", alignItems: "center", cursor: "pointer", borderBottom: "" }}>
                        <div onClick={() => { }} style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                            <div style={{ width: "80%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                {/*expanded ? <ExpandLessRounded /> : <ExpandMoreRounded />*/}
                                <Tooltip title="Mitarbeiter" placement="bottom">
                                    <Upload style={{ marginRight: "0.5vh" }} />
                                </Tooltip>
                                <span>
                                    {file.name}
                                </span>
                            </div>
                            <div style={{ width: "15%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                                <Tooltip title="Datum" placement="bottom">
                                    <Backup />
                                </Tooltip>
                                <span>
                                    {progress.toFixed(2)} %
                                </span>
                            </div>
                        </div>
                        <div style={{ width: "10%", marginLeft: "auto", marginRight: "1vw" }}>
                            <MoreOptionsButton options={[]}></MoreOptionsButton>
                        </div>
                    </div>
                </div >
            }
        </div>
    )
}

export default DocumentUploadRow