// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CTAButton_button__PZ6Wb {\n    border-radius: var(--borderRadiusInner) !important;\n    text-transform: none !important;\n    width: 100%;\n}", "",{"version":3,"sources":["webpack://./src/Components/Buttons/ResetFormButton/CTAButton.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,+BAA+B;IAC/B,WAAW;AACf","sourcesContent":[".button {\n    border-radius: var(--borderRadiusInner) !important;\n    text-transform: none !important;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "CTAButton_button__PZ6Wb"
};
export default ___CSS_LOADER_EXPORT___;
