import { useEffect } from "react";
import { useEmployees } from "../../../Contexts/EmployeeContext/EmployeeProvider";
import { IProjectDTO } from "../../../Contexts/TaskContext/TaskContext";
import { form } from "../CreateTaskForm";
import Form from "../FormUtils/Form";
import FormRow from "../FormUtils/FormRow";

const CreateEditProjectForm = ({ object, setObject }: form<IProjectDTO>) => {

    const { employees } = useEmployees();

    return (
        <Form>
            <FormRow
                label="Projektname:"
                placeholder="Projektname"
                type="text"
                value={object.title}
                onChange={(val) => setObject((old) => ({ ...old, title: val }))}
            />
            <FormRow
                label="Beschreibung:"
                placeholder="Beschreibung"
                type="multiline"
                value={object.description}
                onChange={(val) => setObject((old) => ({ ...old, description: val }))}
            />

            <FormRow
                label="Start des Projekts:"
                type="date"
                value={object.startDate}
                dateOptions={{
                    disableFuture: false,
                    disablePast: false,
                    type: "Date"
                }}
                onChange={(val) => setObject((old) => ({ ...old, startDate: val }))}
            />

            <FormRow
                label="Geplante Fertigstellung:"
                type="date"
                value={object.scheduledDate ?? ""}
                dateOptions={{
                    disableFuture: false,
                    disablePast: false,
                    type: "Date"
                }}
                onChange={(val) => setObject((old) => ({ ...old, scheduledDate: val }))}
            />

            <FormRow
                label="Mitarbeiter*innen hinzufügen:"
                type="select"
                value={object.userIds}
                onChange={(val) => setObject((old) => ({ ...old, userIds: val }))}
                selectOptions={{
                    titleKey: "username",
                    valueKey: "id",
                    options: employees,
                    multiple: true
                }}
            />

            <FormRow
                title="Fertiggestellt"
                type="switch"
                value={object.finished}
                onChange={(val) => setObject((old) => ({ ...old, finished: val }))}
            />
            <FormRow
                label="Fertigstellung des Projekts:"
                type="date"
                value={object.finishedDate ?? ""}
                onChange={(val) => setObject((old) => ({ ...old, finishedDate: val }))}
            />
            <FormRow
                title="Abgebrochen"
                type="switch"
                value={object.aborted}
                onChange={(val) => setObject((old) => ({ ...old, aborted: val }))}
            />
            <FormRow
                label="Abbruch des Projekts:"
                type="date"
                value={object.abortedDate ?? ""}
                onChange={(val) => setObject((old) => ({ ...old, abortedDate: val }))}
            />

        </Form>
    )
}

export default CreateEditProjectForm;