import { useEffect, useLayoutEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { PageInfo } from '../Interfaces/PageInfo'
import styles from './Navigation.module.css'


//Icons
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded'
import AssignmentRoundedIcon from '@mui/icons-material/AssignmentRounded'
import AssignmentTurnedInRoundedIcon from '@mui/icons-material/AssignmentTurnedInRounded'
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded'
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded'
import InsertChartRoundedIcon from '@mui/icons-material/InsertChartRounded'
import Inventory2RoundedIcon from '@mui/icons-material/Inventory2Rounded'
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded'
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded'


//Komponenten
import { PointOfSaleSharp, SatelliteAlt } from "@mui/icons-material"
import { Avatar, Badge, Slide } from '@mui/material'
import { styled } from '@mui/material/styles'
import uuid from 'react-uuid'
import HamburgerButton from '../Components/Buttons/HamburgerButton/HamburgerButton'
import SearchBar from '../Components/SearchBar/SearchBar'
import { useCompanyData } from '../Contexts/CompanyDataContext/CompanyDataProvider'
import { useModals } from '../Contexts/DialogContext/DialogProvider'
import { useStaff } from '../Contexts/StaffContext/StaffProvider'
import { useUser } from '../Contexts/UserContext/UserContext'
import { useMobileView } from '../Hooks/useMobileView'
import NavigationItem from './NavigationItem'

export interface active {
    page: number
    subpage: number
}

export let standardPages: PageInfo[] = [
    {
        id: uuid(),
        name: "Dashboard",
        path: "/dashboard",
        icon: <GridViewRoundedIcon />,
    },
    {
        id: uuid(),
        name: "Aufgaben",
        path: "/aufgaben",
        icon: <AssignmentRoundedIcon />,
        subpages:
            [{
                id: uuid(),
                name: "Lieferschein",
                path: "/aufgaben/lieferschein"
            }],
    },
    {
        id: uuid(),
        name: "Leistungen",
        path: "/leistungen",
        icon: <AssignmentTurnedInRoundedIcon />,
        /*Hier eine Abfrage ob Admin oder nicht, bei Admin anzeigen, sonst nicht: */
        subpages: [
            {
                id: uuid(),
                name: "Übersicht",
                path: "/leistungen/servicesOverview"
            },
        ]
    },
    {
        id: uuid(),
        name: "Kontakte",
        path: "/kontakte",
        icon: <PermContactCalendarRoundedIcon />,
        subpages: [
            {
                id: uuid(),
                name: "Tags",
                path: "/kontakte/tags"
            },
        ]
    },
    {
        id: uuid(),
        name: "Kalender",
        path: "/calendar",
        icon: <CalendarMonthRoundedIcon />,
    },
    {
        id: uuid(),
        name: "Kommunikation",
        path: "/kommunikation/telefon",
        icon: <SatelliteAlt />,
        subpages: [
            {
                id: uuid(),
                name: "Telefon",
                path: "/kommunikation/telefon"
            },
            {
                id: uuid(),
                name: "SMS-Versand",
                path: "/kommunikation/sms"
            },
            {
                id: uuid(),
                name: "Email",
                path: "/kommunikation/email"
            },
            {
                id: uuid(),
                name: "Newsletter",
                path: "/kommunikation/newsletter",
                subpages: [
                    {
                        id: uuid(),
                        name: "Warteschlange",
                        path: "/kommunikation/newsletter/warteschlange"
                    }
                ]
            },
            {
                id: uuid(),
                name: "Messenger",
                path: "/kommunikation/Messenger"
            },
            {
                id: uuid(),
                name: "Social Media",
                path: "/kommunikation/SocialMedia"
            }
        ]
    },
    {
        id: uuid(),
        name: "Verwaltung",
        path: "/verwaltung/personal",
        icon: < Inventory2RoundedIcon />,
        subpages: [
            {
                id: uuid(),
                name: "Personal",
                path: "/verwaltung/personal",
                subpages: [
                    {
                        id: uuid(),
                        name: "Arbeitszeiten",
                        path: "/verwaltung/personal/arbeitszeiten"
                    },
                    {
                        id: uuid(),
                        name: "Urlaube",
                        path: "/verwaltung/personal/urlaube"
                    }
                ]
            },
            {
                id: uuid(),
                name: "Abteilungen",
                path: "/verwaltung/abteilungen"
            },
            {
                id: uuid(),
                name: "Produkte",
                path: "/verwaltung/produkte"
            },
            {
                id: uuid(),
                name: "Dokumente",
                path: "/verwaltung/dokumente"
            },
        ]
    },
    {
        id: uuid(),
        name: "Statistiken",
        path: "/statistiken",
        icon: < InsertChartRoundedIcon />
    },
    {
        id: uuid(),
        name: "Rechnungswesen",
        path: "/rechnungswesen",
        icon: <AccountBalanceRoundedIcon />,
        subpages: [
            {
                id: uuid(),
                name: "Angebote",
                path: "/rechnungswesen/angebote"
            },
            {
                id: uuid(),
                name: "Rechnungen",
                path: "/rechnungswesen/rechnungen"
            },
            {
                id: uuid(),
                name: "Rechnungskreise",
                path: "/rechnungswesen/rechnungskreise"
            },
            {
                id: uuid(),
                name: "Ausgaben/Belege",
                path: "rechnungswesen/belege"
            },
            {
                id: uuid(),
                name: "Firmenabrechnung",
                path: "/rechnungswesen/firmenabrechnung"
            },
            {
                id: uuid(),
                name: "Einnahmenintervalle",
                path: "/rechnungswesen/intervallleistungen"
            },
            {
                id: uuid(),
                name: "Ausgabeintervalle",
                path: "rechnungswesen/ausgangsintervalle"
            },
            {
                id: uuid(),
                name: "Buchhaltungs-Export",
                path: "rechnungswesen/accExport"
            }
        ]
    },
    {
        id: uuid(),
        name: "Kasse",
        path: "/kassa",
        icon: <PointOfSaleSharp />,
        subpages: [
            {
                id: uuid(),
                name: "Datenerfassungsprotokoll",
                path: "/kassa/datenerfassungsprotokoll"
            },
            {
                id: uuid(),
                name: "Einstellungen",
                path: "/kassa/settings"
            },
        ]
    },
    {
        id: uuid(),
        name: "Einstellungen",
        path: "einstellungen/privat",
        icon: <SettingsRoundedIcon />
    }
]

export var stringToColour = function (str: string) {
    var hash = 0;
    for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    var colour = '#';
    for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xFF;
        colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
}

function Navigation() {

    const { user } = useUser();

    const { changeVisibility } = useModals()

    const [pages, setPages] = useState<PageInfo[]>(standardPages);

    useEffect(() => {
        let tempPages = [...standardPages];


        /**Anfang von Menü einschränken für Nicht-Admins (von Nico), hier gehts weiter! */

        //ToDo: Complete and implement a Page for administering OrganizationRights
        tempPages = pages.filter((page: PageInfo) => {
            if ((user?.rights?.readBill ?? false) === false && page.name === "Rechnungswesen") {
                return null;
            } else if (page.name === "Verwaltung") {
                page.subpages = page.subpages?.filter((page) => {
                    if ((user?.rights?.readSubscription ?? false) === false && page.name === "Intervalle") {
                        return false; //Mit true und false kann die Seite für Nichtadmins ausgeblendet werden
                    } else if ((user?.rights?.readProduct ?? false) === false && page.name === "Produkte") {
                        return false;
                    } else if ((user?.rights?.readDepartment ?? false) === false && page.name === "Abteilungen") {
                        return false;
                    } else if ((user?.rights?.readEmployees ?? false) === false && page.name === "Personal") {
                        return false;
                    }

                    return true;
                })
                if (page.subpages?.length === 0) {
                    return null;
                }
            } else if ((user?.rights?.readStatistics ?? false) === false && page.name === "Statistiken") {
                return null;
            } else if (page.name == "Leistungen") {
                page.subpages = page.subpages?.filter((page) => {
                    if ((user?.rights?.readSubscription ?? false) === false && page.name === "Übersicht") {
                        return false;
                    }

                    return true;
                })
            }

            return page;
        })

        if (user?.organizationRoleId === 20147 || user?.organizationRoleId === 20165) {  //EHParkett & MasterCares
            tempPages = [{
                id: uuid(),
                name: "Dashboard",
                path: "/dashboard",
                icon: <GridViewRoundedIcon />,
            }]
        }

        setPages(tempPages)
    }, [user?.rights])


    //Entscheidet darüber, welche Navigation angezeigt wird.
    const showMobile = useMobileView()

    const location = useLocation()

    //* Wird nicht mehr gebraucht, muss jedoch saber aus der Codebase extrahiert werden.
    const [active, setActive] = useState<active>({ page: -1, subpage: -1 })

    useLayoutEffect(() => {
        //! Ineffizient und Bad Practice -> Funktioniert aber.
        if (location.pathname !== "/") {
            setActive((prev) => {
                const pageIndex = pages.findIndex((pageInfo: PageInfo) => pageInfo.path === "/" + location.pathname.split("/")[1])

                const subpageIndex = pages[pageIndex]?.subpages?.findIndex((subpage: PageInfo) => subpage.path === location.pathname)

                setOpenMenu(false);

                return {
                    page: pageIndex ?? -1,
                    subpage: subpageIndex ?? -1
                } as active
            })
        }

    }, [location.pathname])

    //* State-Management für das mobile Menü 

    //Öffnungsstate des mobilen Menüs
    const [openMenu, setOpenMenu] = useState(false)

    //Modus des mobilen Menüs -> Kann beim Einfügen von zusätzlichen Buttons leicht ergänz werden
    const [mobileMode, setMobileMode] = useState<"link" | "search">("link")

    //Steuert das Verhalten des Mobile-Menus basierend auf der Komponente, die das Verhalten auslöst
    const handleOpenMobileMenu = (component: "search" | "link") => {
        if (component === "search") {
            if (openMenu) {
                if (mobileMode === "search") {
                    setOpenMenu(false)
                } else {
                    setMobileMode("search")
                }
            } else {
                setOpenMenu(true)
                setMobileMode("search")
            }
        } else {
            setOpenMenu((val) => !val)
            setMobileMode((val) => val === "search" && openMenu ? "search" : "link")
        }
    }

    var textColorToBackground = function (str: string) {
        var rgb = [0, 0, 0];
        rgb[0] = parseInt(str.substring(1, 3), 16);
        rgb[1] = parseInt(str.substring(3, 5), 16);
        rgb[2] = parseInt(str.substring(5, 8), 16);

        const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

        if (brightness > 125) {
            return "black"
        } else {
            return "white"
        }
    }

    const avatarBgColor = stringToColour(user?.username ?? "");
    const avatarTextColor = textColorToBackground(avatarBgColor);

    const { staff } = useStaff();
    const author = staff.find(temp => temp.id === user?.userId);

    const StyledBadge = styled(Badge)(({ theme }) => (author?.workingStatus !== 0 && {
        '& .MuiBadge-badge': {
            height: "12px",
            width: "12px",
            borderRadius: "12px",
            backgroundColor: author?.workingStatus === 1 ? "green" : (author?.workingStatus === 2 ? "orange" : "blue"),
            color: author?.workingStatus === 1 ? "green" : (author?.workingStatus === 2 ? "orange" : "blue"),
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                content: '""',
            },
        }
    }));

    const [expandedIndex, setExpandedIndex] = useState<number | undefined>();

    const { companyData } = useCompanyData();

    const SmallAvatar = styled(Avatar)(({ theme }) => ({
        width: 22,
        height: 22,
        border: `2px solid ${theme.palette.background.paper}`,
    }));

    if (showMobile) {
        return (
            <div>
                <div className={`flex stretched-justify centered-aligned min-h-[50px] ${!openMenu && "shadow "}`.concat(" " + styles.navbar + " ")} style={{ zIndex: 1000 }}>
                    <HamburgerButton
                        open={openMenu}
                        setOpen={(newOpen) => handleOpenMobileMenu("link")}
                    />
                </div>

                <Slide direction="down" in={openMenu} mountOnEnter unmountOnExit>
                    <div className={styles.mobileContainer} >
                        {
                            mobileMode === "link" ? (
                                pages.map((page: PageInfo, index: number) => (
                                    <NavigationItem
                                        key={page.id}
                                        pageInfo={page}
                                    />
                                ))
                            )
                                :
                                (
                                    <SearchBar
                                        autofocus
                                        value='asd'
                                        onChangeHandler={() => { }}
                                    />
                                )
                        }
                    </div>
                </Slide>
            </div>
        )
    } else {
        return (
            <div className='w-[14%] min-w-[260px]'>
                <nav className='flex flex-col justify-between p-5 bg-default h-screen' >
                    <section className='flex column centered-aligned scroll' style={{ gap: "10px" }}>
                        {companyData?.logoSquareBase64 &&
                            <Avatar
                                src={`data:image/jpeg;base64,${companyData?.logoSquareBase64}`}
                                alt={companyData?.name}
                                sx={{ width: 40, height: 40, backgroundColor: "var(--elevated-background)", boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.75)", color: avatarTextColor }}
                                style={{ position: "absolute", marginRight: 80, zIndex: 1 }}
                                variant="circular"
                            ></Avatar>
                        }
                        <section className='flex column' style={{ marginBottom: "20px", alignItems: "center", justifyContent: "center", gap: "5px" }}
                            onClick={() => { changeVisibility!("editCreateProfilePicture", true); }}>
                            <StyledBadge
                                style={{ zIndex: 0 }}
                                overlap="circular"
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                                variant="dot"
                            >
                                <Avatar
                                    src={`data:image/jpeg;base64,${user?.profilePictureBase64}`}
                                    alt={user?.username?.substring(0, 2)}
                                    sx={{ width: 80, height: 80, backgroundColor: avatarBgColor, color: avatarTextColor }}
                                >
                                </Avatar>
                            </StyledBadge>
                            <span className='subheading'>{user?.username ?? "loading..."}</span>
                        </section>
                        <div className='flex column centered-aligned scroll' style={{ gap: "10px", width: "100%" }}>
                            {
                                pages.filter((page: PageInfo) => page.path !== "einstellungen/privat")
                                    .map((page: PageInfo, index: number) => (
                                        <NavigationItem key={page.id} pageInfo={page} />
                                    ))
                            }
                        </div>
                    </section>
                    <section className='flex column centered-aligned' >
                        <NavigationItem
                            key={pages.find((page: PageInfo) => page.path === "einstellungen/privat")?.id}
                            pageInfo={pages.find((page: PageInfo) => page.path === "einstellungen/privat")!}
                        />
                    </section>
                </nav>
            </div>
        )
    }
}

export default Navigation