import React, { Dispatch, SetStateAction, useEffect, useLayoutEffect, useRef, useState } from "react"
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { IconButton, Popover, Tooltip, debounce } from "@mui/material";
import ClearIcon from '@mui/icons-material/Clear';

export interface IFilterOption {
    title: string       //display value
    valueKey: string    //string of value that should be filtered, e.g. "id"
    referenceList?: any[] //use when valueKey is foreignKey, e.g. customerOrganizationId
    referenceListValueKey?: string //value to be searched for in reference list
    value?: string                        //value passed from parent component for input field
}

type APIData = {
    [key: string]: any
}

interface IFilter<T> {
    dataToFilter: T[] //data that should be filtered and is rendered/displayed by parent component
    setDataToFilter: Dispatch<SetStateAction<T[]>> //function to set the data being displayed in parent component after filtering
    originalData: T[]   //initially the same as dataToFilter, used to reset filter and handle cases when data is updated/changed in parent component
    filterOptions: IFilterOption[]

    //values to set how the popover of the filter opens (https://mui.com/material-ui/react-popover/#anchor-playground)
    anchorOriginVertical?: "top" | "bottom" | "center"
    anchorOriginHorizontal?: "left" | "center" | "right"
    transformOriginVertical?: "top" | "center" | "bottom"
    transformOriginalHorizontal?: "left" | "center" | "right"
}

function isUndefinedOrEmptyArray<T>(array: T[] | undefined): boolean {
    return !array || array.length === 0;
}


function areArraysEqual(arr1: any[], arr2: any[]) {
    // Check if both inputs are arrays
    if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
        return false;
    }

    // Check if the arrays have the same length
    if (arr1.length !== arr2.length) {
        return false;
    }

    // Iterate over the elements of the arrays and recursively compare them
    for (let i = 0; i < arr1.length; i++) {
        if (Array.isArray(arr1[i]) && Array.isArray(arr2[i])) {
            // If the elements are arrays, recursively compare them
            if (!areArraysEqual(arr1[i], arr2[i])) {
                return false;
            }
        } else if (arr1[i] !== arr2[i]) {
            // If the elements are not arrays, compare them directly
            return false;
        }
    }

    // If all elements are equal, return true
    return true;
}

function Filter<T extends APIData>({ dataToFilter, setDataToFilter, filterOptions, originalData, anchorOriginHorizontal, anchorOriginVertical, transformOriginVertical, transformOriginalHorizontal }: IFilter<T>) {
    const originalDataRef = useRef<T[]>();
    const [filterValues, setFilterValues] = useState<{ valueKey: string, value: string }[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);


    const [inputTyped, setInputTyped] = useState(false);

    // Function to update filter values for a specific filter option
    const updateFilterValue = (index: number, value: string) => {
        setInputTyped(true);
        setFilterValues(prevFilterValues => {
            const updatedValues = [...prevFilterValues];
            updatedValues[index] = { ...updatedValues[index], value };
            return updatedValues;
        });
    }

    function clearFilterValues() {
        setFilterValues(filterOptions.map(option => ({
            valueKey: option.valueKey,
            value: "",  // Set initial value based on option.value or empty string
        })));
    }

    /**
     * GENERAL:     Debounce is used to control the rate at which a function or event hanlder is executed
     * COMPONENT:   Control rate at which data changes to avoid flickering of component when data is updated, deleted, ... in parent component
     */
    /*const debouncedSetInitialData = debounce((data) => {
        if (isUndefinedOrEmptyArray(originalData) && !isUndefinedOrEmptyArray(data) && originalDataRef.current !== data) {
            setOriginalData(data);
            originalDataRef.current = data;
            console.log("data", data);
        }
    }, 300)*/


    //useEffect for filtering entries
    useLayoutEffect(() => {
        if (originalData) {

            //Array to store filter condition functions; determines whether an item should be included in filtered results or not
            const filters: ((item: T) => boolean)[] = [];

            //dynamically create filter condition functions
            filterOptions.forEach((option, index) => {

                const filterKey = option.valueKey;
                const filterValue = filterValues[index] ? filterValues[index].value : (option.value ?? "");

                if (filterValue !== undefined && filterValue !== "") {
                    if (option.referenceList) {  //CASE: LIST OF REFERENCE VALUES GIVEN

                        //filter foreign list of elements (e.g organizations, persons, ...)

                        //store function in filter array for foreign elements
                        filters.push(item => {
                            const filteredRefValues = option.referenceList!.filter(refItem => {
                                if (option.referenceListValueKey) {
                                    if (option.referenceListValueKey.includes('.')) { //when referenceKey is nested key, e.g. refItem is staff, valueKey is person.completeName
                                        const refFilterKeyParts = option.referenceListValueKey.split('.');  //split key into multiple keys

                                        const refValueToCompare = refFilterKeyParts.reduce((obj, key) => { //access nested properties of item, stores most nested key in refValueToCompare
                                            if (obj && obj[key]) {
                                                return obj[key];    //return ref value if it exists
                                            }
                                            return undefined;
                                        }, refItem);
                                        if (refValueToCompare) {
                                            return refValueToCompare.toLowerCase().includes(filterValue.toLowerCase());
                                        }
                                    } else {
                                        const refValueToCompare = refItem[option.referenceListValueKey];
                                        return refValueToCompare.toLowerCase().includes(filterValue.toLowerCase());
                                    }
                                }
                                return false;
                            }).map(refItem => refItem.id);

                            const valueToCompare = item[filterKey];
                            return filteredRefValues.includes(valueToCompare);
                        });
                    } else { //CASE: REGULAR VALUE
                        // Add function in filter array for regular values
                        filters.push(item => {
                            if (filterKey.includes('.')) { //case for when accessed key is nested, e.g. item is billDTO, accessed value key is bill.customerName
                                const filterKeyParts = filterKey.split('.'); //split value key into multiple keys

                                const valueToCompare = filterKeyParts.reduce((obj, key) => {    //access nested properties of item
                                    if (obj && obj[key]) { //check if obj and property with name key exists
                                        return obj[key];    //return value if it exists
                                    }
                                    return undefined;
                                }, item)

                                if (valueToCompare) {
                                    return valueToCompare.toLowerCase().includes(filterValue.toLowerCase());
                                }
                            }
                            else { //standard case for when accessed key is not nested
                                const valueToCompare = item[filterKey];
                                if (valueToCompare) {
                                    return valueToCompare.toLowerCase().includes(filterValue.toLowerCase());
                                }
                            }
                        });
                    }
                }
            })
            const filteredData = originalData!.filter(item => filters.every(filter => filter(item)));
            if (!areArraysEqual(filteredData, dataToFilter)) {
                setDataToFilter(filteredData);
            }
        }
    }, [filterValues, originalData, dataToFilter, filterOptions]) //refilter when data changes to include possible newly added entries

    //handleClick for IconButton (FilterIcon)
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    }

    //handle Popover close
    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    }

    return (
        <div>
            <Tooltip title="Einträge Filtern" placement="left">
                <IconButton onClick={handleClick}><FilterAltIcon /></IconButton>
            </Tooltip>
            <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: anchorOriginVertical ?? 'bottom',
                    horizontal: anchorOriginHorizontal ?? 'center',
                }}
                transformOrigin={{
                    vertical: transformOriginVertical ?? 'top',
                    horizontal: transformOriginalHorizontal ?? 'right',
                }}
            >
                <div style={{ paddingTop: "1vh", paddingBottom: "1vh", paddingRight: "1vw", paddingLeft: "1vw" }}>
                    <div style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "1vh", }}>
                        <span className="bold-big" style={{ width: "40%", textAlign: "left" }}>Filtern nach</span>
                        <span className="bold-big" style={{ width: "50%", textAlign: "center" }}>Suchen nach</span>
                    </div>
                    {filterOptions.map((option, index) => (
                        <div key={"option" + option.title} style={{ display: "flex", flexDirection: "column", width: "100%" }}>

                            <div style={{ display: "flex", flexDirection: "row", width: "100%", marginBottom: "0.4vh", alignItems: "center" }}>
                                <span style={{ width: "40%", fontSize: "14px", textAlign: "left" }}>{option.title}</span>
                                <input
                                    style={{ width: "50%", border: "1.5px solid #e6e6e6", borderRadius: "8px", padding: "10px" }}
                                    ref={inputRef}
                                    type="text"
                                    placeholder={option.valueKey == null ? "Wählen Sie einen Filter aus" : "Suchen..."}
                                    value={(filterValues[index] ? filterValues[index].value : (option.value ?? ""))}
                                    onChange={e => updateFilterValue(index, e.target.value)}
                                    disabled={option.valueKey == null}
                                />
                                <div style={{ width: "10%" }}>
                                    <IconButton tabIndex={-1} onClick={() => updateFilterValue(index, "")}><ClearIcon /></IconButton>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </Popover>

        </div>
    )
}

export default Filter;