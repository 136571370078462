import dayjs, { Dayjs } from "dayjs";
import { IPause } from "../Contexts/TimeTrackingContext/TimeTrackingProvider";

export function getClockedInDurationAsString(start: Dayjs, end?: Dayjs): string {

    if (!end) {
        end = dayjs();
    };

    start = dayjs(start);
    end = dayjs(end);

    start = start.second(0);
    end = end.second(0);

    let hoursFloat = end.diff(start, 'hour', true)
    let minutesFloat = end.diff(start, 'minute', true)

    let roundedHour = Math.floor(hoursFloat);
    let roundedMinutes = Math.round(minutesFloat);

    let hoursToDisplay = roundedHour;
    let minutesToDisplay = roundedMinutes % 60;

    if (hoursToDisplay === 0) {
        return `${minutesToDisplay} min`;
    }

    return `${hoursToDisplay} h ${minutesToDisplay} min`;
}


export function getWorktimeDurationAsString(start: Dayjs, end?: Dayjs, pauses?: IPause[]): string {

    if (!end) return "Aktiv";

    let worktimeDurationWithoutPause = getWorktimeDurationAsFloat(start, end, pauses);

    return getHourAndMinuteStringFromFloat(worktimeDurationWithoutPause);

}

function getWorktimeDurationAsFloat(start: Dayjs, end: Dayjs, pauses?: IPause[]): number {
    start = dayjs(start);
    end = dayjs(end);

    let worktimeDuration = getTimeDifferenceInHoursFloat(start, end);

    let pauseDuration = getPauseDuration(pauses);
    let worktimeDurationWithoutPause = worktimeDuration - pauseDuration;

    return worktimeDurationWithoutPause;
}


export function getTimeDifferenceInMinutes(start: Dayjs, end: Dayjs): number {
    start = dayjs(start);
    end = dayjs(end);

    start = start.second(0);
    end = end.second(0);

    let timeDiffMinutes = end.diff(start, 'minute', true);
    timeDiffMinutes = Math.round(timeDiffMinutes);

    return timeDiffMinutes;
}

function getTimeDifferenceInHoursFloat(start: Dayjs, end: Dayjs): number {
    start = dayjs(start);
    end = dayjs(end);

    let timeDiffHoursFloat = end.diff(start, 'hours', true);
    timeDiffHoursFloat = parseFloat(timeDiffHoursFloat.toFixed(2));

    return timeDiffHoursFloat;
}

function getPauseDuration(pauses?: IPause[]): number {
    let totalPauseDuration = 0;

    if (!pauses || pauses.length === 0) return 0;

    pauses.forEach((pause: IPause) => {
        if (pause.end) {
            let pauseStart = dayjs(pause.start);
            let pauseEnd = dayjs(pause.end);

            let pauseDuration = pauseEnd.diff(pauseStart, "hour", true);
            pauseDuration = parseFloat(pauseDuration.toFixed(2));

            totalPauseDuration += pauseDuration;
        }
    })

    return totalPauseDuration;
}

function getHourAndMinuteStringFromFloat(duration: number): string {
    let hours = Math.floor(duration);

    if (hours === 0) {
        let minutes = duration % 1;
        minutes = Math.round(minutes * 60);
        return `${minutes} min`;
    }


    let minutes = duration % hours;
    minutes = Math.round(minutes * 60);



    return `${hours} h ${minutes} min`;
}

