import React from 'react'
import styles from './HamburgerButton.module.css'

function HamburgerButton({open, setOpen} : {open: boolean, setOpen: ((open: boolean) => void)}) {
    
  return (
    <div className={styles.hamburgerButton}  onClick={() => setOpen(!open)}>
              
        <div className={open ? styles.hamburgerInner.concat(" " + styles.isOpen) : styles.hamburgerInner} />
        <div className={open ? styles.hamburgerInner.concat(" " + styles.isOpen) : styles.hamburgerInner} />
              
    </div>
  )
}

export default HamburgerButton