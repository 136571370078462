import { Card } from '@mui/material'
import React from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import RevenueExpenditureCard from '../../Components/AccountingCards/RevenueExpenditureCard'
import TimeTrackingCard from '../../Components/TimeTrackingCard/TimeTrackingCard'
import Page from '../../Navigation/Page'
import KleinunternehmerCard from '../../Components/AccountingCards/KleinunternehmerCard'
import UnternehmerCard from '../../Components/AccountingCards/UnternehmerCard'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import RevenueExpenditureMonthlyCard from '../../Components/AccountingCards/RevenueExpenditureMonthlyCard'
import RevenueExpenditureMonthlyWithPredictionsCard from '../../Components/AccountingCards/RevenueExpenditureMonthlyWithPredictionsCard'
import OpenServicesCard from '../../Components/AccountingCards/OpenServicesCard'
import ExpectedTaxCard from '../../Components/AccountingCards/ExpectedTaxCard'
import AccountingWrapper from './AccountingWrapper'

const Accounting = () => {

  const { companyData } = useCompanyData();

  return (
    <>
      <AccountingWrapper>
        <CardColumn
          width='33%'
          height='100%'>
          <RevenueExpenditureCard height='50%' width='100%' />
          <RevenueExpenditureMonthlyCard height='50%' width='100%' />
        </CardColumn>
        <CardColumn
          width='33%'
          height='100%'>
          {companyData.kleinunternehmer &&
            <KleinunternehmerCard height='50%' width='100%' />
          }
          {!companyData.kleinunternehmer &&
            <UnternehmerCard height='50%' width='100%' />

          }
          <RevenueExpenditureMonthlyWithPredictionsCard height='50%' width='100%' />
        </CardColumn>
        <CardColumn
          width='33%'
          height='100%'>
          <ExpectedTaxCard height='50%' width='100%' />
          <OpenServicesCard height='50%' width='100%' />
        </CardColumn>
      </AccountingWrapper>
    </>
  )
}

export default Accounting