import React, { useEffect, useMemo, useState } from 'react'
import BillingpositionsCard from '../../Components/BillCards/BillingpositionsCard'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import Page from '../../Navigation/Page'

import PdfViewer from '../../Components/PDF/all-pages';
import { sampleBase64pdf } from '../../Components/PDF/samplebase64pdf'
import Card from '../../Components/Card/Card'
import { useNavigate, useParams } from 'react-router-dom'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { IBillDTO, IBillingPosition, IBillListDTO } from '../../Interfaces/Bill'
import { AxiosResponse } from 'axios'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import ReceiptDataCard from '../../Components/BillCards/ReceiptDataCard'
import ReceiptpositionsCard from '../../Components/BillCards/ReceiptpositionsCard';
import BillDataCard, { validateCreateBill } from '../../Components/BillCards/BillDataCard';
import BillPdfViewerCard from '../../Components/PdfViewerCard/BillPdfViewerCard';
import { IDocument } from '../../Contexts/DocumentContext/DocumentContext';
import { base64PdfToImage } from '../../Hooks/Utilities/Printer';
import CreateBill from './CreateBill';


const CreateReceipt = () => {


  return (<>
    <CreateBill type='Receipt' />
  </>)
}

export default CreateReceipt