import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IService } from '../../Interfaces/Service'
import styles from './BillsCard.module.css'

//Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Button, ClickAwayListener, Collapse, Grow, IconButton, Menu, MenuItem, MenuList, Modal, Paper, Popper } from '@mui/material';

import { useNavigate } from 'react-router-dom';
import MoreOptionsButton from '../Buttons/MoreOptionsButton/MoreOptionsButton';
import { IProductDTO, useProducts } from '../../Contexts/ProductContext/ProductProvider';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { IStaff, StaffDTO } from '../../Contexts/StaffContext/StaffProvider';
import { IDepartement, IDepartementDTO, defaultDepartment, useDepartements } from '../../Contexts/DepartementContext/DepartementProvider';
import { IPerson } from '../../Contexts/ContactsContext/ContactsProvider';
import { ExpandLessRounded, ExpandMoreRounded } from '@mui/icons-material';
import { DepartmentFormModes } from './CreateDepartementCard';

interface IDepartmentRow {
    department: IDepartementDTO,
    staff: IStaff[],
    persons: IPerson[]
    setClickedDepartment?: Dispatch<SetStateAction<IDepartement>>
    setFormMode?: Dispatch<SetStateAction<DepartmentFormModes>>
}

interface Document {
    id: number,
    title: string,
    description: string,
    fileEnding: string,
    fileId: string,
    base64: string,
    organizationId: number,
}

const DepartementRow = ({ department, staff, persons, setClickedDepartment, setFormMode }: IDepartmentRow) => {
    const dialogContext = useModals();
    const { deleteDepartement } = useDepartements();

    const onPressEdit = () => {
        dialogContext.changeVisibility!("editCreateDepartment", true, { props: { object: department, mode: "edit" } });
    }

    const onPressDelete = () => {
        deleteDepartement!(department);
    }

    const options = [
        /*{
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        }*/
        {
            text: "Bearbeiten",
            function: onPressEdit
        },
        {
            text: "Löschen",
            function: onPressDelete
        },
    ]

    const [expanded, setExpanded] = useState<boolean>(false);

    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLButtonElement>(null);

    // return focus to the button when we transitioned from !open -> open
    const prevOpen = React.useRef(open);
    React.useEffect(() => {
        if (prevOpen.current === true && open === false) {
            anchorRef.current!.focus();
        }

        prevOpen.current = open;
    }, [open]);

    const [departmentStaff, setDepartmentStaff] = useState<IStaff[]>([]);

    useEffect(() => {
        setDepartmentStaff(staff.filter(element => {
            if (department.userIds?.includes(element.id)) {
                return true;
            }
            return false;
        }))
    }, [staff, department])

    const handleExpand = () => {
        

        if(setFormMode && setClickedDepartment) {
            if(!expanded === true) {
                setClickedDepartment(department)
                setFormMode("editDep");
            }
            else {
                setClickedDepartment(defaultDepartment);
                setFormMode("createDep");
            }
        }
        setExpanded(!expanded);
    }

    return (
        <>
            {department &&
                <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: "white" }} >
                    <header className='flex centered-aligned stretched-justify'>
                        <div className='flex centered-aligned pointer' onClick={() => handleExpand()} style={{ gap: "6px" }}>
                            <div className={styles.accent} />
                            <IconButton
                                onClick={() => { setExpanded((old: boolean) => (!old)) }}
                                style={{ marginLeft: "-8px" }}
                            >
                                {!expanded ? <ExpandMoreRounded fontSize='small' /> : <ExpandLessRounded fontSize='small' />}
                            </IconButton>
                            <span className='bold-big'>{department.name}</span>
                        </div>
                        <MoreOptionsButton options={options}></MoreOptionsButton>
                    </header>
                    {/* TODO List of Employees in Departement */}
                    <Collapse in={expanded}>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"E-Mail: " + (department.eMail ? department.eMail : "Keine E-Mail Adresse hinterlegt")}</span>
                            </div>
                        </section>
                        <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                            <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{"Mitarbeiter: "}</span>
                            </div>
                        </section>
                        {departmentStaff.map(element => {
                            return (
                                <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                                    <div style={{ width: "50%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                        <span style={{ textOverflow: "ellipsis" }}>&nbsp;{element.person?.lastname ? (element.person?.firstname ? ((element.person?.title ? element.person?.title + " " : "") + element.person?.firstname + " " + element.person?.lastname) : (element.person?.lastname)) : element.username}</span>
                                    </div>
                                </section>
                            )
                        })}
                        {department.description && <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "15px" }}>
                            <div style={{ width: "100%", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>
                                <span style={{ textOverflow: "ellipsis" }}>{department.description}</span>
                            </div>
                        </section>}
                    </Collapse>
                </div>
            }
        </>
    )
}

export default DepartementRow;