import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IPerson, IPersonDTO, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreatePersonForm from '../../Forms/CreatePersonForm'

import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'

export const EditCreatePersonDialog = ({ open, onClose, object, mode }: any) => {
    const { changeVisibility } = useModals()

    const { addPerson, updatePerson, defaultPerson } = useContacts()

    const { enqueueSnackbar } = useSnackBar()

    const [personToSubmit, setPersonToSubmit] = useState<IPerson | IPersonDTO>(object ?? defaultPerson!)

    const [loading, setLoading] = useState(false)

    //enqueueSnackbar("CreateFromPerson: Kontakt hinzufügen gestartet", { variant: "info" })

    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editCreatePerson", false)
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (mode == "create") {
                await addPerson!(personToSubmit)
            } else {
                await updatePerson!(personToSubmit)
            }
            changeVisibility!("editCreatePerson", false)
            setPersonToSubmit(defaultPerson!);
        } catch (error) {
            enqueueSnackbar(mode == "create" ? "Fehler beim Erstellen." : "Fehler beim Bearbeiten", { variant: "error" })
        } finally {
            setLoading(false)

        }
    }

    useEffect(() => {
        setPersonToSubmit(object ?? defaultPerson!)
    }, [object])

    return (
        <Modal
            open={open}
            title={mode == "create" ? "Person erstellen" : "Person bearbeiten"}
            onClose={() => handleClose()}
        >
            <CreatePersonForm
                object={personToSubmit}
                setObject={setPersonToSubmit}
            />
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : (mode == "create" ? "Erstellen" : "Speichern")}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}
