import { Print } from '@mui/icons-material'
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import QRCode from 'react-qr-code'
import Card from '../../Components/Card/Card'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import FormRow from '../../Components/Forms/FormUtils/FormRow'
import { useBills } from '../../Contexts/BillContext/BillProvider'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { IProduct } from '../../Contexts/ProductContext/ProductProvider'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { useMobileView } from '../../Hooks/useMobileView'
import { IRegistrierkasse, IRegistrierkasseBelegDTO, IRegistrierkasseDatenerfassungsprotokoll, IRegistrierkassenzertifikat } from '../../Interfaces/Accounting'
import { IOrganization } from '../../Interfaces/Company'
import { ICompanyData } from '../../Interfaces/CompanyData'
import styles from './Kassenbon.module.css'

function KassaVerifizierung() {

    const mobile = useMobileView()

    const { defaultTask } = useTasks();

    const { registrierkasse, registrierkasseZertifikat, registrierkasseDatenerfassungsprotokoll } = useBills();


    const { companyData } = useCompanyData()
    const [identification, setIdentification] = useState<string>(companyData?.uid ?? companyData?.taxNumber!);
    const [identifikationType, setIdentificationType] = useState<0 | 2>(companyData?.uid ? 0 : 2)

    useEffect(() => {
        setIdentificationType(companyData?.uid ? 0 : 2);
    }, [identification])

    useEffect(() => {
        console.log("Zert", registrierkasseZertifikat)
    }, [registrierkasseZertifikat])

    if (!registrierkasseZertifikat) {
        return (
            <>
                <CardColumn
                    height='100%'
                    width='100%'
                >
                    <Card
                        fullHeightBody
                        style={{ height: "100%", width: "100%" }}
                        title="Registrierkasse einrichten"
                    >
                        <div
                            style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1em" }}
                        >
                            <div
                                style={{ width: "75%", height: "75%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em" }}
                            >
                                <div
                                    style={{ width: "50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1em" }}
                                >
                                    <div
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}
                                    >
                                        <div
                                            style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "0.5em" }}
                                        >
                                            <FormRow
                                                label='Steuernummer oder UID'
                                                onChange={() => { }}
                                                type='text'
                                                readonly
                                                value={identification}
                                            />

                                            {/*@ts-ignore*/}
                                            <stripe-buy-button
                                                buy-button-id="buy_btn_1N0M7rCJ5DwX1VoalgtEbSwi"
                                                publishable-key="pk_live_51MyZMcCJ5DwX1VoaUNKnlxO3beokaBU9J0IhhiNJwvtD03ngDLRLn9NL6tXTec6vexJHVPxaGTPq5Gib422vLblk00fD0B5t0L"
                                                client-reference-id={companyData?.id}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{ width: "50%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1em" }}
                                >
                                    <div
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}
                                    >
                                        <div
                                            style={{ width: "75%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "0.5em" }}
                                        >
                                            <p>
                                                Gem. RKSV benötigen Sie ein Zertifikat für die Signatur der erstellten Belege
                                            </p>
                                            <p>
                                                Dieses Zertifikat wird von der Firma A-Trust ausgestellt.
                                                Das Zertifikat kostet bei uns mit Gebühren 100,00 € und muss alle 5 Jahre erneurt werden.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </CardColumn>
            </>
        )
    } else {
        return (
            <>
                <CardColumn
                    height='100%'
                    width='66%'
                >
                    <Card

                        style={{ height: "100%", width: "100%" }}
                        title="Registrierkasse einrichten"
                    >
                        <div
                            style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "start", alignItems: "start", gap: "1em" }}
                        >
                            <div
                                style={{ width: "90%", height: "90%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", gap: "1em" }}
                            >
                                <div
                                    style={{ width: "85%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1em" }}
                                >
                                    <div>
                                        <p>Um Ihre Registrierkasse auf FinanzOnline zu registrieren, müssen Sie folgende Schritte durchführen:</p>
                                        <ol>
                                            <li>Zunächst müssen Sie ein FinanzOnline-Konto erstellen, wenn Sie noch keines haben. Dazu gehen Sie auf die Webseite <a href="https://finanzonline.bmf.gv.at">https://finanzonline.bmf.gv.at</a> und klicken auf "Registrieren".</li>
                                            <li>Nachdem Sie Ihr FinanzOnline-Konto erstellt haben, müssen Sie sich einloggen und den Menüpunkt "Registrierkasse" auswählen.</li>
                                            <li>Dort finden Sie eine Option, um Ihre Registrierkasse zu registrieren. Klicken Sie auf diese Option und folgen Sie den Anweisungen auf dem Bildschirm.</li>
                                            <li>Sie müssen Ihre Registrierkassen-ID und den Hersteller der Registrierkasse angeben. Außerdem müssen Sie den Startzeitpunkt der Verwendung der Registrierkasse und den Finanzamtscode, bei dem Sie registriert sind, angeben.</li>
                                            <li>Nachdem Sie alle notwendigen Informationen eingegeben haben, müssen Sie die Registrierung abschließen, indem Sie auf "Senden" klicken.</li>
                                            <li>Sobald Ihre Registrierkasse auf FinanzOnline registriert ist, erhalten Sie eine Bestätigung per E-Mail.</li>
                                        </ol>
                                        <p>Es ist wichtig zu beachten, dass Sie Ihre Registrierkasse innerhalb von 7 Tagen nach Inbetriebnahme registrieren müssen, um gesetzliche Anforderungen zu erfüllen.</p>

                                    </div>
                                    <div
                                        style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center", width: "100%" }}
                                    >
                                        <div
                                            style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "0.5em" }}
                                        >
                                            <FormRow
                                                label='KassenID'
                                                onChange={() => { }}
                                                type='text'
                                                readonly
                                                value={registrierkasse?.kassenId}
                                            />
                                            <FormRow
                                                label='Zertifikatsseriennummer Hexadezimal'
                                                onChange={() => { }}
                                                type='text'
                                                readonly
                                                value={registrierkasseZertifikat?.seriennummerHex}
                                            />
                                            <FormRow
                                                label='Zertifikatsseriennummer Dezimal'
                                                onChange={() => { }}
                                                type='text'
                                                readonly
                                                value={registrierkasseZertifikat?.seriennummerDec}
                                            />
                                            <FormRow
                                                label='AES-Key (Verschlüsselung)'
                                                onChange={() => { }}
                                                type='text'
                                                readonly
                                                value={registrierkasse?.key}
                                            />

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </CardColumn>
                <CardColumn
                    height='100%'
                    width='33%'
                >
                    <Kassenbon companyData={companyData} kasse={registrierkasse} zertifikat={registrierkasseZertifikat!} beleg={registrierkasseDatenerfassungsprotokoll ? registrierkasseDatenerfassungsprotokoll![0] : { belegTyp: "Startbeleg", belegNr: 1 }} disabled={(registrierkasseDatenerfassungsprotokoll?.length ?? 0) > 0} />
                </CardColumn>
            </>
        )
    }
}


interface KassenbonProps {
    companyData?: ICompanyData
    kasse?: IRegistrierkasse
    zertifikat: IRegistrierkassenzertifikat
    beleg?: IRegistrierkasseDatenerfassungsprotokoll
    products?: Array<IProduct>
    setProducts?: Dispatch<SetStateAction<Array<IProduct>>>
    disabled?: boolean
}
export const Kassenbon = ({ companyData, kasse, zertifikat, beleg: inputbeleg, products, setProducts, disabled = true }: KassenbonProps) => {

    const [netto, setNetto] = useState(0)
    const [brutto, setBrutto] = useState(0)
    const [ust, setUst] = useState<any>([
        { type: "normal", value: 0, netto: 0, tax: 20 },
        { type: "ermeassigt1", value: 0, netto: 0, tax: 10 },
        { type: "ermaessigt2", value: 0, netto: 0, tax: 13 },
        { type: "besonders", value: 0, netto: 0, tax: 25 },
        { type: "null", value: 0, netto: 0, tax: 0 }
    ])
    const [discount, setDiscount] = useState(0);

    const [beleg, setBeleg] = useState<IRegistrierkasseBelegDTO | undefined>(inputbeleg)

    const { companies } = useContacts();
    const [company, setCompany] = useState(companies.find((temp: IOrganization) => temp.id === companyData?.id))

    const printRef = useRef<any>();

    const { createRegistrierkasseBeleg, } = useBills();

    const handlePrint = async () => {
        /*const component = printRef.current;
        const printContents = component.innerHTML;
        const originalContents = document.body.innerHTML;

        const printContainer = document.createElement('div');
        printContainer.innerHTML = printContents;
        document.body.appendChild(printContainer);

        window.print();

        document.body.removeChild(printContainer);
        document.body.innerHTML = originalContents;*/

        if (createRegistrierkasseBeleg) {
            createRegistrierkasseBeleg!({ ...beleg!, products: products ?? [] })
                .then((result) => {
                    setBeleg(result);
                    console.log(result)

                    setTimeout(() => {
                        //wprintBeleg(result);
                    }, 1000)
                });
        }
    }


    const printBeleg = (resultBeleg: IRegistrierkasseBelegDTO) => {
        const component = printRef.current;
        const printContents = component.innerHTML;
        const originalContents = document.body.innerHTML;

        console.log("qrCodeBase64", resultBeleg.qrCodeBase64)

        const printWindow = window.open('', '_blank');

        printWindow!.document.write(`
      <html>
        <head>
          <style>
@media print {
	.no-print, .no-print * {
		display: none;
	}
	.invoice-panel {
		border: 0 solid #fff;
		box-sizing: border-box;
	}
}

@media screen {
	.invoice-panel {
		border: 1px solid #aaa;
	}
}
html, body {
	margin: 0;
	padding: 0;
	border: solid 0 #fff;
}
body, p, div, table {
	font-family:sans-serif;
	font-size: 3.1mm; 
}
.invoice-panel {
	width: 70mm;
	padding: 15px;
	margin: 0 auto;
	text-align: center;
	box-sizing: border-box;
	background-color: #fff;
}
.invoice-panel h1 {
	font-size: 8mm;
	margin: 1mm 0 2.5mm 0;
	padding: 0 0 2.5mm 0;
	text-align: center;
	width: 100%;
	border-bottom: dashed 0.4mm #000;
}
.cell0, .cell1, .cell2, .cell3, .cell4 {
	font-size: 2.8mm;	
}
.cell0, cell4 {
	text-align: center;
}
.cell1 {
	text-align: left;
}
.cell2, .cell3 {
	text-align: right;
}
.cell1 {
	line-height: 3.1mm;
	-moz-hyphens: auto;
	-o-hyphens: auto;
	-webkit-hyphens: auto;
	-ms-hyphens: auto;
	hyphens: auto;
}
.dotted-line {
	margin-bottom: 2.5mm;
	padding-bottom: 2.5mm;
	border-bottom: dashed 0.4mm #000;
}
.bold {
	font-weight: bold;
}
.testMode {
	font-size: 5mm;
	border-top: 0.4mm solid #000;
	border-bottom: 0.4mm solid #000;
	margin: 1.3mm;
	padding: 1.3mm;
}
.logo {
	margin: 0 auto;
	padding-bottom:5mm;
	padding-top:5mm;
}
table {
	display: table;
	border-collapse: separate;
}
table td {
	display: table-cell;
}
.break, table td {
	word-wrap: break-word;
}
.table-fixed {
	width: 100%;
	table-layout: fixed;
}
img {
	-webkit-filter: grayscale(1);
	-webkit-filter: grayscale(100%);
	filter: gray;
	filter: grayscale(100%);
}
.space-bottom {
	margin-bottom:2.5mm;
}
.space-top {
	margin-top:2.5mm;
}
</style>
        </head>
        <body>
          <div>
          <div class="invoice-panel">
	<div class="break">
		<div class="logo"><img src=${`data:image/jpeg;base64,${companyData?.logoSquareBase64}`} width="80%"></div>
	</div>
	<div class="dotted-line break">
		<b>${companyData?.name}</b><br>
		${`${company?.address?.street} ${company?.address?.housenumber}`}<br>
		${`${company?.address?.postalcode} ${company?.address?.city}`}<br>
		<div class="space-top">
		${company?.uid ?? company?.taxNumber}</div>
		${companyData?.telephone ? `Tel. ${companyData?.telephone}` : ``} 
        ${companyData?.eMail ? `Email ${companyData?.eMail}` : ``}
	</div>
	<div class="dotted-line">
		<table cellpadding="2" cellspacing="0" class="table-fixed">
			<tr>
				<td align="left">Kassa: </td>
				<td align="right">${kasse?.kassenId}</td>
			</tr>
			<tr>
				<td align="left">Beleg: </td>
				<td align="right">${resultBeleg?.belegNr}</td>
			</tr>
			<tr>
				<td align="left">Beleg-Datum:</td>
				<td align="right">${resultBeleg?.timestamp?.toLocaleString("de-DE", { year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit", second: "2-digit" })}</td>
			</tr>
			<!-- /*<tr>
				<td align="left">Leistungszeitraum:</td>
				<td align="right">{{Lieferdatum}}</td>
			</tr>
			<tr>
				<td align="left">Auftragsdatum:</td>
				<td align="right">{{Auftragsdatum}}</td>
			</tr>
			<tr>
				<td align="left">Kundennummer:</td>
				<td align="right">{{Kundennummer}}</td>
</tr>*/ -->
		</table>
	</div>
	<div class="dotted-line" style="font-weight:bold;">${resultBeleg?.belegTyp ?? "Standardbeleg"}</div>
	<!-- /*<div class="dotted-line">{{Text oben}}</div>*/ -->
	<div class="dotted-line">
    <table class="table-fixed" cellspacing="0" cellpadding="0">
    <tr style="text-align: right;">
      <td></td>
      <td width="20%"><b>EZ</b></td>
      <td width="20%"><b>GE</b></td>
      <td width="20%"><b>USt</b></td>
    </tr>
    ${products?.map((product: IProduct) => {
            return (`<tr style="text-align:left;">
        <td colspan="4" style="padding-top:10px;">${product.count} x ${product.title}</td>
      </tr>
      <tr style="text-align:right;">
        <td></td>
        <td>${product.salesPrice}</td>
        <td>${product.salesPrice! * product.count!}</td>
        <td>${(((product.ust ?? 0) / 100) * (product.salesPrice! * product.count!)).toFixed(2)}</td>
      </tr>`)
        })}
  </table>
	</div><!--SUMMEN-->
	<div class="dotted-line">
		<table style="width:100%; font-size: 2.8mm;	" cellspacing="4" cellpadding="0">
			<tr>
				<td align="left" style="width:40mm;">Zwischensumme Netto:</td>
				<td style="width:8mm;" >€</td>
				<td align="right">${netto?.toFixed(2)}</td>
			</tr>
			<!--
            <tr>
				<td align="left">{{Rabattbezeichnung}}</td>
				<td>€</td>
				<td align="right">{{Rabatt in Euro}}</td>
			</tr>
			<tr>
				<td align="left">Netto:</td>
				<td>€</td>
				<td align="right">{{Endsumme Netto}}</td>
			</tr> 
            -->
			${ust.map((element: any) => {
            if (element.value !== 0) {
                return (`<tr>
                            <td align="left">+ ${element.tax}% USt.:</td>
                            <td>€</td>
                            <td align="right">${element.value?.toFixed(2)}</td>
                        </tr>`)
            } else {
                return ``
            }
        })}
			<tr style="font-size: 3.1mm; font-weight:bold;">
				<td align="left">Brutto:</td>
				<td>€</td>
				<td align="right">${brutto}</td>
			</tr>
		</table>
	</div>
	
	<div class="dotted-line">
		<!--signature-->${companyData?.name}<br>
		
		<span style="font-size: 4.5mm;">Danke für Ihren Einkauf!</span> 
	</div>
	<!-- <div class="dotted-line">{{Text unten}}</div> -->
	<div class="dotted-line">
    <div class="break">
        <div class="logo">
        <img alt="qrcode" src=${`data:image/jpeg;base64,${resultBeleg.qrCodeBase64}`} width="80%">
        </div>
        </div>
	</div>
</div>
          </div>
          <script>
          window.addEventListener('load', function() {
            setTimeout(function() {
              window.print();
              window.close();
            }, 1000); // 1000 milliseconds = 1 second
          });
          </script>
        </body>
      </html>
    `);
        printWindow!.document.close();

        if (setProducts) {
            setProducts([]);
        }
    }


    useEffect(() => {
        let tempNetto = 0;
        let tempNormalTax = 0;
        let tempErmaessigt1Tax = 0;
        let tempErmaessigt2Tax = 0;
        let tempBesondersTax = 0;
        let tempNullTax = 0;
        let tempNormalNetto = 0;
        let tempErmaessigt1Netto = 0;
        let tempErmaessigt2Netto = 0;
        let tempBesondersNetto = 0;
        let tempNullNetto = 0;
        let tempBrutto = 0;

        products?.forEach((element: IProduct) => {
            let tempTempNetto = parseFloat(((element.salesPrice! * element.count!)).toFixed(2))
            tempNetto += tempTempNetto;
            tempBrutto += parseFloat(((element.salesPrice! * element.count!)).toFixed(2));

            if ((element.ust ?? 0) === 20) {
                tempNormalTax += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
                tempNormalNetto += tempTempNetto;
                tempBrutto += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
            } else if ((element.ust ?? 0) === 10) {
                tempErmaessigt1Tax += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
                tempErmaessigt1Netto += tempTempNetto;
                tempBrutto += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
            }
            else if ((element.ust ?? 0) === 13) {
                tempErmaessigt2Tax += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
                tempErmaessigt2Netto += tempTempNetto;
                tempBrutto += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
            }
            else if ((element.ust ?? 0) === 25) {
                tempBesondersTax += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
                tempBesondersNetto += tempTempNetto;
                tempBrutto += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
            }
            else if ((element.ust ?? 0) === 0) {
                tempNullTax += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
                tempNullNetto += tempTempNetto;
                tempBrutto += (parseFloat(((element.salesPrice! * element.count!)).toFixed(2)) * (element?.ust! / 100))
            }
        });

        setNetto(tempNetto)
        setBrutto(tempBrutto)
        setUst([
            { type: "normal", value: tempNormalTax, netto: tempNormalNetto, tax: 20 },
            { type: "ermeassigt1", value: tempErmaessigt1Tax, netto: tempErmaessigt1Netto, tax: 10 },
            { type: "ermaessigt2", value: tempErmaessigt2Tax, netto: tempErmaessigt2Netto, tax: 13 },
            { type: "besonders", value: tempBesondersTax, netto: tempBesondersNetto, tax: 25 },
            { type: "null", value: tempNullTax, netto: tempNullNetto, tax: 0 }
        ])

        setBeleg({
            kassenId: kasse?.kassenId,
            timestamp: new Date(),
            ustNormal: tempNormalNetto,
            ustErmaessigt1: tempErmaessigt1Netto,
            ustErmaessigt2: tempErmaessigt2Netto,
            ustBesonders: tempBesondersNetto,
            ustNull: tempNullNetto
        })
    }, [products])



    return (
        <>
            <Card
                style={{ height: "100%", width: "100%" }}
                title={beleg?.belegTyp === "Startbeleg" ? "Startbeleg drucken" : "Bon-Vorschau"}
                cta={!disabled}
                ctaTitle="Drucken"
                ctaFunction={() => { handlePrint() }}
                buttonIcon={<Print fontSize='small' />}
            >
                <div
                    style={{ width: "100%", height: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "1em" }}
                    ref={printRef}
                >
                    <div className={styles.invoicepanel}>
                        <div className={styles.break}>
                            <div className={styles.logo}><img src={`data:image/jpeg;base64,${companyData?.logoSquareBase64}`} width="80%" /></div>
                        </div>
                        <div className={styles.dottedline.concat(" ").concat(styles.break)}>
                            <b>{companyData?.name}</b><br />
                            {company?.address?.street + " " + company?.address?.housenumber}<br />
                            {company?.address?.postalcode + " " + company?.address?.city}<br />
                            <div className={styles.spacetop}>
                                {companyData?.uid ?? companyData?.taxNumber}
                            </div>
                            {companyData?.telephone && <><>{"Tel. " + companyData?.telephone}</><br /></>}
                            {companyData?.eMail && <><>{"Email " + companyData?.eMail}</></>}
                        </div>
                        <div className={styles.dottedline}>
                            <table cellPadding="2" cellSpacing="0" className={styles.tablefixed}>
                                <tr>
                                    <td align="left">Kassa: </td>
                                    <td align="right">{kasse?.kassenId}</td>
                                </tr>
                                <tr>
                                    <td align="left">Beleg: </td>
                                    <td align="right">{beleg?.belegNr}</td>
                                </tr>
                                <tr>
                                    <td align="left">Beleg-Datum:</td>
                                    <td align="right">{beleg?.timestamp ? new Date(beleg?.timestamp ?? "").toLocaleString() : new Date().toLocaleString()}</td>
                                </tr>
                                {/*<tr>
                            <td align="left">Leistungszeitraum:</td>
                            <td align="right">{{ Lieferdatum }}</td>
                        </tr>
                        <tr>
                            <td align="left">Auftragsdatum:</td>
                            <td align="right">{{ Auftragsdatum }}</td>
    </tr>*/}
                                {/*<tr>
                            <td align="left">Kundennummer:</td>
                            <td align="right">{{ Kundennummer }}</td>
</tr>*/}
                            </table>
                        </div>
                        <div className={styles.dottedline} style={{ fontWeight: "bold" }}>{beleg?.belegTyp} {/*(Nullbeleg / Startbeleg / Stornobeleg / Beleg)*/}</div>
                        {/*<div className={styles.dottedline}>{{ Text oben }}</div>*/}

                        <div className={styles.dottedline}>
                            <table className={styles.tablefixed} cellSpacing="0" cellPadding="0">
                                <tr style={{ textAlign: "right" }}>
                                    <td></td>
                                    <td width="20%"><b>EZ</b></td>
                                    <td width="20%"><b>GE</b></td>
                                    <td width="20%"><b>USt</b></td>
                                </tr>
                                {products?.map((product: IProduct) => {
                                    return (
                                        <>
                                            <tr style={{ textAlign: "left" }}>
                                                <td colSpan={4} style={{ paddingTop: "10px" }}>{product.count + " x " + product.title}</td>
                                            </tr>
                                            <tr style={{ textAlign: "right" }}>
                                                <td></td>
                                                <td>{product.salesPrice}</td>
                                                <td>{product.salesPrice! * product.count!}</td>
                                                <td>{(((product.ust ?? 0) / 100) * (product.salesPrice! * product.count!)).toFixed(2)}</td>
                                            </tr>
                                        </>
                                    )
                                })}

                            </table>
                        </div>
                        <div className={styles.dottedline}>
                            <table style={{ width: "100%", fontSize: "2.8mm" }} cellSpacing="4" cellPadding="0">
                                <tr>
                                    <td align="left" style={{ width: "40mm" }}>Zwischensumme Netto:</td>
                                    <td style={{ width: "8mm" }} >€</td>
                                    <td align="right">{netto.toFixed(2)}</td>
                                </tr>
                                {/*<tr>
                            <td align="left">{{ Rabattbezeichnung }}</td>
                            <td>€</td>
                            <td align="right">{{ Rabatt in Euro }}</td>
                    </tr>*/}
                                {/*<tr>
                            <td align="left">Netto:</td>
                            <td>€</td>
                            <td align="right">{{ Endsumme Netto }}</td>
                </tr>*/}
                                {ust.map((element: any) => {
                                    if (element.value !== 0) {
                                        return (
                                            <tr>
                                                <td align="left">+ {element.tax}% USt.:</td>
                                                <td>€</td>
                                                <td align="right">{element.value?.toFixed(2)}</td>
                                            </tr>
                                        )

                                    }
                                })}
                                <tr style={{ fontSize: "3.1mm", fontWeight: "bold" }}>
                                    <td align="left">Brutto:</td>
                                    <td>€</td>
                                    <td align="right">{brutto?.toFixed(2)}</td>
                                </tr>
                            </table>
                        </div>

                        <div className={styles.dottedline}>
                            {companyData?.name}
                            <br />
                            <span style={{ fontSize: "4.5mm" }}>Danke für Ihren Einkauf!</span>
                        </div>
                        {/*<div className={styles.dottedline}>{{ Text unten }}</div>;*/}
                        <div className={styles.dottedline} style={{}}>
                            <div className="break" style={{ paddingTop: "1mm", paddingBottom: "1mm" }}>
                                {beleg?.qrCodeBase64 ?
                                    <img width="70%" src={`data:image/jpeg;base64,${beleg?.qrCodeBase64}`} />
                                    :
                                    <QRCode style={{ width: "50%", height: "50%" }} value={beleg?.qrCodePayload ?? "not generated"} />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </Card >
        </>
    )
}


export default KassaVerifizierung;