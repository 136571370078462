// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Company_container__vh8Ko {\n    padding: 0px 0;\n    padding-left: 2px;\n    margin-inline: 0px;\n}\n\n.Company_accent__VkIiR {\n    height: 14px;\n    width: 14px;\n    border-radius: 100px;\n    background-color: green;\n}", "",{"version":3,"sources":["webpack://./src/Components/Company/Company.module.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,oBAAoB;IACpB,uBAAuB;AAC3B","sourcesContent":[".container {\n    padding: 0px 0;\n    padding-left: 2px;\n    margin-inline: 0px;\n}\n\n.accent {\n    height: 14px;\n    width: 14px;\n    border-radius: 100px;\n    background-color: green;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Company_container__vh8Ko",
	"accent": "Company_accent__VkIiR"
};
export default ___CSS_LOADER_EXPORT___;
