import { CircularProgress } from '@mui/material'
import React, { Dispatch, useEffect, useState } from 'react'
import { defaultCompany, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { defaultProduct, IProductDTO, useProducts } from '../../../Contexts/ProductContext/ProductProvider'
import { IOrganization, IOrganizationDTO } from '../../../Interfaces/Company'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateCompanyForm from '../../Forms/CreateCompanyForm'
import CreateProductForm from '../../Forms/CreateProductForm'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'
import EditProductDataForm from '../../Forms/EditProductDataForm'
import { validateProductForm } from '../../ProductCards/ProductDataCard'

export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

const EditCreateProductDialog = ({ open, onClose, object, mode }: dialogWithProps<IProductDTO>) => {

    const { updateProduct, addProduct } = useProducts();

    const { changeVisibility } = useModals()

    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editCreateProduct", false)
    }

    useEffect(() => {
        setProductToSubmit(object ?? defaultProduct)
    }, [object])

    const [loading, setLoading] = useState(false)

    const [productToSubmit, setProductToSubmit] = useState<IProductDTO>(mode == "create" ? { ...defaultProduct } : { ...object! })

    const handleSubmit = async () => {
        try {
            let valid = validateProductForm(productToSubmit, setError);
            if (valid) {
                setLoading(true)
                if (mode == "create") {
                    await addProduct!(productToSubmit)
                } else {
                    await updateProduct!(productToSubmit)
                }
                changeVisibility!("editCreateProduct", false)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const [error, setError] = useState<any>({})

    return (
        <Modal
            open={open}
            title={mode == "create" ? "Produkt erstellen" : "Produkt bearbeiten"}
            onClose={() => handleClose()}
        >
            <EditProductDataForm
                object={productToSubmit}
                setObject={setProductToSubmit}
                errorVariables={error}
            />
            <div style={{ height: "20px" }} />
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}

export default EditCreateProductDialog