import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IPause, IWorkTime, useTimeTracking } from '../../../Contexts/TimeTrackingContext/TimeTrackingProvider'
import { useUser } from '../../../Contexts/UserContext/UserContext'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import PostScriptTimeForm from '../../Forms/PostScriptTimeForm'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import dayjs, { Dayjs } from 'dayjs'



function PostscriptWorkPauseDialog({ open, onClose, object, mode }: dialogWithProps<any>) {
  const { changeVisibility } = useModals()
  const { enqueueSnackbar } = useSnackBar()
  const { user } = useUser()
  const { postscriptPause, postscriptWork, editWorktime, editPause } = useTimeTracking()

  //Zum Zurücksetzen Zeit beim Öffnen/Schließen des Modals
  const defaultTime = {
    start: dayjs(),
    end: dayjs()
  }

  const [editObject, setEditObject] = useState<IWorkTime | IPause | undefined>(object);

  const [title, setTitle] = useState<string>("");
  const [ctaTitle, setCtaTitle] = useState<string>("");

  useEffect(() => {
    if (mode === "work") {
      setTitle("Arbeitszeit nachtragen");
      setCtaTitle("Nachtragen");
    } else if (mode === "editwork") {
      setTitle("Arbeitszeit bearbeiten");
      setCtaTitle("Speichern");
    } else if (mode === "editpause") {
      setTitle("Pause bearbeiten");
      setCtaTitle("Speichern");
    } else {
      setTitle("Pause nachtragen");
      setCtaTitle("Nachtragen");
    }
    if (object) {
      setEditObject(object)
    } else {
      setEditObject(undefined);
    }
  }, [mode])

  const [time, setTime] = useState<{ start: Dayjs, end: Dayjs }>({
    ...defaultTime
  })

  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      setLoading(true)
      if (mode == "work") {
        await postscriptWork!({
          userId: user?.userId!,
          organizationId: user?.organizationId!,
          start: time.start,
          end: time.end
        })
      } else if (mode === "editwork") {
        await editWorktime!({
          id: editObject?.id ?? 0,
          userId: user?.userId!,
          organizationId: user?.organizationId!,
          start: editObject?.start,
          end: editObject?.end
        });
      } else if (mode === "editpause") {
        //TODO
        await editPause!({
          id: editObject?.id ?? 0,
          userId: user?.userId!,
          organizationId: user?.organizationId!,
          start: editObject?.start ?? dayjs(),
          end: editObject?.end
        })
      } else {
        await postscriptPause!({
          userId: user?.userId!,
          organizationId: user?.organizationId!,
          start: time.start,
          end: time.end,
          workTimeId: 0
        })
      }

      handleClose()
    } catch (error) {
      enqueueSnackbar(`Fehler beim Nachtragen.`, { variant: "error" })
      console.error(error)
    } finally {
      setLoading(false)
    }

  }

  const handleClose = async () => {
    setLoading(false)
    if (onClose) {
      onClose()
    }
    setTime(defaultTime)
    changeVisibility!("postscriptWorkPause", false)
    if (mode === "editwork" || mode === "editpause") {
      changeVisibility!("worktimeOverview", true)
    }
  }

  return (
    <Modal
      open={open}
      title={title}
      onClose={handleClose}
    >
      <PostScriptTimeForm
        object={editObject ?? time}
        /* @ts-ignore */
        setObject={editObject ? setEditObject : setTime}
      />
      <CTAButton
        style={{
          marginTop: "20px"
        }}
        title={loading ? <CircularProgress size={18} color='inherit' /> : ctaTitle}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
      />
    </Modal>
  )
}

export default PostscriptWorkPauseDialog