import { AddRounded } from '@mui/icons-material'
import React, { Dispatch, SetStateAction, useState } from 'react'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { card } from '../../Interfaces/Card'
import { ITaskDTO } from '../../Interfaces/Task'
import Card from '../Card/Card'
import CreateTaskForm from '../Forms/CreateTaskForm'
import { useSnackbar } from 'notistack';
import { useMobileView } from '../../Hooks/useMobileView'
import { taskFormModes } from '../../Pages/Aufgaben/Aufgaben'
import { CircularProgress } from '@mui/material'

interface ICreateTaskCard extends card {
  clickedTask?: ITaskDTO
  setClickedTask?: Dispatch<SetStateAction<ITaskDTO>>
  formMode?: taskFormModes
  setFormMode?: Dispatch<SetStateAction<taskFormModes>>
}

const CreateTaskCard = ({ height, width, order, clickedTask, setClickedTask, formMode, setFormMode }: ICreateTaskCard) => {

  const { addTask, updateTask, defaultTask } = useTasks()

  const { enqueueSnackbar } = useSnackbar()

  const [taskToSubmit, setTaskToSubmit] = useState<ITaskDTO>({
    ...defaultTask!
  })

  const [loading, setLoading] = useState(false);

  const handleAddTask = async () => {
    try {
      await addTask!(taskToSubmit)
      enqueueSnackbar("Erfolgreich erstellt", { variant: "success" })
    } catch (error) {

    }
  }

  const handleSubmit = async () => {
    try {
      setLoading(true);
      if (formMode === "createTask") {
        if (validateInput(taskToSubmit)) {
          await addTask!(taskToSubmit);
          setTaskToSubmit(defaultTask!);
        }
      }
      else if (formMode === "editTask") {
        if (validateInput(clickedTask!)) {
          await updateTask!(clickedTask!);
          setClickedTask!(defaultTask!)
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  //form error handling variables
  const [employeeError, setEmployeeError] = useState(false);
  const [customerError, setCustomerError] = useState(false);
  const [titleError, setTitleError] = useState(false);

  const mobile = useMobileView()

  function validateInput(task: ITaskDTO) {
    //check if an employee was asigned to task
    if (task?.userIds?.length === 0) {
      setEmployeeError(true);
    } else {
      setEmployeeError(false);
    }

    //check if task is assigned to a customer
    if (!task.customerOrganizationId) {
      setCustomerError(true);
    } else {
      setCustomerError(false);
    }

    if (task?.title?.length === 0) {
      setTitleError(true);
    } else {
      setTitleError(false);
    }

    if (task?.userIds?.length === 0 || !task.customerOrganizationId || task?.title?.length === 0) {
      return false;
    }
    return true;
  }

  return (
    <>
      {mobile !== true &&
        <Card
          title={formMode === "editTask" ? "Aufgabe bearbeiten" : "Aufgabe erstellen"}
          cta
          ctaFunction={() => handleSubmit()}
          ctaTitle={loading ? <CircularProgress size={14} /> : formMode === "editTask" ? "Speichern" : "Erstellen"}
          buttonIcon={<AddRounded fontSize='small' />}
          style={{ width, height, order }}
        >
          {formMode === "createTask"
            ? (
              <CreateTaskForm
                object={taskToSubmit}
                setObject={setTaskToSubmit}
                errorVariables={{ employeeError, customerError, titleError }}
              />
            ) : formMode === "editTask" ? (
              <CreateTaskForm
                object={clickedTask!}
                setObject={setClickedTask!}
                mode={formMode}
                setFormMode={setFormMode}
                errorVariables={{ employeeError, customerError, titleError }}
              />
            )
              : <></>}

        </Card>}

      {mobile === true &&
        <Card
          title='Aufgabe erstellen'
          defaultExpanded
          cta
          ctaFunction={() => handleAddTask()}
          ctaTitle='Erstellen'
          buttonIcon={<AddRounded fontSize='small' />}
          style={{ width, height, order }}
        >
          <CreateTaskForm
            object={taskToSubmit}
            setObject={setTaskToSubmit}
            errorVariables={{ employeeError, customerError, titleError }}
          />
        </Card>}
    </>


  )
}

export default CreateTaskCard