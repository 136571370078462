import React, { useEffect, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import ProductCard from '../../Components/ProductCards/ProductCard'
import ProductDataCard from '../../Components/ProductCards/ProductDataCard'
import Page from '../../Navigation/Page'
import { IProductDTO, defaultProduct } from '../../Contexts/ProductContext/ProductProvider'

export type productFormModes = "createProduct" | "editProduct";

function Produkte() {

    const [clickedProduct, setClickedProduct] = useState<IProductDTO>(defaultProduct)
    console.log(parseFloat("undefined"));
    const [formMode, setFormMode] = useState<productFormModes>("createProduct");

    //DEBUG
    useEffect(() => {
        console.log(clickedProduct);
        console.log(formMode);
    }, [clickedProduct, formMode])

    return (
        <>
            <CardColumn
                height='100%'
                width='50%'>
                <ProductCard
                    height='100%'
                    width='100%'
                    setClickedProduct={setClickedProduct}
                    setFormMode={setFormMode}
                />
            </CardColumn>
            <CardColumn
                height='100%'
                width='50%'>
                <ProductDataCard
                    height='100%'
                    width='100%'
                    clickedProduct={clickedProduct}
                    setClickedProduct={setClickedProduct}
                    formMode={formMode}
                    setFormMode={setFormMode}
                />
            </CardColumn>
        </>
    )
}

export default Produkte