import { Tooltip } from '@mui/material'
import React, { ReactNode } from 'react'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import styles from './Tag.module.css'

const Tag = ({icon, title, color, backgroundColor, tooltipTitle} : {icon?: ReactNode, title: string, color: string, backgroundColor: string, tooltipTitle?: string}) => {
  return (
    <Tooltip
    title={tooltipTitle ?? title}
    arrow
    placement="bottom"
    >
    <div className={getClassNames([styles.container, "flex", "centered-aligned", "gap5"])} style={{color, backgroundColor, cursor: "default"}}>
        {icon}
        <span style={{width: "calc(70%)", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}>{title}</span>
    </div>
    </Tooltip>
  )
}

export default Tag