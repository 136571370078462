import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { IHoliday, defaultHoliday, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import FormRow from "../FormUtils/FormRow"
import { IEmployee, useEmployees } from "../../../Contexts/EmployeeContext/EmployeeProvider";
import CTAButton from "../../Buttons/CTAButton/CTAButton";
import { CircularProgress } from "@mui/material";

interface ICreateHolidayRequestFrom {
    holiday: IHoliday
    setHoliday: Dispatch<SetStateAction<IHoliday>>
    submitFunction?: () => void
}

function CreateHolidayRequestForm({ holiday, setHoliday, submitFunction }: ICreateHolidayRequestFrom) {

    const { employees } = useEmployees();

    const [loading, setLoading] = useState(false);

    const [activeEmployees, setActiveEmployees] = useState(employees.filter((employee: IEmployee) => employee.active && (employee.organizationRoleName === "Admin" || employee.organizationRoleName === "Owner")));

    useEffect(() => {
        setActiveEmployees(employees.filter((employee: IEmployee) => employee.active && (employee.organizationRoleName === "Admin" || employee.organizationRoleName === "Owner")));
    }, [employees])

    return (
        <form className="form">
            <FormRow
                label="Zugewiesene*r Mitarbeiter*in"
                type="select"
                value={holiday.assignedToUserId}
                onChange={(val) => setHoliday((old) => ({ ...old, assignedToUserId: val }))}
                selectOptions={{
                    options: activeEmployees,
                    titleKey: "username",
                    valueKey: "id",
                    multiple: false
                }}
            />
            <div style={{ display: "flex", flexDirection: "row", gap: "10px" }}>
                <FormRow
                    label="Start des Urlaubs"
                    type="date"
                    dateOptions={{
                        type: "Date",
                    }}
                    value={holiday?.startDate}
                    onChange={(val) => setHoliday((old) => ({ ...old, startDate: val }))}
                />
                <FormRow
                    label="Ende des Urlaubs"
                    type="date"
                    dateOptions={{
                        type: "Date",
                    }}
                    value={holiday?.endDate}
                    onChange={(val) => setHoliday((old) => ({ ...old, endDate: val }))}
                />
            </div>

            <FormRow 
                type="multiline"
                value={holiday?.requesterNote}
                onChange={(val) => setHoliday((old) => ({...old, requesterNote: val}))}
                label="Anmerkung (optional)"
                placeholder="Anmerkungen zum Urlaub"
            />

            {!loading ?
                <CTAButton
                    title="Beantragen"
                    onClickHandler={() => { if (submitFunction) submitFunction(); setLoading(true) }}
                />
                : <div style={{ display: "flex", justifyContent: "center", }}><CircularProgress /></div>}
        </form>
    )
}

export default CreateHolidayRequestForm