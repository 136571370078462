import React, { useEffect, useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import CreateDepartementCard, { DepartmentFormModes } from '../../Components/DepartementCards/CreateDepartementCard'
import DepartementOverviewCard from '../../Components/DepartementCards/DepartementOverviewCard'
import PersonalCard from '../../Components/PersonalCards/PersonalCard'
import PersonalDataCard from '../../Components/PersonalCards/PersonalDataCard'
import ProductDataCard from '../../Components/ProductCards/ProductDataCard'
import Page from '../../Navigation/Page'
import { IDepartement, defaultDepartment } from '../../Contexts/DepartementContext/DepartementProvider'

function Abteilungen() {

    const [clickedDepartment, setClickedDepartment] = useState<IDepartement>(defaultDepartment);
    const [formMode, setFormMode] = useState<DepartmentFormModes>("createDep");

    //DEBUG
    useEffect(() => {
        console.log("clickedDepartment", clickedDepartment);
        console.log("depFormMode", formMode);
    }, [clickedDepartment, formMode])

    return (
        <>
            <CardColumn
                height='100%'
                width='50%'>
                <DepartementOverviewCard
                    height='100%'
                    width='100%'
                    setClickedDepartment={setClickedDepartment}
                    setFormMode={setFormMode}
                />
            </CardColumn>
            <CardColumn
                height='100%'
                width='50%'>
                <CreateDepartementCard
                    height='100%'
                    width='100%'
                    clickedDepartment={clickedDepartment}
                    setClickedDepartment={setClickedDepartment}
                    formMode={formMode}
                    setFormMode={setFormMode}
                />
            </CardColumn>
        </>
    )
}

export default Abteilungen