import { Switch } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IPerson, IPersonDTO, useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { StaffDTO, useStaff } from "../../Contexts/StaffContext/StaffProvider";
import DesignSwitch from "../Buttons/DesignSwitch";
import Android12Switch from "../Buttons/Switch/Android12Switch";
import FormRow from "../Forms/FormUtils/FormRow";
import InputField from "../InputField/InputField";
import SearchableSelect from "../InputField/SearchableSelect";

interface props {
    staff: StaffDTO,
    setStaff: Dispatch<SetStateAction<StaffDTO>>,
}

export default function CreateEditEmploymentRelationForm({ staff, setStaff }: props) {
    return (
        <form className='form'>
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
                <FormRow
                    onChange={(val) => { setStaff((old: StaffDTO) => ({ ...old, employmentRelation: { ...old.employmentRelation, from: val } })) }}
                    value={staff?.employmentRelation?.from}
                    label="Angestellt seit"
                    type="date"
                    dateOptions={{ type: "Date" }}
                />
                <FormRow
                    onChange={(val) => { setStaff((old: StaffDTO) => ({ ...old, employmentRelation: { ...old.employmentRelation, to: val } })) }}
                    value={staff?.employmentRelation?.to}
                    label="Angestellt bis (optional)"
                    type="date"
                    dateOptions={{
                        type: "Date"
                    }}
                />
            </div>
            <div style={{ display: "flex", flexDirection: "row", gap: "15px" }}>
                <FormRow
                    onChange={(val: number) => { setStaff((old: StaffDTO) => ({ ...old, employmentRelation: { ...old.employmentRelation, hours: parseFloat(val.toString()) } })) }}
                    value={staff?.employmentRelation?.hours}
                    label="Wochenstunden"
                    type="number"

                />
                <FormRow
                    onChange={(val: number) => { setStaff((old: StaffDTO) => ({ ...old, employmentRelation: { ...old.employmentRelation, hourlyRate: parseFloat(val.toString()) } })) }}
                    value={staff?.employmentRelation?.hourlyRate}
                    label="Stundensatz (bei Leistungen nach außen)"
                    type="number"
                />
            </div>
            <div>
                <FormRow
                    onChange={(val: number) => { setStaff((old: StaffDTO) => ({ ...old, employmentRelation: { ...old.employmentRelation, salary: parseFloat(val.toString()) } })) }}
                    value={staff?.employmentRelation?.salary}
                    label="Gehalt (mit Lohnnebenkosten)"
                    type="number"
                />
            </div>
            <FormRow
                value={staff.unusedHolidays}
                onChange={(val) => setStaff((old) => ({ ...old, unusedHolidays: val }))}
                type="number"
                label="Verfügbare Urlaubstage (pro Jahr)"
            />
        </form>
    )
}