// CameraCapture.js
import React from 'react';
import Webcam from 'react-webcam';
import CTAButton from '../Buttons/CTAButton/CTAButton';
import { Camera, CameraAlt } from '@mui/icons-material';

const CameraCapture = ({ onCapture }: any) => {
    const webcamRef = React.useRef(null);

    const capture = React.useCallback(() => {
        //@ts-ignore
        const imageSrc = webcamRef?.current?.getScreenshot();
        onCapture(imageSrc);
    }, [webcamRef, onCapture]);

    return (
        <div>
            <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={{
                    width: 1280,
                    height: 720,
                    facingMode: "environment"
                }}
                style={{ width: "calc(100%)" }}
            />
            <CTAButton backgroundColor='base' startIcon={<CameraAlt />} title="Bild aufnehmen" onClickHandler={capture} />
        </div>
    );
};

export default CameraCapture;
