// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ProfilePictureCard_picture-upload-container__I7nPF {\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 1vh;\n    border-bottom: 1px solid rgb(94, 87, 87);\n    border-radius: 1px;\n}\n\n.ProfilePictureCard_crop-container__vS63r {\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 2vh;\n    margin-right: 2vh;\n    margin-bottom: 2vh;\n}\n\n.ProfilePictureCard_completed-crop-container__pxs4r {\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 2vh;\n    margin-right: 2vh;\n}\n\n.ProfilePictureCard_text__FjqpL {\n    display: block;\n    text-align: center;\n    margin-bottom: .5vh;\n}", "",{"version":3,"sources":["webpack://./src/Components/ProfilePictureCard/ProfilePictureCard.module.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".picture-upload-container {\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    margin-bottom: 1vh;\n    border-bottom: 1px solid rgb(94, 87, 87);\n    border-radius: 1px;\n}\n\n.crop-container {\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 2vh;\n    margin-right: 2vh;\n    margin-bottom: 2vh;\n}\n\n.completed-crop-container {\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    margin-left: 2vh;\n    margin-right: 2vh;\n}\n\n.text {\n    display: block;\n    text-align: center;\n    margin-bottom: .5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"picture-upload-container": "ProfilePictureCard_picture-upload-container__I7nPF",
	"crop-container": "ProfilePictureCard_crop-container__vS63r",
	"completed-crop-container": "ProfilePictureCard_completed-crop-container__pxs4r",
	"text": "ProfilePictureCard_text__FjqpL"
};
export default ___CSS_LOADER_EXPORT___;
