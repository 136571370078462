import { deliveryNoteContext } from "../../Interfaces/DeliveryNoteContext"
import { deliveryTask } from "../../Interfaces/Task"

export const DeliveryNoteReducer = (state: any, action: any) => {
    switch(action.type) {

        //* Company-Management
        case 'SET_COMPANYID':
            return {
                ...state,
                companyID: action.payload
            }
        
        case 'REMOVE_COMPANYID':
            return {
                ...state,
                companyID: null
            }

        //* Task-Management
        case 'ADD_TASK_TO_DELIVER': 
            return {
                ...state,
                tasksToDeliver: [...state.tasksToDeliver, {id: Date.now() ,...action.payload}]
            }
        
        case 'REMOVE_TASK_TO_DELIVER': 
            return {
                ...state,
                tasksToDeliver: state.tasksToDeliver.filter((task: deliveryTask) => task.id != action.payload)
            }

        case 'EDIT_TASK_TO_DELIVER': 
            return {
                ...state,
                tasksToDeliver: state.tasksToDeliver.map((task: deliveryTask) => task.id === action.payload.id ? action.payload : task)
            }

        //* Default-Return
        default:
            return {
                ...state
            }
    }
}