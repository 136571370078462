import { Button, CircularProgress, CircularProgressProps, Fab } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Crop } from 'react-image-crop'
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { journalDTO } from '../../Interfaces/Journal'
import InputField from '../InputField/InputField'
import MultiLineInputField from '../InputField/MultiLineInputField'
import styles from '../PersonalSettingsCard/ProfilePictureCard.module.css'
import { AddBox, Delete, FileUpload, PlusOne, Download } from '@mui/icons-material'
import { IDocument, downloadFile, useDocuments } from '../../Contexts/DocumentContext/DocumentContext'
import { authConnection } from '../../Connection/BaseConnection'
import uuid from 'react-uuid'
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';

interface props {
    key: number,
    setObject?: any,
    isDownloadOnly?: boolean,
    existingFile?: any,
    index: number
    file: any,
    setFile: any
}

const FileInput = ({ key, setObject, file, setFile, isDownloadOnly = false, existingFile = false, index }: props) => {
    const [oldFile, setOldFile] = useState<any>();

    //useEffect(() => { setFile(existingFile); }, [existingFile])

    const chunkSize = 1048576 * 10; //1048576 is 1 MB, here we use 4 MB per chunk

    const [showProgress, setShowProgress] = useState(false)
    const [counter, setCounter] = useState(1)
    const [fileToBeUpload, setFileToBeUpload] = useState<any>({})
    const [beginingOfTheChunk, setBeginingOfTheChunk] = useState(0)
    const [endOfTheChunk, setEndOfTheChunk] = useState<number | undefined>()
    const [progress, setProgress] = useState(0)
    const [fileGuid, setFileGuid] = useState("")
    const [fileSize, setFileSize] = useState(0)
    const [chunkCount, setChunkCount] = useState(0)

    const resetChunkProperties = () => {
        setShowProgress(true)
        setProgress(0)
        setCounter(1)
        setBeginingOfTheChunk(0)
        setEndOfTheChunk(chunkSize)
    }

    const getFileContext = (e: any) => {
        if (e.target.files && e.target.files.length > 0) {
            setObject((old: any) => ({ ...old, files: old.files.map((file: any, i: number) => { if (i === key) { return e.target.files ? e.target.files[0] : file } else { return file } }) }))
            setFile(e.target.files[0])
        }

        resetChunkProperties();
        const _file = e.target.files[0];
        setFileSize(_file.size)
        const _totalCount = _file.size % chunkSize == 0 ? _file.size / chunkSize : Math.floor(_file.size / chunkSize) + 1; // Total count of chunks will have been upload to finish the file
        setChunkCount(_totalCount)
        setFileToBeUpload(_file)
        const _fileID = uuid();
        setFileGuid(_fileID)
    }

    const fileUpload = () => {
        if (counter <= chunkCount) {
            var chunk = fileToBeUpload.slice(beginingOfTheChunk, endOfTheChunk);
            uploadChunk(chunk).then(() => { setCounter(counter + 1); })
        }
    }

    const uploadCompleted = async () => {
        var formData = new FormData();
        formData.append('fileName', fileGuid);

        const response: any = await authConnection.post("/document/UploadComplete", null, {
            params: {
                fileName: fileGuid,
                originalFileName: file.name,
                contentType: file.type
            },
            data: formData,
        });

        setObject((old: any) => ({ ...old, documents: old.documents ? [...old.documents, response.data.data] : [response.data.data] }));
        const data = response.data;
        if (data.isSuccess) {
            setProgress(100);
            setTimeout(() => {
                setShowProgress(false);
            }, 100)
        }
    }

    const uploadChunk = async (chunk: any) => {
        try {
            await uploadFile(chunk, "/document/UploadChunks", (event: any) => {
                const progress = (((event.loaded / event.total / chunkCount) + ((counter - 1) / chunkCount)) * 100);
                setProgress(progress);
            })
        } catch (error) {
            console.log('error', error)
        }
    }

    const progressInstance = <LinearProgress value={progress} />;

    useEffect(() => {
        if (fileSize > 0 && progress < 100) {
            setShowProgress(true);
            fileUpload();
        }
    }, [fileToBeUpload, counter]);


    function LinearProgressWithLabel(props: LinearProgressProps & { value: number }) {
        return (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ width: '100%', mr: 1 }}>
                    <LinearProgress variant="determinate" {...props} />
                </Box>
                <Box sx={{ minWidth: 35 }}>
                    <Typography variant="body2" color="text.secondary">{`${Math.round(
                        props.value,
                    )}%`}</Typography>
                </Box>
            </Box>
        );
    }

    const { downloadDocument } = useDocuments();

    const [downloading, setDownloading] = useState<boolean>(false);
    const [success, setSuccess] = useState(false);

    const buttonSx = {
        ...(success && {
            bgcolor: "rgb(10,200,10)",
            '&:hover': {
                bgcolor: "rgb(10,250,10)",
            },
        }),
    };

    const uploadFile = (chunk: any, url: string, onProgress: any) => {
        return authConnection({
            url,
            method: 'POST',
            data: chunk,
            params: {
                id: counter,
                fileName: fileGuid,
            },
            headers: { 'Content-Type': 'application/json' },
            onUploadProgress: onProgress,
        })
            .then((response: any) => {
                const data = response.data;
                if (data.isSuccess) {
                    setBeginingOfTheChunk(endOfTheChunk!);
                    setEndOfTheChunk(endOfTheChunk! + chunkSize);
                    if (counter == chunkCount) {
                        uploadCompleted();
                    }
                } else {
                    console.log('Error Occurred:', data.errorMessage)
                }
            })
            .catch((error) => {
                // Handle error
            });
    };

    // Example usage
    const handleDownload = (file: IDocument) => {
        setDownloading(true);
        downloadFile(file, `/document/download/${file.id}`, (event: any) => {
            const progress = Math.round((event.loaded / event.total) * 100);
            setProgress(progress);
        }).then(() => {
            setDownloading(false);
        });
    }

    function CircularProgressWithLabel(
        props: CircularProgressProps & { value: number },
    ) {
        return (
            <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${Math.round(props.value)}%`}</Typography>
                </Box>
            </Box>
        );
    }

    return (
        <div className='form-row-label' key={key}>
            <span className="label">
                Dateiname:
            </span>
            <div style={{ display: "flex", width: "100%", justifyContent: "center", flexDirection: "column" }}>
                <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <div style={{ width: "70%" }}>
                        <InputField onChangeHandler={() => { }} value={file?.fileName ?? file?.name ?? file?.title} isReadOnly={true}></InputField>
                    </div>
                    <div style={{ display: "flex" }}>
                        {!isDownloadOnly &&
                            <>
                                <Button color='info' variant='outlined' component="label" style={{ marginBottom: "1vh", marginRight: "5%", color: "gray", borderColor: "gray" }}>
                                    <input hidden type="file" accept="*" onChange={(e) => getFileContext(e)} />
                                    <FileUpload />
                                </Button>
                                <Button color='error' variant='outlined' component="label" style={{ marginBottom: "1vh" }} onClick={() => { setObject((old: any) => ({ ...old, files: (old.files ?? []).filter((file: any, i: number) => { return i !== index; }), documents: (old.documents ?? []).filter((file: any, i: number) => i !== index) })) }}>
                                    <Delete />
                                </Button>
                            </>
                        }
                        {isDownloadOnly &&
                            <>
                                {!downloading && <Box sx={{ position: 'relative' }}>
                                    <Fab
                                        size="small"
                                        aria-label="save"
                                        sx={buttonSx}
                                        onClick={() => { handleDownload(file) }}
                                    >
                                        {progress < 100 && progress > 0 ? (progress === 100 ? <span>{progress} %</span> : <CheckIcon fontSize='small' />) : <Download fontSize='small' />}
                                    </Fab>
                                </Box>}
                                {downloading && (
                                    <CircularProgressWithLabel
                                        size={42}
                                        value={progress}
                                        sx={{
                                            color: "rgb(10, 200, 10)"
                                        }}
                                    />
                                )}
                            </>
                        }
                    </div>
                </div>
            </div>
            {showProgress &&
                <>
                    <Box sx={{ width: '100%' }}>
                        <LinearProgressWithLabel value={progress} />
                    </Box>
                    {(Math.round((fileSize * (progress / 100)) / 1024) / 1000).toFixed(2)} MB / {(Math.round((fileSize) / 1024) / 1000).toFixed(2)} MB
                </>
            }
        </div>)
}

export default FileInput;