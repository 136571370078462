import React, { useEffect, useRef, useState } from 'react'
import styles from './SearchBar.module.css'

//Icons
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { style } from '@mui/system';

interface searchBar {
  style?: React.CSSProperties
  value: string
  onChangeHandler: (searchValue: string) => void
  placeholder?: string
  autofocus?: boolean
}

function SearchBar({value, onChangeHandler, placeholder, autofocus, style} : searchBar) {

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if(autofocus) {
      inputRef.current?.focus();
    }
  }, [autofocus])

  return (
    <div className={'flex centered-aligned stretched-justify'.concat(" " + styles.container)} style={style}>
        <SearchRoundedIcon fontSize='small' />
        <input ref={inputRef} type="text" placeholder={placeholder ?? "Suchen..."} value={value} onChange={(e) => onChangeHandler(e.target.value)}/>
    </div>
  )
}

export default SearchBar