import React, { ReactNode, useEffect, useState } from 'react'
import { getClassNames } from '../../../Hooks/Utilities/Utlitites'
import styles from './ButtonControl.module.css'

type button = {
    body: (active: boolean) => ReactNode
    value: any
}

interface buttonControl<T> {
    buttons: button[]
    value: T
    setValue: (newVal: T) => void
}



const ButtonControl = <T extends unknown>({buttons, value, setValue} : buttonControl<T>) => {

    const handleChange = (newVal: T, index: number) => {
      setActive(index)
      setValue(newVal)
    }

    const [active, setActive] = useState(buttons.findIndex((button) => button.value === value))

    useEffect(() => {
      if(value === "companyEdit") {
        setActive(buttons.findIndex((button)=> button.value === "company"));
      }
      if(value === "personEdit") {
        setActive(buttons.findIndex((button)=> button.value === "person"));
      }
    }, [value])
    
  return (
    <div className={getClassNames([styles.wrapper, "flex"])}>
      {
        buttons.map((button, index) => (
          <button key={index} className={getClassNames(['centered', "pointer", styles.button, index === active ? styles.active : ""])} onClick={() => handleChange(button.value, index)}>{button.body(active === index)}</button>
        ))
      }
    </div>
  )
}

export default ButtonControl