import { DragHandleRounded, EditRounded, EventBusyRounded } from '@mui/icons-material';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { Button, Grid, IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { Dispatch, SetStateAction, useState } from "react";
import { useContacts } from '../../../Contexts/ContactsContext/ContactsProvider';
import { useModals } from "../../../Contexts/DialogContext/DialogProvider";
import { IEmployee, useEmployees } from '../../../Contexts/EmployeeContext/EmployeeProvider';
import { useTasks } from "../../../Contexts/TaskContext/TaskContext";
import { IOrganization } from '../../../Interfaces/Company';
import { ITask } from '../../../Interfaces/Task';
import useDragger from '../useDragger';
import modalStyles from "./TaskInfoModal.module.css";

interface props {
    task?: ITask,
    setTask?: Dispatch<SetStateAction<ITask>>
    showTaskInfoModal?: boolean,
    setShowTaskInfoModal?: Dispatch<SetStateAction<boolean>>
}

const TaskInfoModal = ({ task, setTask, setShowTaskInfoModal }: props) => {
    const { updateTask } = useTasks();
    const dialogContext = useModals()
    const { employees } = useEmployees()
    const { companies } = useContacts();

    const [assignedUsers, setAssignedUsers] = useState(employees.filter((e) => task?.userIds?.includes(e.id)))
    const [createdBy, setCreatedBy] = useState(employees.find((e: IEmployee) => e.id == task?.userId))
    const [taskCustomerOrganization, setTaskCustomerOrganization] = useState(companies.find((o: IOrganization) => task?.customerOrganizationId == o.id))

    useDragger('target', 'AppointmentInfoModal')

    const onPressDelete = () => {
        dialogContext.changeVisibility!("yesNoDialog", true,
            {
                props: {
                    object: {
                        submitFunction: () => {
                            updateTask!({ ...task, scheduledDate: undefined });
                            setShowTaskInfoModal!(false);
                        },
                        modalTitle: "Deadline für Aufgabe löschen",
                        confirmButtonText: "Löschen",
                        modalText: "Möchten Sie die Deadline für diese Aufgabe wirklich löschen? Die Aufgabe bleibt vorhanden, aber ohne geplantes Fertigstellungsdatum und verschwindet somit aus dem Kalender."
                    }
                }
            });
    }

    const onPressEdit = () => {
        dialogContext.changeVisibility!("createTask", true, { props: { object: task, mode: "edit" } })
    }


    return (
        <div className={modalStyles.modalContainer}>
            <form className={modalStyles.modalForm}>
                <header className={modalStyles.modalHeader} id='target'>
                    {<DragHandleRounded style={{ color: 'black', marginLeft: '0px', marginRight: 'auto' }} />}
                    <Button onClick={() => {
                        setShowTaskInfoModal!(false)
                    }}
                        style={{ minWidth: '5%' }} size="small"><CloseRoundedIcon fontSize='small' style={{ color: 'black' }} /></Button>
                </header>
                <div className={modalStyles.content}>
                    <Grid container spacing={1}>
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Titel:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{task?.title}</Grid>
                        <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}><hr style={{ border: '1px solid whitesmoke' }} /></Grid>
                        {task?.description &&
                            <>
                                <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Beschreibung:</Grid>
                                <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{task?.description}</Grid>
                                <Grid item xs={12} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}><hr style={{ border: '1px solid whitesmoke' }} /></Grid>
                            </>
                        }
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Geplante Fertigstellung:</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{dayjs(task?.scheduledDate).format('DD.MM.YYYY - HH:mm')}</Grid>
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Zugewiesene Mitarbeiter*innen</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>
                            {assignedUsers?.map((u, idx) => {
                                if (idx === assignedUsers.length - 1) {
                                    return (<span key={idx}>{u.username}</span>)
                                } else {
                                    return (<span key={idx}>{u.username}, </span>)
                                }
                            })}
                        </Grid>
                        <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Kunde</Grid>
                        <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{taskCustomerOrganization?.name}</Grid>
                        {task?.note &&
                            <>
                                <Grid item xs={4} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>Notiz:</Grid>
                                <Grid item xs={8} style={{ whiteSpace: 'normal', wordWrap: 'break-word' }}>{task?.note}</Grid>
                            </>}
                        <span style={{ marginRight: '0px', marginLeft: 'auto', marginTop: '1em', color: 'grey', fontSize: '0.9em', fontStyle: 'italic' }}>
                            Erstellt von {createdBy?.username} am {dayjs(task?.creationDate).format('DD.MM.YYYY - HH:mm')}
                        </span>
                    </Grid>
                </div>

                <footer className={modalStyles.modalFooter}>
                    <Tooltip title='Deadline für diese Aufgabe löschen' arrow disableInteractive style={{ marginRight: 'auto' }}>
                        <IconButton
                            onClick={onPressDelete}>
                            <EventBusyRounded color='error' fontSize='medium' />
                        </IconButton>
                    </Tooltip>

                    <Tooltip title='Aufgabe bearbeiten' arrow disableInteractive>
                        <IconButton
                            onClick={() => onPressEdit()}>
                            <EditRounded color='primary' fontSize='medium' />
                        </IconButton>
                    </Tooltip>

                </footer>
            </form>
        </div>

    )
}

export default TaskInfoModal;