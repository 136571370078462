import React, { useEffect } from 'react'
import Form from './FormUtils/Form'
import { form } from './CreateTaskForm'
import FormRow from './FormUtils/FormRow'


const PostScriptTimeForm = ({ object, setObject }: form<any>) => {
  return (
    <Form>
      <FormRow
        label="Start"
        value={new Date(object.start ?? Date.now.toString())}
        onChange={(val) => setObject((old: any) => ({ ...old, start: val.toISOString() }))}
        type="date"
      />
      <FormRow
        label="Ende"
        value={object.end as Date}
        onChange={(val: Date) => setObject((old: any) => ({ ...old, end: val.toISOString() }))}
        type="date"
      />
    </Form>
  )
}

export default PostScriptTimeForm