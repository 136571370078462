import { AxiosResponse } from 'axios'
import React, { ReactNode, useContext, useEffect, useReducer } from 'react'
import { createContext } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import OrganizationRightsReducer from './OrganizationRightsReducer'

export interface IOrganizationRights {
  id?: number
  organizationId?: number
  organizationRoleId?: number
  createAddress?: boolean
  readAddress?: boolean
  updateAddress?: boolean
  deleteAddress?: boolean
  createBankInformation?: boolean
  readBankInformation?: boolean
  updateBankInformation?: boolean
  deleteBankInformation?: boolean
  createBillPrefix?: boolean
  readBillPrefix?: boolean
  updateBillPrefix?: boolean
  deleteBillPrefix?: boolean
  createBill?: boolean
  readBill?: boolean
  updateBill?: boolean
  deleteBill?: boolean
  createContact?: boolean
  readContact?: boolean
  updateContact?: boolean
  deleteContact?: boolean
  createDepartment?: boolean
  readDepartment?: boolean
  updateDepartment?: boolean
  deleteDepartment?: boolean
  createDocument?: boolean
  readDocument?: boolean
  updateDocument?: boolean
  deleteDocument?: boolean
  updateECockpitOrganization?: boolean
  deleteECockpitOrganization?: boolean
  createEmailLogin?: boolean
  readEmailLogin?: boolean
  updateEmailLogin?: boolean
  deleteEmailLogin?: boolean
  createEmploymentRelation?: boolean
  readEmploymentRelation?: boolean
  updateEmploymentRelation?: boolean
  deleteEmploymentRelation?: boolean
  createJournal?: boolean
  readJournal?: boolean
  updateJournal?: boolean
  deleteJournal?: boolean
  createKey?: boolean
  readKey?: boolean
  updateKey?: boolean
  deleteKey?: boolean
  createOrganizationRights?: boolean
  readOrganizationRights?: boolean
  updateOrganizationRights?: boolean
  deleteOrganizationRights?: boolean
  createOrganization?: boolean
  readOrganization?: boolean
  updateOrganization?: boolean
  deleteOrganization?: boolean
  createPerson?: boolean
  readPerson?: boolean
  updatePerson?: boolean
  deletePerson?: boolean
  createProduct?: boolean
  readProduct?: boolean
  updateProduct?: boolean
  deleteProduct?: boolean
  createProject?: boolean
  readProject?: boolean
  updateProject?: boolean
  deleteProject?: boolean
  createRole?: boolean
  readRole?: boolean
  updateRole?: boolean
  deleteRole?: boolean
  createService?: boolean
  readService?: boolean
  updateService?: boolean
  deleteService?: boolean
  createSubscription?: boolean
  readSubscription?: boolean
  updateSubscription?: boolean
  deleteSubscription?: boolean
  createTask?: boolean
  readTask?: boolean
  updateTask?: boolean
  deleteTask?: boolean
  createTaskHasUser?: boolean
  readTaskHasUser?: boolean
  updateTaskHasUser?: boolean
  deleteTaskHasUser?: boolean
  createTextModule?: boolean
  readTextModule?: boolean
  updateTextModule?: boolean
  deleteTextModule?: boolean
  createTravelFlatrate?: boolean
  readTravelFlatrate?: boolean
  updateTravelFlatrate?: boolean
  deleteTravelFlatrate?: boolean
  createTravel?: boolean
  readTravel?: boolean
  updateTravel?: boolean
  deleteTravel?: boolean
  createWorktime?: boolean
  readWorktime?: boolean
  updateWorktime?: boolean
  deleteWorktime?: boolean
  createEmployees?: boolean
  readEmployees?: boolean
  updateEmployees?: boolean
  deleteEmployees?: boolean
  readStatistics?: boolean
  printWorktime?: boolean
  manageHolidays?: boolean
}

export interface IOrganizationRightsDTO {

}

export interface IOrganizationRole {
  id: number,
  title: string,
  description: string,
  rights?: any
  organizationId?: number
}

interface IOrganizationRightsContext {
  organizationRoles: Array<IOrganizationRole>
}

const OrganizationRightsContext = createContext<IOrganizationRightsContext>({
  organizationRoles: []
})

const OrganizationRightsProvider = ({ children }: { children: ReactNode }) => {
  const connection = useAuthConnection();
  const { enqueueSnackbar, closeSnackbar } = useSnackBar();

  const [state, dispatch] = useReducer(OrganizationRightsReducer, {
    organizationRoles: [],
    organizationRights: []
  })

  const fetchOrganizationRoles = () => {
    connection.get("/organization/getallroles")
      .then((response: AxiosResponse) => {

        connection.get("/organizationrights")
          .then((res: AxiosResponse) => {

            let roles = response.data;
            roles = roles.map((role: IOrganizationRole) => {
              return { ...role, rights: res.data.find((right: any) => right.organizationRoleId === role.id) }
            })

            if (res.status === 200) {
              dispatch({
                type: "SET_ROLES",
                payload: roles
              })
            }

          })
      })
  }

  useEffect(() => {
    fetchOrganizationRoles();
  }, [])


  return (
    <OrganizationRightsContext.Provider
      value={{
        organizationRoles: state.organizationRoles,
      }}
    >
      {children}
    </OrganizationRightsContext.Provider>
  )
}

export default OrganizationRightsProvider

export const useOrganizationRights = () => useContext(OrganizationRightsContext)