import { IProductDTO } from "../Contexts/ProductContext/ProductProvider"

export interface accounting {
  revenue: number,
  expenditure: number,
  profit: number,
  loss: number,
  delayed: number, //unpaid bills by more than 30 days
  revenueExpenditure: any,
  unbilledServices: number,
  billedServices: number,
  expectedTax: number,
  expectedSocialSecurity: number
}

export interface BillPrefix {
  id: number,
  prefix: string,
  isOffer: boolean,
  organizationId: number
}

export interface BillPrefixDTO extends BillPrefix {
  billsCount: number
  paidBillsCount: number
  openBillsCount: number
  delayedBillsCount: number
}

export const defaultBillPrefixDTO: BillPrefixDTO = {
  id: 0,
  prefix: "",
  isOffer: false,
  organizationId: 0,
  billsCount: 0,
  paidBillsCount: 0,
  openBillsCount: 0,
  delayedBillsCount: 0
}

export interface IRegistrierkasse {
  kassenId?: string
  key?: string
  zertifikatId?: number
}

export interface IRegistrierkassenzertifikat {
  id?: number
  seriennummerHex?: string
  seriennummerDec?: string
  benutzername?: string
  passwort?: string
  ablaufdatum?: Date
  identifikation?: string
  identifikationType?: number
  algorithmus?: string
  zertifizierungsstelle?: string
}

export interface IRegistrierkasseBelegCreationDTO extends IRegistrierkasseBelegDTO {
  products: Array<IProductDTO>
}

export interface IRegistrierkasseBelegDTO extends IRegistrierkasseDatenerfassungsprotokoll {
  qrCodeBase64?: string;
}

export interface IRegistrierkasseDatenerfassungsprotokoll {
  id?: number
  belegNr?: number
  belegTyp?: string
  timestamp?: Date
  ustNormal?: number
  ustErmaessigt1?: number
  ustErmaessigt2?: number
  ustBesonders?: number
  ustNull?: number
  umsatzzaehlerstand?: number
  jws?: string
  kassenId?: string
  qrCodePayload?: string
}