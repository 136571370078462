import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IPerson, IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { ExpandMoreRounded, ExpandLessRounded, MoreVertRounded, SettingsCellRounded } from '@mui/icons-material'
import { Collapse, IconButton, Tooltip } from '@mui/material'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'

import companyStyles from '../Company/Company.module.css'
import PersonField from './PersonField'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'
import ContextMenu from '../Menu/ContextMenu'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useSnackBar } from '../../Hooks/useSnackBar'
import ContactDataField from './ContactDataField'
import { IOrganizationDTO } from '../../Interfaces/Company'
import { defaultProduct } from '../../Contexts/ProductContext/ProductProvider'
import { contactFormModes } from '../ContactCards/ContactFormCard'
import { useNavigate } from 'react-router-dom'
import { PhoneCardModes } from '../PhoneCard/PhoneCard'
import { IJournalFilter } from '../../Pages/Communication/EmailPage'


interface personComp {
  person: IPerson
  index: number
  setClickedPerson?: Dispatch<SetStateAction<any>>
  journalFilter?: IJournalFilter
  setJournalFilter?: Dispatch<SetStateAction<IJournalFilter>>
  setFormMode?: Dispatch<SetStateAction<contactFormModes>>
  setPhoneCardMode?: Dispatch<SetStateAction<PhoneCardModes>>
  expanded?: boolean
  setExpandedElement?: any
}

const Person = ({ expanded, setExpandedElement, person, index, setClickedPerson, journalFilter, setJournalFilter, setFormMode, setPhoneCardMode }: personComp) => {

  const { changeVisibility } = useModals()

  const { enqueueSnackbar } = useSnackBar()

  const { deletePerson, defaultPerson } = useContacts()

  const handleExpand = () => {
    if (setClickedPerson && !expanded) {
      setClickedPerson({ ...person })
      console.log("Person", person);
      if (setFormMode) {
        setFormMode("personEdit");
      }
      if(setPhoneCardMode) {
        setPhoneCardMode("person");
      }
      if (setJournalFilter && journalFilter) {
        setJournalFilter((old: IJournalFilter) => ({ ...old, personIds: [person.id!] }))
      }
    }
    else if (setClickedPerson) {
      setClickedPerson({ ...defaultPerson })
      if (setFormMode) {
        setFormMode("person");
      }
      if(setPhoneCardMode) {
        setPhoneCardMode("undefined");
      }
      if (setJournalFilter && journalFilter) {
        setJournalFilter((old: IJournalFilter) => ({ ...old, personIds: old.personIds.filter((temp: number) => temp !== person.id!) }))
      }
    }
    if (!expanded) {
      setExpandedElement(index)
    } else {
      setExpandedElement(undefined)
    }
  }
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleEdit = () => {
    changeVisibility!("editCreatePerson", true, { props: { object: person, mode: "edit" } })
  }

  const handleDelete = async () => {
    try {
      changeVisibility!("yesNoDialog", true, { props: { object: { submitFunction: () => { deletePerson!(person) }, modalTitle: "Person löschen", modalText: "Möchten Sie diese Person wirklich löschen?", confirmButtonText: "Löschen" } } })
    } catch (error) {
      enqueueSnackbar("Fehler beim Löschen.", { variant: "error" })
    }
  }
  /*AHK: Section f. Datenzugriff auf Telefonnummer****************************************************************** */


  /***************************************************************************************************************** */

  //Keys des Person-Objekts?
  const keys = Object.keys(person).map((key) => {
    if (key == "id" || key == "firstname" || key == "lastname" || key == "birthdate" || key == "organizationId") return null
    if (person[key as keyof IPerson] == "" || person[key as keyof IPerson] == null) return null
    return key
  }).filter((val) => val != null)

  return (
    <div className={getClassNames(['flex', 'column', companyStyles.container])} style={{ backgroundColor: (index ?? 2) % 2 == 0 ? "transparent" : "var(--ultra-light)" }}>
      <header className='flex centered-aligned stretched-justify pointer no-select' style={{ marginLeft: "10px", marginRight: "5px", padding: "4px 0" }}>
        <div onClick={handleExpand} className='flex centered-aligned' style={{ gap: "8px", width: "100%" }}>
          <Tooltip title={(person.status ?? 1) == 1 ? "Ok!" : person.status == 2 ? "Mäßig." : person.status == 3 ? "Vorsicht." : "Verstorben"} arrow placement="bottom">
            <div className={getClassNames([companyStyles.accent])} style={{ backgroundColor: (person.status ?? 1) == 1 ? "green" : person.status == 2 ? "orange" : person.status == 3 ? "red": "black", transition: "background-color 1s linear" }} />
          </Tooltip>
          <span style={{ fontWeight: "500", maxWidth: "calc(80%)", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden" }}>{person.lastname} {person.firstname}</span>
        </div>
        <div className='flex' style={{ gap: "-3px" }}>
          <IconButton
            onClick={handleExpand}
            style={{ marginLeft: "-8px" }}
          >
            {!expanded ? <ExpandMoreRounded fontSize='small' /> : <ExpandLessRounded fontSize='small' />}
          </IconButton>
          <IconTooltipButton
            title='Optionen'
            arrow
            onClick={handleOptionsClick}
          >
            <MoreVertRounded fontSize='small' />
          </IconTooltipButton>

        </div>
        <ContextMenu
          anchorEl={anchorEl}
          onClose={(index) => setAnchorEl(null)}
          options={[
            {
              label: "Bearbeiten",
              onClick: (index) => handleEdit(),
              selectable: false
            },
            {
              label: "Status ändern",
              onClick: (index) => changeVisibility!("editStatusPerson", true, { props: { object: person } }),
              selectable: false
            },
            {
              label: "Firma zuordnen",
              onClick: (index) => changeVisibility!("assignToCompany", true, { props: { object: person } }),
              selectable: false
            },
            {
              label: "Laufzettel erstellen",
              onClick: (index) => navigate(`/laufzettel/person/${person.id}`),
              selectable: false
            },
            {
              label: "Löschen",
              onClick: (index) => handleDelete(),
              selectable: false
            },
          ]}
        />
      </header>
      <Collapse in={expanded}>
        <div style={{ width: "100%", height: "1px", backgroundColor: "var(--light-contrast)" }} />

        <ContactDataField person={person} contactData={person.contactData!} />

      </Collapse>
    </div>
  )
}

export default Person

/* 

import { ExpandMoreRounded } from '@mui/icons-material'
import { Collapse, IconButton, Tooltip } from '@mui/material'
import React, { useState } from 'react'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import { company } from '../../Interfaces/Company'
import styles from './Company.module.css'
import CompanyField from './CompanyField'



const Company = ({company, index} : companyComp) => {

  const [expanded, setExpanded] = useState(false)

  const handleExpand = () => {
    setExpanded((old) => !old)
  }

  //Keys des Company-Objekts
  const keys = Object.keys(company).map((key) => {
    if (key == "id" || key == "name") return null
    return key
  }).filter((val) => val != null && val != undefined)
  
  return (
    <div className={getClassNames(['flex', 'column', styles.container])} style={{backgroundColor: (index?? 2) % 2 == 0 ? "transparent" : "var(--ultra-light)"}}>
      <header onClick={handleExpand} className='flex centered-aligned stretched-justify pointer' style={{ marginLeft: "10px", marginRight: "5px", padding: "4px 0"}}>
        <div className='flex centered-aligned' style={{gap: "8px"}}>
          <Tooltip title="Ok!" arrow placement="bottom">
            <div className={getClassNames([styles.accent])} style={{backgroundColor: "green"}} />
          </Tooltip>
          <span style={{fontWeight: "500"}}>{company.name}</span>
        </div>
        <IconButton><ExpandMoreRounded fontSize='small' /></IconButton>
      </header>
      <Collapse in={expanded}>
        <div style={{width: "100%", height: "1px", backgroundColor: "var(--light-contrast)"}} />
        <div className='flex column' style={{padding: "10px", gap: "4px"}}>
          {
            keys.length > 0 ?
            keys.map((key) => (
              <CompanyField  keyValue={key} company={company} />
            ))
            : 
            <div className='centered column' style={{padding: "10px", gap: "10px"}}>
              <span style={{color: "var(--text-contrast"}}>Keine Daten zum Unternehmen.</span>
              <button className='cta-button pointer'>
                Hinzufügen
              </button>
            </div>
          }
        </div>
      </Collapse>
    </div>
  )
}

export default Company
*/