import Card from "../../../Components/Card/Card"
import RemoveIcon from '@mui/icons-material/Remove';
import { IPerson, useContacts } from "../../../Contexts/ContactsContext/ContactsProvider";
import PersonToTagRow from "./PersonToTagRow";
import { SetStateAction, useEffect, useState } from "react";
import Filter, { IFilterOption } from "../../../Components/Filter/Filter";

interface IPersonToTagCard {
    personsToTag: IPerson[]
    handlePersonSelect: (personId: number) => void
    rowIsChecked: (id: number) => boolean
    removeSelectedPersonsFromTag: () => void
}

function PersonToTagCard({ personsToTag, handlePersonSelect, rowIsChecked, removeSelectedPersonsFromTag }: IPersonToTagCard) {

    const { companies } = useContacts();
    const [filteredPersonsToTag, setFilteredPersonsToTag] = useState<IPerson[]>([]);

    const filterOptions: IFilterOption[] = [
        { title: "Name", valueKey: "completeName" },
        { title: "Notiz", valueKey: "note" },
        { title: "Organisation", valueKey: "belongsToOrganizationId", referenceList: companies, referenceListValueKey: "name" },
    ]

    return (
        <Card
            title={"Personen zu Tag"}
            cta
            ctaTitle={"Entfernen"}
            buttonIcon={<RemoveIcon fontSize='small' />}
            ctaFunction={() => removeSelectedPersonsFromTag()}
            right={() => <Filter dataToFilter={filteredPersonsToTag} setDataToFilter={setFilteredPersonsToTag} originalData={personsToTag} filterOptions={filterOptions} />}
        >
            {filteredPersonsToTag.length === 0 ? <>Keine Einträge zu ausgewähltem Tag</> : filteredPersonsToTag.map((person, index) => {
                return (<PersonToTagRow person={person} index={index} handleSelect={handlePersonSelect} rowIsChecked={rowIsChecked} />)
            })}
        </Card>
    )
}

export default PersonToTagCard