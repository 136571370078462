import Card from "../Card/Card"
import GenerateWorktimeOverviewForUserForm from "./GenerateWorktimeOverviewForUserForm"

function GenerateWorktimeOverviewForUser() {
    return (
        <>
            <Card
                title="Arbeitszeitenübersicht erstellen"
                fullHeightBody
            >
                <GenerateWorktimeOverviewForUserForm />
            </Card>
        </>
    )
}

export default GenerateWorktimeOverviewForUser