import Card from "../Card/Card";
import PdfViewer from '../PDF/all-pages';
import { Button, Tooltip } from "@mui/material";
import { Assignment, Check, Download, MonetizationOn, Refresh, Upload } from "@mui/icons-material";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import CreateBillModal from "../BillCards/CreateBillModal";
import { useBills } from "../../Contexts/BillContext/BillProvider";
import { IBillDTO } from "../../Interfaces/Bill";
import { AxiosResponse } from "axios";
import { IDocument, downloadFile } from '../../Contexts/DocumentContext/DocumentContext'
import useAuthConnection from "../../Hooks/useAuthConnection";
import CreateTaskDialog from "../Dialogs/CreateTaskDialog/CreateTaskDialog";
import { ITask, ITaskDTO } from "../../Interfaces/Task";
import { IDeliveryNoteDTO } from "../../Pages/Services/LieferscheinErstellen";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { useTasks } from "../../Contexts/TaskContext/TaskContext";
import DeliveryNoteToBillDialog from "../../Pages/Services/DeliveryNoteToBillDialog";
import DeliveryNoteToServiceDialog from "../../Pages/Services/DeliveryNoteToServiceDialog";

interface props {
    object: IDeliveryNoteDTO | undefined,
    setObject: Dispatch<SetStateAction<IDeliveryNoteDTO>>
    document: string | undefined
    setDocument: Dispatch<SetStateAction<string | undefined>>
    type?: string
    setGenerateTasksOpen?: Dispatch<SetStateAction<boolean>>
}

const DeliveryNotePdfViewerCard = ({ object, setObject, document, setDocument, type, setGenerateTasksOpen }: props) => {
    const { markBillCreated, updateBill } = useBills();

    const [downloading, setDownloading] = useState<boolean>(false);
    const [progress, setProgress] = useState<number>(0);
    const [open, setOpen] = useState<boolean>(false);

    const handleDownloadClick = () => {
        if (object && document) {
            download();
        }
    }

    const connection = useAuthConnection();
    
    const { refreshDocument } = useTasks();
    const generatePdf = (deliveryNote?: IDeliveryNoteDTO) => {
        refreshDocument!(deliveryNote ?? object)
            .then((res) => {
                setDocument(res.base64)
            })
    }

    const download = () => {
        if (object?.documentId !== undefined) {
            setDownloading(true);
            downloadFile({
                id: object.documentId, title: "Lieferschein_" + customerName + "_" + (new Date()).getFullYear() + "-" + (new Date()).toLocaleString('de-DE', { month: '2-digit' }) + "-" + (new Date()).toLocaleString('de-DE', { day: '2-digit' }) + ".pdf"
            }, `/document/download/${object?.documentId}`, (event: any) => {
                const progress = Math.round((event.loaded / event.total) * 100);
                setProgress(progress);
            }).then(() => { setDownloading(false) })
        }
    }

    const handleCreateBillFromDeliveryNote = () => {
        setOpen(false);

        //ToDo:
    }

    function toBase64(e: any) {
        let reader = new FileReader();
        reader.onload = function (e) {
            setDocument((old: any) => ({ ...old, base64: reader.result }));
        }
        reader.readAsDataURL(e.target.files[0]);
    }

    const inputRef = useRef<any>();

    const [newTasksList, setNewTasksList] = useState<Array<ITaskDTO>>([]);

    const { companies } = useContacts();
    const [customerName, setCustomerName] = useState("");

    useEffect(() => {
        if (object?.customerOrganizationId) {
            setCustomerName(companies.find((company) => company.id === object.customerOrganizationId)?.name ?? "")
        } else if (object?.customerPersonId) {

        }
    }, [object?.customerOrganizationId, object?.customerPersonId])

    

    return <>
        <div className="flex flex-col overflow-scroll border-2 rounded-md border-white bg-[var(--light-contrast)] min-w-[600px] h-full" >

            <div style={{ display: "flex", paddingLeft: "1em", paddingRight: "1em", flexDirection: "row", justifyContent: "space-between", position: "sticky", height: "3.5em", border: "solid 0px #000000", top: 0, paddingTop: "0em", borderBottomWidth: "1px", zIndex: 100, backgroundColor: "var(--light-contrast)" }}>
                <Button disabled={object?.id === undefined} variant='text' style={{ color: "black", borderColor: "black" }} onClick={() => { generatePdf(); }}>
                    <>Refresh <Refresh /></>
                </Button>
                <Button disabled={object?.id === undefined} variant='text' style={{ color: "black", borderColor: "black" }} onClick={handleDownloadClick}>
                    {!downloading && <>Herunterladen <Download /></>}
                    {downloading && <span>{progress} %</span>}
                </Button>
                <Tooltip title="Demnächst verfügbar">
                    <Button disabled={object?.id === undefined || object?.billId != undefined || !document} variant='text' style={{ color: object?.billId != undefined ? "green" : "black", borderColor: object?.billId != undefined ? "green" : "black" }}
                        onClick={() => {
                            setOpen(true);
                        }}
                    >
                        {<>Leistung erstllen <Assignment /></>}
                    </Button>
                </Tooltip>
            </div>
            <div style={{ marginTop: "1.5em", display: "flex", justifyContent: "center" }}>
                {document &&
                    <PdfViewer pdf={document.startsWith(`data:`) ? document : `data:application/pdf;base64,${document}`} />
                }
            </div>


        </div>
        <DeliveryNoteToServiceDialog open={open} deliveryNote={object} onClose={() => { setOpen(false) }} onSubmit={() => { }} />
    </>
}

export default DeliveryNotePdfViewerCard;