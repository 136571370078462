import { CircularProgress } from '@mui/material'
import { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useServices } from '../../../Contexts/ServiceContext/ServiceContext'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import { IService, IServiceDTO } from '../../../Interfaces/Service'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateServiceForm from '../../Forms/CreateServiceForm'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import dayjs from 'dayjs'


function EditCreateServiceDialog({ open, onClose, object, mode }: dialogWithProps<IService>) {

  const { changeVisibility } = useModals()

  const { enqueueSnackbar } = useSnackBar()


  const { defaultService, addService, updateService } = useServices()
  //* Management der Daten zum Submitten an die Datenbank
  const [serviceToSubmit, setServiceToSubmit] = useState<IServiceDTO | IService>({
    ...(object ?? defaultService!)
  })



  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)


  //TODO Implementieren -> Bernhard obzw. Nico
  //Funktion zum Handeln des Hinzufügens -> Wird ein Promise werden
  const handleSubmit = async () => {
    try {
      if (validateInput(serviceToSubmit)) {
        setLoading(true)
        if (mode == "create") {
          await addService!(serviceToSubmit)
        } else {
          await updateService!(serviceToSubmit)
        }
        if (onClose) {
          handleClose()
        }
      }
      changeVisibility!("editCreateService", false)
    } catch (error) {
      console.log(error)
      enqueueSnackbar(`Fehler beim ${mode == "create" ? "Erstellen" : "Speichern"}.`, { variant: "error" })
    } finally {
      setLoading(false)
    }

  }

  const handleClose = async () => {
    setServiceToSubmit({ ...defaultService! })
    setLoading(false)
    if (onClose) {
      handleClose()
    }
    changeVisibility!("editCreateService", false)
  }

  useEffect(() => {
    setServiceToSubmit({ ...(object ?? defaultService!) })
  }, [object])

  //form error handling variables
  const [employeeError, setEmployeeError] = useState(false);
  const [customerError, setCustomerError] = useState(false);
  const [titleError, setTtitleError] = useState(false);
  const [dateStartError, setDateStartError] = useState(false);

  function validateInput(service: IServiceDTO | IService) {
    if (service.employeeDatas.length === 0) {
      setEmployeeError(true);
    } else {
      setEmployeeError(false);
    }

    if (!service.clientOrganizationId || service.clientOrganizationId <= 0) {
      setCustomerError(true);
    } else {
      setCustomerError(false);
    }

    if (service.title.length === 0) {
      setTtitleError(true);
    } else {
      setTtitleError(false);
    }

    const from = dayjs(service.employeeDatas[0].from);
    const to = dayjs(service.employeeDatas[0].to);

    if (from.isAfter(to)) {
      setDateStartError(true);
    } else {
      setDateStartError(false);
    }

    if (
      service.employeeDatas.length === 0 ||
      !service.clientOrganizationId ||
      service.clientOrganizationId <= 0 ||
      service.title.length === 0 ||
      from.isAfter(to)
    ) {
      return false;
    }
    return true;
  }

  return (
    <Modal
      open={open}
      title={mode == "create" ? 'Leistung erstellen' : "Leistung bearbeiten"}
      onClose={handleClose}
    >
      {/* TravelForm als eigene Komp. */}
      <CreateServiceForm
        object={serviceToSubmit}
        setObject={setServiceToSubmit}
        errorVariables={{ employeeError, customerError, titleError, dateStartError }}
      />
      <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center", gap: "10px", marginBottom: "1em" }}>
        {serviceToSubmit?.documents?.map((element, index) => {
          console.log(element)

          return <div style={{ display: "flex", flexDirection: "column", width: "100%", justifyContent: "center", alignItems: "center", gap: "10px" }}>{element.sequenceNumber == -1000 && <h3 style={{ marginBottom: "0" }}>Vorher:</h3>}{element.sequenceNumber == 1 && <h3 style={{ marginBottom: "0" }}>Nachher:</h3>}<p>Bild {index}: {element.title}</p><img style={{ width: "80%" }} src={element.base64} /></div>
        })}
      </div>
      <CTAButton
        title={loading ? <CircularProgress size={18} color='inherit' /> : mode == "create" ? "Erstellen" : "Speichern"}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
      />
    </Modal>
  )
}

export default EditCreateServiceDialog