import { IPerson, IPersonDTO } from "../../../Contexts/ContactsContext/ContactsProvider"
import styles from "./PersonToTagRow.module.css"

interface IPersonToTagRow {
    person: IPerson
    index: number
    handleSelect: (id: number) => void
    rowIsChecked: (id: number) => boolean
}

function PersonToTagRow({ person, index, handleSelect, rowIsChecked }: IPersonToTagRow) {
    return (
        <div>

            <label className={styles.row} htmlFor={`checkboxPersonToTag-${person.id}`}>
                <input className={styles.checkbox} type="checkbox" id={`checkboxPersonToTag-${person.id}`} onChange={() => handleSelect(person.id)} checked={rowIsChecked(person.id)}/>
                <div className={styles.completeNameContainer}>{person.firstname} {person.lastname}</div>
            </label>
        </div>
    )
}

export default PersonToTagRow