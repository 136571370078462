import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import LiveTicker from '../../Components/LiveTicker/LiveTicker'
import CurrentMonthStatsCard from '../../Components/StatsCards/CurrentMonthStatsCard/CurrentMonthStatsCard'

function CompanyStats() {
  return (
    <>
      <CardColumn
        width='100%'
        height='98%'
      >
        <CurrentMonthStatsCard
          height='100%'
        />
      </CardColumn>
      <LiveTicker></LiveTicker>
    </>

  )
}

export default CompanyStats