import { createTheme, ThemeProvider } from '@mui/material'
import React, { ReactNode } from 'react'

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: "var(--light)",
          borderRadius: "var(--borderRadius)",
          border: "none",
          outline: "none"
        },
        
      }
    }
  }
})



const MUIThemeProvider = ({children} : {children: ReactNode}) => {
  return (
    <ThemeProvider
    theme={theme}
    >
      {children}
    </ThemeProvider>
  )
}

export default MUIThemeProvider