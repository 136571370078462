/*  
    Here you can add products to the shop. 
    The "modularPrice" flag tells the program if it should consider thresholds for the prices. 
    The "singularIncrease" flag tells the program if there can be more than one of a product in the shopping cart (Prices will stack normally).
    If the "singularIncrease" flag is set, only the first price in the array is used.
    It is not allowed to set "modularPrice" and "singularIncrease" to true at the same time
*/
const productsArray = [
    {
        id: "price_1NY1k9CJ5DwX1VoaMYzGlXOd",
        title: "eCockpit Business Pro",
        prices: [
            { threshold: 0, price: 89.00 },
        ],
        modularPrice: false,
        singularIncrease: false,
        description: '1. Lorem ipsum dolor sit amet,\n2. consetetur sadipscing elitr,\n3. sed diam nonumy eirmod tempor\n4. invidunt ut labore et dolore magna\n5. aliquyam erat, sed diam voluptua.\n6. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1jACJ5DwX1VoaLXwzVgvN",
        title: "eCockpit Business",
        prices: [
            { threshold: 0, price: 49.00 }
        ],
        modularPrice: false,
        singularIncrease: false,
        description: '2Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1i9CJ5DwX1VoaxTYeQxr2",
        title: "Intervallrechnungen",
        prices: [
            { threshold: 0, price: 9.00 }
        ],
        modularPrice: false,
        singularIncrease: false,
        description: '3Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1hoCJ5DwX1Voal3Pygt7t",
        title: "Rechnungswesen",
        prices: [
            { threshold: 0, price: 9.00 }
        ],
        modularPrice: false,
        singularIncrease: false,
        description: '4Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1hXCJ5DwX1VoaaVsYG9Af",
        title: "Newsletter",
        prices: [
            { threshold: 0, price: 19.00 }
        ],
        modularPrice: false,
        singularIncrease: false,
        description: '5Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1h8CJ5DwX1VoazhAdfxuJ",
        title: "Kalender",
        prices: [
            { threshold: 0, price: 9.00 }
        ],
        modularPrice: false,
        singularIncrease: false,
        description: '6Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1gkCJ5DwX1VoaB27aAvRk",
        title: "Aufgabenverwaltung und Leistungserfassung",
        prices: [
            { threshold: 0, price: 9.00 }
        ],
        modularPrice: false,
        singularIncrease: false,
        description: '7Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1qUCJ5DwX1VoauPw25Tbp",
        title: "Extra User",
        prices: [
            { threshold: 0, price: 12.00 }
        ],
        modularPrice: false,
        singularIncrease: true,
        description: '8Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    },
    {
        id: "price_1NY1s0CJ5DwX1VoajoOh5Whb",
        title: "Newsletter Kontingent pro 1k",
        prices: [
            { threshold: 0, price: 1.50 }
        ],
        modularPrice: false,
        singularIncrease: true,
        description: '9Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et.'
    }
]


function getProductData(id: string) {
    let productData = productsArray.find(product => product.id === id);

    if (productData == undefined) {
        console.log("Product data does not exist for ID: " + id);
        return {
            id: "0",
            title: "undefinded",
            prices: {
                threshold: 0,
                price: 0,
            },
            modularPrice: false,
            singularIncrease: false,
            description: ''
        };
    }

    return productData;
}

function getCorrectUserPrice(quant: number, id: string) {
    // find the product
    const product = productsArray.find(product => product.id === id);

    // if product does not exist
    if (!product) return 0;

    let correctPrice = 0;

    if (product.modularPrice === false) {
        correctPrice = product.prices[0].price;
    } else {
        // iterate over prices array
        for (let i = 0; i < product.prices.length; i++) {
            // if quantity is greater than or equal to the current threshold
            // update the correct price and move to the next one
            if (quant >= product.prices[i].threshold) {
                correctPrice = product.prices[i].price;
            } else {
                // if quantity is less than the current threshold
                // we've found the correct price, so we can break the loop
                break;
            }
        }
    }

    return correctPrice;
}


export { productsArray, getProductData, getCorrectUserPrice };