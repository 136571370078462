// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationToTagRow_row__I5GNq {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    padding: 12px;\n}\n\n.OrganizationToTagRow_row__I5GNq:hover {\n    background-color: var(--light) !important;\n    transition: background-color 0.3s ease !important;\n}\n\n.OrganizationToTagRow_checkbox__Tzmx8 {\n    height: 16px;\n    width: 16px;\n    margin: 0px 8px 0px 4px;\n    accent-color: var(--brand);\n}\n\n.OrganizationToTagRow_nameContainer__LBl0J {\n    width: calc(100% - 24px);\n}", "",{"version":3,"sources":["webpack://./src/Pages/Kontakte/Tags/OrganizationToTagRow.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,WAAW;IACX,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,yCAAyC;IACzC,iDAAiD;AACrD;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,uBAAuB;IACvB,0BAA0B;AAC9B;;AAEA;IACI,wBAAwB;AAC5B","sourcesContent":[".row {\n    display: flex;\n    width: 100%;\n    align-items: center;\n    padding: 12px;\n}\n\n.row:hover {\n    background-color: var(--light) !important;\n    transition: background-color 0.3s ease !important;\n}\n\n.checkbox {\n    height: 16px;\n    width: 16px;\n    margin: 0px 8px 0px 4px;\n    accent-color: var(--brand);\n}\n\n.nameContainer {\n    width: calc(100% - 24px);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": "OrganizationToTagRow_row__I5GNq",
	"checkbox": "OrganizationToTagRow_checkbox__Tzmx8",
	"nameContainer": "OrganizationToTagRow_nameContainer__LBl0J"
};
export default ___CSS_LOADER_EXPORT___;
