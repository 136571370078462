import { AddRounded, BusinessCenterRounded, RefreshRounded, SearchRounded, SettingsRounded } from '@mui/icons-material'
import { CircularProgress, IconButton, Tooltip } from '@mui/material'
import { AxiosResponse } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useJournal } from '../../Contexts/JournalContext/JournalProvider'
import { useStaff } from '../../Contexts/StaffContext/StaffProvider'
import { card } from '../../Interfaces/Card'
import { journalDTO } from '../../Interfaces/Journal'
import ButtonControl from '../Buttons/ButtonControl/ButtonControl'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'
import Card from '../Card/Card'
import JournalEntry from '../Journal/JournalEntry'
import SearchBar from '../SearchBar/SearchBar'
import Filter from './Filter'

interface IJournalCard extends card {
}

function JournalCard({ width, height, order }: IJournalCard) {

  //später, um das LOG einzublenden
  const [mode, setMode] = useState<"admin" | "normal">("normal")

  //Suchwort, wenn der Suchbutton geklickt wird kann es eingegeben werden
  const [keyword, setKeyword] = useState<string>("");

  //staff sind Daten, nicht Personal
  const { staff, fetchStaff } = useStaff();

  //Alle Journaleinträge
  const { entries, entriesWithFilter, journalFilter, setJournalFilter, clearJournalWithFilter, fetchJournal, fetchJournalPaginated, fetchJournalPaginatedWithFilter, wasLastList } = useJournal();
  //const {journalDTO, setJournalDTO} = ();

  const { changeVisibility } = useModals();

  //Wieder die Unterscheidung Journal oder LOG
  const buttons = [
    {
      body: (active: boolean) => active ? <span className='centered' style={{ gap: "5px" }}><BusinessCenterRounded fontSize='small' /> Aktivität</span> : <BusinessCenterRounded fontSize='small' />,
      value: "normal"
    },
    {
      body: (active: boolean) => active ? <span className='centered' style={{ gap: "5px" }}><SettingsRounded fontSize='small' />Logs</span> : <SettingsRounded fontSize='small' />,
      value: "admin"
    }
  ]

  /* Start Pagination */
  const [currPage, setCurrPage] = useState(0); // storing current page number
  const [prevPage, setPrevPage] = useState(-1); // storing prev page number
  const [userList, setUserList] = useState<any>([]); // storing list
  const [wasLastFilteredList, setWasLastFilteredList] = useState(false); // setting a flag to know the last list

  //das ist der Ladekreis... wenn loading auf true, dann Anfrage um die nächsten 10 Einträge und Kreis dreht sich.
  const [loading, setLoading] = useState(false)

  //Hält eine Referenz zu einer HTML-Komponenten, das in der Card der Body ist...
  const listInnerRef = useRef<HTMLDivElement>(null);

  useEffect(() => { console.log("EntriesWithFilter", entriesWithFilter) }, [entriesWithFilter])

  //Wenn man nach unten scrollt...
  const onScroll = () => {
    if (listInnerRef.current) { //Cursor über d. Liste (i.d. Card)
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current; //liest die drei Variablen aus dem Objekt aus

      if (scrollTop + clientHeight >= scrollHeight) { //Wenn ganz runtergescrollt ist, dann lade!
        if (!loading && !wasLastFilteredList && (((journalFilter?.companyIds?.length ?? 0 > 0) || (journalFilter?.personIds?.length ?? 0 > 0) || (keyword && keyword.length > 0)))) {
          setLoading(true);
          fetchJournalPaginatedWithFilter(currPage, 10, keyword, journalFilter?.personIds, journalFilter?.companyIds)
            .then((result: AxiosResponse<Array<journalDTO>>) => {
              setPrevPage((old: number) => old + 1);
              setCurrPage((old: number) => old + 1);

              //setUserList((old: Array<journalDTO>) => ([...old, ...result.data]))

              /*if ((result.data?.length ?? 0) < 10) {
                setWasLastFilteredList(true);
              }*/

              setLoading(false);
            })
        } else if (!loading && !wasLastList && !((((journalFilter?.companyIds?.length ?? 0 > 0) || (journalFilter?.personIds?.length ?? 0 > 0) || (keyword && keyword.length > 0))))) {
          setLoading(true); //nur der Ladekreis...
          fetchJournalPaginated()//läd die Einträge
            .then((result: AxiosResponse<Array<journalDTO>>) => {
              setLoading(false);
            });
        }
      }
    }
  };

  useEffect(() => {
    clearTimeout(timerID);
    const id = setTimeout(() => {
      setLoading(true);
      //setUserList([]);
      fetchJournalPaginatedWithFilter(0, 10, keyword, journalFilter?.personIds, journalFilter?.companyIds)
        .then((result: AxiosResponse<Array<journalDTO>>) => {
          setPrevPage((old: number) => 0);
          setCurrPage((old: number) => 1);

          //setUserList((old: Array<journalDTO>) => ([...result.data]))

          if ((result.data?.length ?? 0) < 10) {
            setWasLastFilteredList(true);
          }

          setLoading(false);

        })
    }, 500);
    setTimerID(id)

  }, [keyword, journalFilter])

  useEffect(() => {
    //setUserList([]);
    if (clearJournalWithFilter && journalFilter?.personIds?.length === 0 && journalFilter?.companyIds?.length === 0) {
      clearJournalWithFilter();
    }
    console.log("JournalFilter", journalFilter)
  }, [journalFilter])


  useEffect(() => {
    setCurrPage(0);
    setPrevPage(-1);
    //setUserList([]);
    setWasLastFilteredList(false);
  }, [journalFilter])

  useEffect(() => {
    console.log("1", (!wasLastFilteredList && prevPage !== currPage), "2", (((journalFilter?.companyIds?.length ?? 0 > 0) || (journalFilter?.personIds?.length ?? 0 > 0) || (keyword && keyword.length > 0)) && (!wasLastFilteredList)))
    console.log("JournalFilter changed", journalFilter)
    if (!loading) {
      onScroll();
    }
  }, [journalFilter]);
  /* End Pagination attempt */

  //Regelt das Öffnen und Schließen der Expandable Section der ServiceCard
  const handleExpand = (prevExpanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>>) => {
    //Beim Schließen der Suchleiste wird der Suchwert zurückgesetzt und alle Leistungen wieder angezeigt
    if (prevExpanded) {
      setKeyword("")
    }
    setExpanded((val) => !val)
  }

  const [timerID, setTimerID] = useState<any>(null);

  return (
    <Card
      title='Journal'
      style={{ width, height, order }}
      left={<IconTooltipButton title='Aktualisieren' onClick={() => { fetchJournal!(); setWasLastFilteredList(false); }}><RefreshRounded fontSize='small' /></IconTooltipButton>}
      //expandedElement={<Filter />}
      //right={() => <ButtonControl setValue={(val) => setMode(val)} value={mode} buttons={buttons} />}
      right={({ expanded, setExpanded }) =>
        <Tooltip title="Leistungen suchen">
          <IconButton style={{ backgroundColor: expanded ? "var(--brand)" : undefined, color: expanded ? "white" : undefined }} onClick={() => { handleExpand(expanded, setExpanded); setWasLastFilteredList(false); }}><SearchRounded /></IconButton>
        </Tooltip>}
      expandedElement={<SearchBar value={keyword} onChangeHandler={(val: string) => {
        //clearTimeout(timerID);
        setKeyword(val);
        //const id = setTimeout(() => { setCurrPage(0); setPrevPage(-1); setUserList([]); setWasLastFilteredList(false); setLoading(true); }, 500);
        //setTimerID(id)
      }} />}

      cta
      ctaTitle='Eintrag erstellen'
      buttonIcon={<AddRounded fontSize='small' />}
      ctaFunction={() => {
        changeVisibility!("editCreateJournal", true, { props: { mode: "create" } });

      }}
      onScroll={onScroll}
      innerListRef={listInnerRef}

    >
      <div
        className='flex column'
        style={{ gap: "0px", overflowY: "auto" }}
      >
        {(!((journalFilter?.companyIds?.length ?? 0 > 0) || (journalFilter?.personIds?.length ?? 0 > 0) || (keyword && keyword.length > 0))) && entries?.map((entry: journalDTO) => {
          return (<JournalEntry key={entry.id} journal={entry} />)
        }
        )}

        {((journalFilter?.companyIds?.length ?? 0 > 0) || (journalFilter?.personIds?.length ?? 0 > 0) || (keyword && keyword.length > 0)) && entriesWithFilter?.map((entry: journalDTO) => {
          const tempAuthorName = staff.find(temp => temp.id === entry.author)?.username

          return (<JournalEntry key={entry.id} journal={{ ...entry, authorName: tempAuthorName }} />)
        }
        )}
        {keyword.length !== 0 && userList.length === 0 && !loading &&
          <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>Es konnten keine Journaleinträge zu {keyword} gefunden werden</div>
        }
      </div>
      {loading &&
        <div style={{ width: "100%", display: "flex", justifyContent: "center", paddingTop: "2vw", paddingBottom: "1vw" }}><CircularProgress color="inherit" /></div>
      }
    </Card>
  )
}

export default JournalCard