const patternRechnungsnummer = /(Rechnungs-Nr\.|Beleg Nr\.|Rechnung Nr\.|Rechnungsnummer:\s*)([\w-]+)|(ATU\s*\d*)|(RE[- ]\d+[- ]\d+)/g;
const patternDatum = /(0?[1-9]|[12][0-9]|3[01])[.,](0?[1-9]|1[012])[.,]\d{4}/g;
const patternSteuer = /\d{1,2}(?:,\d{2})?%/g;
let firmenListe;

//These 3 make one together, i dont know why but if you add them together it does not work
const noPrecedingNumber = /(?<![.,\d%])/;
const numericFormat = /\d{1,3}(?:[.,]\d{3})*(?:[.,]\d{2})/;
const noTrailingDotComma = /(?![.,])/;
const patternBetrag = new RegExp(
  /-?\d{1,10}(?:[.,]\d{3})*(?:[.,]\d{2})(?!\s*%)/,
  'g'
);

const text = `Nico Alexander Wiunig
AHK Softwaregutachten.at
Völkermarkter Straße 62
9020 Klagenfurt am Wörthersee Rechnungsdatum: 15.09.2023
Leistungszeitraum: 17.07-15.09.2023

Rechnung Nr. RE-230004
Sehr geehrte Damen und Herren,
vielen Dank für Ihren Auftrag und das damit verbundene Vertrauen!
Hiermit stellen wir Ihnen auf der Seite 2 ff angeführte Leistungen zu folgendem Gesamtbetrag in Rechnung::

Gesamtbetrag netto 1400,00 EUR

Der Rechnungsbeitrag enthält gem. 56 Abs. 1227 UStG 1984 keine

Umsatzsteuer

Gesamtbetrag brutto 1400,00 EUR
Bitte überweisen Sie den Rechnungsbetrag unter Angabe der Rechnungsnummer auf das unten
angegebene Konto.
Mit freundlichen Grüßen
Nico Wiunig`;

//contains all funcions to find the informations from the string
export const processInvoice = (invoice, idx, companyData) => {
  //DEBUGGING
  //invoice = text;
  //END DEBUGGING

  firmenListe = companyData.companies;

  // for(let entry of firmenListe){
  //   console.log(entry.name);
  // }

  let invoicesString = "";
  let invoiceObj = createInvoiceObj();

  //Splits the Rechnungskreis from the Rechnungsnummer ("ATU1245324" => "ATU","1245324")
  function splitRechnungskreisFromNummer() {
    const splitPattern = /(RE|ATU|Rechnungsnummer:)\s*([\d-]+)/g;

    let match;

    while ((match = splitPattern.exec(invoicesString))) {
      const [, invoiceType, invoiceNumber] = match;

      if (invoiceType.length <= 4) {
        invoiceObj.Rechnungskreis = invoiceType;
        invoiceObj.Rechnungsnummer = invoiceNumber;
      } else {
        invoiceObj.Rechnungsnummer = invoiceNumber;
      }
    }
  }

  //finds the whole Rechnungsnummer (ATU1245324)
  const findInvoiceNumber = (invoice) => {
    const matches = invoice.match(patternRechnungsnummer);
    if (matches) {
      const invoiceNumber = matches[0];
      invoicesString += invoiceNumber + "\n";
      splitRechnungskreisFromNummer();
    }
  };

  //finds the tax with the percentage ("20%")
  const findInvoiceSteuer = (invoice) => {
    const matches = invoice.match(patternSteuer);
    if (matches) {
      let splitPercentages = [];
      splitPercentages = getPercentages(matches);

      invoiceObj.MittelwertSteuer = meanWithConversion(matches);
    }
  }

  //finds the Invoice date (Rechnungsdatum)
  const findInvoiceDate = (invoice) => {
    const matches = invoice.match(patternDatum);
    console.log("Rechnungsdaten", matches)
    if (matches) {
      let invoiceDate = matches[0];
      invoiceObj.Rechnungsdatum = new Date(invoiceDate.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$2.$1.$3')).toISOString();
      for (let match of matches) {
        invoice = invoice.replace(match, "").trim();
      }
    }
    invoice = invoice.replace(patternDatum, '')
    return invoice;
  };

  //finds all the prices on the invoice
  const findInvoiceBetraege = (invoice) => {
    const matches = invoice.match(patternBetrag);
    if (matches) {
      const betraege = matches.map((match) =>
        match.replace(/[.,]/g, "").replace(",", "."));

      //make price with only 2 decimals
      const betraegeMitDezimalstellen = betraege.map((betrag) => {
        const dezimalBetrag = (parseFloat(betrag) / 100).toFixed(2);
        return dezimalBetrag.replace(".", ",");
      });

      // Find the highest amount
      const maxBetrag = Math.max(
        ...betraegeMitDezimalstellen.map((betrag) => parseFloat(betrag.replace(",", ".")))
      );

      const nettoLowerBound = maxBetrag / 1.25;
      let nettoValue = maxBetrag;

      for (const value of betraegeMitDezimalstellen) {
        const numericValue = parseFloat(value.replace(',', '.'));
        console.log("numericValue", value, "parseFloat", numericValue)
        if (numericValue >= nettoLowerBound && numericValue <= maxBetrag) {
          nettoValue = numericValue;
          break; // Exit the loop once a netto value is found
        }
      }

      //all the values get set for Brutto, Netto, Steuer, Beträge
      //parseInt(maxBetrag)
      //parseInt(nettoValue)
      invoiceObj.Brutto = maxBetrag.toFixed(2).replace(".", ",");
      console.log("nettoValue", nettoValue)
      invoiceObj.Netto = nettoValue.toFixed(2).replace(".", ",");
      invoiceObj.Steuer = (((parseFloat(maxBetrag) - parseFloat(nettoValue)) / parseFloat(nettoValue)) * 100).toFixed(2).replace(".", ",");
      invoiceObj.Betraege = betraegeMitDezimalstellen;
    }
  };

  const findDuplicateWordForNames = (invoice) => {
    const words = invoice.split(/\s+/); // Split the string into words using whitespace as delimiter
    const wordCount = {};
    const duplicates = [];

    words.forEach(word => {
      const lowercaseWord = word.toLowerCase(); // Convert the word to lowercase to ensure case-insensitive comparison
      if (!wordCount[lowercaseWord]) {
        wordCount[lowercaseWord] = 1;
      } else {
        wordCount[lowercaseWord]++;
        if (wordCount[lowercaseWord] === 2) {
          duplicates.push(lowercaseWord);
        }
      }
    });

    invoiceObj.Duplicates = duplicates;
  }

  /*const findMatchingPairs = (betraege) => {
    if (invoiceObj.Betraege.length == 0) {
      return;
    }

    const matchingPairs = [];

    for (let i = 0; i < betraege.length; i++) {
      for (let j = i + 1; j < betraege.length; j++) {
        const sum = parseFloat(betraege[i].replace(",", ".")) + parseFloat(betraege[j].replace(",", "."));

        // Check if the rounded sum (to 2 decimal places) exists in the array
        if (betraege.some(value => parseFloat(value.replace(",", ".")) === parseFloat(sum.toFixed(2)))) {
          matchingPairs.push({
            value1: betraege[i],
            value2: betraege[j],
            sum: sum.toFixed(2),
          });
        }
      }
    }
    let highestSum = 0;
    let counter = 0;
    let bestCounter = 0;

    for (let match of matchingPairs) {
      if (parseInt(match.sum) > parseInt(highestSum)) {
        highestSum = match.sum;
        bestCounter = counter;
      }
      counter++;
    }
    if (matchingPairs.length != 0) {
      invoiceObj.Brutto = matchingPairs[bestCounter].sum;
      invoiceObj.Steuer = matchingPairs[bestCounter].value2;
      invoiceObj.Netto = matchingPairs[bestCounter].value1;
      invoiceObj.pairs = matchingPairs;
    } else {
      invoiceObj.pairs = [];
    }
  }*/


  //just adds the Name to the Invoice Object
  /*function addNames() {
    for (let entry of firmenListe) {
      if (invoice.includes(entry.name)) {
        invoiceObj.Name = entry;
      }
    }
  }*/

  function findOrganization() {
    for (let entry of firmenListe) {
      if (invoice.includes(entry.name) && entry.id !== idx) {
        invoiceObj.Name = entry;
      }
    }
  }

  // Process each invoice
  //addNames(idx);
  invoice = findInvoiceDate(invoice);
  findInvoiceSteuer(invoice);
  findInvoiceNumber(invoice);
  //findMatchingPairs(invoiceObj.Betraege);
  findOrganization();
  findInvoiceBetraege(invoice);
  //findDuplicateWordForNames(invoice); 
  return invoiceObj;
};


function createInvoiceObj() {
  return {
    Name: "",
    Rechnungskreis: "",
    Rechnungsnummer: "",
    Rechnungsdatum: "",
    Betraege: [],
    Brutto: "",
    Netto: "",
    Steuer: "",
  };
}

//Creates the mean of the percentages in the Invoice
function meanWithConversion(percentages) {
  let sum = 0;
  let count = 0;

  for (let i = 0; i < percentages.length; i++) {
    const value = parseInt(percentages[i], 10); // Convert the element to an integer (base 10)

    // Check if the conversion was successful (not NaN)
    if (!isNaN(value) && value < 30) {
      sum += value;
      count++;
    }
  }

  if (count === 0) {
    throw new Error("No valid numeric values in the array.");
  }

  return sum / count;
}


//Takes the percentage String ("20%") and splits it at the "%" so that you only have the values
function getPercentages(matches) {
  //checks if its an array
  if (!Array.isArray(matches)) {
    throw new Error("Input must be an array.");
  }

  const result = [];
  //goes through the percentage array
  for (let i = 0; i < matches.length; i++) {
    if (typeof matches[i] === "string") {
      result.push(matches[i].split("%")[0]);
    } else {
      // If the entry is not a string, simply add it as it is to the result array.
      result.push(matches[i]);
    }
  }
  return result;
}