// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ButtonControl_wrapper__HJURV > button:first-child {\n    border-radius: 10px 0 0 10px;\n}\n.ButtonControl_wrapper__HJURV > button:last-child {\n    border-radius: 0 10px 10px 0;\n}\n\n.ButtonControl_wrapper__HJURV {\n    gap: 2px;\n}\n\n.ButtonControl_button__-2bos {\n    background-color: transparent;\n    border: none;\n    padding: 5px 9px;\n    transition: background-color .2s;\n}\n\n.ButtonControl_button__-2bos:hover {\n    background-color: var(--light);\n}\n\n.ButtonControl_active__YsmGc {\n    background-color: var(--brand-light);\n    color: var(--brand);\n}\n\n.ButtonControl_active__YsmGc:hover {\n    background-color: var(--brand-light);\n}", "",{"version":3,"sources":["webpack://./src/Components/Buttons/ButtonControl/ButtonControl.module.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;AACA;IACI,4BAA4B;AAChC;;AAEA;IACI,QAAQ;AACZ;;AAEA;IACI,6BAA6B;IAC7B,YAAY;IACZ,gBAAgB;IAChB,gCAAgC;AACpC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,oCAAoC;IACpC,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;AACxC","sourcesContent":[".wrapper > button:first-child {\n    border-radius: 10px 0 0 10px;\n}\n.wrapper > button:last-child {\n    border-radius: 0 10px 10px 0;\n}\n\n.wrapper {\n    gap: 2px;\n}\n\n.button {\n    background-color: transparent;\n    border: none;\n    padding: 5px 9px;\n    transition: background-color .2s;\n}\n\n.button:hover {\n    background-color: var(--light);\n}\n\n.active {\n    background-color: var(--brand-light);\n    color: var(--brand);\n}\n\n.active:hover {\n    background-color: var(--brand-light);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "ButtonControl_wrapper__HJURV",
	"button": "ButtonControl_button__-2bos",
	"active": "ButtonControl_active__YsmGc"
};
export default ___CSS_LOADER_EXPORT___;
