import { dialog } from "../CreateServiceDialog/CreateServiceDialog"
import { User } from '../../../Interfaces/User';
import { useModals } from "../../../Contexts/DialogContext/DialogProvider";
import { useEffect, useState } from "react";
import Modal from "../../Dialog/Dialog";
import InputField from "../../InputField/InputField";
import { CircularProgress } from "@mui/material";
import { ArrowForward } from "@mui/icons-material";
import { useUser } from "../../../Contexts/UserContext/UserContext";
import { authConnection } from "../../../Connection/BaseConnection";

export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

const EditEmailDialog = ({ open, onClose, object, mode }: dialogWithProps<User>) => {
    const { changeVisibility } = useModals();

    const { user } = useUser();

    const [loading, setLoading] = useState(false);
    const [continueButtonDisabled, setContinueButtonDisabled] = useState(true);
    const [passwordVerified, setPasswordVerified] = useState(false);
    const [verificationFailed, setVerifcationFailed] = useState(false);
    const [errorEmail, setErrorEmail] = useState(false);
    const [continueButtonEmailDisabled, setContinueButtonEmailDisabled] = useState(true);
    const [emailLength, setEmailLength] = useState(0);
    const [emailSent, setEmailSent] = useState(false);
    const [continueButtonVerificationDisabled, setContinueButtonVerificationDisabled] = useState(true);
    const [changeSuccess, setChangeSuccess] = useState(false);



    const [password, setPassword] = useState("");
    const [email, setEmail] = useState("");
    const [verificationCode, setVerificationCode] = useState("");


    const handleClose = () => {
        setLoading(false);
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editEmail", false);



        setTimeout(() => {
            //resetData
            setPasswordVerified(false)
            setPassword("");
            setEmail("");
            setVerificationCode("");
            setVerifcationFailed(false);
            setErrorEmail(false);
            setContinueButtonEmailDisabled(true);
            setContinueButtonVerificationDisabled(true);
            setEmailLength(0);
            setEmailSent(false);
            setChangeSuccess(false);
        }, 250)
    }




    useEffect(() => {

        if (password.length > 0) {
            setContinueButtonDisabled(false);
        }
        else {
            setContinueButtonDisabled(true);
        }

    }, [password])

    useEffect(() => {

        if (email.length > 0) {
            setContinueButtonEmailDisabled(false);
        } else {
            setContinueButtonEmailDisabled(true);
        }

        if (email.length === 0 || email.length !== emailLength) {
            setErrorEmail(false);
        }

        setEmailLength(email.length);

    }, [email])

    useEffect(() => {
        if (verificationCode.length === 6) {
            setContinueButtonVerificationDisabled(false);
        } else {
            setContinueButtonVerificationDisabled(true);
        }
    }, [verificationCode])

    const verifyPassword = async () => {
        try {
            setLoading(true);
            const response = await authConnection.post("User/VerifyPassword", {
                requestString: password
            })

            if (response.status === 200) {
                setPasswordVerified(true);
                setVerifcationFailed(false);
            }
        }
        catch (error) {
            setVerifcationFailed(true);
        }
        finally {
            setLoading(false);
        }
    }

    const requestEmailChange = async () => {
        try {
            checkEmail(email);

            if (!errorEmail) {
                setLoading(true);
                const response = await authConnection.post("User/requestEmailUpdate", {
                    requestString: email
                })

                if (response.status === 200) {
                    setEmailSent(true);
                }
            }
        }
        catch (error) {
            console.log(error);
        }
        finally {
            setLoading(false);
        }
    }

    const changeEmail = async () => {
        try {
            setLoading(true);
            const response = await authConnection.post("User/updateEmail", {
                email: email,
                updateEmailCode: verificationCode
            })

            if (response.status === 200) {
                setChangeSuccess(true);
                user!.email = email;
            }
        }
        catch (error) {
            setVerifcationFailed(true);
        }
        finally {
            setLoading(false);
        }
    }

    function checkEmail(email: string) {
        const allowed = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (email == "") {
            setErrorEmail(false);
            return;
        }

        if (!allowed.test(email)) {
            setErrorEmail(true)
        } else {
            setErrorEmail(false)
        }
    }



    return (
        <div>
            <Modal
                open={open}
                title="Email Adresse bearbeiten"
                onClose={() => handleClose()}
            >
                {!passwordVerified &&
                    <div>

                        <h2 style={{ textAlign: "center" }}>
                            Verifizieren Sie ihr Passwort:
                        </h2>
                        <div style={{ textAlign: "center", marginBottom: "2vh" }}>
                            Geben Sie Ihr Passwort ein um fortzufahren
                        </div>

                        <div style={{ marginBottom: "2vh" }}>
                            <InputField
                                type="string"
                                placeholder="Passwort"
                                value={password}
                                isSecure={true}
                                onChangeHandler={(val) => setPassword(val)}
                                error={verificationFailed}
                                errorMessage="Falsches Passwort. Versuchen Sie es erneut"
                            />
                        </div>

                        <div>
                            {!continueButtonDisabled &&
                                <button onClick={async () => verifyPassword()} className="cta-button pointer" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                                </button>}

                            {continueButtonDisabled &&
                                <button disabled className="cta-button-disabled not-allowed" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>
                                        Weiter
                                        <ArrowForward fontSize='small' />
                                    </span>
                                    }
                                </button>
                            }
                        </div>

                    </div>
                }
                {passwordVerified && !emailSent &&
                    <div>
                        <h2 style={{ textAlign: "center" }}>
                            Ändern Sie Ihre Email Adresse
                        </h2>
                        <div style={{ marginBottom: "2vh" }}>
                            <InputField
                                type="string"
                                placeholder="Email"
                                value={email}
                                onChangeHandler={(val) => setEmail(val)}
                                error={errorEmail}
                                errorMessage="Geben Sie eine gültige Email Adresse ein"
                            />
                        </div>
                        <div>
                            {!continueButtonEmailDisabled &&
                                <button onClick={async () => requestEmailChange()} className="cta-button pointer" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                                </button>}

                            {continueButtonEmailDisabled &&
                                <button disabled className="cta-button-disabled not-allowed" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                    {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>
                                        Weiter
                                        <ArrowForward fontSize='small' />
                                    </span>
                                    }
                                </button>
                            }
                        </div>
                    </div>
                }

                {passwordVerified && emailSent && !changeSuccess &&
                    <div>
                        <h2 style={{ textAlign: "center" }}>
                            Wir haben Ihnen einen Code gesendet
                        </h2>
                        <div style={{ textAlign: "center", marginBottom: "2vh" }}>
                            Geben Sie den Code ein, um Ihre neue Email Adresse zu bestätigen.
                        </div>

                        <div style={{ marginBottom: "2vh" }}>
                            <InputField
                                type="string"
                                placeholder="Verfikationscode"
                                value={verificationCode}
                                onChangeHandler={(val) => setVerificationCode(val)}
                                error={verificationFailed}
                                errorMessage="Falscher Code. Versuchen Sie es erneut"
                            />
                        </div>
                        {!continueButtonVerificationDisabled &&
                            <button onClick={async () => changeEmail()} className="cta-button pointer" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>Weiter<ArrowForward fontSize='small' /></span> /*Konditionales Rendern*/}
                            </button>}

                        {continueButtonVerificationDisabled &&
                            <button disabled className="cta-button-disabled not-allowed" style={{ width: "40%", float: "right", marginBottom: "2vh" }}>
                                {loading ? <CircularProgress size={"12px"} color="inherit" /> : <span style={{ gap: "10px" }} className='flex centered'>
                                    Weiter
                                    <ArrowForward fontSize='small' />
                                </span>
                                }
                            </button>
                        }
                    </div>
                }

                {passwordVerified && emailSent && changeSuccess &&
                    <h2 style={{ textAlign: "center" }}>
                        Email erfolgreich geändert
                    </h2>
                }
            </Modal>
        </div>
    )
}

export default EditEmailDialog