import React, { useEffect, useState } from 'react'
import { card } from '../../Interfaces/Card'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'

/* Icons */
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded'
import StopRoundedIcon from '@mui/icons-material/StopRounded'
import PauseRoundedIcon from '@mui/icons-material/PauseRounded'
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';

import Card from '../Card/Card'
import styles from './RevenueExpenditureCard.module.css'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import BaseButton from '../Buttons/BaseButton/BaseButton'
import Tag from '../Tag/Tag'
import { useBills } from '../../Contexts/BillContext/BillProvider'

import { Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, LinearScale, PointElement, LineElement, Title, } from 'chart.js';
import { Bar, Doughnut, Line } from "react-chartjs-2";
import { Button, CircularProgress, LinearProgress } from '@mui/material'
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material'
import useAuthConnection from '../../Hooks/useAuthConnection'

ChartJS.register(CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend);

export const data = {
  labels: ['Einnahmen', 'Ausgaben'],
  datasets: [
    {
      label: '# of Votes',
      data: [12, 19],
      backgroundColor: [
        'rgba(0, 200, 0, 1)',
        'rgba(200, 0, 0, 1)',
      ],
      borderColor: [
        'rgba(0, 200, 0, 1)',
        'rgba(200, 0, 0, 1)',
      ],
      borderWidth: 1,
    },
  ],
};

function RevenueExpenditureMonthlyWithPredictionsCard({ width, height, order }: card) {

  const connection = useAuthConnection();

  const [dataFetched, setDataFetched] = useState(false);
  const [queryParams, setQueryParams] = useState({ calendarweek: getWeekNumber(new Date()), calendaryear: (new Date()).getFullYear() })
  const [loading, setLoading] = useState(false);

  const { accounting } = useBills();

  useEffect(() => {
    if (accounting) {
      setData(accounting.revenueExpenditure);
    }
  }, [accounting])

  const [data, setData] = useState({
    labels: [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
      "Sunday"
    ],
    datasets: [
      {
        label: "Arbeitsstunden",
        data: [
          0, 0, 0, 0, 0, 0, 0
        ],
        backgroundColor: [
          "rgba(24, 24, 245, 0.2)"
        ],
        borderColor: [
          "rgba(24, 24, 245, 1)"
        ],
        borderWidth: 1
      }
    ]
  });

  /*useEffect(() => {
      clearTimeout(timerID);
      const id = setTimeout(() => {
          if (open === true) {
              setLoading(true);
              connection.get(`/worktime/worktimegraph?calendarweek=${queryParams.calendarweek}&calendaryear=${queryParams.calendaryear}&forall=${forall}`).then((res: AxiosResponse) => { setData(res.data); setDataFetched(true); setLoading(false); })
          }
      }, 800);
      setTimerID(id)
  }, [queryParams])*/


  function getWeekNumber(date: Date) {
    var onejan = new Date(date.getFullYear(), 0, 1);
    var dayOfWeek = onejan.getDay();
    var daysInFirstWeek = 7 - dayOfWeek;
    var elapsedDays = (date.getTime() - onejan.getTime()) / (1000 * 60 * 60 * 24);
    var calendarWeekNumber = Math.ceil((elapsedDays - daysInFirstWeek) / 7) + 1; // add 1 to get the calendar week number

    return calendarWeekNumber;
  }

  const handleIncrement = () => {
    setLoading(true);
    setQueryParams((old: { calendarweek: number, calendaryear: number }) => ({ calendarweek: old.calendarweek + 1 > 52 ? 1 : old.calendarweek + 1, calendaryear: old.calendarweek + 1 > 52 ? old.calendaryear + 1 : old.calendaryear }))
  }

  const handleDecrement = () => {
    setLoading(true);
    setQueryParams((old: { calendarweek: number, calendaryear: number }) => ({ calendarweek: old.calendarweek - 1 < 1 ? 52 : old.calendarweek - 1, calendaryear: old.calendarweek - 1 < 1 ? old.calendaryear - 1 : old.calendaryear }))
  }

  const [timerID, setTimerID] = useState<any>(null);

  return (
    <Card
      style={{ height, width, order }}
      title="Offene Positionen"
    >
      {/*{loading &&
        <div style={{ position: "absolute", top: "50%", left: "50%", marginLeft: "-10px" }}>
          <CircularProgress />
        </div>
      }
      <div style={{ display: "flex", flexDirection: "column", height: "80%", justifyContent: "center", alignItems: "center" }}>
        <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
          <Line data={data} />
        </div>
    </div>*/}
      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "1em" }}>
        <span style={{ fontWeight: "600" }}>Umsatz</span>
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <LinearProgress style={{ height: "0.75em" }} variant="determinate" color='warning' value={((accounting?.profit! / accounting?.revenue!)) * 100} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>Bezahlt</span>
          <span style={{ fontWeight: "600" }}>Forderungen</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>{accounting?.profit?.toFixed(2)} €</span>
          <span style={{ fontWeight: "600" }}>{(accounting?.revenue! - accounting?.profit!).toFixed(2)} €</span>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", gap: "10px", marginTop: "1em" }}>
        <span style={{ fontWeight: "600" }}>Forderungen</span>
        <div style={{ paddingLeft: "1em", paddingRight: "1em" }}>
          <LinearProgress style={{ height: "0.75em" }} variant="determinate" color='error' value={accounting?.delayed === 0 ? 0 : ((accounting?.delayed! / (accounting?.revenue! - accounting?.profit!))) * 100} />
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>Im Verzug</span>
          <span style={{ fontWeight: "600" }}>Offen</span>
        </div>
        <div style={{ display: "flex", flexDirection: "row", width: "100%", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
          <span style={{ fontWeight: "600" }}>{accounting?.delayed?.toFixed(2)} €</span>
          <span style={{ fontWeight: "600" }}>{(accounting?.revenue! - accounting?.profit! - accounting?.delayed!).toFixed(2)} €</span>
        </div>
      </div>

    </Card>
  )
}

export default RevenueExpenditureMonthlyWithPredictionsCard