import { Switch, Tooltip } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { IOrganizationRole, useOrganizationRights } from "../../Contexts/OrganizationRightsContext/OrganizationRightsProvider";
import { IPerson, IPersonDTO, useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { StaffDTO, useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { useUser } from "../../Contexts/UserContext/UserContext";
import DesignSwitch from "../Buttons/DesignSwitch";
import Android12Switch from "../Buttons/Switch/Android12Switch";
import FormRow from "../Forms/FormUtils/FormRow";
import InputField from "../InputField/InputField";
import InputFieldRefactored, { InputFieldErrorMessages } from "../InputField/InputFieldRefactored";
import SearchableSelect from "../InputField/SearchableSelect";
import CreateEditEmploymentRelationForm from "./CreateEditEmploymentRelationForm";
import { IOrganization } from "../../Interfaces/Company";
import errorStyles from '../InputField/InputField.module.css'
import { userFormModes } from "../../Pages/Verwaltung/Personal/Personal";

interface props {
    staff: StaffDTO,
    setStaff: Dispatch<SetStateAction<StaffDTO>>,
    password: string,
    setPassword: Dispatch<SetStateAction<string>>,
    confirmPassword: string,
    setConfirmPassword: Dispatch<SetStateAction<string>>
    error: any
    formMode?: userFormModes
}

export default function CreateEditPersonalForm({ staff, setStaff, password, setPassword, confirmPassword, setConfirmPassword, error, formMode }: props) {
    const { persons, fetchPersons } = useContacts();
    const { organizationRoles } = useOrganizationRights();
    const { updateStaff } = useStaff();
    const [person, setPerson] = useState<IPerson | undefined>(staff.person);
    const { user } = useUser();

    useEffect(() => {
        if ((persons === undefined || persons.length <= 0) && (fetchPersons)) {
            fetchPersons();
        }
        setPerson((prev) => { return staff.person });
    }, [staff])

    const { companies } = useContacts();

    useEffect(() => {
        let organisationRoleUser = organizationRoles.find((role: IOrganizationRole) => role.organizationId === staff.organizationId && role.id === staff.organizationRoleId);
        if (organisationRoleUser) {
            setStaff((old: StaffDTO) => ({ ...old, organizationRoleId: organisationRoleUser?.id }))
        }
    }, [staff.organizationId])

    return (
        <form className='form'>
            {(user?.accessibleOrganisations?.length ?? 0) > 1 &&
                <FormRow
                    onChange={(val) => { setStaff((old: StaffDTO) => ({ ...old, organizationId: val })) }}
                    value={staff?.organizationId}
                    selectOptions={{
                        options: companies.filter((organization: IOrganization) => user?.accessibleOrganisations?.includes(organization.id ?? 0)),
                        titleKey: "name",
                        valueKey: "id"
                    }}
                    label="Zugeordnete Firma"
                    type="select"
                />
            }
            <div className='form-row-label'>

                <FormRow
                    label="Username"
                    type="text"
                    placeholder='Username (kann nach Erstellen nicht mehr geändert werden!)'
                    value={staff?.username}
                    onChange={(val) => setStaff((prev: StaffDTO) => ({ ...prev, username: val }))}
                    error={error?.usernameError}
                    errorMessage={InputFieldErrorMessages.UsernameError}
                    readonly={formMode === "editUser" ? true : false}
                />
                {error?.usernameAlreadyExists && <span className={errorStyles.error}>Nutzername bereits vergeben</span>}
            </div>
            <div>
                <FormRow
                    onChange={(val) => { setStaff((old: StaffDTO) => ({ ...old, organizationRoleId: val, employmentRelation: { ...old.employmentRelation, organizationRoleId: val } })) }}
                    value={staff?.organizationRoleId}
                    selectOptions={{
                        options: (user?.accessibleOrganisations?.length ?? 0) > 1 ? organizationRoles.filter((role: IOrganizationRole) => role.organizationId === staff.organizationId) : organizationRoles,
                        titleKey: "title",
                        valueKey: "id"
                    }}
                    label="Berechtigungsstufe"
                    type="select"
                    error={error?.organizationRoleError}
                    errorMessage={InputFieldErrorMessages.OrganizationRoleError}
                />
            </div>
            <div className='form-row-label'>
                <FormRow
                    type="text"
                    label="E-Mail"
                    placeholder='maxmustermann@ecockpit.at'
                    value={staff?.eMail}
                    onChange={(val) => setStaff((prev: StaffDTO) => ({ ...prev, eMail: val }))}
                    error={error?.eMailError}
                    errorMessage={InputFieldErrorMessages.EMailError}
                />
            </div>
            {formMode !== "editUser" &&
                <>
                    <div className='form-row-label'>
                        <FormRow
                            type="password"
                            placeholder='Passwort'
                            label="Passwort"
                            value={password}
                            onChange={(val) => setPassword(val)}
                            error={error?.passwordError || error?.passwordNotMatchError}
                            errorMessage={error.passwordError ? InputFieldErrorMessages.PasswordError : InputFieldErrorMessages.PasswordNotMatchError}
                            newPassword
                        />
                    </div>
                    <div className='form-row-label'>
                        <FormRow
                            type="password"
                            placeholder='Passwort'
                            label="Passwort bestätigen"
                            value={confirmPassword}
                            onChange={(val) => setConfirmPassword(val)}
                            error={error?.passwordError || error?.passwordNotMatchError}
                            errorMessage={error?.passwordError ? InputFieldErrorMessages.PasswordError : InputFieldErrorMessages.PasswordNotMatchError}
                        />
                    </div>
                </>}


            <div className='form-row-label'>
                <span className="label">
                    Person:
                </span>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <SearchableSelect
                        width="100%"
                        titleKey='completeName'
                        valueKey='id'
                        options={persons}
                        value={staff?.personId}
                        setValue={(val: any) => { setPerson(persons.find(person => person.id === val)); setStaff((prev: StaffDTO) => ({ ...prev, personId: val, person: persons.find(person => person.id === val) })); /*updateStaff!({ ...staff, personId: val, person: persons.find(person => person.id === val) });*/ }}
                        placeholder='Person auswählen'
                    />
                </div>
            </div>
            {(user?.rights?.readEmploymentRelation ?? false) &&
                <>
                    <h3 style={{ margin: 0, marginTop: 10 }}>Anstellung</h3>
                    <CreateEditEmploymentRelationForm setStaff={setStaff} staff={staff} />
                </>
            }
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Aktiv</span>
                <Android12Switch color="secondary" checked={!staff.active ? false : staff.active} onChange={(e, val) => setStaff((oldVal: StaffDTO) => ({ ...oldVal, active: val }))} />
            </div>
            {/* 
                <div className='form-row noselect'>
                    <span style={{ fontSize: "14px" }}>E-Mail Multi-Factor-Authentifizierung</span>
                    <span>
                        <Android12Switch color="secondary" checked={staff?.eMailMFA === undefined ? false : staff.eMailMFA} onChange={(e, val) => setStaff((oldVal: StaffDTO) => ({ ...oldVal, eMailMFA: val }))} />
                    </span>
                </div>
        */}
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Erhält Newsletter</span>
                <span>
                    <Android12Switch color="secondary" checked={!staff?.reveivesNewsletter ? false : staff.reveivesNewsletter} onChange={(e, val) => setStaff((oldVal: StaffDTO) => ({ ...oldVal, reveivesNewsletter: val }))} />
                </span>
            </div>
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Automatische Pause</span>
                <Tooltip placement="bottom" title="Wenn diese Einstellung aktiviert ist, werden Pausen automatisch für Arbeitszeiten erstellt, die länger als 6 Stunden sind">
                    <Android12Switch color="secondary" checked={!staff.automaticPause ? false : staff.automaticPause} onChange={(e, val) => setStaff((oldVal: StaffDTO) => ({ ...oldVal, automaticPause: val }))} />
                </Tooltip>
            </div>
            <div className='form-row noselect'>
                <span style={{ fontSize: "14px" }}>Nimmt Bilder für Leistungen auf</span>
                <Tooltip placement="bottom" title="Wenn diese Einstellung aktiviert ist, wird dem Nutzer automatisch aufgefordert, Fotos zu Leistungen zu machen">
                    <Android12Switch color="secondary" checked={!staff.takesPicturesForServices ? false : staff.takesPicturesForServices} onChange={(e, val) => setStaff((oldVal: StaffDTO) => ({ ...oldVal, takesPicturesForServices: val }))} />
                </Tooltip>
            </div>
        </form>
    )
}