import { CircularProgress } from '@mui/material'
import { useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useTimeTracking } from '../../../Contexts/TimeTrackingContext/TimeTrackingProvider'
import { useUser } from '../../../Contexts/UserContext/UserContext'
import { useSnackBar } from '../../../Hooks/useSnackBar'

import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'


export interface IYesNoModal<T> {
  open: boolean
  onClose?: () => void

  object?: {
    submitFunction?: () => void,
    cancelFunction?: () => void,
    mode?: string | null,
    modalTitle?: string,
    cancelButtonText?: string,
    confirmButtonText?: string,
    modalText?: string
  }
}


function YesNoDialog({ open, onClose, object }: IYesNoModal<any>) {
  const { changeVisibility } = useModals()
  const { enqueueSnackbar } = useSnackBar()
  const { user } = useUser()
  const { postscriptPause, postscriptWork, editWorktime } = useTimeTracking()


  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)

  const handleSubmit = async () => {
    try {
      if (object?.submitFunction) {
        object?.submitFunction();
      }
      handleClose()
    } catch (error) {
      enqueueSnackbar(`Fehler beim Nachtragen.`, { variant: "error" })
      console.error(error)
    } finally {
      setLoading(false)
    }

  }

  const handleCancel = async () => {
    try {
      handleClose();
      if (object?.cancelFunction) {
        object?.cancelFunction();
      }

    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }

  const handleClose = async () => {
    setLoading(false)
    if (onClose) {
      onClose()
    }
    changeVisibility!("yesNoDialog", false)
  }

  return (
    <Modal
      open={open}
      title={object?.modalTitle ?? "Wollen Sie diese Aktion wirklich durchführen?"}
      onClose={handleClose}
    >
      {object?.modalText &&
        <div style={{ textAlign: "center", marginBottom: "2vh" }}>
          {object.modalText}
        </div>
      }
      <div style={{ display: "flex" }}>
        <CTAButton
          title={loading ? <CircularProgress size={18} color='inherit' /> : (object?.cancelButtonText ?? "Abbrechen")}
          onClickHandler={() => handleCancel()}
          disabled={loading}
        />
        <CTAButton
          style={{
            marginLeft: "2vh",

          }}
          title={loading ? <CircularProgress size={18} color='inherit' /> : (object?.confirmButtonText ?? "Bestätigen")}
          onClickHandler={() => handleSubmit()}
          disabled={loading}
        />
      </div>

    </Modal>
  )
}

export default YesNoDialog