import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { IService, IServiceDTO, serviceWithNames } from '../../Interfaces/Service'
import styles from './Service.module.css'

//Icons
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { Collapse } from '@mui/material';
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton';
import { KeyboardArrowUpRounded, MoreVertRounded } from '@mui/icons-material';
import ContextMenu from '../Menu/ContextMenu';
import { useModals } from '../../Contexts/DialogContext/DialogProvider';
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { useSnackBar } from '../../Hooks/useSnackBar';
import { useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import { serviceFormModes } from '../../Pages/Services/Services';
import { IStaff, useStaff } from '../../Contexts/StaffContext/StaffProvider';
import dayjs from 'dayjs';

interface ServiceProps {
    service: serviceWithNames
    backgroundColor?: string
    setClickedService?: Dispatch<SetStateAction<IServiceDTO>>
    setFormMode?: Dispatch<SetStateAction<serviceFormModes>>
    isAdminView?: boolean
}

function ServiceOverview({ service, backgroundColor, setClickedService, setFormMode, isAdminView = false }: ServiceProps) {

    dayjs.locale('de');

    const [expanded, setExpanded] = useState(false)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

    const { changeVisibility } = useModals()
    const { removeService } = useServices()
    const { enqueueSnackbar } = useSnackBar()

    const { defaultService } = useServices()

    const { staff } = useStaff();

    const handleDelete = async () => {
        try {
            changeVisibility!("yesNoDialog", true, { props: { object: { function: () => { removeService!(service) } } } })
        } catch (error) {
            enqueueSnackbar("Fehler beim Löschen.", { variant: "error" })
        }
    }

    useEffect(() => {
        if (expanded && setClickedService && setFormMode) {
            setClickedService({ ...service });
            setFormMode("editService");
        }

        if (!expanded && setClickedService && setFormMode) {
            setClickedService(defaultService!);
            setFormMode("createService");
        }
    }, [expanded])

    return (
        <div className={'flex column '.concat(styles.container)} style={{ backgroundColor: backgroundColor ?? "transparent" }}>
            <header className='flex centered-aligned stretched-justify pointer'>
                <div className='flex centered-aligned' style={{ gap: "6px", width: "100%" }} onClick={() => setExpanded((val) => !val)}>
                    <div className={styles.accent} />
                    <span className='bold-big'>{service.title}</span>
                </div>
                <div className='flex centered-aligned'>

                    <IconTooltipButton
                        style={{ marginRight: "-5px" }}
                        onClick={() => setExpanded((val) => !val)}
                    >
                        {!expanded &&
                            <KeyboardArrowDownRoundedIcon fontSize='small' />
                        }
                        {
                            expanded &&
                            <KeyboardArrowUpRounded fontSize='small' />
                        }
                    </IconTooltipButton>
                    <IconTooltipButton
                        title='Optionen'
                        arrow
                        style={{ marginLeft: "-5px" }}
                        onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                        <MoreVertRounded fontSize='small' />
                    </IconTooltipButton>
                    <ContextMenu
                        anchorEl={anchorEl}
                        onClose={() => setAnchorEl(null)}
                        options={
                            [
                                /* TODO {
                                    label: "Verrechnen",
                                    onClick: () => null,
                                    selectable: false
                                },*/
                                {
                                    label: "Bearbeiten",
                                    onClick: () => changeVisibility!("editCreateService", true, { props: { object: service, mode: "edit" } }),
                                    selectable: false
                                },
                                {
                                    label: "Löschen",
                                    onClick: () => handleDelete(),
                                    selectable: false
                                },
                            ]
                        }
                    />
                </div>
            </header>
            <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                <span style={{ textOverflow: "ellipsis" }}>{service.clientOrganizationName}</span>
                <div style={{ height: "12px", width: "1.5px", backgroundColor: 'black', borderRadius: "100px" }} />{/* Pipe-Seperator: |*/}
                <span>{dayjs(service.employeeDatas[0].from).format('ddd DD.MM.YYYY')}</span>
                <div style={{ height: "12px", width: "1.5px", backgroundColor: 'black', borderRadius: "100px" }} />{/* Pipe-Seperator: |*/}
                <span>
                    <p>
                        Dauer: {(() => {
                            const startArbeit = dayjs(service.employeeDatas[0]?.from);
                            const endeArbeit = dayjs(service.employeeDatas[0]?.to);

                            if (!startArbeit || !endeArbeit || endeArbeit.isBefore(startArbeit)) {
                                return <span style={{ fontWeight: 'bold', color: 'green' }}>in Arbeit</span>;
                            } else {
                                const differenzInMillisekunden = endeArbeit.diff(startArbeit);
                                const differenzInMinuten = Math.floor(differenzInMillisekunden / (1000 * 60));
                                const stunden = Math.floor(differenzInMinuten / 60);
                                const minuten = differenzInMinuten % 60;

                                if (Number.isNaN(stunden) || Number.isNaN(minuten)) {
                                    return <span style={{ fontWeight: 'bold', color: 'green' }}>in Arbeit</span>;
                                }

                                return `${stunden} Stunden und ${minuten} Minuten`;
                            }
                        })()}
                    </p>
                </span>
                <div style={{ height: "12px", width: "1.5px", backgroundColor: 'black', borderRadius: "100px" }} />{/* Pipe-Seperator: |*/}
                <span>
                    <p>
                        Verrechenbar: {service.billable ? "Ja" : "Nein"}
                    </p>
                </span>
                <div style={{ height: "12px", width: "1.5px", backgroundColor: 'black', borderRadius: "100px" }} />{/* Pipe-Seperator: |*/}
                <span>
                    <p>
                        Verrechnung: {service.billed ? "erledigt" : "offen"}
                    </p>
                </span>

            </section>
            {isAdminView &&
                <section className='label flex centered-aligned' style={{ padding: "0 10px", gap: "10px", marginTop: "5px" }}>
                    <span style={{ textOverflow: "ellipsis", color: "var(--blue)" }}>@{staff.find((employee: IStaff) => employee.id === service.employeeDatas[0].userId)?.username}</span>
                </section>
            }
            <Collapse in={expanded}>
                <section style={{ padding: "10px", fontSize: "14px" }}>
                    {service.description}
                </section>
            </Collapse>
        </div>
    )
}

export default ServiceOverview