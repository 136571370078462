import { Tooltip } from "@mui/material";
import { BillPrefixDTO } from "../../../Interfaces/Accounting"
import DiscountIcon from '@mui/icons-material/Discount';
import MoreOptionsButton from "../../Buttons/MoreOptionsButton/MoreOptionsButton";
import { useHandleDeleteBillPrefix } from "../../../redux/requestHandlers/BillPrefix";

interface IBillPrefixRow {
    billPrefix: BillPrefixDTO
    index: number
    handleEditClicked: (billPrefix: BillPrefixDTO) => void
}

export default function BillPrefixRow({ billPrefix, index, handleEditClicked }: IBillPrefixRow) {

    const { handleDeleteBillPrefix } = useHandleDeleteBillPrefix();

    const options = [
        {
            text: "Bearbeiten",
            function: () => { handleEditClicked(billPrefix) }
        },
        {
            text: "Löschen",
            function: async () => { await handleDeleteBillPrefix(billPrefix) }
        }
    ]

    return (
        <div className={`flex flex-row w-full gap-2 p-3 ${index % 2 === 0 ? "bg-default" : "bg-default-contrast"}`}>
            <div className="w-[15%]">
                {billPrefix.prefix}
            </div>
            <div className="w-[15%] text-center">
                {billPrefix.billsCount}
            </div>
            <div className="w-[15%] text-center">
                {billPrefix.paidBillsCount}
            </div>
            <div className="w-[15%] text-center">
                {billPrefix.openBillsCount}
            </div>
            <div className="w-[15%] text-center">
                {billPrefix.delayedBillsCount}
            </div>
            <div className="w-[10%] text-center">
                {billPrefix.isOffer ?
                    <Tooltip title="Angebotskreis">
                        <DiscountIcon className="text-green-700" />
                    </Tooltip>
                    :
                    <Tooltip title="Rechungskreis">
                        <DiscountIcon className="text-slate-400 opacity-90" />
                    </Tooltip>}
            </div>

            <div className="flex h-full items-center justify-center ml-auto w-[10%]">
                <MoreOptionsButton options={options} />
            </div>
        </div>
    )
}