import { CircularProgress } from '@mui/material'
import React, { Dispatch, useEffect, useState } from 'react'
import { defaultCompany, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import { IOrganization, IOrganizationDTO } from '../../../Interfaces/Company'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateCompanyForm from '../../Forms/CreateCompanyForm'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'

export interface dialogWithProps<T> extends dialog {
    object?: T | null
    mode?: string | null
}

const EditCreateCompanyDialog = ({ open, onClose, object, mode }: dialogWithProps<IOrganizationDTO | IOrganization>) => {

    const { editCompany, createCompany } = useContacts()

    const { changeVisibility } = useModals()

    const { enqueueSnackbar } = useSnackBar()

    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editCreateCompany", false)
    }



    useEffect(() => {
        setCompanyToSubmit(object ?? defaultCompany)
    }, [object])

    const [loading, setLoading] = useState(false)

    const [companyToSubmit, setCompanyToSubmit] = useState<IOrganizationDTO | IOrganization>(mode == "create" ? { ...defaultCompany } : { ...object! })

    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (mode == "create") {
                //@ts-ignore
                await createCompany!(companyToSubmit)
            } else {
                await editCompany!(companyToSubmit as IOrganization)
            }
            changeVisibility!("editCreateCompany", false)
            setCompanyToSubmit(defaultCompany);
        } catch (error) {
            enqueueSnackbar("Fehler beim Bearbeiten.", {variant: "error"})  
        } finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            title={mode == "create" ? "Organisation erstellen" : "Organisation bearbeiten"}
            onClose={() => handleClose()}
        >
            {object?.eCockpitOrganizationId === null && mode !== "create" &&
                <div style={{textAlign: "center", padding: "3vh"}}>
                    eCockpit Organisationen können nur von einem Administrator in den Einstellungen bearbeitet werden.
                </div>
            }

            {object?.eCockpitOrganizationId !== null &&
                <>
                    < CreateCompanyForm
                        object={companyToSubmit}
                        setObject={setCompanyToSubmit}
                    />
                    <CTAButton
                        title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                        onClickHandler={() => handleSubmit()}
                        disabled={loading}
                    />
                </>
            }

        </Modal>
    )
}

export default EditCreateCompanyDialog