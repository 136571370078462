import AccExportForm from "../../Components/AccExport/AccExportForm";
import { Card, CardContent, CardHeader, CardTitle } from "../../Components/UI/CardNew";



const AccExport = () => {

    return (
        <>
            <Card>
                <CardHeader>
                    <CardTitle>Buchhaltungsdateien herunterladen</CardTitle>
                </CardHeader>
                <CardContent>
                    <AccExportForm />
                </CardContent>
            </Card>
        </>
    )
}
export default AccExport;
