import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "../baseAuthQuery";
import { BillPrefix, BillPrefixDTO } from "../../Interfaces/Accounting";

export const BillPrefixApi = createApi({
    reducerPath: "BillPrefixApi",
    baseQuery: baseAuthQuery,
    tagTypes: ["BillPrefix"],
    endpoints: (builder) => ({
        addBillPrefix: builder.mutation({
            query: (data: BillPrefix) => ({
                url: "/BillPrefix",
                method: "POST",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "BillPrefix" }]
        }),
        getBillPrefixes: builder.query<BillPrefixDTO[], void>({
            query: () => ({
                url: "/BillPrefix"
            }),
            providesTags: (result) => result ? result.map(({ id }) => ({ type: "BillPrefix", id })) : []
        }),
        editBillPrefix: builder.mutation({
            query: (data: BillPrefix) => ({
                url: "/BillPrefix",
                method: "PUT",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "BillPrefix", id: arg.id }]
        }),
        deleteBillPrefix: builder.mutation({
            query: (data: BillPrefix) => ({
                url: "/BillPrefix",
                method: "DELETE",
                body: data
            }),
            invalidatesTags: (result, error, arg) => [{ type: "BillPrefix", id: arg.id }]
        })
    })
})

export const {
    useAddBillPrefixMutation,
    useGetBillPrefixesQuery,
    useEditBillPrefixMutation,
    useDeleteBillPrefixMutation
} = BillPrefixApi