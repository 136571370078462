import { ReactNode } from "react";
import { useMobileView } from "../../Hooks/useMobileView";

interface IPageNew {
    children: ReactNode
}

function PageNew({ children }: IPageNew) {

    const isMobile = useMobileView();

    return (
        <>
            {!isMobile &&
                <div className="flex gap-4 h-full p-4 overflow-auto">
                    {children}
                </div>}

            {isMobile &&
                <div className="flex flex-col p-4 mt-[50px] gap-4">
                    {children}
                </div>
            }

        </>
    )

}

export default PageNew;