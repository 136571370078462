import { useState } from "react"
import HolidayOverviewCard, { HolidayCardModes } from "./HolidayOverviewCard"

function HolidayOverview() {

    const [cardMode, setCardMode] = useState<HolidayCardModes>("requests")

    return (
        <>
            <HolidayOverviewCard cardMode={cardMode} setCardMode={setCardMode} height="100%"/>
        </>
    )
}

export default HolidayOverview