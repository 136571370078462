import React from "react"
import { cn } from "../../utils/cn"

const Card = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("w-full h-full flex flex-col bg-white rounded-xl box-border overflow-hidden shadow max-md:h-[90vh]", className)}
        {...props}
    />
))
Card.displayName = "Card"

const CardHeader = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("border-b-[1px] flex items-center min-h-[50px] pl-2 pr-2", className)}
        {...props}
    />
))

const CardTitle = React.forwardRef<HTMLParagraphElement, React.HTMLAttributes<HTMLHeadingElement>>(({ className, ...props }, ref) => (
    <h3
        ref={ref}
        className={cn("text-base font-bold", className)}
        {...props}
    />
))

const CardContent = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("h-full w-full mt-2 flex flex-col gap-1 overflow-y-auto overflow-x-hidden pl-2 pr-2", className)}
        {...props}
    />
))

const CardFooter = React.forwardRef<HTMLDivElement, React.HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => (
    <div
        ref={ref}
        className={cn("flex items-center bg-brand-light min-h-[45px] pl-2 rounded-br-xl rounded-bl-xl", className)}
        {...props}
    />
))

//Maybe add variants in future
const CardFooterButton = React.forwardRef<HTMLButtonElement, React.HTMLAttributes<HTMLButtonElement>>(({ className, ...props }, ref) => (
    <button
        ref={ref}
        className={cn("font-semibold w-full h-full text-left", className)}
        {...props}
    />
))


export { Card, CardHeader, CardTitle, CardContent, CardFooter, CardFooterButton }