import { Axios, AxiosResponse } from 'axios'
import React, { ReactNode, useContext, useEffect, useReducer } from 'react'
import { createContext } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { DepartementReducer } from './DepartementReducer'
import { errorHandler } from '../../Connection/BaseConnection'
import { autoHideDurationDefault } from '../../Global/Variables'

export interface IDepartementDTO {
    name?: string
    description?: string
    eMail?: string
    userIds?: number[]
}

export interface IDepartement extends IDepartementDTO {
    id?: number
}

export const defaultDepartment: IDepartement = {
    id: 0,
    name: "",
    description: "",
    eMail: "",
    userIds: []
}

interface IDepartementContext {
    employees: any[]
    departements: IDepartement[]
    createDepartement?: any,
    updateDepartement?: any
    deleteDepartement?: any
}

const DepartementContext = createContext<IDepartementContext>({
    employees: [],
    departements: []
})

const DepartementProvider = ({ children }: { children: ReactNode }) => {

    const connection = useAuthConnection()
    const { enqueueSnackbar, closeSnackbar } = useSnackBar()


    const [state, dispatch] = useReducer(DepartementReducer, {
        employees: [],
        departements: []
    })

    const fetchEmployees = async () => {
        const { data } = await connection.get("/user")

        dispatch({
            type: "SET_EMPLOYEES",
            payload: data
        })
    }

    const fetchDepartements = async () => {
        const { data } = await connection.get("/department/")

        dispatch({
            type: "SET_DEPARTEMENTS",
            payload: data
        })
    }

    //ToDo: CreateDepartement
    const createDepartement = async (department: any) => {
        try {
            let x = enqueueSnackbar("Abteilung wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

            connection.post("/department/create", department)
                .then((res: AxiosResponse) => {
                    closeSnackbar(x);
                    if (res.status >= 200 && res.status < 300) {
                        enqueueSnackbar("Abteilung erfolgreich erstellt", { variant: "success" })
                    }
                    else {
                        enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" })
                    }

                    dispatch({
                        type: "ADD_DEPARTEMENT",
                        payload: res.data
                    })
                })
                .catch((error: any) => {
                    errorHandler(error, x, enqueueSnackbar, closeSnackbar)
                })

        } catch (exception) {
            enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" })
        }
    }

    const updateDepartement = async (department: any) => {
        try {
            let x = enqueueSnackbar("Abteilung wird geändert", { variant: "default", autoHideDuration: autoHideDurationDefault })

            const response = await connection.put("/department/update", department)
                .then((res: AxiosResponse) => {
                    closeSnackbar(x);

                    dispatch({
                        type: "UPDATE_DEPARTEMENT",
                        payload: res.data
                    })

                    if (res.status >= 200 && res.status < 300) {
                        enqueueSnackbar("Abteilung erfolgreich geändert", { variant: "success" })
                    }
                    else {
                        enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" })
                    }
                })
                .catch((error: any) => {
                    errorHandler(error, x, enqueueSnackbar, closeSnackbar)
                })
        } catch (exception) {
            enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" })
        }
    }

    const deleteDepartement = async (department: any) => {
        try {
            let x = enqueueSnackbar("Abteilung wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

            await connection.delete("/department/delete", { data: department })
                .then((res: AxiosResponse) => {
                    closeSnackbar(x);

                    dispatch({
                        type: "DELETE_DEPARTEMENT",
                        payload: department
                    })

                    if (res.status >= 200 && res.status < 300) {
                        enqueueSnackbar("Abteilung erfolgreich entfernt", { variant: "success" })
                    }
                    else {
                        enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" })
                    }
                })
                .catch((error: any) => {
                    errorHandler(error, x, enqueueSnackbar, closeSnackbar)
                })
        } catch (exception) {
            enqueueSnackbar("Ein Fehler ist aufgetreten", { variant: "error" })
        }
    }

    //ToDo: EditDepartement
    //ToDo: DeleteDepartement
    //ToDo: AddEmployeeToDepartement
    //ToDo: RemoveEmployeeFromDepartement

    useEffect(() => {
        fetchEmployees();
        fetchDepartements();
    }, [])


    return (
        <DepartementContext.Provider
            value={{
                employees: state.employees,
                departements: state.departements,
                createDepartement,
                updateDepartement,
                deleteDepartement
            }}
        >
            {children}
        </DepartementContext.Provider>
    )
}

export default DepartementProvider

export const useDepartements = () => useContext(DepartementContext)