import { AxiosResponse } from 'axios'
import React, { createContext, ReactNode, useContext, useEffect, useReducer } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { useSnackBar } from '../../Hooks/useSnackBar'
import { IService, IServiceDTO } from '../../Interfaces/Service'
import { ServiceContext } from '../../Interfaces/ServiceContext'
import { useUser } from '../UserContext/UserContext'
import ServiceReducer from './ServiceReducer'
import { authConnection, errorHandler } from '../../Connection/BaseConnection'
import { connect } from 'http2'
import { autoHideDurationDefault } from '../../Global/Variables'
import { ITaskDTO } from '../../Interfaces/Task'
import dayjs from 'dayjs'

const serviceContext = createContext<ServiceContext>({
    services: [],
    currentMonth: 0,
    currentWeek: 0,
    taskServiceCategories: []
})

//TODO CRUD + Kommunikation mit der API -> Nico oder Bernhard + Default-Value für Service (siehe TaskContext) erstellen -> Sollte Rücksicht auf den aktuellen User nehmen

function ServiceProvider({ children }: { children: ReactNode }) {

    //* Verbindung zur API
    const connection = useAuthConnection()


    const { enqueueSnackbar, closeSnackbar } = useSnackBar()

    const { user, setUserWorkingOnTask } = useUser()

    function addHours(date: Date, hours: number): Date {
        date.setHours(date.getHours() + hours);

        return date;
    }

    const defaultService: IServiceDTO = {
        title: "",
        description: "",
        type: 1,
        billable: true,
        billed: false,
        intern: false,
        organizationId: user?.organizationId!,
        clientOrganizationId: -1,
        employeeDatas: [
            {
                userId: user?.userId!,
                from: dayjs().subtract(1, 'hour'),
                to: dayjs(),
                hourlyRate: 80,
            }
        ]
    }

    const [state, dispatch] = useReducer(ServiceReducer, {
        services: [],
        currentMonth: 0
    })

    const addService = async (service: IServiceDTO) => {
        let x = enqueueSnackbar("Leistung wird erstellt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/service", {
            ...service, employeeDatas: service.employeeDatas.map((element: any) => ({ ...element, from: new Date(element.from).toISOString(), to: new Date(element.to).toISOString() }))
        })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Leistung erfolgreich erstellt", { variant: "success" })

                dispatch({
                    type: "ADD_SERVICE",
                    payload: res.data
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const startServiceToTask = async (task: any) => {
        let x = enqueueSnackbar("Leistung wird gestartet", { variant: "default", autoHideDuration: autoHideDurationDefault })
        if (setUserWorkingOnTask) {
            setUserWorkingOnTask(task.id!);
        }
        connection.post("/service/startservicetotask", task)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Leistung erfolgreich erstellt", { variant: "success" })



                dispatch({
                    type: "ADD_SERVICE",
                    payload: res.data
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const stopServiceToTask = async (task: any) => {
        let x = enqueueSnackbar("Leistung wird beendet", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.post("/service/stopservicetotask", task)
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Leistung erfolgreich beendet", { variant: "success" })
                if (setUserWorkingOnTask) {
                    setUserWorkingOnTask(undefined);
                }
                dispatch({
                    type: "UPDATE_SERVICE",
                    payload: res.data
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const updateService = async (service: IServiceDTO) => {
        let x = enqueueSnackbar("Leistung wird gespeichert", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.put("/service", {
            ...service, employeeDatas: service.employeeDatas.map((element: any) => {
                console.log({ ...element, from: new Date(element.from).toISOString(), to: new Date(element.to).toISOString() })

                return { ...element, from: new Date(element.from).toISOString(), to: element.to ? new Date(element.to).toISOString() : null };
            })
        })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Leistung erfolgreich bearbeitet", { variant: "success" })

                dispatch({
                    type: "UPDATE_SERVICE",
                    payload: res.data
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const removeService = async (service: IService) => {
        let x = enqueueSnackbar("Leistung wird entfernt", { variant: "default", autoHideDuration: autoHideDurationDefault })

        connection.delete("/service", { data: { id: service.id } })
            .then((res: AxiosResponse) => {
                closeSnackbar(x);
                enqueueSnackbar("Leistung erfolgreich entfernt", { variant: "success" })

                dispatch({
                    type: "REMOVE_SERVICE",
                    payload: service
                })
            })
            .catch((error: any) => {
                errorHandler(error, x, enqueueSnackbar, closeSnackbar)
            })
    }

    const fetchServices = async () => {
        const { data } = await connection.get("/service")

        dispatch({
            type: "SET_SERVICES",
            payload: data
        })
    }

    const fetchTaskServiceCategories = async () => {
        const { data } = await connection.get("/taskServiceCategory")

        dispatch({
            type: "SET_TASK_SERVICE_CATEGORIES",
            payload: data
        })
    }

    //TODO Auslagern der Vergleichmethode für zwei Daten
    const getStatsOfCurrentMonth = () => {
        const services: IService[] = [...state.services]

        let hours = 0
        let weeksHours = 0;
        let serviceHoursInMonth = 0;

        services.forEach((service) => {
            const serviceDataOfCurrentEmployee = service.employeeDatas.map((employeeData) => {

                if (employeeData.userId == user?.userId) {
                    return employeeData
                } else {
                    return null
                }
            }).filter((data) => data !== null)

            serviceDataOfCurrentEmployee.forEach((data) => {

                const todaysMonth = dayjs().month()
                let todaysWeeksMonday = dayjs().startOf('week');
                let todaysWeeksSunday = dayjs().endOf('week');
                const startDateTime = data?.from != null ? dayjs(data?.from) : dayjs();
                const endDateTime = data?.to != null ? dayjs(data?.to) : dayjs();

                if (todaysMonth == endDateTime.month()) {
                    const hoursOfService = endDateTime.diff(startDateTime, 'hour', true)
                    hours += hoursOfService
                    //console.log(`ServiceContext_hours: ${hours}`);   
                }

                /* Get total service time for current month */
                const currentMonth = dayjs().month();
                const currentYear = dayjs().year();

                if (data && data.from && data.to) {

                    const dataFrom = dayjs(data.from);
                    const dataTo = dayjs(data.to);

                    const monthOfService = dataFrom.month();
                    const yearOfService = dataFrom.year();

                    if (currentMonth === monthOfService && currentYear === yearOfService) {
                        const hoursOfService = dataTo.diff(dataFrom, 'hour', true)
                        serviceHoursInMonth += hoursOfService
                    }
                }
                /**/


                if (todaysWeeksMonday.isBefore(endDateTime) && todaysWeeksSunday.isAfter(endDateTime)) {
                    const hoursOfService = endDateTime.diff(startDateTime, 'hour', true);
                    console.log("hours of Service", hoursOfService);
                    weeksHours += hoursOfService;
                    //console.log(`ServiceContext_weeksHours: ${weeksHours}`);

                }

            })
        })
        dispatch({
            type: "SET_CURRENT_MONTH",
            payload: { inMonth: serviceHoursInMonth, inWeek: weeksHours }
        })
    }

    const getStatsOfPeriod = ({ start, end }: { start: Date, end: Date }) => {
        const services: IService[] = [...state.services]

        let hours = 0

        services.forEach((service) => {
            service.employeeDatas.forEach((data) => {
                if (data && data.userId === user?.userId) {
                    const startDateTime = data?.from.toDate()
                    const endDateTime = data?.to.toDate()

                    if (start.getTime() < startDateTime.getTime() && end.getTime() > endDateTime.getTime()) {
                        const hoursOfService = (endDateTime.getTime() - startDateTime.getTime()) / 1000 / 60 / 60
                        hours += hoursOfService;
                    }
                }
            })
        })

        return hours;
    }

    const setServicesBilled = (ids: Array<number>) => {
        let tempServices = [...state.services];
        tempServices = tempServices.map((element: IService) => {
            if (ids.includes(element.id)) {
                return { ...element, billed: true }
            }
            return element;
        })
        dispatch({
            type: "SET_SERVICES",
            payload: tempServices
        })
    }

    useEffect(() => {
        fetchServices()
        fetchTaskServiceCategories();
    }, [])

    useEffect(() => {
        getStatsOfCurrentMonth()
    }, [state.services])

    return (
        <serviceContext.Provider
            value={{
                services: state.services,
                defaultService,
                removeService,
                addService,
                updateService,
                getStatsOfPeriod,
                setServicesBilled,
                startServiceToTask,
                stopServiceToTask,
                currentMonth: state.currentMonth,
                currentWeek: state.currentWeek,
                taskServiceCategories: state.taskServiceCategories
            }}
        >
            {children}
        </serviceContext.Provider>
    )
}

export default ServiceProvider


export const useServices = () => useContext(serviceContext)