// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Form_dynamicFormContainer__4Y0rk{\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   margin-bottom: 1vh;\n}\n\n.Form_dynamicFormButton__XOvvv {\n    margin-top: 1.5vh;\n    margin-right: 0.5vh;\n    margin-left: 0.5vh;\n}", "",{"version":3,"sources":["webpack://./src/Components/Forms/FormUtils/Form.module.css"],"names":[],"mappings":"AAAA;GACG,aAAa;GACb,uBAAuB;GACvB,mBAAmB;GACnB,kBAAkB;AACrB;;AAEA;IACI,iBAAiB;IACjB,mBAAmB;IACnB,kBAAkB;AACtB","sourcesContent":[".dynamicFormContainer{\n   display: flex;\n   justify-content: center;\n   align-items: center;\n   margin-bottom: 1vh;\n}\n\n.dynamicFormButton {\n    margin-top: 1.5vh;\n    margin-right: 0.5vh;\n    margin-left: 0.5vh;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dynamicFormContainer": "Form_dynamicFormContainer__4Y0rk",
	"dynamicFormButton": "Form_dynamicFormButton__XOvvv"
};
export default ___CSS_LOADER_EXPORT___;
