// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ComponentCarousel_indicatorContainer__jbzSs {\n    display: flex;\n    align-self: center;\n    height: 30px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Carousel/ComponentCarousel.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".indicatorContainer {\n    display: flex;\n    align-self: center;\n    height: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"indicatorContainer": "ComponentCarousel_indicatorContainer__jbzSs"
};
export default ___CSS_LOADER_EXPORT___;
