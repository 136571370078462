import { AddRounded, DeleteRounded, EditRounded, ExpandLess, ExpandMore } from "@mui/icons-material"
import { Checkbox, Collapse, IconButton } from "@mui/material"
import { useEffect, useState } from "react"
import { useModals } from "../../Contexts/DialogContext/DialogProvider"
import { AppointmentCategoryVisibilityTypes, useTasks } from "../../Contexts/TaskContext/TaskContext"
import { IAppointmentCategory } from "../../Interfaces/Appointment"
import styles from "./Calendar.module.css"
import CreateAppointmentCategoryModal from "./CreateAppointmentCategoryModal"

interface CollapsibleCheckboxProps {
    parentColor?: string,
    parentLabel?: string,
    childrenElements?: IAppointmentCategory[],
    setChildrenElements?: React.Dispatch<React.SetStateAction<IAppointmentCategory[]>>,
    displayDefaultCategories?: boolean,
    checkedCategories?: { [categoryId: number]: boolean },
    setCheckedCategories?: React.Dispatch<React.SetStateAction<{ [categoryId: number]: boolean }>>,
}

const CalendarCategoriesCollapsibleCheckbox = ({ parentColor, parentLabel, childrenElements, setChildrenElements, displayDefaultCategories, checkedCategories, setCheckedCategories }: CollapsibleCheckboxProps) => {
    const [expanded, setExpanded] = useState(true)
    const [parentChecked, setParentChecked] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const [categoryVisibility, setCategoryVisibility] = useState<AppointmentCategoryVisibilityTypes>(AppointmentCategoryVisibilityTypes.Organization)
    const [clickedCategory, setClickedCategory] = useState<IAppointmentCategory>()
    const [mode, setMode] = useState<string>("create")
    const dialogContext = useModals()
    const { deleteAppointmentCategory } = useTasks()

    //UseEffect to determine checkbox state of parent checkbox
    useEffect(() => {
        if (checkedCategories) {
            if (Object.values(checkedCategories).every((value) => value)) {
                setParentChecked(true);
            } else if (Object.values(checkedCategories).some((value) => value && !parentChecked)) {
                setParentChecked(false);
            } else {
                setParentChecked(false);
            }
        }
    }, [checkedCategories]);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    //updates child checkbox states and parent checkbox state
    const handleParentCheckboxChange = () => {
        const newParentChecked = !parentChecked;
        setParentChecked(newParentChecked);
        updateAllCategories(newParentChecked)
    };

    const handleChildCheckboxChange = (childElementId: number, checked: boolean) => {
        updateCategoryState(childElementId, checked)
    };

    //updates clicked category checked state
    const updateCategoryState = (categoryId: number, val: boolean) => {
        if (setCheckedCategories)
            setCheckedCategories(old => ({
                ...old,
                [categoryId]: val
            }))
    }

    //updates all categories when checking/unchecking parent checkbox
    const updateAllCategories = (newVal: boolean) => {
        const updatedState: { [categoryId: number]: boolean } = {};
        if (setCheckedCategories) {
            for (const categoryId in checkedCategories) {
                updatedState[Number(categoryId)] = newVal
            }
            setCheckedCategories(updatedState)
        }
    }

    //Checks if parent checkbox state should be indeterminate when some children values are checked and some are not checked
    const checkIndeterminate = () => {
        if (checkedCategories) {
            return Object.values(checkedCategories).some((value) => value && !parentChecked)
        } else {
            return false
        }
    }

    //Open modal with default visiblity set to Organization/Private depending on which category "Add" was clicked in
    const handleParentAddClick = () => {
        setCategoryVisibility(displayDefaultCategories ? AppointmentCategoryVisibilityTypes.Organization : AppointmentCategoryVisibilityTypes.Private)
        setMode("create");
        setOpenModal(true);
    }

    const handleEditClick = (ac: IAppointmentCategory) => {
        setClickedCategory(ac);
        setMode("edit");
        setOpenModal(true);
    }

    const handleDeleteClick = (ac: IAppointmentCategory) => {
        dialogContext.changeVisibility!("yesNoDialog", true,
            {
                props: {
                    object: {
                        submitFunction: () => {
                            deleteAppointmentCategory!(ac!);
                        },
                        modalTitle: "Kategorie löschen",
                        confirmButtonText: "Löschen",
                        modalText: "Möchten Sie diese Kategorie wirklich löschen?"
                    }
                }
            });
    }

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    alignItems: 'center',
                }}
            >
                {/* Parent Checkbox */}
                <Checkbox
                    checked={parentChecked}
                    onChange={handleParentCheckboxChange}
                    indeterminate={checkIndeterminate()}
                    style={{ color: parentColor, marginRight: '0rem' }}
                />
                <span style={{ fontSize: '0.875rem', marginLeft: '0rem' }}>{parentLabel}</span>
                <div style={{ marginRight: '0px', marginLeft: 'auto' }}>
                    <IconButton size='small' onClick={handleParentAddClick} style={{ marginRight: '0px', marginLeft: 'auto' }}><AddRounded fontSize="small" style={{ color: 'darkslategray' }} /></IconButton>
                    <IconButton size='small' onClick={handleExpandClick} style={{ color: parentColor, marginRight: '0px' }} >
                        {expanded ? <ExpandLess fontSize="small" /> : <ExpandMore fontSize="small" />}
                    </IconButton>
                </div>
            </div>
            <Collapse in={expanded}>
                <div style={{ marginLeft: '1rem' }}>
                    {childrenElements?.length === 0
                        ?
                        <span style={{ fontSize: '0.8rem' }}>Keine Kategorien vorhanden</span>
                        :
                        childrenElements?.map((childElement, index) => (
                            <div
                                className={styles.categoriesParentElement}
                                key={childElement.id}
                                style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                            >
                                {/* Children Checkboxes */}
                                <Checkbox
                                    checked={(checkedCategories && childElement.id) ? checkedCategories[childElement.id] : false}
                                    onChange={(event) => childElement.id && handleChildCheckboxChange(childElement.id, event?.target.checked)}
                                    style={{ color: childElement.color }}
                                    size="small"
                                />
                                <span className={styles.categoryTextElement} style={{ fontSize: '0.8rem' }}>{childElement.name}</span>

                                {((childElement && childElement.id) && (childElement.id > 0)) &&
                                    <div className={styles.categoryButtonContainer} style={{ marginRight: '0px', marginLeft: 'auto', display: 'flex' }}>
                                        <IconButton className={styles.categoryButtons} size="small" style={{ color: 'var(--brand)' }}
                                            onClick={() => handleEditClick(childElement)}>
                                            <EditRounded fontSize="small" />
                                        </IconButton>
                                        <IconButton className={styles.categoryButtons} size="small" style={{ color: 'red' }}
                                            onClick={() => handleDeleteClick(childElement)}>
                                            <DeleteRounded fontSize="small" />
                                        </IconButton>
                                    </div>
                                }

                            </div>
                        ))}

                </div>
            </Collapse>
            <CreateAppointmentCategoryModal open={openModal}
                setOpen={setOpenModal}
                visibility={categoryVisibility}
                mode={mode}
                appointmentCategory={clickedCategory} />
        </div>
    )
}

export default CalendarCategoriesCollapsibleCheckbox