import { useEffect, useMemo, useState } from "react";
import { StaffDTO, useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { IPause, IWorkTime, useTimeTracking } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import { useUser } from "../../Contexts/UserContext/UserContext";
import { card } from "../../Interfaces/Card"
import Card from "../Card/Card"
import WorkTimeRowAdmin from "./WorkTimeRowAdmin";
import Pagination from "../Pagination/Pagination";
import { useServices } from "../../Contexts/ServiceContext/ServiceContext";
import { IService } from "../../Interfaces/Service";
import Filter, { IFilterOption } from "../Filter/Filter";
import dayjs, { Dayjs } from "dayjs";
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'

function WorktimeOverviewCard({ width, height, order }: card) {

    dayjs.extend(isSameOrAfter);
    dayjs.extend(isSameOrBefore);

    const { worktimesOrganization, pausesOrganization } = useTimeTracking();
    const { services } = useServices();
    const { user } = useUser()
    const { staff } = useStaff();

    const [worktimesOrg, setWorktimesOrg] = useState<IWorkTime[]>([]);

    const originalWorktimes = useMemo(() => worktimesOrganization, [worktimesOrganization])

    const filterOptions: IFilterOption[] = [
        { title: "Mitarbeiter", valueKey: "userId", referenceList: staff, referenceListValueKey: "person.completeName" },
        { title: "Nutzername", valueKey: "userId", referenceList: staff, referenceListValueKey: "username" }
    ]

    useEffect(() => {
        if (worktimesOrganization) {
            setWorktimesOrg(worktimesOrganization);
        }
    }, [worktimesOrganization])

    const [page, setPage] = useState<number>(0);
    const itemsPerPage = 10;

    //Function to check if a service is within a specific time frame
    function ServiceInTimeFrameOfWorktime(service: IService, worktimeStart: Dayjs, worktimeEnd: Dayjs, worktimeUserId: number) {
        const serviceInTimeFrame = service.employeeDatas.some((employeeData) => {
            // Convert employeeData.from and .to to Day.js objects
            const start = dayjs(employeeData.from);
            const end = dayjs(employeeData.to);

            // Add tolerance of one hour to worktime start and end
            const worktimeStartWithTolerance = dayjs(worktimeStart).subtract(30, 'minute');
            const worktimeEndWithTolerance = dayjs(worktimeEnd).add(30, 'minute');

            // Check if the start and end of the service is within the worktime frame
            return start.isSameOrAfter(worktimeStartWithTolerance) &&
                end.isSameOrBefore(worktimeEndWithTolerance) &&
                employeeData.userId === worktimeUserId;
        });


        return serviceInTimeFrame;
    }

    return (
        <Card
            title='Arbeitszeiten'
            style={{ width, height, order }}
            fullHeightBody={true}
            right={() => <Filter dataToFilter={worktimesOrg} setDataToFilter={setWorktimesOrg} filterOptions={filterOptions} originalData={originalWorktimes} />}
        >
            {worktimesOrg && worktimesOrg.length > 0 && worktimesOrg.map((worktime, index) => {
                if (index >= (page) * itemsPerPage && index < (page + 1) * itemsPerPage) {
                    return (
                        <WorkTimeRowAdmin worktime={worktime} n={index} id={worktime.id} key={index} pauses={pausesOrganization?.filter((pause: IPause) => {
                            if (pause.workTimeId === worktime.id) {
                                return true;
                            }
                            return false;
                        })}
                            staffMember={staff.find((tempStaff: StaffDTO) => (tempStaff.id === worktime.userId))}
                            servicesToWorktime={services.filter((service: IService) => {
                                if (ServiceInTimeFrameOfWorktime(service, worktime.start ?? dayjs(), worktime.end ?? dayjs(), worktime.userId)) {
                                    return true;
                                }
                                return false;
                            })}
                        />
                    )
                }
            })}
            <div style={{ marginTop: "2em" }}>
                <Pagination items={worktimesOrganization ?? []} itemsPerPage={itemsPerPage} page={page} setPage={setPage} />
            </div>


        </Card>
    )
}

export default WorktimeOverviewCard