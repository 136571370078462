// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Dropdown_container__TTSaI {\n    padding: 20px;\n    border-radius: var(--borderRadius);\n    background-color: var(--light);\n}\n\n.Dropdown_dropdown__kHtO6 {\n    position: absolute;\n    margin-top: 30px;\n    padding: 10px 20px;\n    background-color: var(--light);\n    margin-left: -20px;\n    border-radius: var(--borderRadius);\n}", "",{"version":3,"sources":["webpack://./src/Components/Dropdown/Dropdown.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,kCAAkC;IAClC,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,8BAA8B;IAC9B,kBAAkB;IAClB,kCAAkC;AACtC","sourcesContent":[".container {\n    padding: 20px;\n    border-radius: var(--borderRadius);\n    background-color: var(--light);\n}\n\n.dropdown {\n    position: absolute;\n    margin-top: 30px;\n    padding: 10px 20px;\n    background-color: var(--light);\n    margin-left: -20px;\n    border-radius: var(--borderRadius);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "Dropdown_container__TTSaI",
	"dropdown": "Dropdown_dropdown__kHtO6"
};
export default ___CSS_LOADER_EXPORT___;
