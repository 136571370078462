import React, { Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react'
import { useTasks } from '../../Contexts/TaskContext/TaskContext'
import { ITask, ITaskDTO } from '../../Interfaces/Task'
import Task from '../Task/Task'
import TaskExpandable, { isIterable } from '../Task/TaskExpandable'
import { AddRounded, CheckBoxOutlineBlankRounded, CheckBoxRounded, CheckRounded, RefreshRounded, MoreVertRounded, SearchRounded, Tune, Person, Business } from '@mui/icons-material'


import styles from './TaskCard.module.css'
import ErrorDialog from '../Dialogs/ErrorDialog/ErrorDialog'
import { useDeliveryNote } from '../../Contexts/DeliveryNoteContext/DeliveryNoteContext'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'
import { card } from '../../Interfaces/Card'
import CreateTaskDialog from '../Dialogs/CreateTaskDialog/CreateTaskDialog'
import { blankcard } from '../ServiceCard/ServiceCard'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { useLocation, useNavigate } from 'react-router-dom'
import ContextMenu from '../Menu/ContextMenu'
import { Grow, IconButton, Tooltip } from '@mui/material'
import SearchBar from '../SearchBar/SearchBar'
import { groupBy } from '../../Hooks/Utilities/Utlitites'
import { taskFormModes } from '../../Pages/Aufgaben/Aufgaben'
import ButtonControl from '../Buttons/ButtonControl/ButtonControl'
import TaskFilterDialog from '../Dialogs/TaskFilterDialog/TaskFilterDialog'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { Card, CardContent, CardFooter, CardHeader, CardTitle } from '../UI/CardNew'

export type ITaskSelectMode = "select" | "normal"

interface ITaskCard extends blankcard {
  showOnlyOwnTasks?: boolean
  setClickedTask?: Dispatch<SetStateAction<ITaskDTO>>
  setFormMode?: Dispatch<SetStateAction<taskFormModes>>
  openModalOnTaskClick?: boolean
  right?: (props: { expanded: boolean, setExpanded: React.Dispatch<React.SetStateAction<boolean>> }) => ReactNode
  cardMode?: "task" | "project"
  setCardMode?: Dispatch<SetStateAction<"task" | "project">>
}


//TODO: refactor passed props
function TaskCard({ height = "100%", width = "100%", order, cta, showOnlyOwnTasks, setClickedTask, setFormMode, openModalOnTaskClick = true, right, setCardMode, cardMode }: ITaskCard) {

  const { tasks, tasksUnformated, allTasksUnformated } = useTasks()

  const { tasksToDeliver } = useDeliveryNote()

  //Regelt, ob das FehlermeldungsModal angezeigt wird
  const [showError, setShowError] = useState(false)

  const [showCreateDialog, setShowCreateDialog] = useState(false)

  const { changeVisibility } = useModals()

  const [mode, setMode] = useState<ITaskSelectMode>("normal")

  const { user, setUser } = useUser();

  const [keyword, setKeyword] = useState("");

  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filterMode, setFilterMode] = useState<"unfinished" | "custom">("unfinished")

  useEffect(() => {
    if (filterMode === "unfinished") {
      setFilteredTasks(
        (groupBy!([...tasksUnformated?.filter((element: ITaskDTO) => {
          return ((element.description?.toLowerCase().includes(keyword.toLowerCase()) || element.title?.toLowerCase().includes(keyword.toLowerCase())) && (!showOnlyOwnTasks || element.userIds?.includes(user?.userId!)))
        })], "customerOrganizationId"))
      )
    } else {
      setFilteredTasks(
        (groupBy!([...allTasksUnformated?.filter((element: ITaskDTO) => {
          return ((element.description?.toLowerCase().includes(keyword.toLowerCase()) || element.title?.toLowerCase().includes(keyword.toLowerCase())) && (!showOnlyOwnTasks || element.userIds?.includes(user?.userId!)))
        }
        )], "customerOrganizationId"))
      )
    }
  }, [keyword, tasks, filterMode])


  useEffect(() => { console.log("tasksUnformated", tasksUnformated, "filteredTasks", filteredTasks) }, [filteredTasks, tasksUnformated])

  const buttonControl = [
    {
      body: (active: boolean) => <span>Projekte</span>,
      value: "project"
    },
    {
      body: (active: boolean) => <span>Aufgaben</span>,
      value: "task"
    }

  ]

  const [filter, setFilter] = useState<any>({});

  const [open, setOpen] = useState<boolean>(false);

  const onClose = () => {
    setOpen(false);
  }

  const cardContentRef = useRef<HTMLDivElement>(null);

  return (
    <Card style={{ height: height }}>
      <CardHeader className='justify-between'>
        <CardTitle>{showOnlyOwnTasks ? "Meine Aufgaben" : "Alle Aufgaben"}</CardTitle>
        {(cardMode && setCardMode) && <ButtonControl value={cardMode} setValue={setCardMode} buttons={buttonControl} />}
      </CardHeader>
      <CardContent ref={cardContentRef}>
        {
          Object.keys(filteredTasks).map((key, index) => (
            <TaskExpandable
              key={index}
              mode={mode}
              tasks={filteredTasks[parseInt(key)]}
              index={index}
              showOnlyOwnTasks={showOnlyOwnTasks}
              setClickedTask={setClickedTask}
              setFormMode={setFormMode}
              openModalOnTaskClick={openModalOnTaskClick}
              cardContentRef={cardContentRef}
            />)
          )
        }
      </CardContent>
      <CardFooter>
        <button className='font-semibold w-full h-full text-left' onClick={() => changeVisibility!("createTask", true)}>
          <AddRounded fontSize='small' /> Aufgabe erstellen
        </button>
      </CardFooter>
      <TaskFilterDialog open={open} onClose={onClose} setOpen={setOpen} setFilter={setFilter} />
    </Card>

  )
}

export default TaskCard