import { useContext } from 'react';
import { Card, CardActions, CardContent, CardHeader, Typography, Button, Box } from '@mui/material';
import MyCard from '../Card/Card';
import { CartContext } from '../../Contexts/ShoppingCartContext/CartContext';
import { productsArray } from './ProductsStore';
import CardColumn from '../Card/CardColumn/CardColumn';
import { Margin } from '@mui/icons-material';
import CTAButton from '../../Components/Buttons/CTAButton/CTAButton';
import { East, West } from '@mui/icons-material';

type Product = {
    id: string;
    title: string;
    modularPrice: boolean;
    singularIncrease: boolean;
    prices: {
        threshold: string | number | undefined;
        price: string | number | undefined;
    }[];
    description: string;
};

type ProductCardProps = {
    product: Product;
};

function ProductCard({ product }: ProductCardProps) {
    const { id, title, modularPrice, singularIncrease, prices, description } = product;
    const { getProductQuantity, addOneToCart, removeOneFromCart, deleteFromCart } = useContext(CartContext);
    const productQuantity: any = getProductQuantity(id);

    const cart: any = useContext(CartContext);
    const items = cart.items;
    const hasBusiness1 = items.some((item: { id: string; }) => item.id === productsArray[1].id);


    return (
        <div style={{margin: '0 10px 10px'}}>
        <MyCard title={title} disableBodyMargin>
            <div style={{display: 'flex', flexDirection: 'column', minHeight: '250px'}}>
                <div style={{flex: 1}}>
                    <Typography sx={{ whiteSpace: 'pre-wrap' }}>{description}</Typography>
                </div>
                
                <Typography fontWeight={'bold'} fontSize={'larger'}>
                    {modularPrice
                        ? prices.map((priceObj, index) => (
                            <div key={index}>
                                Grenze: {priceObj.threshold}, Price: {priceObj.price}€
                            </div>
                        ))
                        : `${prices[0].price}€`}
                </Typography>

                {productQuantity > 0 ?
                    <>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
                            <Typography variant="body1">Im Warenkorb: {productQuantity}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                <Button variant="outlined" style={{ display: modularPrice || singularIncrease ? "block" : "none" }} onClick={() => addOneToCart(id)}>+</Button>
                                <Button variant="outlined" style={{ display: modularPrice || singularIncrease ? "block" : "none" }} onClick={() => removeOneFromCart(id)}>-</Button>
                            </Box>
                        </Box>
                        <CTAButton
                            
                            style={{ width: "50%", color: "var(--error)" }}
                            onClickHandler={() => {
                                deleteFromCart(id);
                            }}
                            title={<>{'Artikel entfernen'}</>}
                        />
                    </>
                    :
                    <>{product.id === productsArray[0].id && hasBusiness1 ?
                        <CTAButton
                            style={{ width: "50%", backgroundColor: "var(--brand-attention)", color: "var(--text)" }}
                            onClickHandler={() => {
                                addOneToCart(id);
                                removeOneFromCart(productsArray[1].id);
                            }}
                            title={<>{'Upgrade'}</>}
                        />
                        :
                        <CTAButton
                                style={{ width: "50%" }}
                                onClickHandler={() => addOneToCart(id)}
                                title={<>{'Artikel Hinzufügen'}</>}
                            />

                    }</>
                }
            </div>
        </MyCard>
        </div>        
    );
}

export default ProductCard;
