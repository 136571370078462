import { useEffect, useMemo, useRef, useState } from 'react'
import { card } from '../../Interfaces/Card'

/* Icons */

import { useBills } from '../../Contexts/BillContext/BillProvider'
import OfferRow from './OfferRow'
import { IBillListDTO } from '../../Interfaces/Bill'
import Filter, { IFilterOption } from '../Filter/Filter'
import { BillPrefix } from '../../Interfaces/Accounting'
import { useNavigate } from 'react-router-dom'
import { AddRounded } from '@mui/icons-material'
import { Card, CardContent, CardFooter, CardFooterButton, CardHeader, CardTitle } from '../UI/CardNew'
import Pagination from '../Pagination/Pagination'

function OfferCard({ width, height, order }: card) {

  const navigate = useNavigate();
  const { bills, billPrefixes } = useBills();

  const [offerBills, setOfferBills] = useState<IBillListDTO[]>([]);
  const [offerBillsOriginal, setOfferBillsOriginal] = useState<IBillListDTO[]>([]);

  const [offerPrefixes, setOfferPrefixes] = useState<Array<BillPrefix>>([]);

  const originalOfferBills = useMemo(() => getOfferBills(bills, offerPrefixes), [bills, offerPrefixes]);

  function getOfferBills(bills: IBillListDTO[], offerPrefixes: BillPrefix[]) {
    const filteredBills = bills.filter(billDTO => {
      return offerPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
    });
    return filteredBills;
  }

  useEffect(() => {
    setOfferPrefixes(billPrefixes.filter(prefix => prefix.isOffer === true))
  }, [billPrefixes])


  useEffect(() => {
    const filteredBills = bills.filter(billDTO => {
      return offerPrefixes.find(prefix => prefix.id === billDTO.bill.prefix);
    });
    setOfferBills(filteredBills);
    setOfferBillsOriginal(filteredBills);
  }, [bills, offerPrefixes])


  const [mode, setMode] = useState("all");

  const buttons = [
    {
      body: (active: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Alle</span>,
      value: "all"
    },
    {
      body: (active: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Rechnungen</span>,
      value: "bills"
    },
    {
      body: (custom: boolean) => <span className='centered' style={{ gap: "2px", fontSize: "small" }}>Belege</span>,
      value: "receipts"
    },
    /* ToDo: Add custom filtering
    {
      body: (active: boolean) => active ? <span onClick={() => { }} className='centered' style={{ gap: "2px", fontSize: "small" }}><Tune fontSize='small' /></span> : <Tune fontSize='small' />,
      value: "custom"
    }*/
  ]

  const filterOptions: IFilterOption[] = [
    { title: "Kunde", valueKey: "bill.customerName" },
    { title: "Rechnungskreis", valueKey: "bill.billPrefix" }
  ]

  const [page, setPage] = useState<number>(0);
  const offerRowContainerRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (offerRowContainerRef) {
      offerRowContainerRef.current?.scrollTo(0, 0);
    }
  }, [page])

  return (
    <Card>
      <CardHeader className='flex justify-between mr-2'>
        <CardTitle>Angebote</CardTitle>
        <Filter dataToFilter={offerBills} setDataToFilter={setOfferBills} filterOptions={filterOptions} originalData={offerBillsOriginal} />
      </CardHeader>
      <CardContent>
        <div ref={offerRowContainerRef} className='flex flex-col h-[93%] overflow-auto'>
          {offerBills.map((billDTO: IBillListDTO) => {
            return (
              <OfferRow billDTO={billDTO} />
            )
          })}
        </div>
        <Pagination items={offerBills} itemsPerPage={25} page={page} setPage={setPage} />

      </CardContent>

      <CardFooter>
        <CardFooterButton onClick={() => navigate("/rechnungswesen/angebote/erstellen")}><AddRounded fontSize='small' />Angebot erstellen</CardFooterButton>
      </CardFooter>
    </Card>
  )
}

export default OfferCard