import React from 'react'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal, { ModalWithTitleProps } from '../../Dialog/Dialog'
import {BasicModalProps} from '../../Dialog/Dialog'

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useModals } from '../../../Contexts/DialogContext/DialogProvider';

interface ErrorDialogProps extends ModalWithTitleProps {
    errorMessage: string
}

function ErrorDialog({onClose, open, title, errorMessage} : ErrorDialogProps) {

  const {changeVisibility} = useModals()

  const handleClose = () => {
    if (onClose) {
      onClose()
    } else {
      changeVisibility!("error", false)
    }
  }


  
  return (
    <Modal
    onClose={handleClose}
    open={open}
    title={title}
    >
        <div className='flex column centered-aligned' style={{width: "100%", gap: "20px"}}>
          <div className='centered' style={{gap: "10px"}}>
            <div className='warning-container'>
              <InfoOutlinedIcon fontSize="inherit" />
            </div>
            <span style={{maxWidth: "100%"}}>
              {errorMessage}
            </span>
          </div>
            <CTAButton
            title='Ok!'
            onClickHandler={handleClose}
            backgroundColor="base"
            />
        </div>
    </Modal>
  )
}

export default ErrorDialog