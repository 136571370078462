import { Menu, MenuItem } from '@mui/material'
import React, { ReactNode } from 'react'
import styles from './ContextMenu.module.css'

interface IMenu {
  options: option[]
  anchorEl: null | HTMLElement
  onClose: (index: number) => void
  selected?: number | null
}

type option = {
  label: string
  onClick: (index: number) => void
  selectable: boolean
}

const handleSelectableClick = (event: React.MouseEvent<HTMLElement>, onClick: void) => {

}


const ContextMenu = ({ anchorEl, onClose, options, selected }: IMenu) => {
  const open = Boolean(anchorEl)

  return (
    <Menu
      classes={{
        paper: styles.container,
      }}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      {
        options.map((option, index) => (
          <MenuItem
            key={index}
            selected={selected == index}
            onClick={option.selectable ? (e) => { handleSelectableClick(e, option.onClick(index)); onClose(0); } : (e) => { option.onClick(index); onClose(0) }}
          >
            {
              option.label
            }
          </MenuItem>
        ))
      }
    </Menu>
  )
}

export default ContextMenu