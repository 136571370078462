import { CircularProgress } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IPerson, IPersonDTO, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreatePersonfromPhone from '../../Forms/CreatePersonfromPhone'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'


export const EditCreatePersonfromPhone = ({ open, onClose, object, mode }: any) => {


    const { changeVisibility } = useModals()

    const { addPerson, updatePerson, defaultPerson } = useContacts()

    const { enqueueSnackbar } = useSnackBar()

    const [personToSubmit, setPersonToSubmit] = useState<any>(defaultPerson!)

    const [loading, setLoading] = useState(false)

    //enqueueSnackbar("CreateFromPhone: Kontakt hinzufügen gestartet", { variant: "info" })


    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editCreatePersonfromPhone", false)
    }

    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (mode == "create") {
                await addPerson!(personToSubmit)
            } else {
                await updatePerson!(personToSubmit)
            }
            changeVisibility!("editCreatePersonfromPhone", false)
            setPersonToSubmit(defaultPerson!);
        } catch (error) {
            enqueueSnackbar(mode == "create" ? "Fehler beim Erstellen." : "Fehler beim Bearbeiten", { variant: "error" })
        } finally {
            setLoading(false)

        }
    }

    useEffect(() => {
        if (object?.telefonnummer) {
            setPersonToSubmit({ ...(defaultPerson!), telefonnummer: object?.telefonnummer, contactData: [{ id: 0, type: 1, value: object?.telefonnummer }, { id: 1, type: 2, value: "" }, { id: 2, type: 3, value: "" }] })
        }
    }, [object])
    
    return (
        <Modal
            open={open}
            title={mode == "create" ? "Telefonummer hinzufügen" : "Person bearbeiten"}
            onClose={() => handleClose()}
        >
            <CreatePersonfromPhone
                object={personToSubmit}
                setObject={setPersonToSubmit}
            />
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : (mode == "create" ? "Speichern" : "Speichern")}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}
