import { CircularProgress } from '@mui/material'
import React, { Dispatch, useEffect, useState } from 'react'
import { defaultCompany, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { defaultProduct, IProductDTO, useProducts } from '../../../Contexts/ProductContext/ProductProvider'
import { defaultStaff, StaffDTO, useStaff } from '../../../Contexts/StaffContext/StaffProvider'
import { IOrganization, IOrganizationDTO } from '../../../Interfaces/Company'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateCompanyForm from '../../Forms/CreateCompanyForm'
import CreateProductForm from '../../Forms/CreateProductForm'
import CreateEditPersonalForm from '../../PersonalCards/CreateEditPersonalForm'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'

export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

const EditCreatePersonalDialog = ({ open, onClose, object, mode }: dialogWithProps<StaffDTO>) => {

    const { updateStaff, addStaff } = useStaff();

    const { changeVisibility } = useModals()

    const [password, setPassword] = useState<string>("");
    const [confirmPassword, setConfirmPassword] = useState<string>("");
    const [staffToSubmit, setStaffToSubmit] = useState<StaffDTO>(object ?? defaultStaff);

    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editCreatePersonal", false)
    }

    useEffect(() => {
        setStaffToSubmit(object ?? defaultStaff)
    }, [object])

    const [loading, setLoading] = useState(false)

    const handleSubmit = async () => {
        try {
            setLoading(true)
            validateInput();
            if (mode == "create") {
                await addStaff!({ ...staffToSubmit, password: password })
            } else {
                await updateStaff!({ ...staffToSubmit, password: password })
            }
            changeVisibility!("editCreatePersonal", false)
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const [error, setError] = useState<any>({ usernameError: false, organizationRoleError: false, eMailError: false, passwordError: false, passwordNotMatchError: false });

    function validateInput() {
        let valid = true;
        if (staffToSubmit.username.length <= 4) {
            setError((old: any) => ({ ...old, usernameError: true }));
            valid = false;
        } else {
            setError((old: any) => ({ ...old, usernameError: false }));
        }

        if (!staffToSubmit.organizationRoleId || staffToSubmit.organizationRoleId <= 0) {
            setError((old: any) => ({ ...old, organizationRoleError: true }));
            valid = false;
        } else {
            setError((old: any) => ({ ...old, organizationRoleError: false }));
        }

        if (staffToSubmit.eMail.length <= 6 || !staffToSubmit.eMail.includes("@")) {
            setError((old: any) => ({ ...old, eMailError: true }));
            valid = false;
        } else {
            setError((old: any) => ({ ...old, eMailError: false }));
        }

        if (password.length < 8) {
            setError((old: any) => ({ ...old, passwordError: true }));
            valid = false;
        } else {
            setError((old: any) => ({ ...old, passwordError: false }));
        }

        if (password !== confirmPassword) {
            setError((old: any) => ({ ...old, passwordNotMatchError: true }));
            valid = false;
        } else {
            setError((old: any) => ({ ...old, passwordNotMatchError: false }));
        }

        if (staffToSubmit.employmentRelation !== undefined && staffToSubmit.employmentRelation !== null) {
            //Todo
        } else {

        }

        return valid;
    }

    return (
        <Modal
            open={open}
            title={mode == "create" ? "Personal erstellen" : "Personal bearbeiten"}
            onClose={() => handleClose()}
        >
            <CreateEditPersonalForm
                error={error}
                confirmPassword={confirmPassword}
                setConfirmPassword={setConfirmPassword}
                password={password}
                setPassword={setPassword}
                setStaff={setStaffToSubmit}
                staff={staffToSubmit}
            />
            <div style={{ height: "20px" }} />
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}

export default EditCreatePersonalDialog