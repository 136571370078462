import StartIcon from '@mui/icons-material/Start';
import { IconButton, Tooltip } from "@mui/material";
import dayjs from "dayjs";
import { useEffect, useLayoutEffect, useState } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "../../Components/UI/CardNew";
import { Sheet, SheetContent, SheetTrigger } from "../../Components/UI/Sheet";
import { AppointmentCategoryVisibilityTypes, useTasks } from "../../Contexts/TaskContext/TaskContext";
import { IHoliday, defaultHoliday } from "../../Contexts/TimeTrackingContext/TimeTrackingProvider";
import { useMobileView } from "../../Hooks/useMobileView";
import { IAppointment, defaultAppointment } from "../../Interfaces/Appointment";
import { ITaskDTO } from "../../Interfaces/Task";
import AppointmentInfoModal from "./AppointmentInfoModal/AppointmentInfoModal";
import CalendarHeader from "./CalendarHeader";
import Month from "./CalendarMonth";
import CalendarSidebar from "./CalendarSidebar";
import { getMonth, getWeek } from "./CalendarUtils";
import Week from "./CalendarWeek";
import HolidayInfoModal from "./HolidayInfoModal/HolidayInfoModal";
import TaskInfoModal from "./TaskInfoModal/TaskInfoModal";


function Calendar() {
    const [currentMonth, setCurrentMonth] = useState(getMonth());
    const [currentWeek, setCurrentWeek] = useState(getWeek())

    const { monthIndex, smallCalendarMonth, setMonthIndex, defaultTask, appointmentCategories } = useTasks();
    const [weekIndex, setWeekIndex] = useState(dayjs().week())
    const [showAppointmentInfoModal, setShowAppointmentInfoModal] = useState(false)
    const [clickedAppointment, setClickedAppointment] = useState<IAppointment>((defaultAppointment));
    const [selectedView, setSelectedView] = useState<string>("Monat")

    const [clickedTask, setClickedTask] = useState<ITaskDTO>((defaultTask!))
    const [showTaskInfoModal, setShowTaskInfoModal] = useState(false)

    const [clickedHoliday, setClickedHoliday] = useState<IHoliday>(defaultHoliday)
    const [showHolidayInfoModal, setShowHolidayInfoModal] = useState(false);

    // const [isMobile, setIsMobile] = useState(false);
    const [privateCheckedCategories, setPrivateCheckedCategories] = useState(appointmentCategories?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Private)
        .reduce((accum, category) => ({ ...accum, [category.id!]: true }),
            {} as { [categoryId: number]: boolean })); //.reduce is used to apply a function to turn two values of an array into a single value/object

    const [organizationCheckedCategories, setOrganizationCheckedCategories] = useState(appointmentCategories?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Organization)
        .reduce((accum, category) => ({ ...accum, [category.id!]: true }),
            {} as { [categoryId: number]: boolean }));

    useEffect(() => {
        setPrivateCheckedCategories(
            appointmentCategories
                ?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Private)
                .reduce((accum, category) => ({ ...accum, [category.id!]: true }), {} as { [categoryId: number]: boolean }));

        setOrganizationCheckedCategories(
            appointmentCategories
                ?.filter(ac => ac.visibility === AppointmentCategoryVisibilityTypes.Organization)
                .reduce((accum, category) => ({ ...accum, [category.id!]: true }), {} as { [categoryId: number]: boolean }));


    }, [appointmentCategories])

    //useLayoutEffect is used because otherwise it causes flickering when switching month
    useLayoutEffect(() => {
        setCurrentMonth(getMonth(monthIndex))
    }, [monthIndex])

    useLayoutEffect(() => {
        setCurrentWeek(getWeek(weekIndex))
    }, [weekIndex])

    useEffect(() => {
        if (smallCalendarMonth !== null) {
            setMonthIndex!(smallCalendarMonth);
        }
    }, [smallCalendarMonth])

    const isMobile = useMobileView();

    const [isSheetOpen, setIsSheetOpen] = useState(false);

    return (
        <Sheet modal={false} open={isSheetOpen} onOpenChange={setIsSheetOpen}>
            <Card>
                <CardHeader className="justify-between">
                    <CardTitle className="flex flex-row gap-2 items-center">
                        {isMobile &&
                            <SheetTrigger>
                                <div className={`rounded-full border ${isSheetOpen && "bg-brand-light"}`}>
                                    <Tooltip title="Kalender-Menü ausklappen">
                                        <IconButton>
                                            <StartIcon />
                                        </IconButton>
                                    </Tooltip>

                                </div>


                            </SheetTrigger>
                        }
                        Kalender</CardTitle>

                </CardHeader>
                <CardContent>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
                        <CalendarHeader selectedView={selectedView} setSelectedView={setSelectedView}
                            weekIndex={weekIndex} setWeekIndex={setWeekIndex} />

                        <div style={{ display: 'flex', flex: '1 1 0%', height: '92%' }}>
                            {!isMobile &&
                                <CalendarSidebar
                                    selectedView={selectedView}
                                    setWeekIndex={setWeekIndex}
                                    privateCheckedCategories={privateCheckedCategories}
                                    setPrivateCheckedCategories={setPrivateCheckedCategories}
                                    organizationCheckedCategories={organizationCheckedCategories}
                                    setOrganizationCheckedCategories={setOrganizationCheckedCategories}
                                />
                            }
                            {isMobile &&
                                <SheetContent side={"left"}>
                                    <CalendarSidebar
                                        selectedView={selectedView}
                                        setWeekIndex={setWeekIndex}
                                        privateCheckedCategories={privateCheckedCategories}
                                        setPrivateCheckedCategories={setPrivateCheckedCategories}
                                        organizationCheckedCategories={organizationCheckedCategories}
                                        setOrganizationCheckedCategories={setOrganizationCheckedCategories}
                                    />
                                </SheetContent>
                            }
                            {selectedView === "Monat"
                                ?
                                <Month month={currentMonth}
                                    selectedView={selectedView}
                                    showAppointmentInfoModal={showAppointmentInfoModal}
                                    setShowAppointmentInfoModal={setShowAppointmentInfoModal}
                                    setClickedAppointment={setClickedAppointment}
                                    setClickedTask={setClickedTask}
                                    setClickedHoliday={setClickedHoliday}
                                    setShowTaskInfoModal={setShowTaskInfoModal}
                                    showHolidayInfoModal={showHolidayInfoModal}
                                    setShowHolidayInfoModal={setShowHolidayInfoModal}
                                    showTaskInfoModal={showTaskInfoModal}
                                    privateCheckedCategories={privateCheckedCategories}
                                    organizationCheckedCategories={organizationCheckedCategories} />
                                :
                                <Week week={currentWeek}
                                    selectedView={selectedView}
                                    showAppointmentInfoModal={showAppointmentInfoModal}

                                    setShowAppointmentInfoModal={setShowAppointmentInfoModal}
                                    setClickedAppointment={setClickedAppointment}
                                    setClickedTask={setClickedTask}
                                    setClickedHoliday={setClickedHoliday}
                                    setShowTaskInfoModal={setShowTaskInfoModal}
                                    showHolidayInfoModal={showHolidayInfoModal}
                                    setShowHolidayInfoModal={setShowHolidayInfoModal}
                                    showTaskInfoModal={showTaskInfoModal}
                                    privateCheckedCategories={privateCheckedCategories}
                                    organizationCheckedCategories={organizationCheckedCategories} />
                            }


                        </div>
                    </div>
                </CardContent>



                {
                    showAppointmentInfoModal && <AppointmentInfoModal appointment={clickedAppointment} setAppointment={setClickedAppointment} showAppointmentInfoModal={showAppointmentInfoModal}
                        setShowAppointmentInfoModal={setShowAppointmentInfoModal}
                    />
                }
                {
                    showTaskInfoModal && <TaskInfoModal task={clickedTask} setTask={setClickedTask}
                        setShowTaskInfoModal={setShowTaskInfoModal} showTaskInfoModal={showTaskInfoModal} />
                }

                {
                    showHolidayInfoModal &&
                    <HolidayInfoModal holiday={clickedHoliday} setHoliday={setClickedHoliday} setShowHolidayInfoModal={setShowHolidayInfoModal} />
                }

            </Card >
        </Sheet>
    )
}

export default Calendar;