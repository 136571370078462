import React, { ReactNode, useContext, useEffect, useReducer } from 'react'
import { createContext } from 'react'
import useAuthConnection from '../../Hooks/useAuthConnection'
import { EmployeeReducer } from './EmployeeReducer'

export interface IEmployeeDTO {
    username: string
    active?: boolean
    organizationRoleName?: string
}

export interface IEmployee extends IEmployeeDTO {
    id: number
}

interface IEmployeeContext {
    employees: IEmployee[]
}

const EmployeeContext = createContext<IEmployeeContext>({
    employees: []
})

const EmployeeProvider = ({children} : {children: ReactNode}) => {

    const connection = useAuthConnection()
    

    const [state, dispatch] = useReducer(EmployeeReducer, {
        employees: []
    })

    const fetchEmployees = async () => {
        const {data} = await connection.get("/user")

        dispatch({
            type: "SET_EMPLOYEES",
            payload: data
        })
    }

    useEffect(() => {
        fetchEmployees()
    }, [])

    
  return (
    <EmployeeContext.Provider 
    value={{
        employees: state.employees
    }}
    >
        {children}
    </EmployeeContext.Provider>
  )
}

export default EmployeeProvider

export const useEmployees = () => useContext(EmployeeContext)