import { CircularProgress } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import uuid from 'react-uuid'
import { IContactData, IContactDTO, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IPerson } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useUser } from '../../../Contexts/UserContext/UserContext'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import PersonContactDataDynamicForm, { generateId } from '../../Forms/PersonContactDataDynamicForm'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import { ContactsTwoTone } from '@mui/icons-material'

function EditCreateContactDynamicDialog({ open, onClose, object, mode }: dialogWithProps<IPerson>) {


    const { changeVisibility } = useModals();

    const { enqueueSnackbar } = useSnackBar();

    const { user } = useUser();

    const { addContact, defaultContact, defaultPerson } = useContacts();

    const defaultPerson1: IPerson = {
        id: 0,
        firstname: "",
        lastname: "",
        salutation: "",
        organizationId: user?.organizationId ?? 14,
        note: "",
        belongsToOrganizationId: user?.organizationId ?? 14,
        title: "",
        status: 1,
        birthdate: null,
        contactData: [{id: generateId(), type: 1, value: ""}, {id: generateId(), type: 2, value: ""},{id: generateId(), type: 3, value: ""}]
    }

    const [loading, setLoading] = useState(false)


    const [person, setPerson] = useState<IPerson>(defaultPerson1)

    const setContactDataEdit = (newContactData: IContactData[]) => {
        if(person.contactData === undefined) {
            return;
        }

        const updatedContactData = [...person.contactData!];
    
        newContactData.forEach((newEntry) => {
          const index = updatedContactData.findIndex((data) => data.id === newEntry.id);
          if(index === -1) {
            updatedContactData.push(newEntry);
          } else {
            updatedContactData[index] = newEntry;
          }
        });
    
        const idsToRemove = updatedContactData.filter((data) => !newContactData.find((newEntry) => newEntry.id === data.id));
        idsToRemove.forEach((toRemove) => {
          const removeIndex = updatedContactData.findIndex((cd) => cd.id === toRemove.id);
          updatedContactData.splice(removeIndex, 1);
        });
        setPerson({...person, contactData: updatedContactData});
      }

    // if(object !== undefined) {
    //     setPerson(object)
    // }

    //const [contactData, setContactData] = useState<IContactData[]>(person.contactData ?? [...phoneFields, ...emailFields, ...websiteFields])

    const handleSubmit = async () => {
        try {
            setLoading(true)
            if (mode === "create") {
                let tempContactData: IContactDTO[] = []

                if (person.contactData !== undefined && person.contactData.length > 0) {
                    person.contactData.map((contact) => {
                        if (contact.value != "") {
                            const tempContact: IContactDTO = {
                                type: contact.type,
                                value: contact.value,
                                personId: person.id
                            }
                            tempContactData.push(tempContact)
                        }
                    })
                }
                await addContact!(tempContactData);
                changeVisibility!("editCreateContactDynamic", false);
            }
        } catch (error) {

            enqueueSnackbar(mode == "create" ? "Fehler beim Erstellen." : "Fehler beim Bearbeiten", { variant: "error" })
            console.log(error)
        } finally {
            setLoading(false)
        }
    }

    const handleClose = async () => {
        setLoading(false)
        if (onClose) {
            onClose()
        }
        changeVisibility!("editCreateContactDynamic", false)
    }


    const [personInitialized, setPersonInitialized] = useState(false);
    const objectReference = useRef<IPerson>();

    useEffect(() => {
        if((object !== undefined && object !== null && !personInitialized) || (object !== undefined && object !== null && objectReference.current !== object)) {
            setPerson({...object});
            setPersonInitialized(true);
            objectReference.current = object;
        }
    }, [person])

    return (
        <Modal
            open={open}
            title={mode === "create" ? "Kontaktdaten für " + person.completeName + " erstellen" : "Kontaktdaten von " + person.completeName + " bearbeiten"}
            onClose={handleClose}
        >
            <PersonContactDataDynamicForm
                object={object}
                contactData={object?.contactData}
                setContactData={setContactDataEdit}
            />
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}

export default EditCreateContactDynamicDialog