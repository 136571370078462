// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ContextMenu_container__6tbOu {\n    box-shadow: rgb(0 0 0 / 10%) 0px 3px 3px -3px, rgb(0 0 0 / 10%) 0px 6px 8px 1px, rgb(0 0 0 / 10%) 0px 3px 14px 2px !important;\n    border-radius: 10px !important;\n}", "",{"version":3,"sources":["webpack://./src/Components/Menu/ContextMenu.module.css"],"names":[],"mappings":"AAAA;IACI,6HAA6H;IAC7H,8BAA8B;AAClC","sourcesContent":[".container {\n    box-shadow: rgb(0 0 0 / 10%) 0px 3px 3px -3px, rgb(0 0 0 / 10%) 0px 6px 8px 1px, rgb(0 0 0 / 10%) 0px 3px 14px 2px !important;\n    border-radius: 10px !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "ContextMenu_container__6tbOu"
};
export default ___CSS_LOADER_EXPORT___;
