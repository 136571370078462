import React, { ReactNode } from 'react'

const Form = ({children, style} : {children: ReactNode, style?: React.CSSProperties}) => {
  return (
    <form className='form' style={{...style}}>
        {children}
    </form>
  )
}

export default Form