import { ExpandLessRounded, ExpandMoreRounded, MoreVertRounded } from '@mui/icons-material'
import { Collapse, IconButton, Tooltip } from '@mui/material'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { defaultCompany, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../Contexts/DialogContext/DialogProvider'
import { ContactDataType, IContactData } from '../../Contexts/ContactsContext/ContactsProvider'
import { getClassNames } from '../../Hooks/Utilities/Utlitites'
import { IOrganization } from '../../Interfaces/Company'
import IconTooltipButton from '../Buttons/IconTooltipButton/IconTooltipButton'
import { contactFormModes } from '../ContactCards/ContactFormCard'
import { generateId } from '../Forms/PersonContactDataDynamicForm'
import ContextMenu from '../Menu/ContextMenu'
import styles from './Company.module.css'
import CompanyField from './CompanyField'
import { PhoneCardModes } from '../PhoneCard/PhoneCard'
//import { usePersons } from '../../Contexts/PersonContext/PersonProvider'
import { IPerson, IPersonDTO } from '../../Contexts/ContactsContext/ContactsProvider'
import { IJournalFilter } from '../../Pages/Communication/EmailPage'

interface companyComp {
  company: IOrganization
  index?: number
  setClickedCompany?: Dispatch<SetStateAction<any>>
  journalFilter?: IJournalFilter
  setJournalFilter?: Dispatch<SetStateAction<IJournalFilter>>
  setFormMode?: Dispatch<SetStateAction<contactFormModes>>
  setPhoneCardMode?: Dispatch<SetStateAction<PhoneCardModes>>
  expanded?: boolean
  setExpandedElement?: any
}

const Company = ({ company, index, setClickedCompany, journalFilter, setJournalFilter, setFormMode, setPhoneCardMode, expanded, setExpandedElement }: companyComp) => {
  const { changeVisibility } = useModals()

  const { deleteCompany } = useContacts()

  const navigate = useNavigate();

  const handleExpand = () => {
    if (!expanded) {
      if (setFormMode) {
        setFormMode("companyEdit");
      }
      if (setPhoneCardMode) {
        setPhoneCardMode("organization")
      }
      if (setJournalFilter && journalFilter) {
        setJournalFilter((old: IJournalFilter) => ({ ...old, companyIds: [company.id!] }))
      }
    }
    else if (setClickedCompany) {
      setClickedCompany({ ...defaultCompany })
      if (setFormMode) {
        setFormMode("company");
      }
      if(setPhoneCardMode) {
        setPhoneCardMode("undefined");
      }
      if (setJournalFilter && journalFilter) {
        setJournalFilter((old: IJournalFilter) => ({ ...old, companyIds: old.companyIds.filter((temp: number) => temp !== company.id!) }))
      }
    }
    if (!expanded) {
      setClickedCompany!({ ...company })
      setExpandedElement(index)
    } else {
      setExpandedElement(undefined)
    }
  }

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)

  const handleOptionsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleEdit = () => {
    changeVisibility!("editCreateCompany", true, { props: { object: company, mode: "edit" } })
  }

//Zähler für Personen bei Firma
  //Ich benötige einige Anzahlen an Daten, zB. Firmen & Personen natürlich
  let firmenCnt = 0;
  let personenCnt = 0;  
  let kundenCnt = 0;
  
  //Anzahl d. Firmen
  const { companies } = useContacts();
  let tempCompanies = companies;
  firmenCnt = tempCompanies.length;

  //Anzahl Personen
  const { persons } = useContacts(); //es wird useContacts statt usePersonen verwendet, da das zukünftig nicht mehr gebräuchlich sein wird
  let tempPersons = persons;
  personenCnt = tempPersons.length;

    //Anzahl Person/Firma
    let tempCustomer = companies.filter((company: IOrganization) => company.customer === true)
    kundenCnt = tempCustomer.length;

    const [personCnt, setPersonCnt] = useState( persons.filter((person: IPersonDTO) => {
      //console.log("Prüfung: ", person, company);
      return person.belongsToOrganizationId === company.id}
      ).length)




  //Keys des Company-Objekts
  /*const keys = Object.keys(company).map((key) => {
    if (key == "id" || key == "name" || key == "eCockpitOrganizationId" || key == "status") return null
    if (company[key as keyof company] == "" || company[key as keyof company] == null) return null
    return key
  }).filter((val) => val != null)*/

  const companyData: IContactData[] = [
    { id: (1), type: ContactDataType.phone, value: company.telephone ?? "" },
    { id: (2), type: ContactDataType.mail, value: company.eMail ?? "" },
    { id: (3), type: ContactDataType.website, value: company.website ?? "" }
  ]

  return (
    <div className={getClassNames(['flex', 'column', styles.container])} 
    style={{ backgroundColor: (index ?? 2) % 2 == 0 ? "transparent" : "var(--ultra-light)", maxWidth: "100%", width: "100%" }}>
      <header className='flex centered-aligned stretched-justify pointer no-select' style={{}}>
        <div onClick={handleExpand} className='flex centered-aligned' 
        style={{ gap: "8px", width: "80%", paddingLeft: "3%" }}>
          <Tooltip title={(company.status ?? 1) == 1 ? "Ok!" : company.status == 2 ? "Mäßig." : company.status == 3 ? "Vorsicht." : "Konkurs/Geschlossen"} arrow placement="bottom">
            <div className={getClassNames([styles.accent])} style={{ backgroundColor: (company.status ?? 1) == 1 ? "green" : company.status == 2 ? "orange" : company.status == 3 ? "red" : "black", transition: "background-color 1s linear", paddingLeft: "2%" }} />
          </Tooltip>
          <span style={{ fontWeight: "500", maxWidth: "calc(80%)", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "100%" }}>{company.name}</span>
          {<div>{personCnt}</div>}{//hier wird die Anzahl der Presonen pro Firma i.d. Liste eingefügt. (Header von Companydata, neben dem Firmennamen, vor dem Aufklappzeichen)
          }
        </div>
        
        <div className='flex' style={{ gap: "-3px", width: "20%" }}>
          <div></div> {/*Hier kann ich die Personenanzahl je Firma berechnen und anzeigen, ACHTUNG: Format! */}
          <IconButton
            onClick={handleExpand}
            style={{ marginLeft: "-8px" }}
          >
            {!expanded ? <ExpandMoreRounded fontSize='small' /> : <ExpandLessRounded fontSize='small' />}
          </IconButton> 
          <IconTooltipButton
            title='Optionen'
            arrow
            onClick={handleOptionsClick}
          >
            <MoreVertRounded fontSize='small' />
          </IconTooltipButton>
       
        </div>
        <ContextMenu
          anchorEl={anchorEl}
          onClose={(index) => setAnchorEl(null)}
          options={[
            {
              label: "Bearbeiten",
              onClick: (index) => handleEdit(),
              selectable: false
            },
            {
              label: "Status ändern",
              onClick: (index) => changeVisibility!("editStatus", true, { props: { object: company } }),
              selectable: false
            },
            {
              label: "Laufzettel erstellen",
              onClick: (index) => navigate(`/laufzettel/organization/${company.id}`),
              selectable: false
            },
            {
              label: "Löschen",
              onClick: (index) => changeVisibility!("yesNoDialog", true, { props: { object: { submitFunction: () => { deleteCompany!(company) }, modalTitle: "Organisation löschen", confirmButtonText: "Löschen", modalText: "Möchten Sie diese Organisation wirklich löschen?" } } }),
              selectable: false
            },
          ]}
        />
        
      </header>
      
      <Collapse in={expanded}>
      
        <div style={{ width: "100%", height: "1px", backgroundColor: "var(--light-contrast)" }} />

        <CompanyField companyData={companyData} company={company} />

      

      </Collapse>
    </div>
  )
}

export default Company