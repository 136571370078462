import React, { useState } from 'react'
import { useCompanyData } from '../../Contexts/CompanyDataContext/CompanyDataProvider'
import { card } from '../../Interfaces/Card'
import { ICompanyData } from '../../Interfaces/CompanyData'
import Card from '../Card/Card'
import EditCompanyDataForm from '../Forms/EditCompanyDataForm'
import styles from './CompanyDataCard.module.css'

const CompanyDataCard = ({ width, height, order }: card) => {

  const { setCompanyData, companyData, updateCompanyData } = useCompanyData()
  const [loading, setLoading] = useState(false);

  return (
    <Card
      style={{ height, width, order }}
      title='Organisationsdaten'
      cta
      ctaTitle='Speichern'
      ctaFunction={() => { updateCompanyData!(companyData) }}
    >
      {/* @ts-ignore */}
      <EditCompanyDataForm object={companyData} setObject={setCompanyData} />
    </Card>
  )
}

export default CompanyDataCard