import React from 'react'
import BillsCard from '../../Components/BillCards/BillsCard'
import Page from '../../Navigation/Page'
import ReceiptsCard from '../../Components/ReceiptCards/ReceiptsCard'

const ManageReceipts = () => {
  return (
    <>
      <ReceiptsCard
        height='100%'
        width='100%'
      />
    </>
  )
}

export default ManageReceipts