import { Key, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";

interface Props {
    items: any[],
    itemsPerPage: number,
    page: number,
    setPage: Dispatch<SetStateAction<number>>,
}

const Pagination = ({ items, itemsPerPage, page, setPage }: Props) => {

    const [buttons, setButtons] = useState<any>([
        {
            icon: <KeyboardDoubleArrowLeft />
        },
        {
            icon: <b>1</b>
        },
        {
            icon: <KeyboardDoubleArrowRight />
        }
    ]);

    let tempPage = page;
    useEffect(() => { tempPage = page }, [page])

    function incrementPage() {
        setPage(tempPage + 1);
    }

    useEffect(() => {
        let tempButtons: any = [{
            icon: <KeyboardDoubleArrowLeft />,
            onClick: () => setPage((old) => old - 1),
            disabled: page - 1 < 0,
            index: page - 1
        }];

        let count = 0;
        let maxPages = items.length / itemsPerPage;
        for (let i = maxPages <= 5 ? 0 : (page - 2 > 0 ? page - 2 : 0); i < items.length / itemsPerPage && count < 5; i++) {
            count++;
            tempButtons.push({
                icon: (<b>{i + 1}</b>),
                onClick: () => setPage(i),
                index: i
            });
        }

        tempButtons.push({
            icon: <KeyboardDoubleArrowRight />,
            onClick: incrementPage,
            disabled: page + 1 > maxPages,
            index: page + 1
        });
        setButtons(tempButtons);
    }, [items, page])

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", width: "100%" }}>
                {buttons.map((button: any, index: number) => <>
                    <button className='base-button flex centered pointer' key={index}>
                        <Button
                            id="composition-button"
                            //aria-controls={open ? 'composition-menu' : undefined}
                            //aria-expanded={open ? 'true' : undefined}
                            aria-haspopup="true"
                            onClick={button.onClick}
                            style={{ color: button.disabled === true || button.index === page ? "lightgrey" : "black" }}
                            disabled={button.disabled ?? false /* TODO */}
                        >
                            {button.icon}
                        </Button>
                    </button>
                </>)}
            </div>
        </>
    )
}

export default Pagination;