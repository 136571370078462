import { useEffect, useState } from "react";
import { useModals } from "../../../Contexts/DialogContext/DialogProvider"
import { IPause, IWorkTime, useTimeTracking } from "../../../Contexts/TimeTrackingContext/TimeTrackingProvider"
import { useSnackBar } from "../../../Hooks/useSnackBar";
import Modal from "../../Dialog/Dialog";
import EditWorktimePauseAdminForm from "../../Forms/EditWorktimePauseAdminForm";
import { StaffDTO } from "../../../Contexts/StaffContext/StaffProvider";
import CTAButton from "../../Buttons/CTAButton/CTAButton";
import { CircularProgress } from "@mui/material";
import { useUser } from "../../../Contexts/UserContext/UserContext";
import dayjs, { Dayjs } from "dayjs";

export interface IPostscriptTimeAdminForm {
    open: boolean
    onClose?: () => void
    mode?: string | null
    object?: {
        t?: IWorkTime | IPause,
        staffMember?: StaffDTO,
        worktimeToPause?: IWorkTime
    }
}

const EditWorktimePauseAdminDialog = ({ open, onClose, object, mode }: IPostscriptTimeAdminForm) => {
    const { changeVisibility } = useModals();
    const { enqueueSnackbar } = useSnackBar();
    const { postscriptPause, postscriptWork, editWorktime, editPause } = useTimeTracking()
    const { user } = useUser()

    //Zum Zurücksetzen Zeit beim Öffnen/Schließen des Modals
    const defaultTime = {
        start: dayjs(),
        end: dayjs().add(1, 'hour')
    }
    const [time, setTime] = useState<{ start: Dayjs, end: Dayjs }>({
        ...defaultTime
    })
    const [objectToSubmit, setObjectToSubmit] = useState<IWorkTime | IPause | undefined | null>(object?.t);

    //*Ladestate beim Abschicken an die API
    const [loading, setLoading] = useState(false)

    const [title, setTitle] = useState<string>("");
    const [ctaTitle, setCtaTitle] = useState<string>("");

    const handleClose = async () => {
        setLoading(false)
        if (onClose) {
            onClose()
        }
        setTime(defaultTime)
        changeVisibility!("postscriptWorkPauseAdmin", false)
        if (mode === "editpause") {
            changeVisibility!("pausesOverviewAdmin", true, { props: { object: object?.worktimeToPause, mode: "admin" } })
        }
    }

    useEffect(() => {
        setObjectToSubmit(object?.t);
    }, [object])

    useEffect(() => {
        getMode(mode as string);
    }, [mode])

    function getMode(mode: string) {
        switch (mode) {
            case "editwork":
                setTitle(`Arbeitszeit bearbeiten`);
                setCtaTitle("Speichern");
                break;
            case "editpause":
                setTitle("Pause bearbeiten");
                setCtaTitle("Speichern");
                break;
            default:
                setTitle("Bearbeiten")
                setCtaTitle("Speichern")
        }
    }

    const handleSubmit = async () => {
        switch (mode) {
            case "editwork":
                try {
                    setLoading(true);
                    await editWorktime!({
                        id: objectToSubmit?.id ?? 0,
                        userId: objectToSubmit?.userId ?? 0,
                        organizationId: objectToSubmit?.organizationId ?? user?.organizationId!,
                        start: objectToSubmit?.start,
                        end: object?.t?.end ? objectToSubmit?.end : undefined
                    })
                } finally {
                    setLoading(false);
                }
                break;
            case "editpause":
                setLoading(true);
                await editPause!({
                    id: objectToSubmit?.id ?? 0,
                    userId: objectToSubmit?.userId ?? 0,
                    organizationId: objectToSubmit?.organizationId ?? user?.organizationId!,
                    start: objectToSubmit?.start ?? dayjs(),
                    end: object?.t?.end ? objectToSubmit?.end : undefined
                }).then(() => { setLoading(false) })
                break;
        }

    }

    return (
        <Modal
            open={open}
            title={title}
            onClose={handleClose}
        >
            <EditWorktimePauseAdminForm
                object={objectToSubmit ?? time}
                staffMember={object?.staffMember}
                setObject={objectToSubmit ? setObjectToSubmit : setTime}
            />
            <CTAButton
                style={{
                    marginTop: "20px"
                }}
                title={loading ? <CircularProgress size={18} color='inherit' /> : ctaTitle}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}

export default EditWorktimePauseAdminDialog