import React from 'react'
import { useMobileView } from '../../../Hooks/useMobileView'


function CardRow({height, width, children} : {width: "50%" | "66%" | "100%" | string, height: "33%" | "50%" | "66%" | "100%" | string, children: React.ReactNode}) {

  const mobile = useMobileView()

  return (
    <div className={`flex centered-aligned ${mobile ? "column" : undefined}`} style={{width: mobile ? "100%" : width, height, gap: "20px"}}>
        {children}
    </div>
  )
}

export default CardRow