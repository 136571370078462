import PdfViewer from '../../../Components/PDF/all-pages';
import Modal from "../../Dialog/Dialog"

const PdfPreviewDialog = ({ open, document, setOpen }: any) => {

    return <Modal
        open={open}
        title="Vorschau"
        onClose={() => { setOpen(false) }}
        style={{backgroundColor: "lightgrey"}}
    >
        <div style={{ backgroundColor: "lightgray", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
            {!document?.base64 &&
                <h2>Vorschau wird geladen</h2>
            }
            {document?.base64 &&
                <PdfViewer pdf={`data:${document ? document.fileEnding : ""};base64,${document?.base64}`} />
            }
        </div>
    </Modal>
}

export default PdfPreviewDialog;