// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchableItem_container__B9zmG {\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\n.SearchableItem_container__B9zmG:hover {\n    background-color: var(--light-contrast);\n}\n\n.SearchableItem_containerArrowHover__6gB-L {\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 5px;\n    background-color: var(--light-contrast);\n}", "",{"version":3,"sources":["webpack://./src/Components/InputField/SearchableItem.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,uCAAuC;AAC3C;;AAEA;IACI,iBAAiB;IACjB,eAAe;IACf,kBAAkB;IAClB,uCAAuC;AAC3C","sourcesContent":[".container {\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 5px;\n}\n\n.container:hover {\n    background-color: var(--light-contrast);\n}\n\n.containerArrowHover {\n    padding: 5px 10px;\n    cursor: pointer;\n    border-radius: 5px;\n    background-color: var(--light-contrast);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "SearchableItem_container__B9zmG",
	"containerArrowHover": "SearchableItem_containerArrowHover__6gB-L"
};
export default ___CSS_LOADER_EXPORT___;
