import { IPerson, IPersonDTO } from "../../../Contexts/ContactsContext/ContactsProvider"
import styles from "./SelectablePersonRow.module.css"

interface ISelectablePersonRow {
    person: IPerson
    index: number
    handleSelect: (id: number) => void
    rowIsChecked: (id: number) => boolean
}

function SelectablePersonRow({ person, index, handleSelect, rowIsChecked }: ISelectablePersonRow) {
    return (
        <div>
            <label className={styles.row} htmlFor={`checkboxSelectablePerson-${person.id}`}>
                <input className={styles.checkbox} type="checkbox" id={`checkboxSelectablePerson-${person.id}`} onChange={() => handleSelect(person.id)} checked={rowIsChecked(person.id)}/>
                <div className={styles.completeNameContainer}>
                    {person.firstname} {person.lastname}
                </div>
            </label>
        </div>
    )
}

export default SelectablePersonRow