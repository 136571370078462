import { CircularProgress } from '@mui/material'
import React, { Dispatch, useEffect, useState } from 'react'
import { defaultCompany, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { IIntervallDTO, useIntervalls } from '../../../Contexts/IntervallContext/IntervallProvider'
import { defaultProduct, IProductDTO, useProducts } from '../../../Contexts/ProductContext/ProductProvider'
import { IOrganization, IOrganizationDTO } from '../../../Interfaces/Company'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import CreateCompanyForm from '../../Forms/CreateCompanyForm'
import CreateProductForm from '../../Forms/CreateProductForm'
import EditCreateIntervallForm from '../../Forms/EditCreateIntervall'
import { dialog } from '../CreateServiceDialog/CreateServiceDialog'
import { validateIntervallForm } from '../../IntervallCards/IntervallDataCard'

export interface dialogWithProps<T> extends dialog {
    object: T | null
    mode?: string | null
}

const EditCreateIntervalDialog = ({ open, onClose, object, mode }: dialogWithProps<IIntervallDTO>) => {

    const { updateIntervall, addIntervall } = useIntervalls();

    const { changeVisibility } = useModals()

    useEffect(() => { setIntervallToSubmit(object) }, [object])

    const handleClose = () => {
        setLoading(false)
        if (onClose) {
            handleClose()
        }
        changeVisibility!("editCreateIntervall", false)
    }

    const [loading, setLoading] = useState(false)

    const [intervallToSubmit, setIntervallToSubmit] = useState<IIntervallDTO | undefined | null | any>(mode == "create" ? { active: true, sendEmailAutomatically: true } : object)

    useEffect(() => { console.log("Intervall", intervallToSubmit) }, [intervallToSubmit])
    useEffect(() => { console.log("Object", object) })


    const handleSubmit = async () => {
        try {
            if (validateIntervallForm(intervallToSubmit, setError)) {
                setLoading(true)
                if (mode == "create") {
                    await addIntervall!(intervallToSubmit)
                } else {
                    await updateIntervall!(intervallToSubmit)
                }
                changeVisibility!("editCreateIntervall", false)
            }
        } catch (error) {

        } finally {
            setLoading(false)
        }
    }

    const [error, setError] = useState<any>({})

    return (
        <Modal
            open={open}
            title={mode == "create" ? "Intervall erstellen" : "Intervall bearbeiten"}
            onClose={() => handleClose()}
            maxWidth={"lg"}
        >
            <EditCreateIntervallForm
                intervall={intervallToSubmit}
                setIntervall={setIntervallToSubmit}
                mode={mode}
                error={error}
                setError={setError}
            />
            <div style={{ height: "20px" }} />
            <CTAButton
                title={loading ? <CircularProgress size={18} color='inherit' /> : "Speichern"}
                onClickHandler={() => handleSubmit()}
                disabled={loading}
            />
        </Modal>
    )
}

export default EditCreateIntervalDialog