import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { IDepartement, useDepartements } from "../../Contexts/DepartementContext/DepartementProvider";
import { useContacts } from "../../Contexts/ContactsContext/ContactsProvider";
import { useStaff } from "../../Contexts/StaffContext/StaffProvider";
import { card } from "../../Interfaces/Card";
import Card from "../Card/Card";
import PersonalRow from "../PersonalCards/PersonalRow";
import DepartementRow from "./DepartementRow";
import Filter, { IFilterOption } from "../Filter/Filter";
import { DepartmentFormModes } from "./CreateDepartementCard";

interface IDepartmentOverviewCard extends card {
    setClickedDepartment?: Dispatch<SetStateAction<IDepartement>>
    setFormMode?: Dispatch<SetStateAction<DepartmentFormModes>>
}

function DepartementOverviewCard({ width, height, order, setClickedDepartment, setFormMode }: IDepartmentOverviewCard) {

    const { staff, fetchStaff } = useStaff();
    const { departements } = useDepartements();
    const { persons } = useContacts();

    const [cardDepartments, setCardDepartments] = useState<IDepartement[]>([])

    const originalCardDepartments = useMemo(() => departements, [departements])

    useEffect(() => {
        if(departements) {
            setCardDepartments(departements);
        }
        console.log(departements);
    }, [departements])

    const filterOptions: IFilterOption[] = [
        {title: "Abteilungsname", valueKey: "name"},
        {title: "Beschreibung", valueKey: "description"},
        {title: "E-Mail", valueKey: "eMail"}
    ]

    return (
        <Card
            title='Abteilungen'
            style={{ width, height, order }}
            fullHeightBody={true}
            right={() => <Filter dataToFilter={cardDepartments!} setDataToFilter={setCardDepartments} filterOptions={filterOptions} originalData={originalCardDepartments}/>}
        >
            {cardDepartments && cardDepartments.map((departement: IDepartement) => {
                return (
                    <DepartementRow key={departement.id} department={departement} staff={staff} persons={persons} setClickedDepartment={setClickedDepartment} setFormMode={setFormMode}/>
                )
            })}
        </Card>
    )
}

export default DepartementOverviewCard