import { useSnackbar } from "notistack";
import { BillPrefix } from "../../Interfaces/Accounting";
import { useAddBillPrefixMutation, useDeleteBillPrefixMutation, useEditBillPrefixMutation } from "../apiSlices/BillPrefix";
import { RTKQueryErrorHandler } from "./utils";

export const useHandleAddBillPrefix = () => {
    const [addBillPrefix, { isLoading, isError, error }] = useAddBillPrefixMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleAddBillPrefix = async (billPrefix: BillPrefix) => {
        let x = enqueueSnackbar("Rechnungskreis wird erstellt", { variant: "default" });

        try {
            const result = await addBillPrefix(billPrefix).unwrap();
            //Success
            closeSnackbar(x);
            enqueueSnackbar("Rechnungskreis erfolgreich erstellt", { variant: "success" });
        }
        catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error" })
        }
    }

    return { handleAddBillPrefix, isLoading, isError, error };
}

export const useHandleDeleteBillPrefix = () => {
    const [deleteBillPrefix, { isLoading, isError, error }] = useDeleteBillPrefixMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleDeleteBillPrefix = async (billPrefix: BillPrefix) => {
        let x = enqueueSnackbar("Rechungskreis wird entfernt", { variant: "default" });

        try {
            const result = await deleteBillPrefix(billPrefix).unwrap();
            //Success
            closeSnackbar(x);
            enqueueSnackbar("Rechnungskreis erfolgreich entfernt", { variant: "success" });
        } catch (err) {
            closeSnackbar(x);
            console.log(err);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
        }
    }

    return { handleDeleteBillPrefix, isLoading, isError, error };
}

export const useHandleEditBillPrefix = () => {
    const [editBillPrefix, { isLoading, isError, error, isSuccess }] = useEditBillPrefixMutation();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const handleEditBillPrefix = async (billPrefix: BillPrefix) => {
        let x = enqueueSnackbar("Rechnungskreis wird bearbeitet", { variant: "default" });

        try {
            const result = await editBillPrefix(billPrefix).unwrap();

            //Success
            closeSnackbar(x);
            enqueueSnackbar("Rechungskreis erfolgreich bearbeitet", { variant: "success" });
        }
        catch (err) {
            closeSnackbar(x);
            const errorMessage = RTKQueryErrorHandler(err);
            enqueueSnackbar(errorMessage, { variant: "error", autoHideDuration: 5000 });
        }
    }

    return { handleEditBillPrefix, isLoading, isError, error, isSuccess };
}
