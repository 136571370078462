import { AddRounded } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import Card from "../../Components/Card/Card";
import CardColumn from "../../Components/Card/CardColumn/CardColumn";
import NewsletterQueueRow from "../../Components/NewsletterQueueRow/NewsletterQueueRow";
import { INewsletterQueueItem, useContacts } from "../../Contexts/ContactsContext/ContactsProvider";

const NewsletterQueue = () => {
    const { newsletterQueue, newsletterDrafts } = useContacts();
    const navigate = useNavigate();

    return (
        <>
            <CardColumn
                height="100%"
                width="100%"
            >
                <Card
                    style={{ height: "100%", width: "100%" }}
                    title="Newsletter Warteschlange"
                    buttonIcon={<AddRounded fontSize='small' />}
                    ctaFunction={() => { navigate(`/kommunikation/Newsletter/bearbeiten/`) }}
                >
                    <div style={{ display: "flex", flexDirection: "column", gap: "0.75em" }}>
                        <div style={{ display: "flex", flexDirection: "row", width: "100%", alignItems: "center", justifyContent: "space-between", paddingLeft: "1em", paddingRight: "1em" }}>
                            <div style={{ display: "flex", flexDirection: "row", width: "90%", alignItems: "center", paddingLeft: "1em", paddingRight: "1em" }}>
                                <div style={{ display: "flex", flexDirection: "row", width: "20%", alignItems: "center", gap: "0.33em" }}>Status</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "25%", alignItems: "center", gap: "0.33em" }}>Titel</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "20%", alignItems: "center", gap: "0.33em" }}>Autor</div>
                                <div style={{ display: "flex", flexDirection: "row", width: "20%", alignItems: "center", gap: "0.33em" }}>Versendedatum</div>
                            </div>
                        </div>
                        {newsletterQueue?.map((queueItem: INewsletterQueueItem) => {
                            let draft = newsletterDrafts?.find((draft) => draft.id === queueItem.newsletterDesignId);

                            return <NewsletterQueueRow onPressCancelSchedule={() => { }} draft={draft!} queueItem={queueItem} onPressEdit={() => { navigate(`/kommunikation/Newsletter/bearbeiten/${draft!.id}`) }} />
                        })}
                    </div>
                </Card>
            </CardColumn>
        </>
    )

}

export default NewsletterQueue