//import { BrowserRouter, Navigate, Outlet, Route, Routes } from 'react-router-dom'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'

import { Notifications } from 'react-push-notification'
import GenerateWorktimeOverviewForUser from '../Components/PersonalCards/GenerateWorktimeOverviewForUser'
import { useBills } from '../Contexts/BillContext/BillProvider'
import { useUser } from '../Contexts/UserContext/UserContext'
import WebSocketComponent from '../Contexts/WebSocket/WebSocketComponent'
import Aufgaben from '../Pages/Aufgaben/Aufgaben'
import AccExport from '../Pages/Buchhaltung/AccExport'
import Accounting from '../Pages/Buchhaltung/Accounting'
import CheckBill from '../Pages/Buchhaltung/CheckBill'
import CreateBill from '../Pages/Buchhaltung/CreateBill'
import CreateBillPrefix from '../Pages/Buchhaltung/CreateBillPrefix'
import CreateOffer from '../Pages/Buchhaltung/CreateOffer'
import CreateReceipt from '../Pages/Buchhaltung/CreateReceipt'
import FirmenAbrechnung from '../Pages/Buchhaltung/FirmenAbrechnung'
import ManageBillPrefix from '../Pages/Buchhaltung/ManageBillPrefix'
import ManageBills from '../Pages/Buchhaltung/ManageBills'
import ManageOffers from '../Pages/Buchhaltung/ManageOffers'
import ManageReceipts from '../Pages/Buchhaltung/ManageReceipts'
import Calendar from '../Pages/Calendar/Calendar'
import EmailPage from '../Pages/Communication/EmailPage'
import Messenger from '../Pages/Communication/Messenger'
import Newsletter from '../Pages/Communication/Newsletter'
import NewsletterOverview from '../Pages/Communication/NewsletterOverview'
import NewsletterQueue from '../Pages/Communication/NewsletterQueue'
import SmsPage from '../Pages/Communication/SmsPage'
import SocialMedia from '../Pages/Communication/SocialMedia'
import Telefon from '../Pages/Communication/Telefon'
import Home from '../Pages/Home/Home'
import DatenerfassungsprotokollPage from '../Pages/Kassa/DatenerfassungsprotokollPage'
import Kassa from "../Pages/Kassa/Kassa"
import KassaVerifizierung from '../Pages/Kassa/KassaVerifizierung'
import Contacts from '../Pages/Kontakte/Contacts'
import TagAdministration from '../Pages/Kontakte/Tags/TagAdministration'
import DeliveryNote from '../Pages/Services/DeliveryNote/DeliveryNote'
import LieferscheinErstellen from '../Pages/Services/LieferscheinErstellen'
import Services from '../Pages/Services/Services'
import ServicesOverview from '../Pages/Services/ServicesOverview'
import SettingsCompany from '../Pages/Settings/SettingsCompany'
import SettingsPersonal from '../Pages/Settings/SettingsPersonal'
import CompanyStats from '../Pages/Statistiken/CompanyStats'
import Stats from '../Pages/Statistiken/Stats'
import Abteilungen from '../Pages/Verwaltung/Abteilungen'
import Ausgangsintervalle from '../Pages/Verwaltung/Ausgangsintervalle'
import Intervallleistungen from '../Pages/Verwaltung/Intervallleistungen'
import LaufzettelErstellen, { LaufzettelErstellenPerson } from '../Pages/Verwaltung/LaufzettelErstellen'
import Arbeitszeitenuebersicht from '../Pages/Verwaltung/Personal/Arbeitszeitenuebersicht'
import HolidayOverview from '../Pages/Verwaltung/Personal/Holiday/HolidayOverview'
import Personal from '../Pages/Verwaltung/Personal/Personal'
import Produkte from '../Pages/Verwaltung/Produkte'
import RootLayout from '../RootLayout'
import Dokumente from '../Pages/Verwaltung/Dokumente'
import posthog from 'posthog-js'
import { useEffect } from 'react'

//Der Router für den authentifizierten User
function AuthRouter() {

  const { user } = useUser()
  const { registrierkasseZertifikat, registrierkasse } = useBills();

  useEffect(() => {
    if (user) {
      posthog.identify(user.userId!.toString(), {
        username: user.username,
        email: user.email,
      });
      posthog.group("eCockpitOrganizationId", user.organizationId.toString());
    }
  }, [user])

  return (
    <BrowserRouter>
      <WebSocketComponent />
      <Notifications />
      {/*  <Navigation /> */}
      <Routes>

        <Route
          index
          element={<Navigate to="/dashboard" replace />}
        />

        <Route path="/" element={<RootLayout />} >

          <Route path="dashboard" element={<Home />} />

          {/* START Aufgaben Routes*/}
          <Route path="aufgaben" element={<Aufgaben />} />
          <Route path='aufgaben/lieferschein' element={<DeliveryNote />} />
          <Route path='aufgaben/admin' element={<Aufgaben adminView={true} />} />
          <Route path='aufgaben/lieferschein/erstellen' element={<LieferscheinErstellen />} />
          {/* END Aufgaben Routes*/}

          {/* START Leistungen Routes */}
          <Route path='leistungen' element={<Services />} />
          <Route path='leistungen/admin' element={<Services />} />
          <Route path='leistungen/servicesOverview' element={<ServicesOverview />} />
          {/* END Leistungen Routes */}

          <Route path='/statistiken' element={<Stats />} />
          <Route path='/statistiken/unternehmen' element={<CompanyStats />} />

          <Route path='kontakte' element={<Contacts />} />
          <Route path='/kontakte/tags' element={<TagAdministration />} />

          <Route path='calendar' element={<Calendar />} />

          <Route path='kommunikation/telefon' element={<Telefon />} />
          <Route path='kommunikation/sms' element={<SmsPage />} />
          <Route path='kommunikation/email' element={<EmailPage />} />
          <Route path='kommunikation/socialMedia' element={<SocialMedia />} />

          <Route path='kommunikation/newsletter/' element={<NewsletterOverview />} />
          <Route path='kommunikation/newsletter/bearbeiten/' element={<Newsletter />} />
          <Route path='kommunikation/newsletter/bearbeiten/:id' element={<Newsletter />} />
          <Route path='kommunikation/newsletter/warteschlange/' element={<NewsletterQueue />} />
          <Route path='kommunikation/messenger' element={<Messenger />} />

          <Route path='verwaltung/personal' element={<Personal />} />
          <Route path='verwaltung/personal/arbeitszeiten' element={<Arbeitszeitenuebersicht />} />
          <Route path='verwaltung/personal/arbeitszeituebersicht/erstellen/:id' element={<GenerateWorktimeOverviewForUser />} />
          <Route path='verwaltung/personal/urlaube' element={<HolidayOverview />} />
          <Route path='verwaltung/abteilungen' element={<Abteilungen />} />
          <Route path='verwaltung/produkte' element={<Produkte />} />
          <Route path='verwaltung/dokumente' element={<Dokumente />} />

          <Route path='laufzettel/organization/:id' element={<LaufzettelErstellen />} />
          <Route path='laufzettel/person/:id' element={<LaufzettelErstellenPerson />} />

          {/* RECHNUNGSWESEN START */}
          <Route path='rechnungswesen' element={<Accounting />} />

          <Route path='rechnungswesen/angebote' element={<ManageOffers />} />
          <Route path='rechnungswesen/angebote/erstellen' element={<CreateOffer />} />
          <Route path='rechnungswesen/angebote/bearbeiten/:id' element={<CreateOffer />} />

          <Route path='rechnungswesen/rechnungen' element={<ManageBills />} />
          <Route path='rechnungswesen/rechnungen/erstellen' element={<CreateBill />} />
          <Route path='rechnungswesen/rechnungen/ansicht' element={<CheckBill />} />
          <Route path='rechnungswesen/rechnungen/bearbeiten/:id' element={<CreateBill />} />

          <Route path='rechnungswesen/belege' element={<ManageReceipts />} />
          <Route path='rechnungswesen/belege/erstellen' element={<CreateReceipt />} />
          <Route path='rechnungswesen/belege/bearbeiten/:id' element={<CreateReceipt />} />

          <Route path='rechnungswesen/firmenabrechnung' element={<FirmenAbrechnung />} />

          <Route path='rechnungswesen/intervallleistungen' element={<Intervallleistungen />} />
          <Route path='rechnungswesen/ausgangsintervalle' element={<Ausgangsintervalle />} />

          <Route path='rechnungswesen/accExport' element={<AccExport />} />

          <Route path='rechnungswesen/rechnungskreise' element={<ManageBillPrefix />} />
          <Route path='rechnungswesen/rechnungskreise/erstellen' element={<CreateBillPrefix />} />
          {/* RECHNUNGSWESEN END */}

          <Route path='kassa' element={(registrierkasse && registrierkasseZertifikat) ? <Kassa /> : <KassaVerifizierung />} />
          <Route path='kassa/settings' element={<KassaVerifizierung />} />
          <Route path='kassa/datenerfassungsprotokoll' element={<DatenerfassungsprotokollPage />} />

          <Route path='einstellungen/privat' element={<SettingsPersonal />} />
          <Route path='einstellungen/unternehmen' element={<SettingsCompany />} />

        </Route>

      </Routes>
    </BrowserRouter >
  )
}

export default AuthRouter