import Page from '../../Navigation/Page'
import React, { useEffect, useRef, useState } from "react";
import CardColumn from "../../Components/Card/CardColumn/CardColumn";
import Card from '../../Components/Card/Card';
import { EmailEditor } from "react-email-editor"
import CTAButton from '../../Components/Buttons/CTAButton/CTAButton';
import FormRow from '../../Components/Forms/FormUtils/FormRow';
import { INewsletterDesign, useContacts } from '../../Contexts/ContactsContext/ContactsProvider';
import SaveNewsletterDraftDialog from '../../Components/Dialogs/SaveNewsletterDraftDialog/SaveNewsletterDraftDialog';
import { useUser } from '../../Contexts/UserContext/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import YesNoDialog from '../../Components/Dialogs/YesNoDialog/YesNoDialog';
import { AttachFile, Save, Send } from '@mui/icons-material';
import NewsletterWrapper from './NewsletterWrapper';
import AttachmentsDialog from '../../Components/Dialogs/AttachmentsDialog/AttachmentsDialog';

const Newsletter = () => {
    const { id } = useParams();

    const navigate = useNavigate();

    const onReady = () => {
        if (newsletterDraft.design === undefined) {
            let tempDraft = newsletterDrafts?.find(draft => draft.id === parseInt(id ?? "-1"));
            if (tempDraft) {
                console.log(tempDraft)
                setNewsletterDraft(tempDraft)
                editorRef.current.loadDesign(JSON.parse(tempDraft.design!))
            }
        }
    }

    const { tags, addNewsletterDraft, updateNewsletterDraft, scheduleNewsletter } = useContacts();
    const { user } = useUser();
    const { newsletterDrafts, newsletterSent } = useContacts();

    const editorRef = useRef<any>();

    const [recipientTags, setRecipientTags] = useState<Array<any>>([]);
    const [scheduledFor, setScheduledFor] = useState<Date>(new Date());
    const [recipientCount, setRecipientCount] = useState<number>(0);
    const [newsletterDraft, setNewsletterDraft] = useState<INewsletterDesign>({ authorId: user?.userId })
    const [attachmentsOpen, setAttachmentsOpen] = useState<boolean>(false);
    const [sendNewsletterOpen, setSendNewsletterOpen] = useState<boolean>(false);
    const [subject, setSubject] = useState<string>("");

    const exportHTML = () => {
        editorRef.current.exportHtml((data: any) => {
            const { design, html } = data;

        })
    }

    const sendNewsletter = () => {
        editorRef.current.exportHtml((data: any) => {
            const { design, html } = data;

            if (scheduleNewsletter) {
                scheduleNewsletter({ ...newsletterDraft, design: JSON.stringify(design), html: html, tagIds: recipientTags, scheduledFor: scheduledFor })
            }
        })
    }

    const saveDraft = () => {
        editorRef.current.exportHtml((data: any) => {
            const { design, html } = data;

            let x = newsletterDraft.title;
            if (newsletterDraft.title?.includes("Kopie") || newsletterDraft.title?.length === 0 || newsletterDraft.title === "" || newsletterDraft.title == null) {
                x = (html + "").split("<h1")[1].split("</h1>")[0].split(">")[1];

                if (newsletterDraft.title?.includes("Presseaussendung")) {
                    x = "Presseaussendung: " + x;
                }
            }

            setNewsletterDraft((old: INewsletterDesign) => ({ ...old, title: x, design: design, html: html, lastEdited: new Date((new Date()).toISOString()) }))
            if (!id) {
                if (addNewsletterDraft) {
                    addNewsletterDraft({ ...newsletterDraft, title: x, design: JSON.stringify(design), html: html, lastEdited: new Date((new Date()).toISOString()), files: [] })
                        .then((data) => {
                            setNewsletterDraft(data);
                            navigate(`/kommunikation/Newsletter/bearbeiten/${data.id}`)
                        })
                }
            } else {
                if (updateNewsletterDraft) {
                    updateNewsletterDraft({ ...newsletterDraft, title: x, design: JSON.stringify(design), html: html, lastEdited: new Date((new Date()).toISOString()), files: [] })
                }
            }
            setAttachmentsOpen(false);
        })
    }

    useEffect(() => {
        let temp = 0;
        tags.filter(tag => recipientTags.includes(tag.id)).forEach((tag) => temp += (tag.countOrganizations! + tag.countPersons!))
        setRecipientCount(temp);
    }, [recipientTags])

    useEffect(() => { console.log("NewsletterDraft", newsletterDraft) }, [newsletterDraft])



    return (
        <>
            {/* */}
            <CardColumn
                height='100%'
                width='100%'
            >
                <Card
                    style={{ height: "100%" }}
                    fullHeightBody
                    title="Newsletter"
                    disableScroll
                    right={() => <div style={{ width: "25%", display: "flex", flexDirection: "row", gap: "1em" }}><CTAButton endIcon={<AttachFile />} title="Anhänge" onClickHandler={() => { setAttachmentsOpen(true) }}></CTAButton><CTAButton endIcon={<Send />} title="Versenden" onClickHandler={() => { setSendNewsletterOpen(true) }}></CTAButton></div>}
                >
                    <div style={{ display: "flex", flexDirection: "row", gap: "1em", justifyContent: "space-between" }}>
                        <FormRow
                            label={`Empfänger (Anzahl Empfänger: ${recipientCount})`}
                            onChange={(val) => {
                                setRecipientTags(val);
                            }}
                            value={recipientTags}
                            type='select'
                            selectOptions={{
                                options: tags ?? [],
                                titleKey: "title",
                                valueKey: "id",
                                multiple: true
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "1em", justifyContent: "space-between" }}>
                        <FormRow
                            label={`Newsletter versenden am`}
                            onChange={(val: Date) => {
                                setScheduledFor(val);
                            }}
                            value={scheduledFor}
                            type='date'
                            dateOptions={{
                                disablePast: true,
                                type: "DateTime"
                            }}
                        />
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", gap: "1em", justifyContent: "space-between", marginBottom: "1em" }}>
                        <FormRow
                            label={`Betreff`}
                            onChange={(val: string) => { setNewsletterDraft((old: any) => ({ ...old, title: val })) }}
                            value={newsletterDraft.title}
                            type='text'
                        />
                    </div>
                    <EmailEditor
                        style={{ height: "550px" }}
                        ref={editorRef}
                        onReady={onReady}
                    />
                    <div style={{ display: "flex", flexDirection: "column", gap: "1em", marginTop: "1em", width: "100%" }}>
                        <CTAButton startIcon={<Save />} title="Speichern" onClickHandler={() => { saveDraft() }}></CTAButton>
                    </div>
                </Card>
            </CardColumn>
            <YesNoDialog
                open={sendNewsletterOpen}
                object={
                    {
                        confirmButtonText: "Speichern",
                        cancelButtonText: "Zurück",
                        cancelFunction: () => { setSendNewsletterOpen(false) },
                        modalText: `Wollen Sie den Newsletter "${newsletterDraft.title}" wirklich für die eingegebene Uhrzeit planen?`,
                        modalTitle: "Newsletter planen bestätigen",
                        submitFunction: () => { sendNewsletter(); setSendNewsletterOpen(false); },
                    }
                }
                onClose={() => { setSendNewsletterOpen(false) }}
            />
            <AttachmentsDialog onSubmit={saveDraft} onClose={() => { setAttachmentsOpen(false) }} open={attachmentsOpen} object={newsletterDraft} setObject={setNewsletterDraft} />
        </>
    )
};

export default Newsletter;
