import { IOrganizationDTO } from "../../../Interfaces/Company"
import styles from "./OrganizationToTagRow.module.css"

interface IOrganizationToTagRow {
    organization: IOrganizationDTO
    index: number
    handleSelect: (id: number) => void
    rowIsChecked: (id: number) => boolean
}

function OrganizationToTagRow({ organization, index, handleSelect, rowIsChecked }: IOrganizationToTagRow) {
    return (
        <div>

            <label className={styles.row} htmlFor={`checkboxOrganizationToTag-${organization.id}`}>
                <input className={styles.checkbox} type="checkbox" id={`checkboxOrganizationToTag-${organization.id}`} onChange={() => handleSelect(organization.id!)} checked={rowIsChecked(organization.id!)}/>
                <div className={styles.nameContainer}>{organization.name}</div>
            </label>
        </div>
    )
}

export default OrganizationToTagRow