import { Upload } from "@mui/icons-material";
import { Button, CircularProgress } from "@mui/material";
import { Dispatch, RefObject, SetStateAction, useRef, useState } from "react";
import { createWorker } from "tesseract.js";

//Regex pattern for recognizing Emails
const patternEmail = /[\w-\.]+@([\w-]+\.)+[\w-]{2,4}/g;

//Regex pattern for recognizing phone numbers
const patternTelefonnummer = /(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{1,3}\)?[\s.-]?\d{1,3}?[\s.-]?\d{1,3}?[\s.-]?\d{1,3}?[\s.-]?\d{1,3}[\s.-]?\d{1,4}/g;

//Regex pattern for recognizing the city
const patternStadt = /(\d{4,5})(\s)([A-zöüäß]{3,})(\s)([A-zöüäß]{2})(\s)([A-zöüäß]{3,})|(\d{4,5})(\s)([A-zöüäß]{3,})/g;

//Regex pattern for recognizing the street
const patternStrasse = /([A-zöüäß-]{3,}\s\d{1,4})|([A-zöüäß]{3,}\s[A-zöüäß]{3,}\s\d{1,4})/g;

//Regex pattern for the name of the person
const patternName = /[A-Z][a-z'-]+(?: [A-Z][a-z'-]+)*/;

//Creates the object if the Visitenkarte
export interface IVisitenkarte {
  firstname?: string,
  lastname?: string,
  street?: string,
  housenumber?: number,
  postalcode?: number,
  city?: string,
  telephone?: Array<string>,
  email?: string,
  website?: string
}

interface ProcessVisitenkarteProps {
  imageUri: string,
  setProgress?: Dispatch<SetStateAction<number>>
}

//this generates permutations from email like firstname.lastname@domain.tld this generates an array ["firstname lastname", "lastname firstname", ...]
//this allows us to recognize names from email adresses like firstname.lastname@domain.tld, firstname@lastname.tld, etc. 
function generatePermutations(inputArray: Array<string>) {
  const result: Array<string> = [];

  function permute(arr: Array<string>, permuted?: Array<string>) {
    if (!permuted) {
      permuted = [];
    }
    if (permuted.length >= 2) {
      result.push(permuted.join(' '));
    }
    if (arr.length === 0) {
      return;
    }

    for (let i = 0; i < arr.length; i++) {
      const rest = arr.slice(0, i).concat(arr.slice(i + 1));
      permute(rest, permuted.concat(arr[i]));
    }
  }

  permute(inputArray);

  return result;
}

function findSubstringIgnoreCase(mainString: string, searchString: string) {
  //Convert both the main string and the search string to lowercase (or uppercase)
  const mainStringLower = mainString.toLowerCase();
  const searchStringLower = searchString.toLowerCase();

  // Use indexOf() to find the lowercase substring
  const indexOfSubstring = mainStringLower.indexOf(searchStringLower);

  if (indexOfSubstring !== -1) {
    // Use the index to extract the original substring with the original casing
    const matchedSubstring = mainString.substring(indexOfSubstring, indexOfSubstring + searchString.length);
    return matchedSubstring;
  } else {
    // Return null or handle the case when the substring is not found
    return null;
  }
}

//Generell function for processing the Visitenkarte
export const processVisitenkarte = async (imageUri: string, setProgress: Dispatch<SetStateAction<number>>) => {
  const worker = createWorker({
    logger: (m: any) => {
      if (setProgress && m.status == 'recognizing text') {
        const progress = Math.round(m.progress * 100);
        console.log("Progress", progress)
        setProgress(progress);
      }
    },
  });

  await worker.load();
  await worker.loadLanguage("deu");
  await worker.initialize("deu");
  await worker.setParameters({});
  const {
    data: { text }, // text is the text that tesseract recognizes
  } = await worker.recognize(imageUri);

  console.log("Text", text);

  //create new Visitenkarte Object
  let visitenkarteObj: IVisitenkarte = {};

  //Matches the pattern for the name and saves it to the Object
  function addNames() {
    if (visitenkarteObj.email) {
      let email = visitenkarteObj.email;

      const emailText = email.replace(/[._%+-@]/g, ' '); // Remove special characters
      const emailStrings = emailText.split(/\s+/);
      emailStrings.pop(); //get rid of tld

      //const combinationStrings = wordPermutations.map((permutation: string) => permutation.join(' '));
      let potentialNames = generatePermutations(emailStrings); // Remove empty spaces
      console.log("potentialNames", potentialNames);

      let potentialName = "";

      potentialNames.forEach((name: string) => {
        const matchedSubstring = findSubstringIgnoreCase(text, name);

        if (matchedSubstring && matchedSubstring.length > potentialName.length) {
          potentialName = matchedSubstring;
        }
      });

      if (potentialName) {
        let strings = potentialName.split(' ');
        visitenkarteObj.firstname = "";
        visitenkarteObj.lastname = "";
        strings.forEach((string: string, index: number) => {
          if (index < strings.length - 1) {
            visitenkarteObj.firstname += string;
          } else {
            visitenkarteObj.lastname = string;
          }
        });
      }
      //this will be extended in the future
    } else {
      const matches = text.match(patternName);
      if (matches) {
        let nameParts = matches[0].split(' ');
        visitenkarteObj.firstname = nameParts[0];
        visitenkarteObj.lastname = nameParts[1];
      }
    }
  }

  //Matches the pattern for the EMail and saves it to the Object
  const findMail = () => {
    const matches = text.match(patternEmail);
    if (matches) {
      visitenkarteObj.email = matches[0];
    }
  }

  //Matches the pattern for the phone number and saves it to the Object
  const findTelefonnummer = () => {
    const matches = text.match(patternTelefonnummer);
    if (matches) {
      visitenkarteObj.telephone = matches;
    }
  }

  //Matches the pattern for the address (city and street) and saves it to the Object
  const findAdresse = () => {
    const matchStadt = text.match(patternStadt);
    if (matchStadt) {
      let stadtParts = matchStadt[0].split(' ');
      visitenkarteObj.postalcode = parseInt(stadtParts[0]);
      visitenkarteObj.city = stadtParts[1];
    }

    const matchStrasse = text.match(patternStrasse);
    if (matchStrasse) {
      let strasseParts = matchStrasse[0].split(' ');
      visitenkarteObj.housenumber = parseInt(strasseParts[strasseParts.length - 1]);
      visitenkarteObj.street = strasseParts.length == 2 ? strasseParts[0] : strasseParts[0].concat(" ").concat(strasseParts[1]);
    }
  }

  //All the functions get called here for extracting the infos
  findMail();
  findTelefonnummer();
  findAdresse();
  addNames();

  console.log("visitenkarteObj", visitenkarteObj)

  return visitenkarteObj;
};


export interface ScanVisitenkartenButtonProps {
  inputRef: RefObject<HTMLInputElement>,
  setImageData: Dispatch<SetStateAction<string>>,
  setVisitenkarte: Dispatch<SetStateAction<IVisitenkarte | undefined>>
}

//this scales down the image, so we can speed up recognizing text
//we could furthermore improve performance by seperating the picture
export function optimizeImage(imageUri: string): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.src = imageUri;

    image.onload = () => {
      const canvas = document.createElement('canvas');
      const maxWidth = 1920;
      const maxHeight = 1080;

      let width = image.width;
      let height = image.height;

      if (width > maxWidth || height > maxHeight) {
        if (width / maxWidth > height / maxHeight) {
          height *= maxWidth / width;
          width = maxWidth;
        } else {
          width *= maxHeight / height;
          height = maxHeight;
        }
      }

      canvas.width = width;
      canvas.height = height;

      const ctx = canvas.getContext('2d');
      ctx?.drawImage(image, 0, 0, width, height);

      const downscaledImageUrl = canvas.toDataURL('image/jpeg', 0.6);

      // Get the downscaled file size
      fetch(downscaledImageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          resolve(downscaledImageUrl)
        })
        .catch((error) => {
          reject(error);
        });
    };
  })
}

//Example
export const ScanVisitenkarteButton = ({ setImageData, setVisitenkarte }: ScanVisitenkartenButtonProps) => {
  const [ocrProgress, setOcrProgress] = useState(0);

  const inputRef = useRef<any>();

  function handleImageChange(e: any) {
    const file = e.target.files[0];
    if (file) {
      setOcrProgress(0);
      const reader = new FileReader();
      reader.onloadend = () => {
        const imageDataUri = reader.result as string;
        setImageData(imageDataUri);

        optimizeImage(imageDataUri)
          .then((downscaledImageDataUri: string) => {
            processVisitenkarte(downscaledImageDataUri, setOcrProgress).then((res) => { setVisitenkarte(res) });
          })
      };
      reader.readAsDataURL(file);
    }
  }

  return <>
    <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
      <Button onClick={() => { inputRef?.current?.click(); }} variant='text' style={{ color: "black", borderColor: "black" }}>
        Visitenkarten scan <Upload />
        <input ref={inputRef} style={{ display: "none" }} type='file' accept="image/*" onChange={handleImageChange} />
      </Button>
      {(ocrProgress !== 0 && ocrProgress !== 100) &&
        <div style={{ width: "100%", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
          <div>Einlesen...</div>
          <CircularProgress variant="indeterminate" value={ocrProgress} />
        </div>}
    </div>
  </>
}