import { Avatar, Button, CircularProgress } from '@mui/material'
import { AxiosResponse } from 'axios'
import { useEffect, useRef, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useStaff } from '../../../Contexts/StaffContext/StaffProvider'
import { useUser } from '../../../Contexts/UserContext/UserContext'
import useAuthConnection from '../../../Hooks/useAuthConnection'
import { useSnackBar } from '../../../Hooks/useSnackBar'
import { IService } from '../../../Interfaces/Service'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
/* @ts-ignore */
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'
import { useDebounceEffect } from './useDebounceEffect'
import { canvasPreview } from './canvasPreview'
import 'react-image-crop/dist/ReactCrop.css'
import styles from '../../ProfilePictureCard/ProfilePictureCard.module.css'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { stringToColour } from '../../../Navigation/Navigation'
import { IPerson, IPersonDTO, useContacts } from '../../../Contexts/ContactsContext/ContactsProvider'

function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

function PersonProfilePictureDialog({ open, onClose, object, setObject, mode }: any) {
  const { changeVisibility } = useModals()
  const { enqueueSnackbar } = useSnackBar()
  const { fetchStaff } = useStaff();
  const { user } = useUser();
  const { setProfilePicture } = useContacts();

  var textColorToBackground = function (str: string) {
    var rgb = [0, 0, 0];
    rgb[0] = parseInt(str.substring(1, 3), 16);
    rgb[1] = parseInt(str.substring(3, 5), 16);
    rgb[2] = parseInt(str.substring(5, 8), 16);

    const brightness = Math.round(((rgb[0] * 299) + (rgb[1] * 587) + (rgb[2] * 114)) / 1000);

    if (brightness > 125) {
      return "black"
    } else {
      return "white"
    }
  }

  const avatarBgColor = stringToColour(user?.username ?? "");
  const avatarTextColor = textColorToBackground(avatarBgColor);



  const [fileName, setFileName] = useState<any>();
  const [preview, setPreview] = useState<any>();

  //Zum Zurücksetzen Zeit beim Öffnen/Schließen des Modals
  const defaultTime = {
    start: new Date(),
    end: new Date()
  }

  const [time, setTime] = useState<{ start: Date, end: Date }>({
    ...defaultTime
  })

  //*Ladestate beim Abschicken an die API
  const [loading, setLoading] = useState(false)

  const connection = useAuthConnection();

  const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result)
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }


  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }



  const handleSubmit = async () => {
    try {
      setLoading(true)
      previewCanvasRef.current?.toBlob((blob) => {
        blobToBase64(blob).then((bob64: any) => {
          let formData = new FormData();
          formData.append("file", blob!)

          console.log(bob64);

          if (object?.id > 100) {
            if (setProfilePicture) {
              setProfilePicture(formData, object!.id!).then(res => setObject((old: IPersonDTO) => ({ ...old, profilePictureBase64: res })))
            }
          } else {
            setObject((old: IPersonDTO) => ({ ...old, profilePictureBase64: bob64 }));
          }
        });
      })

      resetData();
      handleClose();
    } catch (error) {
      enqueueSnackbar(`Fehler beim Nachtragen.`, { variant: "error" })
      console.error(error)
    } finally {
      setLoading(false)
    }

  }

  const handleClose = async () => {
    setLoading(false)
    if (onClose) {
      onClose()
    }
    setTime(defaultTime)
    changeVisibility!("editCreateProfilePicture", false)
  }

  const [imgSrc, setImgSrc] = useState('')
  const previewCanvasRef = useRef<HTMLCanvasElement | null>(null)
  const imgRef = useRef<HTMLImageElement | null>(null)
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(1)

  const resetData = () => {
    setImgSrc('');
    setCompletedCrop(undefined)
    //setCrop(undefined);
    setScale(1);
    setRotate(0);
    setAspect(1);
    previewCanvasRef.current = null;
    imgRef.current = null;
  }

  function onSelectFile(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader()
      reader.addEventListener('load', () =>
        setImgSrc(reader.result?.toString() || ''),
      )
      reader.readAsDataURL(e.target.files[0])
    }
  }

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  function handleToggleAspectClick() {
    if (aspect) {
      setAspect(undefined)
    } else if (imgRef.current) {
      const { width, height } = imgRef.current
      setAspect(1)
      setCrop(centerAspectCrop(width, height, 1))
    }
  }

  useEffect(() => { console.log(object) }, [object])

  return (
    <Modal
      open={open}
      title={"Profilbild zu Person hochladen"}
      onClose={handleClose}
    >
      <section className='flex column' style={{ marginBottom: "1.5vh", alignItems: "center", justifyContent: "center", gap: "5px" }}
      >
        <Avatar
          src={object?.profilePictureBase64 ? `data:image/jpeg;base64,${object?.profilePictureBase64.includes("base64,") ? object.profilePictureBase64.split("base64,")[1] : object.profilePictureBase64}` : `https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png`}
          alt={user?.username?.substring(0, 2)}
          sx={{ width: 100, height: 100, backgroundColor: avatarBgColor, color: avatarTextColor }}
        >
        </Avatar>
        <span className='subheading'>{object?.completeName ?? ""}</span>
      </section>
      <div className={styles['picture-upload-container']}>

        <Button color='primary' variant='outlined' component="label" style={{ marginBottom: "1vh" }}>
          <input hidden type="file" accept="image/*" onChange={onSelectFile} />
          <AddAPhotoIcon color='action' style={{ marginRight: "5px" }} />
          Datei auswählen
        </Button>
      </div>
      {!!imgSrc && (
        <>
          <div className={styles['text']}>Profilbild anpassen:</div>
          <div className={styles['crop-container']}>

            <ReactCrop
              crop={crop}
              onChange={(_, percentCrop) => { setCrop(percentCrop); }}
              onComplete={(c) => setCompletedCrop(c)}
              aspect={aspect}
            >
              <img
                ref={imgRef}
                alt="Crop me"
                src={imgSrc}
                style={{ transform: `scale(${scale}) rotate(${rotate}deg)`, width: "100%" }}
                onLoad={onImageLoad}
              />
            </ReactCrop>
          </div>
        </>
      )}
      {!!completedCrop && (
        <>
          <div className={styles['text']}>Vorschau:</div>
          <div className={styles['completed-crop-container']}>

            <canvas
              ref={previewCanvasRef}
              style={{
                border: '1px solid black',
                objectFit: 'contain',
                width: completedCrop.width,
                height: completedCrop.height,
              }}
            />
          </div>
        </>
      )}

      <CTAButton
        style={{
          marginTop: "20px"
        }}
        title={loading ? <CircularProgress size={18} color='inherit' /> : "Profilbild aktualisieren"}
        onClickHandler={() => handleSubmit()}
        disabled={loading}
      />
    </Modal>
  )
}

export default PersonProfilePictureDialog