import { throws } from 'assert'
import React, { createContext, ReactNode, useContext, useReducer } from 'react'
import { isIterable } from '../../Components/Task/TaskExpandable'
import { deliveryNoteContext } from '../../Interfaces/DeliveryNoteContext'
import { deliveryTask, ITask } from '../../Interfaces/Task'
import { DeliveryNoteReducer } from './DeliveryNoteReducer'

const DeliveryNoteContext = createContext<deliveryNoteContext>({
    tasksToDeliver: [],
    companyID: null
})

//TODO Lieferschein-Erstellung -> Fabian

function DeliveryNoteProvider({ children }: { children: ReactNode }) {

    const [state, dispatch] = useReducer(DeliveryNoteReducer, {
        tasksToDeliver: [],
        companyID: null
    })

    //Setzt die ID des Unternehmens in den State -> Kontrolle für das Hinzufügen von neuen Tasks in den Lieferschein
    const setCompanyID = (id: number | null) => {
        dispatch({
            type: "SET_COMPANYID",
            payload: id
        })
    }

    const addTaskToDeliver = (task: ITask) => {
        if (task.organizationId != state.companyID && state.companyID != null) {
            throw "Multiple Companies"
        } else {
            setCompanyID(task.organizationId!)

            dispatch({
                type: "ADD_TASK_TO_DELIVER",
                payload: task
            })
        }

    }

    const removeTaskToDeliver = (id: number) => {
        dispatch({
            type: "REMOVE_TASK_TO_DELIVER",
            payload: id
        })

        if (state.tasksToDeliver.length === 1) {
            setCompanyID(null)
        }


    }

    const getCountOfTasksInDeliveryNote = (tasks: ITask[]): number => {
        let count = 0

        const ids: number[] = state.tasksToDeliver.map((task: deliveryTask) => task.id)

        if (isIterable(tasks)) {
            tasks.forEach((task) => {
                if (ids.includes(task?.id ?? 0)) {
                    count++
                }
            })
        }


        return count
    }


    return (
        <DeliveryNoteContext.Provider
            value={{
                //Werte
                tasksToDeliver: state.tasksToDeliver,
                companyID: state.companyID,

                //Funktionen
                getCountOfTasksInDeliveryNote,
                addTaskToDeliver,
                removeTaskToDeliver

            }}
        >
            {children}
        </DeliveryNoteContext.Provider>
    )
}

export default DeliveryNoteProvider

export const useDeliveryNote = () => useContext(DeliveryNoteContext)