import { IconButton, Tooltip } from '@mui/material'
import React, { ReactNode } from 'react'

type placement = "bottom-end" | "bottom-start" | "bottom" | "left-end" | "left-start" | "left" | "right-end" | "right-start" | "right" | "top-end" | "top-start" | "top" | undefined

function IconTooltipButton({children, title, placement, arrow, onClick, style} : {children : ReactNode, title?: string, placement?: placement, arrow?: boolean, onClick?: (e: React.MouseEvent<HTMLElement>) => void, style?: React.CSSProperties}) {
  
  if (title) {
    return (
      <Tooltip title={title} placement={placement} arrow={arrow ?? false}>
          <IconButton size='small' style={{...style}} onClick={onClick ? (e) => onClick(e) : () => null}>
              {children}
          </IconButton>
      </Tooltip>
    )
  } else {
    return (
      <IconButton size='small' style={{...style}} onClick={onClick ? (e) => onClick(e) : () => null}>
          {children}
      </IconButton>
    )
  }
}

export default IconTooltipButton