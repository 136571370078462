import React, { useEffect, useState } from 'react'
import { useModals } from '../../../Contexts/DialogContext/DialogProvider'
import { useTasks } from '../../../Contexts/TaskContext/TaskContext'
import { ITask, ITaskDTO } from '../../../Interfaces/Task'
import CTAButton from '../../Buttons/CTAButton/CTAButton'
import Modal from '../../Dialog/Dialog'
import FormRow from '../../Forms/FormUtils/FormRow'
import { dialogWithProps } from '../EditCreateCompanyDialog/EditCreateCompanyDialog'
import { card } from '../../../Interfaces/Card'
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import Card from '../../Card/Card'
import { Bar } from 'react-chartjs-2';
import useAuthConnection from '../../../Hooks/useAuthConnection'
import { AxiosResponse } from 'axios'
import { KeyboardArrowLeft, KeyboardArrowRight, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight } from '@mui/icons-material'
import { Button, CircularProgress } from '@mui/material'



ChartJS.register(
    BarElement, CategoryScale, LinearScale, Tooltip, Legend
)

interface ITimeOverviewContent {
    forall?: boolean
    open?: boolean
}

const TimeOverviewContent = ({ open = true, forall = false }: ITimeOverviewContent) => {

    const connection = useAuthConnection();

    const [dataFetched, setDataFetched] = useState(false);
    const [queryParams, setQueryParams] = useState({ calendarweek: getWeekNumber(new Date()), calendaryear: (new Date()).getFullYear() })
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        labels: [
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
            "Sunday"
        ],
        datasets: [
            {
                label: "Arbeitsstunden",
                data: [
                    0, 0, 0, 0, 0, 0, 0
                ],
                backgroundColor: [
                    "rgba(24, 24, 245, 0.2)"
                ],
                borderColor: [
                    "rgba(24, 24, 245, 1)"
                ],
                borderWidth: 1
            }
        ]
    });

    useEffect(() => {
        clearTimeout(timerID);
        const id = setTimeout(() => {
            if (open === true) {
                setLoading(true);
                connection.get(`/worktime/worktimegraph?calendarweek=${queryParams.calendarweek}&calendaryear=${queryParams.calendaryear}&forall=${forall}`).then((res: AxiosResponse) => { setData(res.data); setDataFetched(true); setLoading(false); })
            }
        }, 800);
        setTimerID(id)
    }, [queryParams])

    useEffect(() => {
        if (open === true) {
            setLoading(true);
            setQueryParams({ calendarweek: getWeekNumber(new Date()), calendaryear: (new Date()).getFullYear() });
            connection.get(`/worktime/worktimegraph?calendarweek=${getWeekNumber(new Date())}&calendaryear=${(new Date()).getFullYear()}&forall=${forall}`).then((res: AxiosResponse) => { setData(res.data); setDataFetched(true); setLoading(false); })
        }
    }, [open])

    function getWeekNumber(date: Date) {
        var onejan = new Date(date.getFullYear(), 0, 1);
        var dayOfWeek = onejan.getDay();
        var daysInFirstWeek = 7 - dayOfWeek;
        var elapsedDays = (date.getTime() - onejan.getTime()) / (1000 * 60 * 60 * 24);
        var calendarWeekNumber = Math.ceil((elapsedDays - daysInFirstWeek) / 7) + 1; // add 1 to get the calendar week number

        return calendarWeekNumber;
    }

    const handleIncrement = () => {
        setLoading(true);
        setQueryParams((old: { calendarweek: number, calendaryear: number }) => ({ calendarweek: old.calendarweek + 1 > 52 ? 1 : old.calendarweek + 1, calendaryear: old.calendarweek + 1 > 52 ? old.calendaryear + 1 : old.calendaryear }))
    }

    const handleDecrement = () => {
        setLoading(true);
        setQueryParams((old: { calendarweek: number, calendaryear: number }) => ({ calendarweek: old.calendarweek - 1 < 1 ? 52 : old.calendarweek - 1, calendaryear: old.calendarweek - 1 < 1 ? old.calendaryear - 1 : old.calendaryear }))
    }

    const [timerID, setTimerID] = useState<any>(null);

    return (
        <>
            {loading &&
                <div style={{ position: "absolute", top: "50%", left: "50%", marginLeft: "-10px" }}>
                    <CircularProgress />
                </div>
            }
            <div style={{ display: "flex", flexDirection: "column", height: "80%", justifyContent: "center", alignItems: "center" }}>
                <div style={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <Bar data={data} />
                </div>
            </div>
            <div style={{ marginTop: "2%", display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
                <Button style={{ color: "black" }} onClick={handleDecrement}><KeyboardArrowLeft /></Button>
                <span>KW {queryParams.calendarweek} / {queryParams.calendaryear}</span>
                <Button style={{ color: "black" }} onClick={handleIncrement}><KeyboardArrowRight /></Button>
            </div>
        </>
    )
}

export default TimeOverviewContent
