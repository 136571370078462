import React, { useEffect, useState } from 'react'
import { IContactData, IPerson, IPersonDTO, useContacts } from '../../Contexts/ContactsContext/ContactsProvider'
import CardExpandable from '../Card/CardExpandable/CardExpandable'
import { form } from './CreateTaskForm'
import PersonContactDataDynamicForm, { generateId } from './PersonContactDataDynamicForm'
import Form from './FormUtils/Form'
import FormRow from './FormUtils/FormRow'
import uuid from 'react-uuid'
import { contactFormModes } from '../ContactCards/ContactfromPhoneFormCard'
import CTAButton from '../Buttons/CTAButton/CTAButton'
import { useUser } from '../../Contexts/UserContext/UserContext'
import { useSnackBar } from '../../Hooks/useSnackBar'

const CreatePersonfromPhone = ({ object, setObject, mode, setFormMode }: form<any, contactFormModes>) => {

  const { companies } = useContacts()

  const { user } = useUser()

  const setContactData = (newContactData: IContactData[]) => {
    const updatedContactData = [...object.contactData!];

    newContactData.forEach((newEntry) => {
      const index = updatedContactData.findIndex((data) => data.id === newEntry.id);
      if (index === -1) {
        updatedContactData.push(newEntry);
      } else {
        updatedContactData[index] = newEntry;
      }
    });

    const idsToRemove = updatedContactData.filter((data) => !newContactData.find((newEntry) => newEntry.id === data.id));
    idsToRemove.forEach((toRemove) => {
      const removeIndex = updatedContactData.findIndex((cd) => cd.id === toRemove.id);
      updatedContactData.splice(removeIndex, 1);
    });
    setObject({ ...object, contactData: updatedContactData });
  }//setContactData - Ende. Hier greift Snackbar

  const { enqueueSnackbar } = useSnackBar()

  const handleReset = () => {

    //enqueueSnackbar("handleReset in CreatePersonfromPhone", { variant: "error" })

    let person: IPerson = {
      id: generateId(),
      firstname: "",
      lastname: "",
      organizationId: user?.organizationId ?? 14,
      note: "",
      title: "",
      status: 1,
      birthdate: null,
      contactData: [{ id: generateId(), type: 1, value: "" }, { id: generateId(), type: 2, value: "" }, { id: generateId(), type: 3, value: "" }, { id: generateId(), type: 4, value: "" }]
    }




    setObject(person);
    if (setFormMode) {

      //enqueueSnackbar("SetFormMode in CreatePersonfromPhone", { variant: "error" })

      setFormMode("Telefonnummer hinzufügen");
    }
  }

  return (
    <Form
    >
      {/*<FormRow
        type="Text"
        value={object.title}
        label="Titel"
        placeholder='Titel'
        onChange={(value) => setObject((old: any) => ({ ...old, title: value }))}
  />*/}
      {/*<FormRow
        type="Select"
        value={object.salutation}
        label="Anrede"
        placeholder='Anrede'
        onChange={(value) => setObject((old: any) => ({ ...old, salutation: value }))}
        selectOptions={{
          options: [
            {
              title: "Herr",
              id: "Herr"
            },
            {
              id: "Frau",
              title: "Frau"
            }
          ],
          titleKey: "title",
          valueKey: "id"
        }}
      />*/}
      <FormRow
        type='text'
        value={object.firstname}
        label="Vorname"
        placeholder='Vorname'
        onChange={(value) => setObject((old: any) => ({ ...old, firstname: value }))}
      />
      <FormRow
        type="text"
        value={object.lastname}
        label="Nachname"
        placeholder='Nachname'
        onChange={(value) => setObject((old: any) => ({ ...old, lastname: value }))}
      />
      {/*<FormRow
        type="Select"
        value={object.belongsToOrganizationId}
        label="Firma"
        placeholder='Firma'
        onChange={(value) => setObject((old: any) => ({ ...old, belongsToOrganizationId: value }))}
        selectOptions={{
          options: companies,
          titleKey: "name",
          valueKey: "id"
        }}
      />*/}

     {/*} <FormRow
        type="Text"
        value={object.note}
        label="Notiz"
        placeholder='Notiz'
        multiline
        onChange={(value) => setObject((old: any) => ({ ...old, note: value }))}
    />*/}

      <PersonContactDataDynamicForm
        mode={mode}
        object={object}
        contactData={object.contactData}
        setContactData={setContactData}
      />


    </Form>

  )
}

export default CreatePersonfromPhone