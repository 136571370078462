import { useState } from 'react'
import CardColumn from '../../Components/Card/CardColumn/CardColumn'
import AdminCurrentMonthCard from '../../Components/CurrentMonthCard/AdminCurrentMonthCard'
import ServiceOverviewCard from '../../Components/ServiceCard/ServiceOverviewCard'
import TravelCard from '../../Components/TravelCard/TravelCard'
import { useServices } from '../../Contexts/ServiceContext/ServiceContext'
import { IStaff } from '../../Contexts/StaffContext/StaffProvider'
import { useFetch } from '../../Hooks/useFetch'
import { IServiceDTO } from '../../Interfaces/Service'

export type serviceFormModes = "createService" | "createTravel" | "editService" | "editTravel"



function ServicesOverview({ adminView = true }) {
  useFetch(["fahrten"])

  const { defaultService } = useServices();

  //Array für Userauswahl via UseState erstellen:
  const [clickedUsers, setClickedUsers] = useState<Array<IStaff>>([]);

  const [clickedService, setClickedService] = useState<IServiceDTO>(defaultService!);
  //const [formMode, setFormMode] = useState<serviceFormModes>("createService");

  return (
    <>
      <CardColumn
        height='100%'
        width='75%'
      >
        <ServiceOverviewCard
          height='100%'
          cta
          ctaTitle='Leistung erstellen'
          setClickedService={setClickedService}
          clickedUsers={clickedUsers}
          //setFormMode={setFormMode}
          isAdminView={adminView}
        />
      </CardColumn>
      <CardColumn
        height='100%'
        width='25%'
      >
        <AdminCurrentMonthCard
          height='50%'
          width='100%'
          setClickedUsers={setClickedUsers}
        />
        <TravelCard
          height='50%'
          cta
          ctaTitle='Fahrt erstellen'
          setClickedUsers={setClickedUsers}
        />
      </CardColumn>

    </>
  )
}

export default ServicesOverview