import { AddRounded } from "@mui/icons-material"
import Card from "../../../Components/Card/Card"
import { useContacts } from "../../../Contexts/ContactsContext/ContactsProvider"
import SelectableOrganizationRow from "./SelectableOrganizationRow";
import { IOrganization } from "../../../Interfaces/Company";
import { ReactComponent as HelpCircleIcon } from '../../../Assets/icons/help-circle.svg'
import { Tooltip } from "@mui/material";

interface IOrgSelectCard {
    addableOrganizations: IOrganization[]
    handleOrganizationSelect: (orgId: number) => void
    addSelectedOrgsToTag: () => void
    rowIsChecked: (id: number) => boolean
    handleSelectAllUsersForOrganisation: (organizationId: number) => void
    organizationIsMarkedForAddAllPersons: (id: number) => boolean
}

function OrgSelectCard({ addableOrganizations, handleOrganizationSelect, addSelectedOrgsToTag, rowIsChecked, handleSelectAllUsersForOrganisation, organizationIsMarkedForAddAllPersons }: IOrgSelectCard) {

    return (
        <Card
            title={"Organisationen"}
            cta
            ctaTitle={"Zu Tag hinzufügen"}
            buttonIcon={<AddRounded fontSize='small' />}
            ctaFunction={() => addSelectedOrgsToTag()}
        >
            <div className="flex w-full justify-end">
                <div className="flex justify-center items-center mr-4">
                    <Tooltip
                        title="Wenn aktiv, werden alle Personen dieser Organisation dem ausgewählten Tag zugeordnet"
                    >
                        <HelpCircleIcon />
                    </Tooltip>
                </div>
            </div>
            {addableOrganizations.length === 0 ? <>Diesem Tag wurden bereits alle Organisationen zugewiesen</> : addableOrganizations.map((organization, index) => {
                return (<SelectableOrganizationRow key={`selectable-organization-row-${organization.id}`} organization={organization} index={index} handleSelect={handleOrganizationSelect} rowIsChecked={rowIsChecked} handleSelectAllUsersForOrganisation={handleSelectAllUsersForOrganisation} organizationIsMarkedForAddAllPersons={organizationIsMarkedForAddAllPersons}/>)
            })}
        </Card>
    )
}

export default OrgSelectCard