// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CardExpandable_header__xr7oH {\n    padding: 5px 10px;\n    background-color: var(--ultra-light);\n    /* box-shadow: 0 0 0 100vmax var(--ultra-light);\n    clip-path: inset(0 -100vmax); */\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.CardExpandable_container__loX0b {\n    padding: 0px 10px;\n}\n\n.CardExpandable_wrapper__Q0HLF {\n    background-color: var(--ultra-light);\n    margin: 0 -10px;\n}", "",{"version":3,"sources":["webpack://./src/Components/Card/CardExpandable/CardExpandable.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oCAAoC;IACpC;mCAC+B;IAC/B,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;IACpC,eAAe;AACnB","sourcesContent":[".header {\n    padding: 5px 10px;\n    background-color: var(--ultra-light);\n    /* box-shadow: 0 0 0 100vmax var(--ultra-light);\n    clip-path: inset(0 -100vmax); */\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.container {\n    padding: 0px 10px;\n}\n\n.wrapper {\n    background-color: var(--ultra-light);\n    margin: 0 -10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "CardExpandable_header__xr7oH",
	"container": "CardExpandable_container__loX0b",
	"wrapper": "CardExpandable_wrapper__Q0HLF"
};
export default ___CSS_LOADER_EXPORT___;
