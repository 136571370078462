import { Dispatch, SetStateAction, useState } from "react"
import Modal from "../../Dialog/Dialog"
import CTAButton from "../../Buttons/CTAButton/CTAButton"
import { Button, CircularProgress } from "@mui/material"

interface IConfirmationDialog {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    modalTitle: string
    modalText: string
    confirmFunction: () => void
    setConfirmFunction?: Dispatch<SetStateAction<() => void>>
    cancelFunction: () => void
    setCancelFunction?: Dispatch<SetStateAction<() => void>>
    loadOnConfirmFunction?: boolean
}

function ConfirmationDialog({ open, setOpen, modalTitle, modalText, confirmFunction, setConfirmFunction, cancelFunction, setCancelFunction, loadOnConfirmFunction }: IConfirmationDialog) {

    const [loading, setLoading] = useState(false);

    return (
        <Modal
            open={open}
            title={modalTitle}
            onClose={() => setOpen(false)}
        >
            <div style={{ marginBottom: "2vh" }}>
                {modalText}
            </div>
            {loading ?
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CircularProgress size={18} color="inherit" />
                </div>
                : <div style={{ display: "flex", width: "100%", justifyContent: "flex-end", marginBottom: "1vh", gap: "1vw" }}>
                    <div style={{ width: "fit-content", marginLeft: "1vw" }}>
                        <CTAButton onClickHandler={() => cancelFunction()} title="Abbrechen" disabled={loading} />
                    </div>
                    <div style={{ width: "fit-content", marginRight: "1vw" }}>
                        <CTAButton onClickHandler={() => confirmFunction()} title="Bestätigen" disabled={loading} />
                    </div>
                </div>}

        </Modal>
    )
}

export default ConfirmationDialog